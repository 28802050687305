const translations = {
  progress: {
    footer: {
      warning:
        'Please refrain from using DaVinci during AutoCut work to prevent potential issues.',
    },
    steps: {
      global: {
        starting: 'Starting...',
        processing: 'Processing...',
        ending: 'Wrapping up...',
      },
      dev: {
        ping: 'Pinging DaVinci Resolve...',
        pong: 'Ponging DaVinci Resolve...',
      },
      common: {
        getSilences: 'Identifying silent sections...',
        getAudioInfos: 'Gathering audio details...',
        readAudioFile: 'Loading audio file...',
        computeRMS: 'Analyzing audio file...',
        convertRMSToDB: 'Analyzing audio file...',
        exportDrt: 'Exporting timeline...',
        extractDrt: 'Extracting timeline...',
        getXmlTimelineFolderPath: 'Reading timeline...',
        getXmlTimelineFullpath: 'Reading timeline...',
        loadXmlTimeline: 'Reading timeline...',
        writeFile: 'Saving file...',
        writeXml: 'Importing updated timeline...',
        updateDrt: 'Importing updated timeline...',
        importXml: 'Importing updated timeline...',
      },
      podcast: {
        mainProcess: 'Editing podcast...',
        getVideoTracksWeight: 'Setting up video tracks...',
        getAudioTracks: 'Setting up audio tracks...',
        getPodcastInterval: 'Estimating podcast duration...',
        getPodcastDuration: 'Calculating podcast length...',
        renders: 'Rendering audio in DaVinci Resolve\n(may take a few minutes)',
        getTalkingTimelines: 'Allocating speaking times...',
        getTransitions: 'Planning camera transitions...',
        getCutTimecodes: 'Preparing cut points...',
        cutAtTimecodes: 'Executing cuts...',
      },
      silences: {
        mainProcess: 'Removing silences...',
        getTimelineInfos: 'Fetching timeline information...',
        renders: 'Rendering audio in DaVinci Resolve\n(may take a few minutes)',
        convertAudioToMono: 'Configuring Audio...',
        getCutTimecodes: 'Setting up cut points...',
        cutAtTimecodes: 'Performing cuts...',
        deleteSilentTrackItems: 'Deleting silent segments...',
      },
      swearWords: {
        mainProcess: 'Censuring profanities...',
        prepareTimeline: 'Preparing timeline...',
        getSwearWords: 'Analysing transcript...',
        cutAndApplyVolume: 'Applying volume filter...',
        applyBleeps: 'Inserting beeps...',
      },
      zoom: {
        mainProcess: 'Applying zoom effects...',
        getTimelineInfos: 'Fetching timeline information...',
        getWavData:
          'Rendering audio in DaVinci Resolve\n(may take a few minutes)',
        computeBestZoomIntervals: 'Calculating optimal zoom intervals...',
        createVideoTrack: 'Creating new video tracks...',
        appendAdjustmentLayers: 'Adding adjustment layers',
        createZoomFusionComps: 'Importing zoom components...',
      },
      captions: {
        transcript: {
          mainProcess: 'Generating transcript...',
          get_language: 'Detecting language...',
          renderWav:
            'Rendering audio in DaVinci Resolve (may take a few minutes)',
          split_chunks: 'Dividing transcript into segments...',
          uploadFile: 'Uploading file...',
          getTranscript:
            'Transcribing audio using AI (this may take a few minutes)',
          writeFile: 'Saving transcript...',
        },
        generation: {
          mainProcess: 'Adding captions...',
          initialization: 'Initializing captioning...',
          readTranscript: 'Loading transcript...',
          getTimelineInfos: 'Fetching timeline details...',
          appendAdjustmentLayer: 'Adding adjustment layer...',
          importFusionComp: 'Importing caption components...',
        },
      },
    },
  },
  launchManager: {
    title: 'Setup / Update',
    steps: {
      preload: {
        title: 'Loading',
      },
      update: {
        title: 'Checking for updates',
        modal: {
          title: 'AutoCut Updated',
          subtitle: 'AutoCut has been updated',
          body: 'We have updated AutoCut for an improved experience. Please restart AutoCut using the button below.',
          cta: 'Restart AutoCut',
        },
      },
      python: {
        title: 'Installing Python',
      },
      ffmpeg: {
        title: 'Installing FFMPEG',
      },
      resources: {
        title: 'Installing resources',
      },
      computeIPC: {
        title: 'Connecting to AI services',
      },
      daVinciIPC: {
        title: 'Launch AutoCut in DaVinci',
        subtitle: "Can't find AutoCut? Restart DaVinci",
        stillNot:
          "Still can't find it after restarting DaVinci & AutoCut?{br}Contact us at <copy><embedTranslation>globals_contactMail</embedTranslation></copy>",
        ppro: 'Want to use AutoCut with Premiere Pro? <a>Download here</a>',
      },
    },
    footer: {
      discord: 'Join our Discord community!',
      learnMore: 'Learn tips to maximize AutoCut features!',
    },
    errors: {
      compute: 'Cannot connect to AI services. Please contact support.',
      davinci:
        'Cannot connect to DaVinci Resolve. Ensure it is running and the AutoCut script is active.',
      cta: 'Try Again',
    },
  },
  homepage: {
    cards: {
      status: {
        trial: 'Free Trial',
        upgrade: 'Upgrade Now',
        coming_soon: 'Coming Soon!',
        version_not_supported: 'Unsupported DaVinci Resolve Version',
        upgrade_to: 'Upgrade to {plan}',
      },
    },
  },
  modes: {
    podcast: {
      title: 'Auto Podcast',
      steps: {
        select: {
          title: 'Choose a timeline with at least 2 video and audio tracks',
          label: 'Select Timeline',
          descriptions: {
            '1': 'Navigate to the Edit tab',
            '2': 'Open the timeline you want to edit',
          },
          cta: 'Select Active Timeline',
        },
        customization: {
          label: 'Podcast Settings',
          steps: {
            speakers: {
              title: 'Speakers',
              description:
                'Assign each speaker an audio track and name them. Use one track per speaker. For shared speakers, use both names (e.g., "Alice & Bob").',
              header: {
                audio: 'Audio Track',
                name: 'Speaker Name',
              },
              textInputPlaceholder: "Enter speaker's name...",
              trackTemplate: 'Track A{index}',
              cta: 'Add Speaker',
            },
            cameras: {
              title: 'Cameras',
              description:
                'Assign each camera a video track and select visible speakers. One track per camera, multiple speakers allowed.',
              header: {
                audio: 'Video Track',
                name: 'Speaker(s)',
              },
              speakerSelectPlaceholder: 'Select speaker(s)...',
              trackTemplate: 'Track V{index}',
              cta: 'Add Camera',
            },
            cameraTime: {
              title: 'Camera Duration',
              description:
                'Set the minimum and maximum duration a camera stays before switching.',
              presets: {
                title: 'Default Presets',
                items: {
                  calm: 'Calm',
                  paced: 'Paced',
                  energetic: 'Energetic',
                  hyperactive: 'Hyperactive',
                },
              },
              minimumCamTime: {
                title: 'Minimum Duration',
                description:
                  'Minimum time (seconds) a camera stays before switching.',
                unit: 'seconds',
              },
              maximumCamTime: {
                title: 'Maximum Duration',
                description:
                  'Maximum time (seconds) a camera stays before switching.',
                unit: 'seconds',
              },
            },
            silentVideoClips: {
              title: 'Handling Unused Clips',
              description:
                'Choose to remove or disable unused parts of the podcast.',
              items: {
                remove: 'Remove',
                disable: 'Disable',
              },
            },
          },
          cta: {
            disable: 'Start Podcast Editing - Disable Unused Clips',
            delete: 'Start Podcast Editing - Remove Unused Clips',
          },
        },
      },
      error: {
        validation: {
          minimumCamTime: {
            min: 'Minimum camera time must be at least 0 seconds',
            higherThanMax:
              'Minimum camera time must be less than maximum camera time',
          },
          maximumCamTime: {
            min: 'Maximum camera time must be at least 0 seconds',
          },
          deleteUnusedClips: {
            required: 'Select an option to remove or disable unused clips',
          },
          speakers: {
            videoTracks: {
              min: 'Select at least one video track for each speaker',
            },
            audioTrack: {
              min: 'Assign an audio track to each speaker',
            },
          },
        },
      },
    },
    silence: {
      title: 'AutoCut Silences',
      steps: {
        noiseLevel: {
          title: 'Noise Threshold',
          description:
            'Set the sound level to identify silences, visible below.',
        },
        silenceSize: {
          title: 'Silence Duration',
          items: {
            silence: {
              title: 'Remove Silences Longer Than',
              description: 'Minimum duration (ms) to remove silences.',
              unit: 'milliseconds',
            },
            talk: {
              title: 'Keep Talks Longer Than',
              description: 'Minimum duration (ms) to retain talk segments.',
              unit: 'milliseconds',
            },
          },
        },
        paddingSize: {
          title: 'Padding',
          items: {
            before: {
              title: 'Padding Before',
              description:
                'Silent time before noise to ensure smooth speech start.',
              unit: 'milliseconds',
            },
            after: {
              title: 'Padding After',
              description:
                'Silent time after noise to ensure smooth speech end.',
              unit: 'milliseconds',
            },
          },
        },
        transition: {
          params: {
            title: 'Transitions',
            warning: 'Transitions require silences to be removed.',
            default: {
              title: 'Default',
              choices: {
                none: 'None',
              },
            },
            j_l_cut: {
              title: 'J-Cut & L-Cut',
              helper:
                'Adds padding around cuts for smooth transitions and creates a new transition track.',
              choices: {
                j_cut: 'J-Cut',
                l_cut: 'L-Cut',
                both: 'Both',
              },
            },
            premiere_pro: {
              title: 'DaVinci Resolve',
              choices: {
                constant_power: 'Constant Power',
              },
            },
          },
        },
        preview: {
          title: 'Preview',
          description:
            'View retained and removed segments. Use arrows to navigate or center on the cursor position.',
          legend: {
            kept: 'Kept',
            deleted: 'Deleted',
            margin: 'Margins',
          },
          cta: 'Generate Preview',
          center: 'Center around cursor',
        },
      },
      error: {
        validation: {
          noiseLevel: {
            negative: 'Noise level must be a negative value',
            int: 'Noise level must be a number',
          },
          minTimeOfSilence: {
            required: 'Minimum silence duration is required',
            min: 'Minimum silence duration must be at least 0 ms',
          },
          minTimeOfTalk: {
            required: 'Minimum talk duration is required',
            min: 'Minimum talk duration must be at least 0 ms',
          },
          marginBefore: {
            min: 'Padding before must be at least 0 ms',
          },
          marginAfter: {
            min: 'Padding after must be at least 0 ms',
          },
        },
      },
      transition: {
        params: {
          title: 'Transitions',
          warning: 'Transitions require silences to be removed.',
          default: {
            title: 'Default',
            choices: {
              none: 'None',
            },
          },
          progress: {
            insert: 'Adding new audio tracks...',
            move: 'Adjusting audio for transitions...',
            handling: 'Applying transition: {transition}',
          },
        },
      },
    },
    swearWords: {
      title: 'AutoProfanity Filter',
      parameters: {
        bleepSoundSelector: {
          mute: 'Mute',
          helper: 'Select bleep sound effect',
        },
      },
      footerButton: 'Remove profanities',
      infoText:
        'Swear Words Bleeper, thanks to AI, analyzes your audio and automatically adds {br} bleeping sounds on top of profanities.',
    },
    zoom: {
      title: 'AutoZoom',
      parameters: {
        preview: {
          title: 'Zoom Preview',
          description: 'Set the focal point for zooming',
          maximumZoom: 'Maximum zoom level',
          errorMessage: 'Error generating preview',
        },
        zoomTypes: 'Zoom Styles',
        defaultsPresets: {
          title: 'Default Presets',
          calm: 'Calm',
          paced: 'Paced',
          energetic: 'Energetic',
          hyperactive: 'Hyperactive',
        },
        zoomCoverage: 'Total Zoom Coverage',
        constraint: {
          title: 'Limit Zoom to Individual Clips',
          helper:
            'If enabled, zoom effects won’t cross clip boundaries. If disabled, zoom spans the entire selection.',
        },
        maximumZoomDuration: 'Maximum Zoom Duration',
        smoothZooms: {
          title: 'Smooth Zooms',
          coverage: 'Smooth Zoom Coverage',
          nervousness: 'Zoom speed and intensity',
        },
        dynamicZooms: {
          title: 'Dynamic Zooms',
          coverage: 'Dynamic Zoom Coverage',
        },
      },
      types: {
        smooth: 'Smooth',
        dynamic: 'Dynamic',
      },
      footerButton: 'Apply Zoom',
      infoText:
        'AutoZoom automatically adds zoom effects based on audio levels.',
    },
    captions: {
      title: 'AutoCaptions',
      steps: {
        language: {
          button: 'Open Caption Editor (Style & Transcription)',
        },
        customization: {
          button: 'Add Captions',
          parts: {
            style: {
              title: 'Caption Style',
              modal: {
                title: 'Edit Style',
                apply: 'Apply Style',
                save: 'Apply and Save as Preset',
                text: {
                  title: 'Font',
                  font: 'Font Name',
                  help: 'How to add a new font family?',
                  color: 'Text Color',
                },
                animation: {
                  title: 'Animation',
                  wordBox: 'Text Box',
                },
                outline: {
                  title: 'Outline',
                  width: 'Outline Thickness',
                },
                box: {
                  title: 'Text Box',
                  paddingX: 'Horizontal Padding',
                  paddingY: 'Vertical Padding',
                  opacity: 'Box Opacity',
                  radius: 'Box Corner Radius',
                  disabled:
                    'Selected font does not support box feature for this language.',
                  tips: 'Choose a font that supports the selected language for this feature.',
                },
              },
            },
            preview: {
              title: 'Preview',
              modal: {
                title: 'Position & Size',
                apply: 'Apply Style',
                fontSize: 'Font Size',
                nbLines: 'Number of Lines',
                maxWidth: 'Maximum Width',
                help: 'Preview feature is under development and may not be accurate.',
              },
            },
            transcription: {
              title: 'Edit Transcript',
              modal: {
                save: 'Save',
                select: 'Select captions to edit',
                placeholder: 'Choose transcript segment to edit or customize',
                formatNumbers: 'Format Numbers',
                checkTranscript: {
                  title: 'Transcript Reviewed?',
                  description: "You haven't reviewed the transcript yet.",
                  dontShowAgain: "Don't remind me again",
                },
              },
              tooltips: {
                lowConfidence: 'Low confidence words',
                merge: 'Merge chunks',
                split: 'Split chunks',
              },
              emojis: {
                animated: 'Animated',
                static: 'Static',
              },
            },
            presets: {
              noPresetSelected: 'Select a preset to preview',
              noPreviewAvailable: 'No Preview Available',
              title: 'Presets',
              delete: {
                title: 'Delete Presets',
                cancel: 'Cancel',
                confirm: 'Delete Preset',
              },
              search: {
                title: 'Community Presets',
                input: 'Search Presets',
                cancel: 'Cancel',
                confirm: 'Import Preset',
                usage: 'Used by {count} users',
                error:
                  'Error copying preset. Contact us via Discord or at contact@autocut.fr',
              },
              create: {
                title: 'Create New Preset',
                placeholder: 'Preset Name (minimum two words)',
                community: 'Share with Community',
                public: 'Public',
                cancel: 'Cancel',
                confirm: 'Create Preset',
                alreadyExists:
                  'Preset already exists. Choose a different name.',
              },
            },
          },
        },
      },
      error: {
        validation: {
          userPresets: {
            missing:
              'User presets are missing. Reset your settings in the header menu.',
            titleTooShort: 'The title must be at least 5 characters long',
            titleTooLong: 'The title must be at most 25 characters long',
            titleNoSpace: 'The title must contain at least two words',
          },
          position: {
            xPercentage: {
              min: 'X position must be at least 0',
              max: 'X position must be at most 1',
            },
            yPercentage: {
              min: 'Y position must be at least 0',
              max: 'Y position must be at most 1',
            },
            emojiXPercentage: {
              min: 'Emoji X position must be at least 0',
              max: 'Emoji X position must be at most 1',
            },
            emojiYPercentage: {
              min: 'Emoji Y position must be at least 0',
              max: 'Emoji Y position must be at most 1',
            },
          },
          text: {
            fontSize: {
              int: 'Font size must be a number',
              min: 'Font size must be at least 1',
            },
            font: {
              missing:
                'Selected font is incomplete. Re-select it or choose another.',
            },
          },
          outline: {
            width: {
              int: 'Outline width must be a number',
              min: 'Outline width must be at least 0',
            },
          },
          formating: {
            nbLines: {
              int: 'Number of lines must be a number',
              min: 'Number of lines must be at least 1',
            },
            maxWidth: {
              int: 'Maximum width must be a number',
              min: 'Maximum width must be at least 0',
            },
          },
          textBox: {
            xPadding: {
              int: 'Horizontal padding must be a number',
              min: 'Horizontal padding must be at least 0',
            },
            yPadding: {
              int: 'Vertical padding must be a number',
              min: 'Vertical padding must be at least 0',
            },
            opacity: {
              int: 'Opacity must be a number',
              min: 'Opacity must be at least 0',
              max: 'Opacity cannot exceed 100',
            },
            radius: {
              int: 'Radius must be a number',
              min: 'Radius must be at least 0',
            },
          },
        },
      },
    },
    general: {
      steps: {
        select: {
          title: 'Set In/Out Points',
          descriptions: {
            '1': 'Without setting In/Out Points, AutoCut will apply to the entire timeline.',
            '2': 'Manually mute clips or tracks to exclude them from audio analysis.',
          },
          cta: 'Confirm In/Out Points',
        },
        transcription: {
          title: 'Select Audio Clip Language',
        },
        customization: {
          title: 'Customize Settings',
        },
      },
    },
  },
  auth: {
    login: {
      title: 'Enter License Key to Activate AutoCut',
      placeholder: 'Enter your license key',
    },
    trial: {
      title: 'Start Free Trial',
      steps: {
        email: {
          title: 'Enter Your Email Address',
          subtitle: 'Receive a trial license via email',
          placeholder: 'email@example.com',
          cta: 'Next',
        },
        sendingEmail: {
          title: 'Sending Email',
          success: 'Email successfully sent to {email}',
        },
      },
    },
    errors: {
      no_key: 'License key is required',
      is_key_valid_error: 'Key validation failed. Please try again later.',
      invalid_response: 'Received an unexpected response from the server.',
      cant_read_host_error: "Unable to retrieve the computer's name.",
      cant_read_uuid_error: "Unable to retrieve the computer's UUID.",
    },
  },
  modals: {
    usageStats: {
      title: 'Usage Statistics',
      select: {
        label: 'Choose a mode',
      },
      stats: {
        thisMonth: 'This Month',
        allTime: 'All Time',
        mostUsed: 'Most Used Feature',
      },
    },
    endProcess: {
      title: 'Process Completed',
      time: 'Completed in {minutes} minutes and {seconds} seconds!',
      timeSaved:
        'Time Saved: <b>{minutesSaved}</b> minutes and <b>{secondsSaved}</b> seconds',
      timeSavedAllTime:
        'Total Time Saved with AutoCut: {hourSaved} hours and {minutesSaved} minutes! 🎉',
      feedback: 'We value your feedback on the results:',
      review: 'Rate AutoCut!',
      captions: {
        title: 'Captions Added!',
        footer:
          'Need to adjust subtitles or transcription? Return to the Caption Editor.',
        button: 'Back to Transcript Editor',
        text_1: 'Animated captions have been added! 🎉',
        text_2: 'Edit captions if necessary:',
        list_1: 'Place the timeline cursor on the desired captions',
        list_2: 'Navigate to the Fusion page',
        list_3: 'Edit captions in the TextPlus node manually',
        text_3: 'For more tips, watch this <a>tutorial</a>.',
      },
      nextMode: {
        silence: {
          text: 'For smooth audio, use AutoCut Silences to eliminate awkward pauses effortlessly.',
          button: 'Go to AutoCut Silences',
        },
        podcast: {
          text: 'Enhance your podcast with AutoCut Podcast for streamlined editing and automatic camera transitions.',
          button: 'Go to AutoCut Podcast',
        },
        repeat: {
          text: 'Engage your audience! Use AutoCut Repeat to keep only the most engaging parts of your content.',
          button: 'Go to AutoCut Repeat',
        },
        zoom: {
          text: 'Bring your videos to life with AutoZoom! Add dynamic zoom effects that captivate your audience.',
          button: 'Go to AutoZoom',
        },
        caption: {
          text: 'Connect with viewers using AutoCaptions. Add stylish, synchronized subtitles to enhance storytelling.',
          button: 'Go to AutoCaptions',
        },
        resize: {
          text: 'Optimize your videos for any platform with AutoResize.',
          button: 'Go to AutoResize',
        },
        broll: {
          text: 'Enhance your narrative with AutoB-Roll, leveraging AI to seamlessly add stock footage.',
          button: 'Go to AutoB-Roll',
        },
        swear: {
          word: {
            text: 'Maintain professional dialogue with AutoProfanity Filter. Automatically censor unwanted language.',
            button: 'Go to AutoProfanity Filter',
          },
        },
      },
    },
    discord: {
      title: 'Discord',
    },
    unsupportedVersion: {
      title: 'Unsupported DaVinci Resolve Version',
      currentVersion: 'Detected DaVinci Resolve version: {version}',
      text: 'AutoCut requires DaVinci Resolve version 18.6 or higher.',
      update: 'Update at: <a>DaVinci Website</a>',
    },
  },
  errors: {
    modal: {
      title: {
        noSelectedAudioClip: 'No selected audio clip',
        noTimelineFound: 'No timeline found',
        subsequenceNotReachable: 'Subsequence Unreachable',
        unknown: 'Unknown Error Occurred',
        noSpace: 'Insufficient Storage Error',
        exportUnknown: 'Export Error in DaVinci Resolve',
        invalidInputData: 'Temporary file generation error',
        fileNotFound: 'File Read Error',
        scriptNotLoaded: 'AutoCut Script Load Error',
        ffmpegError: 'File read error.',
        exportCancelled: 'Export Canceled',
        timelineMuted: 'No Audible Tracks',
        noAudibleAudio: 'No Audible Audio Found',
        offline: 'Offline Status Detected',
        resourceTimeout: 'Resource Timeout',
        backupNotFound: 'Backup Not Found',
        setCursorOutInterval: 'Out of In/Out Points',
        emptyPath: 'Selection Analysis Error',
        sequenceMismatch: 'Operation Unable to Complete',
      },
      text: {
        noSelectedAudioClip:
          'No audio clip exist between your selected in and out points',
        noTimelineFound: 'No active sequence has been found',
        subsequenceNotReachable: "Can't reach selected subsequences.",
        unknown: 'AutoCut error with unknown cause.',
        noSpace: "Your device's storage is insufficient.",
        exportUnknown: 'Disk storage shortfall caused this error.',
        invalidInputData: 'Temporary file generation encountered an error.',
        fileNotFound: 'Verify {filename} is at location: {filepath}',
        scriptNotLoaded:
          'Close all other extensions, DaVinci Resolve and retry.',
        ffmpegError: '{filepath} file is possibly corrupted.',
        exportCancelled: 'Audio analysis stopped if encoding cancelled. Retry.',
        timelineMuted: 'Selected audio tracks are muted.',
        noAudibleAudio: 'Detectable audio is unavailable.',
        offline: 'Servers are unreachable.',
        resourceTimeout: 'Couldn’t download resources within 30 seconds',
        backupNotFound: "Can't find the backup sequence to restore.",
        setCursorOutInterval: 'Selected section is outside In/Out points.',
        emptyPath: 'Selected clips might not have paths on your computer.',
        sequenceMismatch: 'Avoid sequence changes while AutoCut processes.',
      },
      tips: {
        noSelectedAudioClip: 'Please correctly set up your in and out points',
        noTimelineFound: 'Please select a valid timeline',
        subsequenceNotReachable:
          'Ensure subsequences in the timeline share identical names with the original sequences in the project panel.',
        unknown:
          'Try modifying selection/settings. Persisting issues? Contact us.',
        noSpace: 'Free up space and relaunch AutoCut.',
        exportUnknown: 'Free up space and relaunch AutoCut.',
        invalidInputData:
          'Retry with same selection. Contact us with your file if issue persists.',
        fileNotFound:
          'Retry with same selection. Contact us with your file if issue persists',
        scriptNotLoaded: 'Persisting issue? Contact us.',
        ffmpegError: 'Persisting issue? Contact us.',
        exportCancelled: ' ',
        timelineMuted: 'Unmute at least one track.',
        noAudibleAudio:
          'Ensure clips are active and audible. Persisting issue? Contact us on Discord or at contact@autocut.fr.',
        offline: 'Check internet connection and retry.',
        resourceTimeout: 'Check internet connection and retry.',
        backupNotFound:
          'Check "AutoCut-Backup" bin at project root for backup presence.',
        setCursorOutInterval:
          'Remove In/Out points to access this transcript section.',
        noSoundDetected:
          "Detected no sound. Verify language matches clips, tracks aren't muted, and In/Out points include sound clips. {br}Persisting issue? Contact us on Discord or at contact@autocut.fr.",
        emptyPath: 'Ensure clips are atomic medias or subsequences.',
        sequenceMismatch:
          'Avoid sequence changes and modifications until AutoCut process completion.',
      },
    },
  },
  globals: {
    contactMail: 'contact@autocut.com',
    defaults: {
      components: {
        ProcessModal: {
          defaultStepName: 'Processing',
        },
        KebabMenu: {
          account: 'Manage License Key',
          usageStatistics: 'Usage Statistics',
          bug: 'Report a Bug',
          review: 'Review AutoCut',
          tuto: 'Watch Tutorial',
          logout: 'Logout',
          settings: 'Reset Settings',
          featureRequest: 'Request a Feature',
          academy: 'Help Center',
        },
        LicenseInfos: {
          timeLeft: '{daysLeft} days remaining',
          info: '{licenceType} License',
          trialButton: 'Learn More',
          paidButton: 'Manage License',
        },
        PromoBanner: {
          promo: 'Save up to 20% in the next 24 hours!',
          shop: 'Shop Now',
        },
        FileInput: {
          uploadScreenshot: 'Upload Issue Screenshot',
        },
        LinkToUserFileInput: {
          sendLogLinkToUserFile: {
            title: 'Provide a link to your video & audio file.',
            description:
              '(Recommended: SwissTransfer: https://www.swisstransfer.com/)',
            helper:
              "To upload via Swisstransfer, visit swisstransfer.com, click 'Upload Files', obtain a link, and paste it here.",
            end: 'Necessary for troubleshooting and resolution.',
          },
        },
        Select: {
          'no-more': 'No more options',
          'select-all': 'Select All',
          placeholder: 'Choose an option',
        },
        PreviewFrame: {
          previewOnIndicator: 'Preview Centered on Indicator',
        },
        FontNotFoundModal: {
          title: 'Font not found',
          description:
            'The font was not found on your system, please select a new font to add captions.',
          help: 'You can add a new font family by following this tutorial.',
        },
      },
    },
    misc: {
      retry: 'Retry',
    },
    units: {
      second: 'Seconds',
      millisecond: 'Milliseconds',
      percentage: '%',
      px: 'Pixels',
    },
  },
  old: {
    text: {
      errorSelection:
        'Invalid selection.{br}Select tracks directly from audio/video files.{br}If a sequence is selected, split the videos within it.',
      loading: 'Executing cuts...',
      Step4: {
        AutoZoom: {
          ratio: 'Maximum Zoom Coefficient',
          step: 'Percentage of Zoomed Video',
        },
        Title: 'AutoZoom (Beta)',
      },
      Step5: {
        Backup: 'Activate Backup',
        Title: 'Preview',
      },
      autocutv2:
        'AutoCut v2 offers advanced options for optimal silence detection.',
      autocutAi:
        'AutoCut AI automatically detects silences with minimal setup.',
      autocutPodcast:
        'AutoCut Podcast quickly edits podcast videos based on your camera setup.',
      autocutRepeat:
        'AutoCut Repeat removes bad takes, keeping only the best parts.',
      autocutCaptions:
        'AutoCaptions quickly adds styled subtitles to your videos.',
      Step3: {
        Remove: 'Remove',
        Keep: 'Keep',
        CenterButton: 'Center on Indicator',
        Previsualisation: {
          __text__: 'Preview:',
          loading: 'Loading...',
        },
        Noise: {
          __text__: 'Noise Level',
          helper:
            'Set sound level to classify silence. Changes appear in the preview below.',
          unit: 'Decibels',
        },
        DeleteSilence: 'Delete Silences',
        KeepSilence: {
          Yes: 'Yes',
          No: 'No',
        },
        Title: 'Adjust Noise Level',
      },
      Step2: {
        After: 'Padding After',
        Before: 'Padding Before',
        Title: 'Padding',
      },
      Step2Ai: {
        Title: 'Verify Preview',
      },
      Step1: {
        Talks: 'Keep Talks Longer Than',
        Silences: 'Delete Silences Longer Than',
        Title: 'Silence Durations',
      },
      Step1Ai: {
        Silences: 'Select AI Aggressiveness',
        Title: 'AI Aggressiveness',
      },
      Step1Podcast: {
        Title: 'Speakers',
      },
      step6: {
        selected: {
          clips: 'Selected Clips',
        },
      },
      NoInternet: 'An internet connection is required.',
      noClip: {
        title: 'Select clips to cut in your timeline',
        no_selection:
          "If AutoCut isn't responding, try restarting DaVinci Resolve.",
        tuto: {
          __text__: 'Need help? Watch our',
          link: ' video tutorial',
        },
        loading: {
          bar: 'Loading - Analyzing sequence...',
        },
      },
      needPProUpdate: 'DaVinci Resolve must be version 15 or later.',
      No_Key: 'Enter a license key',
      Login: {
        SubmitButton: 'Activate',
        SubmitButton2: 'Activate',
        PasteButton: 'Paste',
      },
      Welcome: {
        Title: 'Welcome',
        freeTrial: 'Start Free Trial',
        UseLicence: 'Use a License Key',
        BuyLicence: 'Purchase a License Key',
      },
      UseLicence: {
        Title: 'Use a License Key',
        Text: 'Enter your license key to start using AutoCut!',
      },
      GetLicence: {
        Title: 'Get a Free Trial License via Email',
        Select: 'How did you find AutoCut?',
        Text: 'Enter your email to receive a free trial license',
        Button: 'Request Free License',
      },
      UseNewLicence: {
        Title: 'License Key Sent to {s}!',
        Text: 'Check your email, enter the license key, and start using AutoCut. If not received, check spam folder.',
        Resend: "Didn't receive the email?",
        ResendButton: 'Resend to {mail}',
        ResendSucces: 'Email sent successfully!',
      },
      NoFFMPEG: 'FFMPEG error occurred. Contact support.',
      legend: {
        kept: 'Kept',
        deleted: 'Deleted',
        margin: 'Margins',
      },
      yes: 'Yes',
      no: 'No',
      undo: {
        modal: 'Not happy with the results?',
      },
      since: {
        your: {
          last: {
            visit: 'Since your last visit',
          },
        },
      },
      not: {
        supported: {
          modal: {
            corrupted: 'Unsupported file format: {filename}',
            speed: 'Unsupported clip speed. Use default speed.',
            multi: {
              audio: 'All audio tracks must be selected in a file.',
              stream: 'File contains multiple audio streams.',
            },
            '51_stereo': '5.1 audio detected in stereo by DaVinci Resolve.',
            audio: {
              layout: 'Unsupported audio layout in file.',
            },
            remote: {
              file: "File {filename} isn't accessible.",
            },
          },
        },
        maintained: {
          modal: 'Update to a newer DaVinci Resolve version to use AutoCut.',
        },
      },
      end: {
        not: {
          supported: {
            modal: {
              corrupted: 'Choose different clips for cutting.',
              speed: 'Reset clip speeds or choose different clips.',
              multi: {
                audio:
                  'Ensure default DaVinci Resolve audio order for all tracks.',
                stream: 'Choose different clips for cutting.',
              },
              '51_stereo':
                'Select different clips or adjust sequence channels.',
              audio: {
                layout:
                  'Select different clips or provide a sequence screenshot if the issue persists.',
              },
              remote: {
                file: 'Ensure files are stored locally, not on the cloud.',
              },
            },
          },
        },
      },
      use: {
        paid: {
          licence: 'Enter your license key to activate AutoCut.',
        },
      },
      no_audios: 'Audio will be analyzed to detect and remove silences.',
      step1: {
        podcast: {
          title: 'Camera Display Durations',
          minimum: 'Minimum Camera Time',
          maximum: 'Maximum Camera Time',
        },
      },
      silences: {
        __text__: 'Silences',
        step: {
          delete: {
            __text__: 'Remove {mode}',
            keep: {
              spaces: 'Remove {mode}, keep spaces',
            },
          },
          keep: 'Keep {mode}',
          mute: 'Mute {mode}',
        },
      },
      repetitions: 'Repetitions',
      podcast: {
        add: {
          speaker: 'Add Speaker',
          camera: 'Add Camera',
        },
        track: 'Track',
        camera: {
          title: 'Cameras',
          helper:
            'Specify speakers visible per camera. Multiple speakers allowed per camera.',
        },
        speaker: 'Speaker',
        all: {
          speakers: 'All Speakers',
        },
      },
    },
    help: {
      bug: 'Report a Bug',
      review: 'Rate AutoCut',
      multipiste: 'Need multitrack help? Watch our tutorial',
      tuto: 'Watch Tutorial',
      academy: 'Help Center',
      buy: 'Buy License Key',
      account: 'Manage License Key',
      logout: 'Logout',
      reset: {
        settings: 'Restore Settings',
      },
      transcription: {
        provider: {
          step: 'Transcription quality varies by chosen model.',
        },
      },
    },
    bugBtnDialog: 'Email support at: contact@autocut.com',
    first: {
      launch: {
        error: 'Startup error during AutoCut launch. Please restart.',
      },
    },
    helper: {
      Step4: {
        '1': 'Enable AutoZoom to apply zoom effects to clips.',
        '2': 'Zoom coefficient based on specified values and clip volume.',
        '3': 'Select desired zoom percentage.',
      },
      Step5: 'Backup allows you to undo all changes made after cutting.',
      Step1: {
        '1': 'Silence duration for removal (seconds).',
        '2': 'Talk segment duration for retention (seconds).',
        podcast: {
          minium: 'Minimum display time before camera switch (seconds).',
          maximum: 'Maximum display time before camera switch (seconds).',
        },
      },
      Step1Ai: 'Select aggressiveness level (1-4) for AI silence removal.',
      Step2: {
        '1': 'Silence duration before talk.',
        '2': 'Silence duration after talk.',
      },
      Step3: {
        '1': 'Set sound level to classify silences. Preview changes below.',
        '2': 'Review kept and deleted segments. Use arrows to navigate; center on cursor.',
        '3': 'Silences will be cut without being removed.',
      },
      Save: {
        Default: 'Saves settings and loads them on next startup.',
      },
      Undo: {
        Cut: 'Reverts the last cut, restoring the previous state of the clip.',
      },
      speakers: {
        podcast: {
          __text__: 'Add and name speakers, assigning their audio tracks.',
          audio: {
            tracks:
              'Select the audio track for each speaker. One track per speaker.',
          },
          video: {
            tracks:
              'Select the video tracks for each speaker. Multiple speakers per camera allowed.',
          },
        },
      },
    },
    warn: {
      Step5: 'Caution: Disabling backup prevents undo.',
    },
    title: {
      Step5: {
        Backup: 'Activate Backup',
      },
      undo: {
        modal: 'Done!',
      },
      not: {
        supported: {
          modal: {
            corrupted: 'A clip format is unsupported',
            speed: 'Clips with default speed only',
            multi: {
              audio: 'AutoCut may struggle with multi-audio tracks',
              stream: 'Multiple audio streams in a clip',
            },
            '51_stereo': '5.1 audio layout displayed in stereo',
            audio: {
              layout: 'Unsupported clip audio layout',
            },
            remote: {
              file: 'Clip absent from device',
            },
          },
        },
        maintained: {
          modal:
            'AutoCut updates unavailable for pre-2021 DaVinci Resolve versions.',
        },
      },
      use: {
        trial: {
          licence: 'Review email and input license key',
        },
        paid: {
          licence: 'Activate License Key',
        },
      },
      no_audios: 'No audio selected',
      banner: {
        podcast: {
          beta: 'Thank you for testing Podcast mode.',
        },
      },
      silences: {
        step: 'Manage {mode}',
      },
      podcast: {
        silences: {
          step: 'Remove/Mute Silences',
        },
      },
    },
    generate: {
      preview: 'Create a Preview',
    },
    silence: {
      link: {
        tuto: 'https://www.youtube.com/watch?v=7MEK3rZQDHI',
      },
      button: {
        delete: {
          j_cut: 'J-Cut & Delete Silences',
          l_cut: 'L-Cut & Delete Silences',
          both: 'J&L-Cut & Delete Silences',
          constant: {
            power: 'Cut, Delete Silences & Apply Audio Transition',
          },
          none: 'Cut & Delete {mode}',
          keep: {
            spaces: {
              none: 'Cut {mode} & Keep Spaces',
            },
          },
        },
        keep: {
          none: 'Cut & Retain {mode}',
        },
        mute: {
          none: 'Cut & Mute {mode}',
        },
      },
    },
    podcast: {
      link: {
        tuto: 'https://youtu.be/EgsUlSLsK8Y?si=BHwFFuuLjw8DsHYX',
      },
      getting: {
        tracks: 'Retrieving tracks...',
        silences: 'Retrieving silences...',
      },
      edit: {
        track: 'Editing Track {indexTrackInfo}: {current} out of {max}',
      },
      final: {
        message: {
          __text__: 'Success! Podcast edited successfully',
          error: 'Podcast editing error encountered',
        },
      },
      button: {
        disable: 'Edit & Mute Unused Clips',
        delete: 'Edit & Remove Unused Clips',
      },
      not: {
        available: {
          title: 'Choose a sequence with 2+ video and audio tracks',
        },
      },
      minimum: {
        higher: {
          maximum: 'Minimum camera time should be less than the maximum.',
        },
      },
      empty: {
        audio: {
          track: 'Audio tracks cannot be empty.',
        },
        video: {
          track: 'Video tracks cannot be empty.',
        },
      },
      preset: {
        title: 'Default Presets',
        calm: 'Calm',
        paced: 'Paced',
        energetic: 'Energetic',
        hyperactive: 'Hyperactive',
      },
    },
    error: {
      UseLicence: {
        __text__: 'Unlink keys from other devices through the',
        link: 'AutoCut client portal.',
      },
      modal: {
        title: {
          subsequenceNotReachable: 'Subsequence Unreachable',
          unknown: 'Unknown Error Occurred',
          noSpace: 'Insufficient Storage Error',
          exportUnknown: 'Export Error in DaVinci Resolve',
          invalidInputData: 'Temporary file generation error',
          fileNotFound: 'File Read Error',
          scriptNotLoaded: 'AutoCut Script Load Error',
          ffmpegError: 'File read error.',
          exportCancelled: 'Export Canceled',
          timelineMuted: 'No Audible Tracks',
          noAudibleAudio: 'No Audible Audio Found',
          offline: 'Offline Status Detected',
          resourceTimeout: 'Resource Timeout',
          backupNotFound: 'Backup Not Found',
          setCursorOutInterval: 'Out of In/Out Points',
          emptyPath: 'Selection Analysis Error',
          sequenceMismatch: 'Operation Unable to Complete',
        },
        text: {
          subsequenceNotReachable: "Can't reach selected subsequences.",
          unknown: 'AutoCut error with unknown cause.',
          noSpace: "Your device's storage is insufficient.",
          exportUnknown: 'Disk storage shortfall caused this error.',
          invalidInputData: 'Temporary file generation encountered an error.',
          fileNotFound: 'Verify {filename} is at location: {filepath}',
          scriptNotLoaded:
            'Close all other extensions, DaVinci Resolve and retry.',
          ffmpegError: '{filepath} file is possibly corrupted.',
          exportCancelled:
            'Audio analysis stopped if encoding cancelled. Retry.',
          timelineMuted: 'Selected audio tracks are muted.',
          noAudibleAudio: 'Detectable audio is unavailable.',
          offline: 'Servers are unreachable.',
          resourceTimeout: 'Couldn’t download resources within 30 seconds',
          backupNotFound: "Can't find the backup sequence to restore.",
          setCursorOutInterval: 'Selected section is outside In/Out points.',
          emptyPath: 'Selected clips might not have paths on your computer.',
          sequenceMismatch: 'Avoid sequence changes while AutoCut processes.',
        },
        tips: {
          subsequenceNotReachable:
            'Ensure subsequences in the timeline share identical names with the original sequences in the project panel.',
          unknown:
            'Try modifying selection/settings. Persisting issues? Contact us.',
          noSpace: 'Free up space and relaunch AutoCut.',
          exportUnknown: 'Free up space and relaunch AutoCut.',
          invalidInputData:
            'Retry with same selection. Contact us with your file if issue persists.',
          fileNotFound:
            'Retry with same selection. Contact us with your file if issue persists',
          scriptNotLoaded: 'Persisting issue? Contact us.',
          ffmpegError: 'Persisting issue? Contact us.',
          exportCancelled: ' ',
          timelineMuted: 'Unmute at least one track.',
          noAudibleAudio:
            'Ensure clips are active and audible. Persisting issue? Contact us on Discord or at contact@autocut.fr.',
          offline: 'Check internet connection and retry.',
          resourceTimeout: 'Check internet connection and retry.',
          backupNotFound:
            'Check "AutoCut-Backup" bin at project root for backup presence.',
          setCursorOutInterval:
            'Remove In/Out points to access this transcript section.',
          noSoundDetected:
            "Detected no sound. Verify language matches clips, tracks aren't muted, and In/Out points include sound clips. {br}Persisting issue? Contact us on Discord or at contact@autocut.fr.",
          emptyPath: 'Ensure clips are atomic medias or subsequences.',
          sequenceMismatch:
            'Avoid sequence changes and modifications until AutoCut process completion.',
        },
        lost: {
          cep: {
            title: 'Connection Lost Error',
            text: {
              '1': 'Connection with DaVinci Resolve lost. Restart extension.',
              '2': 'Failure? Restart DaVinci Resolve.',
            },
          },
        },
      },
      language: {
        not: {
          supported: 'Language support unavailable for this mode.',
        },
      },
    },
    retour: "Key doesn't exist!",
    countdown: {
      days: 'Trial Days Left',
    },
    saveas: {
      default: {
        settings: 'Save Current Parameters',
      },
    },
    button: {
      no_silences: 'No Silence Found',
      no_audios: 'Selected Audio Clip Absent',
      SavingBackup: 'Backing Up Project...',
      RestoringBackup: 'Restoring Project...',
      ExportingAudio: 'Exporting Audio...',
      UploadingAudio: 'Uploading Audio...',
      SearchingSilences: 'Searching for Silences...',
      CuttingStep: {
        Audio:
          'Performing Audio Cuts on Track {numTrack} : {current} out of {max}',
        Video:
          'Performing Video Cuts on Track {numTrack} : {current} out of {max}',
      },
      PostCuttingStep: 'Cuts Completed...',
      PreDeletingStep: 'Searching for Silence Clips to Delete...',
      PostDeletingStep: 'Silences Removed...',
      finalizing: 'Finalizing...',
      DeletingStart: 'Deleting Silences...',
      AssociatingStep: 'Conducting Association {current} out of {max}',
      Success: 'Process Successful!',
      Failure: 'Failure... Encountered Issue',
      NoSilences: 'No Silences Detected',
      undo: {
        modal: 'Reverse',
      },
      not: {
        supported: {
          modal: 'Close',
        },
      },
      PreZoomingStep: 'Obtaining Sub-Clips for Zoom...',
      ZoomingStep: 'Adding Zoom : {current} out of {max}',
      Autozoom: 'Preparing AutoZoom...',
      no_video: {
        to_zoom: 'No Video to Zoom.',
      },
      getSubclip: {
        step: 'Retrieving Sub-Clip for AutoZoom...',
      },
      checkSilenceSuppression: {
        step: 'Verifying Silence Removal...',
      },
      back: 'Back',
      DeletingStep: {
        delete: {
          Audio:
            'Silence Deletion on Audio Track {numTrack}: {current} out of {max}',
          Video:
            'Silence Deletion on Video Track {numTrack}: {current} out of {max}',
        },
        mute: {
          Audio:
            'Muting Silence on Audio Track {numTrack}: {current} out of {max}',
          Video:
            'Muting Silence on Video Track {numTrack}: {current} out of {max}',
        },
      },
      skip: 'Skip',
      save: 'Save',
    },
    warning: {
      DontTouchTimeline: 'Avoid Editing Timeline During Process',
      undo: {
        modal: 'Detected missing metadata in audio file analysis.',
      },
      modal: {
        title: {
          ffmpeg: 'Potential FFMPEG Issue',
        },
        text: {
          ffmpeg: 'Detected missing metadata in file {filename}.',
        },
        tips: {
          ffmpeg: 'Consider re-encoding the file if issues persist.',
        },
      },
      beta: {
        version:
          'Beta DaVinci Resolve version detected. Expect potential AutoCut issues. Testing on recent stable DaVinci Resolve version is recommended.',
      },
    },
    preview: {
      loading: {
        selection: {
          validation: 'Validating selection...',
          parsing: {
            step: 'Retrieving data: clip {current} of {max}...',
          },
        },
        silence: {
          detection: {
            step: 'Detecting silence: clip {current} of {max}...',
          },
        },
        drawing: 'Generating preview...',
      },
      frame: {
        no_sequence: 'Start a sequence to view a preview',
      },
    },
    contact: {
      us_title: 'Sorry for the inconvenience',
      us_text: {
        '2': 'For non-editing related issues (account, key, payment, etc.):',
        __text__:
          "If AutoCut isn't working correctly and you'd like to report the issue:",
      },
      us_button: 'Report Issue',
      us_contact: 'Contact Us:',
      us_copy: 'Contact information copied!',
    },
    switch: {
      to_send: {
        log: {
          button: 'Alternatively, send a log file',
        },
      },
    },
    send: {
      log: {
        title: 'We Apologize for the Issue',
        details: 'This log file shows how your media is processed.',
        select: 'Choose a daily log file to help resolve the issue.',
        text: {
          area: 'Describe your issue in detail:',
        },
        button: 'Submit Log File',
        success: {
          title: 'File Received! We will Investigate.',
        },
        thank: {
          text: 'Continue your editing.',
          text2: 'We will update you once resolved.',
        },
        message: {
          error: 'Message must be at least 10 characters',
        },
        screen: {
          timeline: {
            __text__: 'Include a screenshot of your DaVinci Resolve timeline.',
            before: 'Include a screenshot before cutting.',
            after: 'Include a screenshot after cutting.',
          },
          error: {
            message: 'Include a screenshot of the error message.',
          },
        },
        trial: {
          mail: 'Provide the email associated with the trial.',
        },
        other: 'Provide any additional information to help resolve the issue.',
      },
    },
    select: {
      issue: {
        title: 'Select Your Issue Below.',
        subtitle: 'This helps us resolve your issue quickly.',
      },
      one: {
        audios: 'Select one or more audio clips to use AutoCut.',
      },
    },
    upload: {
      screenshot: 'Submit a Screenshot',
    },
    modal: {
      feedback: {
        title: 'Feedback',
        input: {
          feedback: {
            title: 'Your Thoughts on AutoCut?',
            placeholder: 'Enter your feedback...',
          },
          improve: {
            title: 'Suggestions for Improvement?',
            placeholder: 'Enter your suggestions...',
          },
        },
        button: {
          text: 'Submit',
        },
        sent: {
          title: 'Thank You for Your Feedback!',
          text: 'Have feature ideas? Let us know!',
          button: {
            text: 'Request Feature',
          },
        },
      },
      bug: {
        report: {
          title: 'Bug Report',
          description: {
            placeholder: 'Describe the issue...',
            error: 'Bug description must be at least 20 characters.',
          },
          reproduce: {
            placeholder: 'Steps to reproduce the issue...',
            error: 'Reproduction details must be at least 20 characters.',
          },
          blocking: 'Is this a blocking issue?',
          button: {
            text: {
              __text__: 'Submit Bug Report',
              success: 'Thank you for your report!',
            },
          },
          link: {
            error: 'Provide a valid file link.',
          },
        },
      },
    },
    issue: {
      undo: {
        modal: 'Encountered issues with cuts?',
      },
    },
    star: {
      review: {
        title: 'Leave an AutoCut Review!',
        captions: {
          title: 'Rate AutoCaptions!',
        },
        text: 'Share your experience with AutoCut!',
      },
    },
    review: {
      title: 'Provide More Details',
      text: 'Happy with the cuts? Give feedback to help improve AutoCut!',
      form: {
        placeholder: 'Enter your feedback...',
      },
      button: 'Submit',
      thank: {
        title: 'Thank You for Your Feedback!',
        text: 'Continue with your editing.',
      },
    },
    exchange: {
      title: 'Get 7 Extra Trial Days!',
      text: 'Rate AutoCut on Adobe Exchange to receive 7 additional trial days!',
      button: 'Rate AutoCut on Adobe Exchange',
      info: "Installed directly from our site? No need to reinstall. Just click 'Free' to start rating.",
    },
    licence: {
      trial: 'Trial',
      paid: 'Paid',
      info: '{licenceType} License',
      time: {
        left: '{daysLeft} Days Remaining',
      },
    },
    trial: {
      promo: {
        text: 'Save up to 20% in 24 Hours',
      },
      resend: {
        error: 'Error resending trial key. Please try again later.',
      },
      expired: {
        modal: {
          title: 'Trial Expired.',
          text1: 'Your trial has expired. Subscribe to continue using AutoCut.',
          text2: 'Visit your AutoCut dashboard at:',
          text3: 'Subscribe for a license key.',
          text4: 'For support, contact: {supportEmail}',
          button: 'Subscribe at 20% Discount',
        },
      },
      title: 'Free Trial',
      stepper: {
        onboarding: 'Help Us Improve AutoCut',
        email: 'Enter Your Email',
        sending: {
          email: 'Sending Email',
        },
        login: 'Enter License Key',
      },
      onboarding: {
        subtitle: 'Share insights to help develop new DaVinci Resolve features',
        discover: 'How did you find AutoCut?',
        job: {
          status: 'Current Job Status',
        },
        type: {
          creation: 'Types of DaVinci Resolve Projects',
        },
      },
      sending: {
        email: {
          success: 'Email sent successfully to {email}',
        },
      },
      task: {
        captions: 'Use AutoCaptions: <link>Learn More</link>',
        podcast: 'Use AutoCut Podcast: <link>Learn More</link>',
        zoom: 'Use AutoZoom: <link>Learn More</link>',
        repeat: 'Use AutoCut Repeat: <link>Learn More</link>',
        broll: 'Use AutoB-Roll: <link>Learn More</link>',
        swear: {
          word: 'Use AutoProfanity Filter: <link>Learn More</link>',
        },
        preset: 'Create a new preset for AutoCaptions, AutoZoom, or AutoResize',
        testimonial:
          'Leave a Testimonial (using the same email): <link>Give Testimonial</link>',
      },
    },
    shop: {
      now: 'Shop Now',
    },
    back: {
      to_autocut: 'Return to AutoCut',
    },
    'discover-option-youtube-video': 'YouTube Video',
    'discover-option-youtube-comment': 'YouTube Comment',
    'discover-option-reddit': 'Reddit',
    'discover-option-google': 'Google',
    'discover-option-facebook': 'Facebook',
    'discover-option-friend': 'Friend',
    'discover-option-school': 'School',
    'discover-option-other': 'Other',
    link: {
      not: {
        supported: {
          modal: {
            __text__: 'For more information and solutions, visit ',
            no_solution: 'For error clarification, see ',
            end: ', our documentation and tutorials site',
          },
        },
      },
    },
    text1: {
      use: {
        trial: {
          licence: 'An email has been sent to ({email})',
        },
      },
    },
    text2: {
      use: {
        trial: {
          licence:
            'Check your spam or promotions folder if the email is missing.',
        },
      },
    },
    placeholder: {
      use: {
        licence: 'Enter Your License Key',
      },
    },
    "can't": {
      reach: {
        server: {
          error: 'Cannot reach the server. Please try again later.',
        },
      },
      read: {
        uuid: {
          error: "Cannot read the computer's UUID.",
        },
        host: {
          error: "Cannot read the computer's name.",
        },
      },
    },
    asking: {
      key: {
        error: 'Error checking key with server. Please try again later.',
      },
    },
    sending: {
      log: {
        file: {
          error: 'Failed to send daily log. Please try again later.',
        },
      },
    },
    is_key: {
      valid: {
        error: 'Error validating key. Please try again later.',
      },
    },
    base: {
      error: {
        message: 'An error occurred ',
      },
    },
    invalid: {
      response: {
        error: 'Received an unexpected response from the server.',
      },
    },
    description: {
      banner: {
        podcast: {
          beta: 'In development; for issues or suggestions, contact us at contact@autocut.com',
        },
      },
    },
    header: {
      trial: {
        button: 'Learn More',
      },
      paid: {
        button: 'Manage',
      },
      speakers: {
        name: 'Speaker Name',
        track: "Speaker's Audio Track",
      },
      cameras: {
        name: {
          __text__: 'Camera Video Track',
          helper:
            'Select the video track for each camera, one track per camera.',
        },
        speakers: {
          __text__: 'Visible Speaker(s)',
          helper:
            'Select which speakers are visible on this camera. Multiple speakers allowed.',
        },
      },
    },
    game: {
      saved: {
        hours: 'Saved {hourSavedText} hour(s),',
      },
      have: {
        a_break: 'Take a well-deserved break!',
      },
    },
    onboarding: {
      skip: 'Skip Onboarding',
      close: 'Close',
      previous: 'Previous',
      next: 'Next ({current}/{max})',
      onboarding: {
        step1: {
          title: 'Welcome to AutoCut!',
          body: "During your first use, AutoCut will guide you through various features for seamless editing. Let's get started!",
        },
        step2: {
          title: 'Free Trial Key',
          body: 'Explore all features with a trial key, valid for 14 days.',
        },
        step3: {
          title: 'License Key',
          body: 'Enter your licensed key (trial or paid) to access all AutoCut tools.',
        },
        step4: {
          title: 'Purchase a License Key',
          body: 'Buy a license key via the button, which redirects you to our site with purchase details.',
        },
      },
      modeSelection: {
        step1: {
          title: 'AutoCut Modes',
          body: 'Choose a mode that fits your editing needs from the available options.',
        },
        step2: {
          title: 'Need Help?',
          body: 'Hover over interface elements to see tooltips and learn more.',
        },
      },
      legacy: {
        step1: {
          title: 'AutoCut V2',
          body: 'AutoCut V2 detects silences based on audio noise levels. Initial setup is required.',
        },
        step2: {
          title: 'Noise Level',
          body: 'Use the slider to set the minimum noise level classified as silence.',
        },
        step3: {
          title: 'Next Steps',
          body: 'Navigate through the steps using the arrows and explore more AutoCut features.',
        },
        step4: {
          title: 'Generating Preview',
          body: 'Export audio before processing. Click "Generate Preview" to visualize audio cuts.',
        },
        step6: {
          title: 'Time to Cut',
          body: 'Review the preview and proceed with the cuts if satisfied.',
        },
        step7: {
          title: 'Time to Cut',
          body: 'Select your preferred cutting options.',
        },
        step8: {
          title: 'Time to Cut',
          body: 'Relax and let AutoCut handle the editing!',
        },
      },
      podcast: {
        step1: {
          title: 'AutoCut Podcast',
          body: 'Quickly edit your podcast videos with AutoCut Podcast.',
        },
        step2: {
          title: 'Speakers',
          body: 'Add speakers using the "Add Speaker" button. Assign one track per speaker.',
        },
        step3: {
          title: 'Speakers',
          body: "Configure each speaker's setup as needed.",
        },
        step4: {
          title: "Speaker's Name",
          body: 'Assign a name to each speaker for identification.',
        },
        step5: {
          title: 'Video Track Selection',
          body: 'Select the video tracks that display each speaker.',
        },
        step6: {
          title: 'Audio Track Selection',
          body: 'Assign an audio track to each speaker. Only one track per speaker.',
        },
        step7: {
          title: 'Edit Time',
          body: 'Define speakers and proceed with editing.',
        },
        step8: {
          title: 'Edit Time',
          body: 'Use the arrow to select editing options.',
        },
        step9: {
          title: 'Edit Time',
          body: 'Relax and let AutoCut manage the editing process.',
        },
      },
      title: 'Welcome to AutoCut',
      subtitle: 'Your Ultimate DaVinci Resolve Extension',
    },
    start: {
      transcription: 'Start Transcription',
    },
    no_more: {
      credits: 'Credits Exhausted',
    },
    file: {
      too: {
        long: 'Audio file is too long',
      },
      sent: 'File uploaded, awaiting transcription...',
    },
    please: {
      wait1: 'Awaiting audio transcription...',
      wait2: 'This process may take a few minutes, depending on length.',
      wait3: 'Uploading file:',
    },
    language: {
      selection: {
        step: {
          title: {
            __text__: 'Audio Language',
            helper: 'Specify the language of the audio clip',
          },
        },
        french: {
          __text__: 'French',
          beta: 'French (Beta)',
        },
        danish: 'Danish',
        dutch: 'Dutch',
        english: {
          __text__: 'English',
          united: {
            states: 'English - United States',
          },
        },
        flemish: 'Flemish',
        german: {
          __text__: 'German',
          beta: 'German (Beta)',
        },
        hindi: {
          __text__: 'Hindi',
          beta: 'Hindi (Beta)',
        },
        italian: 'Italian',
        japanese: 'Japanese',
        korean: 'Korean',
        norwegian: {
          __text__: 'Norwegian',
          nynorsk: 'Norwegian Nynorsk',
        },
        polish: 'Polish',
        portuguese: {
          __text__: 'Portuguese',
          brazil: 'Portuguese - Brazil',
          'brazil—beta': 'Portuguese - Brazil (Beta)',
        },
        'portuguese—beta': 'Portuguese (Beta)',
        spanish: {
          __text__: 'Spanish',
          latin: {
            america: 'Spanish - Latin America',
            'america—beta': 'Spanish - Latin America (Beta)',
          },
        },
        'spanish—beta': 'Spanish (Beta)',
        swedish: 'Swedish',
        tamil: 'Tamil',
        chinese: 'Chinese',
        russian: 'Russian',
        turkish: 'Turkish',
        catalan: 'Catalan',
        arabic: {
          __text__: 'Arabic',
          algeria: 'Arabic - Algeria',
          bahrain: 'Arabic - Bahrain',
          egypt: 'Arabic - Egypt',
          iraq: 'Arabic - Iraq',
          israel: 'Arabic - Israel',
          jordan: 'Arabic - Jordan',
          kuwait: 'Arabic - Kuwait',
          lebanon: 'Arabic - Lebanon',
          mauritania: 'Arabic - Mauritania',
          morocco: 'Arabic - Morocco',
          oman: 'Arabic - Oman',
          qatar: 'Arabic - Qatar',
          saudi: {
            arabia: 'Arabic - Saudi Arabia',
          },
          palestine: 'Arabic - Palestine',
          syria: 'Arabic - Syria',
          tunisia: 'Arabic - Tunisia',
          united: {
            arab: {
              emirates: 'Arabic - United Arab Emirates',
            },
          },
          yemen: 'Arabic - Yemen',
        },
        indonesian: 'Indonesian',
        finnish: 'Finnish',
        vietnamese: 'Vietnamese',
        hebrew: 'Hebrew',
        ukrainian: 'Ukrainian',
        greek: 'Greek',
        malay: 'Malay',
        czech: 'Czech',
        romanian: 'Romanian',
        hungarian: 'Hungarian',
        thai: 'Thai',
        urdu: 'Urdu',
        croatian: 'Croatian',
        bulgarian: 'Bulgarian',
        lithuanian: 'Lithuanian',
        latin: 'Latin',
        maori: 'Maori',
        malayalam: 'Malayalam',
        welsh: 'Welsh',
        slovak: 'Slovak',
        telugu: 'Telugu',
        persian: 'Persian',
        latvian: 'Latvian',
        bengali: 'Bengali',
        serbian: 'Serbian',
        azerbaijani: 'Azerbaijani',
        slovenian: 'Slovenian',
        kannada: 'Kannada',
        estonian: 'Estonian',
        macedonian: 'Macedonian',
        breton: 'Breton',
        basque: 'Basque',
        icelandic: 'Icelandic',
        armenian: 'Armenian',
        nepali: 'Nepali',
        mongolian: 'Mongolian',
        bosnian: 'Bosnian',
        kazakh: 'Kazakh',
        albanian: 'Albanian',
        swahili: 'Swahili',
        galician: 'Galician',
        marathi: 'Marathi',
        punjabi: 'Punjabi',
        sinhala: 'Sinhala',
        khmer: 'Khmer',
        shona: 'Shona',
        yoruba: 'Yoruba',
        somali: 'Somali',
        afrikaans: 'Afrikaans',
        occitan: 'Occitan',
        georgian: 'Georgian',
        belarusian: 'Belarusian',
        tajik: 'Tajik',
        sindhi: 'Sindhi',
        gujarati: 'Gujarati',
        amharic: 'Amharic',
        yiddish: 'Yiddish',
        lao: 'Lao',
        uzbek: 'Uzbek',
        faroese: 'Faroese',
        haitian: {
          creole: 'Haitian Creole',
        },
        pashto: 'Pashto',
        turkmen: 'Turkmen',
        maltese: 'Maltese',
        sanskrit: 'Sanskrit',
        luxembourgish: 'Luxembourgish',
        burmese: 'Burmese',
        tibetan: 'Tibetan',
        tagalog: 'Tagalog',
        malagasy: 'Malagasy',
        assamese: 'Assamese',
        tatar: 'Tatar',
        hawaiian: 'Hawaiian',
        lingala: 'Lingala',
        hausa: 'Hausa',
        bashkir: 'Bashkir',
        javanese: 'Javanese',
        sundanese: 'Sundanese',
      },
    },
    big: {
      sequence: {
        info: {
          message:
            'Your sequence has many clips! It may take longer to load. Select only necessary clips and cut them in a separate sequence.',
        },
      },
    },
    homepage: {
      title: 'Homepage',
      comming: {
        soon: 'Coming Soon!',
      },
    },
    autozoom: {
      dynamism: {
        __text__: 'Dynamism',
        normal: 'Normal',
        high: 'High',
        extreme: 'Extreme',
        custom: 'Custom:',
      },
      button: {
        __text__: 'Apply Zoom',
        message: {
          step1: 'Preparing AutoZoom...',
          step2: 'Extracting Audio Data...',
          step3: 'Calculating Zoom Intervals...',
          step4: 'Applying Zoom {current} of {max}...',
        },
      },
      success: 'Success! Zoom effects applied successfully.',
      info: {
        text: 'AutoZoom adds dynamic zoom effects to selected clips based on audio levels.',
      },
      invalid: {
        selection: {
          screen: {
            title: 'Select audio and video clips in your timeline',
            text1: 'Select timeline clips to apply AutoZoom.',
          },
        },
      },
      anchor: {
        title: 'Set Anchor Point',
        helper:
          'The anchor point is where the zoom focuses. Colored boxes show maximum and minimum zoom effects.',
      },
      dual: {
        range: {
          title: 'Define Zoom Coefficient Range',
          helper:
            "Zoom levels are based on these values and the clip's maximum volume.",
        },
      },
      preview: {
        on_indicator: 'Preview Centered on Indicator',
        error: {
          message: 'Error loading preview',
          button: 'Retry',
        },
      },
      min: {
        zoom: {
          title: 'Minimum Zoom Duration',
          helper: 'Zoom effects will not be shorter than this duration.',
        },
      },
      max: {
        zoom: {
          title: 'Maximum Zoom Duration',
          helper: 'Zoom effects will not exceed this duration.',
        },
      },
      constraint: {
        zoom: {
          title: 'Limit Zoom to Clips',
          helper:
            'If enabled, zoom effects are confined to individual clips. If disabled, they span the entire selection.',
        },
      },
      zoom: {
        types: {
          __text__: 'Zoom Styles',
          helper:
            'Choose between Smooth (gradual zoom) and Dynamic (immediate zoom).',
        },
        coverage: {
          __text__: 'Total Zoom Coverage',
          helper: 'Percentage of zoom applied.',
        },
      },
      smooth: {
        __text__: 'Smooth',
        zoom: {
          __text__: 'Smooth Zooms',
          coverage: 'Smooth Zoom Coverage',
          nervousness: {
            __text__: 'Nervousness',
            helper:
              'Lower values for slower, gentler zooms; higher values for faster, intense zooms.',
          },
        },
      },
      dynamic: {
        __text__: 'Dynamic',
        zoom: {
          __text__: 'Dynamic Zooms',
          coverage: 'Dynamic Zoom Coverage',
        },
      },
    },
    zoom: {
      preset: {
        title: 'Default Presets',
        calm: 'Calm',
        paced: 'Paced',
        energetic: 'Energetic',
        hyperactive: 'Hyperactive',
      },
    },
    repeat: {
      info: {
        banner:
          'AutoCut Repeat removes repetitive segments. Use the scissors to toggle between cutting or keeping parts for precision.',
      },
      text: {
        step: {
          edit: {
            transcript: 'Fix Transcript',
          },
        },
      },
      invalid: {
        selection: {
          screen: {
            title:
              'Select a timeline audio (and optional video) clip for AutoCut Repeat.',
            text1:
              'Select a timeline audio (and optional video) clip for AutoCut Repeat.',
          },
        },
      },
      select: {
        algorithm: 'Choose Algorithm',
      },
      precise: {
        algorithm: {
          info: 'Our new efficient algorithm processes approximately one minute of video per minute.',
        },
      },
      fast: {
        algorithm: 'Fast',
      },
      toggle: {
        cut: {
          button: {
            text: 'Toggle Cut',
          },
        },
      },
      play: {
        button: {
          text: 'Play',
        },
      },
    },
    captions: {
      step: {
        customization: 'Customize Captions',
        script: 'Select Clips and Language',
        transcription: 'Edit Transcript',
      },
      script: {
        transcript: {
          part: 'Transcript',
        },
        sequence: {
          part: 'Sequence',
          name: 'Name',
          track: {
            clip: {
              unit: {
                __text__: '{nbClips} Clips | {from} → {to}',
                detail: '{clipName} | {from} → {to}',
              },
            },
          },
        },
      },
      transcript: {
        action: 'Add Captions',
        coming: {
          soon: 'Caption Editor coming soon! Currently, edit captions via the Essential Graphics panel. Select captions in the timeline and modify in the panel edit tab.',
        },
        transcription: {
          part: 'Transcription',
        },
        info: 'Welcome to the Caption Editor – optionally edit captions directly in the DaVinci Resolve timeline after adding them.',
        add: {
          emojis: {
            cta: 'Create Emojis',
          },
        },
      },
      text: {
        noClip: {
          title: 'Select Clips for Subtitles',
          no_selection:
            'If AutoCut is unresponsive, try restarting DaVinci Resolve.',
        },
      },
      process: {
        transcript: {
          button: 'Process',
        },
      },
      customization: {
        info: 'AutoCaption is in beta and expected to stabilize by November 10th.',
        back: {
          button: 'Delete Transcript and Return',
        },
        example: {
          __text__:
            'Preview <opacity50><i>Drag to adjust caption/emoji position</i></opacity50>',
          text: 'AutoCaption adds subtitles visible in the preview. Customize options within AutoCaptions; edit transcription if needed. Captions are instantly added to the timeline.',
        },
        animation: {
          __text__: 'Animation',
          helper: 'Control text animation effects',
          color: {
            __text__: 'Highlight with <color>Audio</color>',
            text: {
              color: 'Text Color',
            },
            before: {
              highlight: {
                color: 'Pre-Highlight Color',
              },
            },
            after: {
              highlight: {
                color: 'Post-Highlight Color',
              },
            },
          },
          advanced: {
            color: {
              options: 'Advanced Color Options',
            },
          },
          word: {
            box: 'Text with <color>Box</color>',
          },
          bold: {
            with: {
              text: 'Text is <b>Bold</b>',
            },
          },
          floating: {
            text: 'Text is Floating',
          },
        },
        transition: {
          __text__: 'Transition',
          helper: 'Manage caption transitions; duration may not match example.',
          effect: {
            zoomIn: 'Zoom-In',
            blurIn: 'Blur-In',
          },
        },
        outline: {
          __text__: 'Outline',
          helper: 'Manage outline styles',
          color: 'Outline Color',
          width: 'Outline Width',
        },
        font: {
          __text__: 'Font',
          helper: 'Manage font styles',
          font: 'Font Name',
          help: 'How to add a new font family?',
          size: 'Font Size',
          weight: 'Font Weight',
          color: 'Font Color',
        },
        formating: {
          __text__: 'Text Formatting',
          helper: 'Control text formatting options',
          ponctuation: 'Remove Punctuation',
          uppercase: 'Uppercase',
          bold: 'Bold',
          italic: 'Italic',
          glow: {
            __text__: 'Glow',
            color: 'Glow Color',
            intensity: 'Glow Intensity',
          },
        },
        transform: {
          __text__: 'Transform',
          x: 'X Position',
          y: 'Y Position',
          helper: 'Adjust caption position and rotation',
          position: {
            __text__: 'Vertical Position',
            emoji: 'Emoji Vertical Position',
            info: 'Move text in the preview',
          },
          rotation: 'Rotation',
        },
        multiple: {
          lines: 'Multiple Lines',
        },
        box: {
          __text__: 'Boxes',
          helper: 'Styling options for text boxes',
        },
        boxes: {
          padding: {
            x: 'Horizontal Padding',
            y: 'Vertical Padding',
          },
          opacity: 'Box Opacity',
          radius: 'Corner Radius',
          color: 'Box Color',
        },
        emojis: {
          __text__: 'Emojis',
          helper: 'AI-generated emojis',
          size: 'Emoji Size',
        },
        shadow: 'Shadow',
        transcript: 'Transcript',
        action: 'Advance',
        select: {
          chunk: 'Choose transcript segment to edit or customize',
        },
        delete: {
          caption: {
            modal: {
              title: 'Confirm Caption Removal',
              cancel: 'No, Keep It',
              confirm: 'Yes, Delete It',
            },
          },
        },
        get: {
          presets: {
            number: {
              error:
                'Error retrieving presets count. Contact us via Discord or at contact@autocut.com',
            },
            error:
              'Error retrieving presets. Contact us via Discord or at contact@autocut.com',
          },
          public: {
            presets: {
              error:
                'Error retrieving public presets. Contact us via Discord or at contact@autocut.com',
            },
          },
        },
        presets: {
          title: 'Presets',
          save: {
            new: {
              preset: 'Save Current Settings as Preset',
            },
          },
          view: {
            more: 'View More',
            less: 'View Less',
          },
        },
        save: {
          preset: {
            info: {
              text: 'Save these settings as a preset for future use!',
            },
          },
        },
      },
      editor: {
        title: 'Caption Editor',
        description: {
          title: 'Select Captions to Edit',
        },
        chunk: {
          low: {
            confidence: {
              tooltip: 'Low confidence words',
            },
          },
          merge: {
            tooltip: 'Merge Chunks',
          },
          split: {
            tooltip: 'Split Chunks',
          },
        },
        sound: {
          play: 'Enable Sound Playback',
        },
      },
      fontSelect: {
        notFoundFontModal: {
          title: 'Font Missing',
          message:
            'The font <i><b>{fontLabel}</b></i> is unavailable. Default font will be used.',
          helper: 'Add new fonts using this tutorial.',
        },
      },
    },
    version: {
      not: {
        supported: 'Unsupported Version',
      },
    },
    transcript: {
      usage: {
        unit: 'Minutes',
      },
      upgrade: {
        button: 'Upgrade to AI for {mode}',
      },
      too: {
        short: {
          error:
            'Transcript may be too short or language unsupported. If the issue persists, contact us at contact@autocut.com.',
        },
      },
    },
    feature: {
      request: {
        __text__: 'Feature Request',
        form: {
          placeholder:
            'Describe your feature request for better consideration.',
        },
        screenshot: 'Attach screenshots or videos for clarity:',
        button: 'Submit Feature Request',
        success: {
          title: 'Submitted! 🥳',
          body: 'Thank you for helping AutoCut improve! We will review your request promptly.',
        },
        error: {
          title: 'Oops, something went wrong... 🤕',
          body: "We're sorry for the inconvenience. Please try again later or contact us at contact@autocut.com.",
        },
      },
    },
    with: {
      emojis: 'With Emojis',
    },
    resize: {
      invalid: {
        selection: {
          screen: {
            title: 'Set In & Out Points in Your Timeline',
            text1: 'To use AutoResize, set In & Out Points in your timeline.',
            cta: 'Set In & Out Points for Entire Sequence',
          },
        },
      },
      text: {
        info: 'AutoResize automatically adjusts and reframes your sequence for optimal social media formats.',
      },
      in_out: {
        step: {
          title: 'In/Out Points',
          helper: 'Ensure In & Out Points match the desired section.',
        },
      },
      preset: {
        step: {
          title: 'Presets',
          helper:
            'Choose a preset to resize your sequence or create a custom one. View dimensions below.',
        },
        undefined: 'Select a preset.',
      },
      watermark: {
        step: {
          title: 'Watermark',
          helper:
            'Add a watermark to your sequence in the bottom right corner.',
          text: 'Upload Your Watermark',
        },
      },
      button: 'Generate Social Clip',
      reframe: {
        undefined: 'Select "Follow Subject" speed.',
        step: {
          title: 'Follow Subject',
          helper: 'Choose the speed for the camera to follow the subject.',
          slower: 'Slow',
          default: 'Normal',
          faster: 'Fast',
        },
      },
      backup: 'Backing Up Your Sequence...',
      create: {
        new: {
          sequence: 'Creating New Sequence...',
        },
      },
      resizing: {
        sequence: 'Resizing New Sequence...',
      },
      adding: {
        watermark: 'Adding Watermark...',
      },
      cleaning: {
        sequence: 'Cleaning Sequence...',
      },
    },
    broll: {
      invalid: {
        selection: {
          screen: {
            title: 'Select Base Clips for B-Roll',
            text1: 'For AutoB-Roll, select at least one audio-inclusive clip.',
          },
        },
      },
      text: {
        info: 'AutoB-Roll uses AI to analyze audio and automatically add relevant Pexels B-Rolls.',
      },
      duration: {
        step: {
          title: 'B-Roll Durations',
          minimum: {
            title: 'Minimum B-Roll Duration',
            helper: 'Minimum duration for each B-Roll.',
          },
          maximum: {
            title: 'Maximum B-Roll Duration',
            helper: 'Maximum duration for each B-Roll.',
          },
        },
      },
      choice: {
        step: {
          title: 'B-Roll Selection',
        },
      },
      no_broll: {
        screen: {
          title: 'Transcript processed, but no B-Roll found.',
          text1: 'To use AutoB-Roll, select an audio clip with speech.',
          text2:
            'Check transcript language and ensure audio tracks are not muted.',
        },
      },
      button: {
        __text__: 'Add B-Rolls',
        next: 'Next',
      },
      exporting: 'Exporting Selected Audio Clips...',
      analyse: 'Analyzing Audio...',
      getting: {
        broll: 'Fetching B-Rolls... (may take a few minutes)',
      },
      transcript: {
        finished: 'Transcript Generated',
      },
      step: {
        parameters: 'B-Roll Settings',
        language: 'Select Clips and Language',
        brolls: 'Edit B-Roll Selection',
      },
      adding: {
        broll: 'Adding B-Rolls...',
      },
      restore: 'Restoring Your Sequence...',
      backup: 'Backing Up Your Sequence...',
      credit: {
        modal: {
          title: 'B-Roll Credits:',
          provided: {
            by: 'Provided by Pexels',
          },
          link: 'Link',
          button: 'Copy to Clipboard',
        },
      },
      search: {
        modal: {
          title: 'Select B-Roll',
          input: {
            placeholder: 'Search for B-Rolls',
          },
          searching: 'Searching for B-Rolls...',
        },
      },
    },
    discordCta: {
      title: 'Discord',
    },
    double: {
      click: {
        to_edit: 'Double Click to Edit',
      },
    },
    swear: {
      words: {
        invalid: {
          selection: {
            screen: {
              title: 'Select Clips for Profanity Filtering',
              text1: 'Select a clip with audio for AutoProfanity Filter.',
            },
          },
        },
        text: {
          info: 'AutoProfanity Filter uses AI to detect and automatically censor profanities with bleep sounds.',
        },
        mute: 'Mute',
        button: 'Remove Profanities',
        audio: {
          export: 'Exporting Audio...',
          analyse: 'Analyzing Audio...',
          insert: 'Inserting Bleeps...',
          filter: 'Applying Volume Adjustments...',
        },
        script: {
          transcript: {
            part: 'Transcript',
          },
        },
      },
    },
    bleep: {
      selection: {
        step: {
          title: 'Select bleep sound effect',
          helper: 'Select the beep sound effect',
        },
      },
    },
    create: {
      format: {
        modal: {
          title: 'Create New Format',
          button: {
            __text__: 'Create',
            disabled: 'Name Taken',
          },
          name: 'Name',
          width: 'Width',
          height: 'Height',
        },
      },
    },
    process: {
      modal: {
        warning:
          'Please avoid making changes in DaVinci Resolve to prevent issues.',
        generating: {
          emojis: 'Creating emojis from transcription...',
        },
        formatting: {
          numbers: 'Formatting numbers in transcription...',
        },
      },
    },
    backup: {
      modal: {
        title: 'AutoCut Backup',
        text: {
          '1': 'Backups occur before processing. Find them in the "AutoCut-Backup" folder.',
          '2': 'Use them as needed.',
        },
      },
    },
    confirm: {
      modal: {
        title: 'Are You Sure?',
        confirm: {
          label: 'Confirm',
        },
        cancel: {
          label: 'Cancel',
        },
      },
    },
    input: {
      speaker: {
        name: {
          placeholder: "Enter Speaker's Name...",
        },
      },
    },
    login: {
      resend: {
        email: "Didn't receive it? Resend email to {trialEmail}",
      },
    },
    typeofcreation: {
      option: {
        interview: 'Interviews',
        podcast: 'Podcasts',
        youtube: 'YouTube Videos',
        short: 'TikTok/Shorts/Reels',
        internal: 'Internal Videos',
        other: 'Other',
      },
    },
    jobstatus: {
      option: {
        content: {
          creator: 'Content Creator',
        },
        video: {
          editor: {
            professional: 'Professional Video Editor',
            semi: {
              professional: 'Semi-Professional Video Editor',
            },
            beginner: 'Beginner Video Editor',
          },
        },
        student: 'Student',
        other: 'Other',
      },
    },
    progress: {
      captions: {
        modal: {
          message: {
            initialization: 'Setting Up Captions...',
          },
        },
      },
    },
    stat: {
      undo: {
        modal: 'Great job! Completed in {minutes} min {seconds} sec.',
      },
    },
    cancelCutModal: {
      timesaved: {
        this: {
          cut: 'Time Saved: <b>{minutesSaved} {minutesSaved, plural, one {minute} other {minutes}}</b> and <b>{secondsSaved} {secondsSaved, plural, one {second} other {seconds}}</b>.',
        },
        all: {
          cuts: 'Total Time Saved: A massive <b>{hourSaved} {hourSaved, plural, one {hour} other {hours}}</b> and <b>{minutesSaved} {minutesSaved, plural, one {minute} other {minutes}}</b>! 🎉',
        },
      },
    },
    selected: {
      clips: {
        helper:
          'Deactivate audio tracks from silence detection to keep background music or game sounds. To leave silences untouched in specific clips, don’t select those clips.',
        video: 'Selected Video Tracks:',
        audio: 'Selected Audio Tracks:',
      },
    },
    free: {
      trial: {
        usage: {
          exceeded: {
            title: 'Free Trial Limit Reached',
            cta: 'Subscribe Now',
            text: 'Exceeded 1000 AI feature minutes.{br}Subscribe to continue using AutoCut AI.',
          },
        },
      },
    },
    paid: {
      usage: {
        exceeded: {
          title: 'Transcription Limit Reached',
          text: 'Exceeded 6000 AI feature minutes.{br}Contact us for assistance.',
        },
      },
    },
    validation: {
      error: {
        ai_transitions:
          'Select a valid transition type (See <a><b><embedTranslation>text_transition_step_title</embedTranslation></b></a>).',
        ai_silences:
          'Select a valid silence treatment option (See <a><b><embedTranslation>title_silences_step</embedTranslation></b></a>).',
        broll: {
          min: {
            max: {
              duration:
                'Maximum B-Roll time must be greater than minimum (See <a><b><embedTranslation>broll_duration_step_title</embedTranslation></b></a>).',
            },
          },
          minimumBRollTime:
            'Minimum B-Roll time must be positive (See <a><b><embedTranslation>broll_duration_step_minimum_title</embedTranslation></b></a>).',
          maximumBRollTime:
            'Maximum B-Roll time must be positive (See <a><b><embedTranslation>broll_duration_step_maximum_title</embedTranslation></b></a>).',
        },
        caption: {
          position:
            'Captions must stay within the preview area (See <a><b><embedTranslation>captions_customization_transform_position</embedTranslation></b></a>).',
          emoji: {
            position:
              'Emojis must stay within the preview area (See <a><b><embedTranslation>captions_customization_transform_position_emoji</embedTranslation></b></a>).',
          },
          text: {
            fontSize:
              'Font size must be a positive number (See <a><b><embedTranslation>captions_customization_font_size</embedTranslation></b></a>).',
          },
          outline: {
            width:
              'Outline width must be a positive number (See <a><b><embedTranslation>captions_customization_outline_width</embedTranslation></b></a>).',
          },
          transition: {
            effects:
              'Choose a valid caption transition effect (See <a><b><embedTranslation>captions_customization_transition</embedTranslation></b></a>).',
          },
          emojis: {
            size: 'Emoji size must be positive (See <a><b><embedTranslation>captions_customization_font_size</embedTranslation></b></a>).',
          },
          formating: {
            nbLines:
              'At least one line must be selected for formatting (See <a><b><embedTranslation>captions_customization_formating_nbLines</embedTranslation></b></a>).',
          },
          title: {
            too: {
              short: 'Title must be at least 5 characters.',
              long: 'Title cannot exceed 25 characters.',
            },
            no_space:
              'Title must contain at least two words separated by space.',
          },
        },
        font: {
          fontFamily:
            'Font family is required (See <a><b><embedTranslation>captions_customization_font_font</embedTranslation></b></a>).',
          label:
            'Select a font (See <a><b><embedTranslation>captions_customization_font_font</embedTranslation></b></a>).',
        },
        languageOfTranscription:
          'Select a transcription language (See <a><b><embedTranslation>language_selection_step_title_helper</embedTranslation></b></a>).',
        cameras: {
          speakerIds:
            'Each camera must have at least one assigned speaker (See <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
          label:
            'Each camera must have a video track assigned (See <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
        },
        podcast: {
          minimum: {
            cam: {
              time: 'Minimum camera time must be positive (See <a><b><embedTranslation>text_step1_podcast_minimum</embedTranslation></b></a>).',
            },
          },
          maximum: {
            cam: {
              time: 'Maximum camera time must be positive (See <a><b><embedTranslation>text_step1_podcast_maximum</embedTranslation></b></a>).',
            },
          },
          deleteUnusedClips:
            'Choose whether to remove or mute silences (See <a><b><embedTranslation>title_podcast_silences_step</embedTranslation></b></a>).',
          speakers: {
            cameras:
              'Each speaker must be assigned to one camera (See <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
            audio:
              'Each speaker must have an assigned audio track (See <a><b><embedTranslation>header_speakers_track</embedTranslation></b></a>).',
          },
          cameras: {
            __text__:
              'Each camera must have its own track (See <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
            length:
              'At least two separate cameras are required (See <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
          },
        },
        resize: {
          autoReframePreset: {
            required:
              'Auto Reframe Preset is required (See <a><b><embedTranslation>resize_reframe_step_title</embedTranslation></b></a>).',
            invalid:
              'Preset options: slower, default, or faster (See <a><b><embedTranslation>resize_reframe_step_title</embedTranslation></b></a>).',
          },
          watermark:
            'Valid watermark formats: JPEG, PNG, or WebP. (See <a><b><embedTranslation>resize_watermark_step_title</embedTranslation></b></a>).',
          preset: {
            name: 'Preset name is required.',
            width: 'Width must be at least 1px.',
            height: 'Height must be at least 1px.',
          },
        },
        rgb: 'RGB values must be between 0 and 255.',
        silence: {
          noiseLevel: {
            type: 'Noise level must be a number (See <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>).',
            nonpositive:
              'Noise level cannot be positive (See <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>).',
            integer:
              'Noise level must be an integer (See <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>).',
          },
          minTimeOfSilence: {
            type: 'Silence time must be a number (See <a><b><embedTranslation>text_Step1_Silences</embedTranslation></b></a>).',
          },
          maxTimeOfSilence: {
            type: 'Silence time must be a number (See <a><b><embedTranslation>text_Step1_Talks</embedTranslation></b></a>).',
          },
          marginBefore: {
            type: 'Padding before must be a number (See <a><b><embedTranslation>text_Step2_Before</embedTranslation></b></a>).',
          },
          marginAfter: {
            type: 'Padding after must be a number (See <a><b><embedTranslation>text_Step2_After</embedTranslation></b></a>).',
          },
          transitions:
            'Select a valid transition type (See <a><b><embedTranslation>text_transition_step_title</embedTranslation></b></a>).',
          silences:
            'Select a valid silence treatment option (See <a><b><embedTranslation>title_silences_step</embedTranslation></b></a>).',
        },
        swearWord: {
          bleepFile:
            'Select a bleep sound effect (See <a><b><embedTranslation>bleep_selection_step_title_helper</embedTranslation></b></a>).',
        },
        textBox: {
          xPadding:
            'Horizontal padding must be a number (See <a><b><embedTranslation>captions_customization_boxes_padding_x</embedTranslation></b></a>).',
          yPadding:
            'Vertical padding must be a number (See <a><b><embedTranslation>captions_customization_boxes_padding_y</embedTranslation></b></a>).',
          opacity: {
            min: 'Opacity must be at least 0% (See <a><b><embedTranslation>captions_customization_boxes_opacity</embedTranslation></b></a>).',
            max: 'Opacity cannot exceed 100% (See <a><b><embedTranslation>captions_customization_boxes_opacity</embedTranslation></b></a>).',
          },
        },
        anchor:
          'Anchor must be within the preview area (See <a><b><embedTranslation>autozoom_anchor_title</embedTranslation></b></a>).',
        zoom: {
          autoZoomMinCoef: {
            min: 'Minimum zoom coefficient must be greater than 1 (See <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
            max: 'Minimum zoom coefficient must be less than 2 (See <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
          },
          autoZoomMaxCoef: {
            min: 'Maximum zoom coefficient must be greater than 1 (See <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
            max: 'Maximum zoom coefficient must be less than 2 (See <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
          },
          minZoomTime: 'Minimum zoom duration must be positive seconds.',
          maxZoomTime: 'Maximum zoom duration must be positive seconds.',
          dynamicZoomPercentage: {
            min: 'Zoom percentage must be above 0%',
            max: 'Zoom percentage must be below 100%',
          },
          coef: 'Maximum zoom coefficient must be greater than minimum (See <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
          time: 'Maximum zoom time must be greater than minimum time.',
          dynamicMinZoomIntensity:
            'Dynamic zoom intensity must be between 0-100% (See <a><b><embedTranslation>autozoom_dynamic_zoom_coverage</embedTranslation></b></a>).',
          smoothZoomPercentage:
            'Smooth zoom coverage must be between 0-100% (See <a><b><embedTranslation>autozoom_smooth_zoom_coverage</embedTranslation></b></a>).',
          nervousness:
            'Nervousness level must be between 0-100% (See <a><b><embedTranslation>autozoom_smooth_zoom_nervousness</embedTranslation></b></a>).',
          totalZoomPercentage:
            'Total zoom coverage must be between 0-100% (See <a><b><embedTranslation>autozoom_zoom_coverage</embedTranslation></b></a>).',
          types: {
            at_least: {
              one: {
                true: 'Select at least one zoom type (See <a><b><embedTranslation>autozoom_zoom_types</embedTranslation></b></a>).',
              },
            },
          },
        },
        repeat: {
          algorithm: 'Invalid algorithm selected.',
        },
      },
    },
    key: {
      expired: {
        modal: {
          title: 'Payment Issue',
          button: {
            recover: 'Recover Subscription',
          },
          to_continue:
            'Your subscription was <b>{status}</b> due to payment issues. To continue:',
          deactivated: 'deactivated',
          deleted: 'deleted',
          recover: {
            list: {
              click:
                'Click the button below to recover your subscription or check your email for payment details.',
              re_activated:
                'Your key will be <b>re-activated immediately</b> after updating your payment.',
            },
          },
          new: {
            list: {
              website:
                'Visit AutoCut: <span>https://www.autocut.com/#pricing</span>',
              subscribe: 'Purchase a new license key',
            },
          },
          support: 'Need help? Contact us at: <span>contact@autocut.com</span>',
          thanks: 'Thank you!',
        },
        moda: {
          button: {
            new: 'Buy New Subscription',
          },
        },
      },
    },
    gaming: {
      trial: {
        modal: {
          title: 'Start Your AutoCut Journey',
          header: {
            days: {
              left: 'Begin your 14-day trial with {completedElements} additional features. {daysLeft} days left to explore!',
            },
            more: {
              days: 'Continue with an extra <b>{bonusDays} days</b> to fully experience AutoCut.',
            },
          },
          timeline: {
            left: '14-Day Free Trial',
            right: '24-Day Free Trial',
          },
          task: {
            list: {
              title: 'Task Progress: {completed}/{maximum} Completed',
            },
          },
        },
      },
    },
    reload: {
      autocut: {
        modal: {
          title: 'Reload Needed',
          cta: 'Close All Extensions',
          text: 'Multiple instances of AutoCut detected. Click below to close them and prevent issues.',
        },
      },
    },
    'key-already-activated':
      'Key is active on another device. Unlink it on the AutoCut dashboard (https://www.autocut.com/en/) and try again.',
    'key-does-not-exist':
      'Key not found. Please verify your key and try again.',
    unsupported: {
      version: {
        modal: {
          title: {
            __text__: 'Unsupported DaVinci Resolve Version',
            '24.4.0': 'Downgrade to DaVinci Resolve 24.3 or upgrade to 24.5',
          },
          cta: 'Acknowledge',
          text: {
            __text__:
              'Unsupported DaVinci Resolve version ({version}) detected. Experience may be affected. Use the <b>latest stable release</b> for optimal AutoCut performance.',
            '24.4.0':
              'Unsupported DaVinci Resolve version ({version}). Upgrade to 24.5 or downgrade to 24.3 to use AutoCut.',
          },
        },
      },
      rendering: {
        engine: {
          title: 'Unsupported Rendering Engine',
          modal: {
            text: 'Current rendering engine is unsupported. AutoCaptions and AutoZoom will not function. {br}{br}Select GPU acceleration in project settings.',
          },
        },
      },
    },
    handshake: {
      restart: {
        modal: {
          title: 'Restart Required',
          cta: 'Close All Extensions',
          text: 'Multiple AutoCut instances detected. Click below to close them and avoid issues.',
        },
      },
    },
    disabled: {
      features: {
        info: 'Selected language/font combination disables: <b>{features}</b>. These features will not be available in captions.',
        tips: 'Potential character support issue. Change font or language to enable these features.',
      },
    },
    upgrade: {
      to: 'Upgrade to {plan}',
    },
    deepgram: {
      error: {
        retry: 'Retry transcription with a different AI model.',
        modal: {
          title: 'Translation Error',
          cta: 'Join Discord',
          text: 'An error occurred during audio translation. It might be due to audio quality or language support. {br}Report via Discord or email contact@autocut.com.',
        },
      },
    },
    completing: {
      preset: {
        preview: 'Finalizing preset preview... Almost done.',
      },
    },
    chapters: {
      preview: {
        error:
          'Error generating preview. Adjust timestamps or restart AutoCut if the issue persists.',
      },
      step: {
        language: 'Select Clips and Language',
        editor: 'Chapter Editor',
      },
      button: {
        chapters: 'Add Chapters',
      },
      editor: {
        chapters: {
          title: 'Detected Chapters',
        },
        parameters: {
          title: 'Chapter Settings',
        },
        download: {
          youtube: {
            chapters: {
              file: {
                download: 'Download YouTube Chapters',
                success: 'Chapter.txt saved in Premiere Pro project',
              },
            },
          },
        },
      },
      invalid: {
        selection: {
          screen: {
            title: 'Select Clips for Chapter Base',
            text1: 'Select at least one audio-inclusive clip.',
          },
        },
      },
      process: {
        transcript: {
          button: 'Generate Chapters',
        },
      },
      adding: {
        chapters: 'Adding Chapters...',
      },
      backup: 'Backing Up Your Sequence...',
      analyse: 'Analyzing Audio...',
      getting: {
        chapters: 'Generating Chapters...',
      },
      transcript: {
        finished: 'Chapters Generated',
      },
      disabled: {
        language: 'Selected language does not support chapter detection.',
        file: {
          too: {
            long: 'Clips exceed the maximum length. Use clips under {limit} minutes.',
          },
        },
      },
    },
    time: {
      badge: {
        error: {
          wrong: {
            format: 'Incorrect time format. Use HH:MM:SS.',
          },
        },
      },
    },
    emoji: {
      picker: {
        animated: {
          option: 'Animated',
        },
        static: {
          option: 'Static',
        },
      },
    },
    viral: {
      clips: {
        invalid: {
          selection: {
            screen: {
              title: 'Select timeline clips for Viral Clips Detection',
              text1: 'Select at least one audio-inclusive clip.',
            },
          },
        },
        disabled: {
          language: 'Viral Clips Detection is unsupported in this language.',
          file: {
            too: {
              long: 'Clips are too long. Select clips under {limit} minutes.',
            },
          },
        },
        process: {
          transcript: {
            button: 'Generate Viral Clips',
          },
        },
        button: {
          process: 'Create Clips',
        },
        selector: {
          title: 'Detected {number} Potential Viral Clips',
        },
        virality: {
          score: 'Viral Potential Score',
        },
        step: {
          language: 'Select Clips and Language',
          selector: 'Select Viral Clips',
        },
        backup: 'Backing Up Your Sequence...',
        analyse: 'Analyzing Audio...',
        getting: {
          clips: 'Generating Viral Clips...',
        },
        transcript: {
          finished: 'Viral Clips Generated',
        },
        adding: {
          creating: {
            clips: 'Creating Clips...',
          },
        },
      },
      score: 'Viral Score',
    },
    mode: {
      description: {
        silence: 'Remove silences from the timeline',
        ai: 'Automatically remove silences from the timeline',
        podcast: 'Instantly edit podcasts',
        repeat: 'Remove repetitive takes with AI',
        zoom: 'Automatically add zoom effects',
        captions: 'Add animated captions using AI',
        resize: 'Resize sequences for different platforms',
        broll: 'Automatically add B-Roll footage with AI',
        profanity: 'Censor unwanted language automatically',
        chapter: 'Detect and add chapter markers automatically',
        viral: 'Identify viral potential clips in long videos easily',
      },
    },
    formatted: {
      numbers: {
        title: 'Numbers Formatted',
        modal: {
          cta: 'Close Modal',
          text: '<b>{number} words</b> have been converted to numbers in the transcript.',
        },
      },
    },
    transcription: {
      provider: {
        step: {
          title: 'Choose Transcription Model',
          model: {
            '1': 'Model 1 (default)',
            '2': 'Model 2',
          },
        },
      },
    },
  },
} as const;
//1417 keys
export default translations;
