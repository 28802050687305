import React, {useState} from 'react';

import css from './TagSelection.module.css';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

export type TagSelectionProps = {
  options: {label: string; value: string}[];
  handleSelectChange: (values: string[]) => void;
};

export const TagSelection = ({
  options,
  handleSelectChange,
}: TagSelectionProps) => {
  const [selected, setSelected] = useState<string[]>([]);

  const handleClick = (value: string) => {
    let tempSelected = selected;

    if (selected.includes(value)) {
      tempSelected = selected.filter(item => item !== value);
    } else {
      tempSelected = [...selected, value];
    }
    setSelected(tempSelected);
    handleSelectChange(tempSelected);
  };

  return (
    <FlexContainer
      gap={8}
      flexWrap="wrap"
    >
      {options.map(option => (
        <div
          className={`${css.item} ${
            selected.includes(option.value) && css.selected
          }`}
          key={option.value}
          onClick={() => handleClick(option.value)}
        >
          {option.label}
        </div>
      ))}
    </FlexContainer>
  );
};
