import {InputRangeParameter} from '@autocut/components/InputRangeParamater/InputRangeParameter';
import {NumberInput} from '@autocut/components/NumberInput';
import ParameterLayout from '@autocut/components/ParametersGroupLayout/ParameterLayout';
import {ParametersGroupLayout} from '@autocut/components/ParametersGroupLayout/ParametersGroupLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {AutocutModes} from '@autocut/enums/modes.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {updateCoverage} from '@autocut/utils/zoom';
import {setAutocutStore} from '@autocut/utils/zustand';
import {useIntl} from 'react-intl';

export const DynamicZoomStep = () => {
  const {zoomTypes, dynamicZoomPercentage, dynamicMaxZoomTime} =
    useAutoCutStore(state => state.ui.parameters.zoom);
  const isOnlyZoomType =
    Object.values(zoomTypes).filter(Boolean).length === 1 ? true : false;
  const intl = useIntl();

  return (
    <FlexContainer style={{paddingTop: Spacing.s4, paddingBottom: Spacing.s4}}>
      <ParametersGroupLayout
        title={intl.formatMessage({
          id: 'modes_zoom_parameters_dynamicZooms_title',
          defaultMessage: 'Dynamic zooms',
        })}
        status={zoomTypes.DYNAMIC}
        onStatusChange={status => {
          setAutocutStore('ui.parameters.zoom.zoomTypes.DYNAMIC', status);
          if (status)
            updateCoverage(
              'dynamicZoomPercentage',
              AutocutModes.Zoom.defaultParameters.dynamicZoomPercentage,
            );
          else updateCoverage('dynamicZoomPercentage', 0);
        }}
        hiddenContent={null}
      >
        <ParameterLayout
          title={
            <TranslatedMessage
              id="modes_zoom_parameters_dynamicZooms_coverage"
              defaultMessage="Dynamic zooms coverage"
            />
          }
          grow
        >
          <InputRangeParameter
            value={dynamicZoomPercentage}
            step={0.01}
            min={0}
            max={1}
            disabled={isOnlyZoomType}
            unit="%"
            unitFormatting={value => `${(value * 100).toFixed()}`}
            onChange={value =>
              updateCoverage('dynamicZoomPercentage', parseFloat(value))
            }
          />
        </ParameterLayout>
        <ParameterLayout
          title={
            <TranslatedMessage
              id="modes_zoom_parameters_maximumZoomDuration"
              defaultMessage="Maximum zoom duration"
            />
          }
          grow
          height={32}
        >
          <NumberInput
            unitId="globals_units_second"
            value={`${dynamicMaxZoomTime}`}
            targetedParameter={'dynamicMaxZoomTime'}
            onChange={(value: string) =>
              setAutocutStore(
                'ui.parameters.zoom.dynamicMaxZoomTime',
                parseFloat(value),
              )
            }
            inputContainerStyle={{paddingTop: 6, paddingBottom: 6}}
          />
        </ParameterLayout>
      </ParametersGroupLayout>
    </FlexContainer>
  );
};
