import React, {useEffect, useRef, useState} from 'react';

import css from './Login.module.css';
import {useNavigate} from 'react-router-dom';

import {useLocale} from '@autocut/hooks/useLocale';
import {Button} from '@autocut/components/Button/Button';
import {FooterLayout} from '@autocut/components/FooterLayout';
import {Input} from '@autocut/components/Inputs/Input';
import {OldTranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import logLevel from '@autocut/enums/logLevel.enum';
import {ModalType} from '@autocut/enums/modals.enum';
import {useLogger} from '@autocut/hooks/useLogger';
import {ModeWrapper} from '@autocut/pages/modes/ModeWrapper';
import {isKeyValid} from '@autocut/utils/auth.utils';
import {clipboard} from '@autocut/utils/clipboard.util';
import ConditionalWrap from '@autocut/utils/conditional-wrapping';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {login} from '@autocut/utils/license/login';
import {manageError} from '@autocut/utils/manageError';
import {setAutocutStore} from '@autocut/utils/zustand';
import {HiOutlineKey} from 'react-icons/hi';
import {LuClipboard} from 'react-icons/lu';
import {askForTrialKey} from '@autocut/utils/keys/askForTrialKey.util';
import {initAfterLogged} from '@autocut/utils/init';
import {useIntl} from 'react-intl';
import {TranslationKeys} from '@autocut/contexts/LocaleContext';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

const RESEND_TIMEOUT = 1000 * 15;

export type LoginProps = {
  trialEmail?: string;
};

export const Login = ({trialEmail = undefined}: LoginProps) => {
  const intl = useIntl();
  const {locale} = useLocale();
  const navigate = useNavigate();
  const {logMessage} = useLogger('UseLicenseKeyModal');

  const [licenceKey, setLicenceKey] = useState<string>('');
  const [error, setError] = useState<string>('');

  const [canResend, setCanResend] = useState<boolean>(false);

  useEffect(() => {
    if (trialEmail)
      setTimeout(() => {
        logMessage(logLevel.notice, 'Can resend trial key');
        setCanResend(true);
      }, RESEND_TIMEOUT);
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      void handleLogin();
    }
  };

  const handleResendEmail = async () => {
    if (!trialEmail) return;

    try {
      const result = await askForTrialKey(trialEmail, locale);
    } catch (error: any) {
      setError(
        intl.formatMessage({id: error.message, defaultMessage: error.message}),
      );
    }
  };

  const handleLogin = async () => {
    if (licenceKey !== '') setError('');

    if (!licenceKey || licenceKey === '') {
      logMessage(logLevel.warn, 'No key provided');
      setError(
        intl.formatMessage({
          id: 'auth_errors_no_key',
          defaultMessage: 'Please enter a key',
        }),
      );
      return;
    }

    try {
      const data = await isKeyValid(licenceKey, true);
      if (data.result) {
        logMessage(logLevel.info, 'Key is valid');
        const license = {
          ...data.licence,
          key: licenceKey,
        };
        await login({
          key: licenceKey,
          license,
          additionalInformations: data.additionalInformations,
          ...data.accessTokens,
        });
        await initAfterLogged();

        logMessage(logLevel.info, 'Redirect to homepage.');
        navigate('/homepage');
        return;
      }

      if (
        data &&
        data.licence &&
        data.licence.type === 'trial' &&
        data.licence.left == 0
      ) {
        logMessage(logLevel.error, 'Trial key expired.');

        setAutocutStore('ui.openedModalName', ModalType.TrialExpired);
        navigate('/onboarding');
        return;
      }

      if (data && data.message === 'Your key has expired.') {
        logMessage(logLevel.error, 'Key has expired.');

        if (data.additionalInformations) {
          setAutocutStore('user.additionalInformations', s => ({
            ...s.user.additionalInformations,
            ...data.additionalInformations,
          }));
        }

        setAutocutStore('ui.openedModalName', ModalType.KeyExpired);
        navigate('/onboarding');
        return;
      }

      if (data && data.message) {
        setError(
          intl.formatMessage({
            id: data.message as TranslationKeys,
            defaultMessage: data.message,
          }),
        );
      } else {
        setError('Something went wrong, please try again later.');
      }
    } catch (error: any) {
      setError(
        intl.formatMessage({
          id: 'auth_errors_is_key_valid_error',
          defaultMessage: 'Error while checking key, please try again later.\n',
        }) + intl.formatMessage({id: error.message}),
      );

      if (error.message.includes('An error has occurred Error: Network Error'))
        return;

      manageError({
        error: new IncrementalError(error, 'UseLicenseKeyModal'),
        disableModal: true,
      });
      return;
    }
  };

  return (
    <ConditionalWrap
      condition={trialEmail === undefined}
      wrap={children => (
        <ModeWrapper
          Icon={HiOutlineKey}
          text={intl.formatMessage({
            id: 'auth_login_title',
            defaultMessage: 'Use your license key',
          })}
          customHomeButtonHandler={() => navigate('/onboarding')}
        >
          {children}
        </ModeWrapper>
      )}
    >
      <FlexContainer
        flexDirection="column"
        gap={48}
      >
        <FlexContainer
          flexDirection="column"
          gap={12}
        >
          <FlexContainer
            alignItems="center"
            gap={8}
            className={css.title}
          >
            <OldTranslatedMessage
              id="title_use_paid_licence"
              defaultMessage="Use your license key"
            />
            <div className={css.keyContainer}>
              <HiOutlineKey
                size={20}
                color="white"
              />
            </div>
          </FlexContainer>
          <FlexContainer
            flexDirection="column"
            gap={8}
            className={css.subtitle}
          >
            <FlexContainer flexDirection="column">
              <div>
                {trialEmail && (
                  <OldTranslatedMessage
                    id="text1_use_trial_licence"
                    defaultMessage="You just received a mail on your email ({email})"
                    values={{
                      email: trialEmail,
                    }}
                  />
                )}
              </div>
              <div>
                <OldTranslatedMessage
                  id="text_use_paid_licence"
                  defaultMessage="Enter your license key to activate AutoCut."
                />
              </div>
            </FlexContainer>

            <div className={css.inputContainer}>
              <Input
                placeholder={
                  !trialEmail
                    ? intl.formatMessage({
                        id: 'auth_login_placeholder',
                        defaultMessage: 'Enter your key',
                      })
                    : 'Trial_xXXxXxxX'
                }
                type="text"
                value={licenceKey}
                onChange={e => setLicenceKey(e.target.value)}
                onKeyDown={handleKeyDown}
                inputSize="md"
              />
              <LuClipboard
                className={css.pasteButton}
                color="white"
                size={20}
                title="Paste from clipboard"
                onClick={async () => {
                  const text = (await clipboard.paste()) as string;
                  setLicenceKey(text);
                }}
              />
            </div>
            <div>
              {canResend && (
                <Button
                  buttonType="tertiary"
                  compact
                  onClickFunction={handleResendEmail}
                >
                  <OldTranslatedMessage
                    id="login_resend_email"
                    defaultMessage="You didn't receive it? Resend email to {trialEmail}"
                    values={{
                      trialEmail,
                    }}
                  />
                </Button>
              )}
            </div>
          </FlexContainer>
        </FlexContainer>
        <FooterLayout>
          <div className={css.buttonContainer}>
            <div className={css.errorMessage}>{error}</div>

            <Button
              onClickFunction={handleLogin}
              weight="bold"
            >
              <OldTranslatedMessage
                id="text_Login_SubmitButton"
                defaultMessage="Activate"
              />
            </Button>
          </div>
        </FooterLayout>
      </FlexContainer>
    </ConditionalWrap>
  );
};
