/* eslint-disable react/forbid-elements */
import {
  DO_NOT_USE_AllTranslationKeys,
  getTranslationKeyStatus,
  TranslationKeys,
} from '@autocut/contexts/LocaleContext';
import {preload} from '@autocut/types/ElectronPreload';
import {CURRENT_ENV, Env} from '@autocut/utils/currentEnv.utils';
import {RemovePrefix} from '@autocut/utils/type.utils';
import chroma from 'chroma-js';
import {
  ComponentPropsWithoutRef,
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {BiCopy} from 'react-icons/bi';
import {FormattedMessage} from 'react-intl';

export type TranslatedMessageAdditionalProps = {
  href?: string;
  copyText?: string;
  color?: CSSProperties['color'];
  backgroundColor?: CSSProperties['backgroundColor'];
};

export type TranslatedMessageProps = Omit<
  ComponentPropsWithoutRef<typeof FormattedMessage>,
  'id'
> & {
  valuePamareters?: TranslatedMessageAdditionalProps;
  noAnchor?: boolean;
  id: TranslationKeys;
};

const Copiable = ({
  text,
  children,
}: {
  text: string;
  children: React.ReactNode;
}) => {
  const hideTimeout = useRef<any>(null);
  const [copied, setCopied] = useState(false);

  return (
    <span
      onClick={() => {
        if (hideTimeout.current) {
          clearTimeout(hideTimeout.current);
        }
        preload().electron.setClipboard(text);
        setCopied(true);
        hideTimeout.current = setTimeout(() => {
          setCopied(false);
        }, 1000);
      }}
      style={{
        position: 'relative',
        cursor: 'pointer',
        display: 'inline-block',
      }}
    >
      {children} <BiCopy />
      <div
        style={{
          position: 'absolute',
          top: 0,
          width: '100%',
          height: '100%',
          backgroundColor: '#15151c',
          fontSize: '0.8em',
          opacity: copied ? 0.85 : 0,
          transition: copied ? 'opacity 0.15s' : 'opacity 0.3s',
        }}
      >
        Copied !
      </div>
    </span>
  );
};

const getDefaultTranslatedMessageValues = (
  valuePamareters: TranslatedMessageAdditionalProps,
  id: TranslationKeys,
): Exclude<TranslatedMessageProps['values'], undefined | null> => {
  const {href, color, copyText} = valuePamareters;

  return {
    embedTranslation: chunks => {
      console.log('embedTranslation', chunks, color);
      return typeof chunks[0] === 'string' ? (
        <TranslatedMessage
          // @ts-ignore
          id={chunks[0]}
          defaultMessage={chunks[0]}
          valuePamareters={valuePamareters}
          noAnchor
        />
      ) : (
        chunks
      );
    },
    br: <br />,
    b: chunks => <b>{chunks}</b>,
    li: chunks => <li>{chunks}</li>,
    i: chunks => <i>{chunks}</i>,
    a: chunks => {
      if (!href) {
        console.warn(`No color provided for copy translation "${id}"`);
      }

      return href ? (
        <span
          onClick={async () => {
            await preload().electron.openLinkInBrowser(href);
          }}
          style={{
            color: 'inherit',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          {chunks}
        </span>
      ) : (
        chunks
      );
    },
    color: chunks => {
      if (!color) {
        console.warn(`No color provided for copy translation "${id}"`);
      }

      return color ? <span style={{color}}>{chunks}</span> : chunks;
    },
    backgroundColor: chunks => {
      if (!color) {
        console.warn(`No color provided for copy translation "${id}"`);
      }

      return color ? (
        <span
          style={{
            backgroundColor: color,
            borderRadius: 2,
            padding: '2px 4px',
          }}
        >
          {chunks}
        </span>
      ) : (
        chunks
      );
    },
    border: chunks => {
      if (!color) {
        console.warn(`No color provided for copy translation "${id}"`);
      }

      return color ? (
        <span
          style={{
            border: `1px solid ${color}`,
            color: chroma(color).luminance() > 0.8 ? 'black' : 'white',
            borderRadius: 3,
            padding: '2px 4px',
          }}
        >
          {chunks}
        </span>
      ) : (
        chunks
      );
    },
    opacity50: chunks => <span style={{opacity: '50%'}}>{chunks}</span>,
    copy: chunks => {
      if (!copyText) {
        console.warn(`No copyText provided for copy translation "${id}"`);
      }

      return copyText ? <Copiable text={copyText}>{chunks}</Copiable> : chunks;
    },
  };
};

export type OnlyOldTranslations = RemovePrefix<
  Exclude<DO_NOT_USE_AllTranslationKeys, TranslationKeys>,
  'old_'
>;

export const TranslatedMessage = ({
  id,
  values,
  valuePamareters,
  ...props
}: TranslatedMessageProps) => {
  const additionalStyle: CSSProperties = useMemo(() => {
    if (CURRENT_ENV !== Env.Production) {
      const status = getTranslationKeyStatus(id);

      const res: CSSProperties = {};
      if (status === 'missing') {
        res.border = '2px solid red';
      } else if (status === 'partial') {
        res.border = '2px solid orange';
      } else if (id.startsWith('old_')) {
        res.border = '2px solid yellow';
      }
      return res;
    }
    return {};
  }, [id]);

  return (
    <FormattedMessage
      id={id}
      values={{
        ...getDefaultTranslatedMessageValues(valuePamareters || {}, id),
        ...values,
      }}
      {...props}
    >
      {chunks => <span style={additionalStyle}>{chunks}</span>}
    </FormattedMessage>
  );
};

export const OldTranslatedMessage = (
  props: Omit<TranslatedMessageProps, 'id'> & {id: OnlyOldTranslations},
) => {
  const id = props.id;
  console.log('Old translation id used, please migrate it:', `old_${id}`);
  return (
    <TranslatedMessage
      {...props}
      id={`old_${id}` as any}
    />
  );
};
