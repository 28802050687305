import {useOldIntl} from '@autocut/hooks/useOldIntl';

import {
  OldTranslatedMessage,
  OnlyOldTranslations,
} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import css from './silencesFooterButton.module.css';
import {forwardRef} from 'react';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {colors} from '@autocut/designSystem/colors';

export type FooterButtonProps = {
  isDisabled?: boolean;
  modeId?: 'silence' | 'ai' | 'repeat';
  onClick: (...args: any) => Promise<void> | void;
  isLoading?: boolean;
};

export const SilencesFooterButton = forwardRef<
  HTMLButtonElement,
  FooterButtonProps
>(
  (
    {
      isDisabled = false,
      isLoading,
      modeId = 'silence',
      onClick: handleCutEvent,
    },
    ref,
  ) => {
    const intl = useOldIntl();

    const {silences, transition} = useAutoCutStore(state => ({
      silences: state.ui.parameters[modeId].silences,
      transition: state.ui.parameters[modeId].transistions,
    }));

    const buttonTextId = `silence_button_${
      silences ? silences : 'delete'
    }_${transition}` as OnlyOldTranslations;

    return (
      <div
        id="legacy-step8"
        className={css.container}
      >
        <Button
          ref={ref}
          disabled={isDisabled || isLoading}
          onClick={async () => await handleCutEvent(intl)}
          color={colors.primary600}
          isLoading={isLoading}
        >
          <OldTranslatedMessage
            id={buttonTextId}
            defaultMessage="Cut and delete silences"
            values={{
              mode: intl.formatMessage({
                id: `text_${modeId === 'repeat' ? 'repetitions' : 'silences___text__'}`,
                defaultMessage: 'silences',
              }),
            }}
          />
        </Button>
      </div>
    );
  },
);
