import FontMeasure from '@autocut/components/FontMeasure/FontMeasure';
import {PreviewAudio} from '@autocut/components/PreviewAudio/PreviewAudio';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {PaddedSection} from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {preload} from '@autocut/types/ElectronPreload';
import {compute} from '@autocut/utils/compute.utils';
import {host} from '@autocut/utils/host';
import {
  addProcessSteps,
  endCurrentProcessProgress,
  endProcessStep,
  initProcessProgress,
  startProcessProgress,
  updateProcessStep,
} from '@autocut/utils/process/progress';
import {useNavigate} from 'react-router-dom';

export const DevelopmentDashboard = () => {
  const navigate = useNavigate();

  const handleProcessModal = async () => {
    const {requestId: pingRequestId, promise: pingPromise} =
      preload().computeNodeIpc.startTask({
        domain: {
          slug: 'ping',
          target: 'davinci',
        },
        priority: 0,
      });

    const processProgress = initProcessProgress('test', 'globals_contactMail');
    const [step1, step2] = addProcessSteps(processProgress, [
      {
        id: 'compute-ping',
        translationKey: 'auth_login_title',
        progress: pingRequestId,
        countFor: 2,
      },
      {
        translationKey: 'globals_defaults_components_KebabMenu_account',
        progress: 5,
      },
    ]);

    startProcessProgress(processProgress);

    await pingPromise;

    endProcessStep(processProgress, step1.id);

    for (let i = 1; i <= 5; i++) {
      await new Promise(resolve => setTimeout(resolve, 1000));
      updateProcessStep(processProgress, step2.id, {
        progress: {currentStep: i},
      });
    }
    endProcessStep(processProgress, step2.id);

    endCurrentProcessProgress();
  };

  const handleGetTimelineSettings = async () => {
    const renders = await compute.timeline.render({
      descriptor: {
        tracks: {
          '1': 'all',
          '2': 'all',
        },
      },
    });

    const trackOneRenderPath = renders.tracks['1'];

    const dbValues = await compute.utils.getDbValuesBetween({
      filePath: trackOneRenderPath,
      start: 15,
      end: 20,
      precision: 0.05,
    });

    console.log({dbValues});
  };

  const handlePreProcessStats = async () => {
    const stats = await compute.timeline.getPreProcessStats();

    console.log({stats});
  };

  const handleDevTask = async () => {
    const test = await compute.dev();
    console.log({test});
  };

  return (
    <FlexContainer
      flexDirection="column"
      gap={Spacing.s2}
    >
      <Button
        color={colors.primary600}
        size="sm"
        variant="secondary.dashed"
        onClick={() => navigate('/ds')}
      >
        ✨ Go to design system
      </Button>
      <FormSection title="Dev">
        <PaddedSection>
          <Button
            color={colors.primary600}
            size="sm"
            fullWidth={false}
            onClick={handleDevTask}
          >
            Run dev task
          </Button>
        </PaddedSection>
      </FormSection>
      <FormSection title="BigData">
        <PaddedSection>
          <Button
            color={colors.primary600}
            size="sm"
            fullWidth={false}
            onClick={async () => {
              const bigData = await compute.utils.bigData();
              console.log('bigData : ', bigData);
            }}
          >
            From Compute
          </Button>
          {/* <Button
            color={colors.primary600}
            size="sm"
            fullWidth={false}
            onClick={async () => {
              const bigData = await host.misc.bigData();
              console.log('bigData : ', bigData);
            }}
          >
            From Host
          </Button> */}
        </PaddedSection>
      </FormSection>
      <FormSection title="Podcast">
        <PaddedSection>
          <Button
            color={colors.primary600}
            size="sm"
            fullWidth={false}
            onClick={handleGetTimelineSettings}
          >
            Get Timeline settings
          </Button>
        </PaddedSection>
      </FormSection>
      <FormSection title="Zoom">
        <PaddedSection>
          <Button
            color={colors.primary600}
            size="sm"
            fullWidth={false}
            onClick={handleGetTimelineSettings}
          >
            Get Timeline settings
          </Button>
        </PaddedSection>
      </FormSection>
      <FormSection title="Process Modal">
        <PaddedSection>
          <Button
            color={colors.primary600}
            size="sm"
            fullWidth={false}
            onClick={handleProcessModal}
          >
            Open process modal
          </Button>
        </PaddedSection>
      </FormSection>
      <FormSection title="Pre-Process stats">
        <PaddedSection>
          <Button
            color={colors.primary600}
            size="sm"
            fullWidth={false}
            onClick={handlePreProcessStats}
          >
            Print pre-process stats
          </Button>
        </PaddedSection>
      </FormSection>
      <PreviewAudio />
      <FontMeasure />
    </FlexContainer>
  );
};
