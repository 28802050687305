import {logger} from '@autocut/utils/logger';
import {setTokens} from '../http.utils';
import {setKey} from '../localStorage.utils';
import {setAutocutStore} from '../zustand';
import logLevel from '@autocut/enums/logLevel.enum';

const logMessage = (level: logLevel, message = 'log', objects = {}) => {
  logger('logout', level, message, objects);
};

export const logout = (reason: string, keepKey = false) => {
  if (!keepKey) {
    setKey('');
    setAutocutStore('user.key', '');
  }

  setAutocutStore('user.isLogged', false);

  setAutocutStore('user.license', undefined);

  logMessage(logLevel.info, 'User logged out.', {reason});

  setTokens({accessToken: '', refreshToken: ''});
  setAutocutStore('user.accessToken', '');
};
