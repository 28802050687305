import {preload, ProgressStep} from '@autocut/types/ElectronPreload';
import {JSONTimeline} from '@autocut/types/JSONTimeline';
import {AutoCutState, autocutStoreVanilla} from './zustand';
import {accessTokensModule} from './http.utils';
import {
  GoogleTranscriptionLocation,
  TranscriptionProvider,
  TranscriptionType,
} from '@autocut/types/Transcription';
import {IncrementalError} from './errors/IncrementalError';

export type ComputeRequestId = ReturnType<
  ReturnType<typeof preload>['computeNodeIpc']['startTask']
>['requestId'];

export type ComputeHandler<OutputType> = Promise<OutputType> & {
  requestId: ComputeRequestId;
};

const computeHandler = <OutputType>(
  task: Task<any>,
): ComputeHandler<OutputType> => {
  const runRequest = () => {
    console.log(
      ...[
        `[Compute] Requesting "${task.domain.target}/${task.domain.slug}" ${task.data ? 'with data: ' : ''}`,
        ...(task.data ? [task.data] : []),
      ],
    );

    return preload().computeNodeIpc.startTask<OutputType>({
      priority: 0,
      tokens: {
        accessToken: accessTokensModule.accessToken,
        refreshToken: accessTokensModule.refreshToken,
      },
      ...task,
    });
  };

  const request = runRequest();
  const res = request.promise.catch(err => {
    console.log('ERROR IN COMPUTE HANDLER', err);
    throw new IncrementalError(
      err.content,
      `${err.from}(${err.content.fingerprint})`,
    );
  }) as ComputeHandler<OutputType>;
  res.requestId = request.requestId;

  return res;
};

export const compute = {
  dev: () =>
    computeHandler<any>({
      domain: {slug: 'dev', target: autocutStoreVanilla().ui.host},
      // debug: true,
    }),
  utils: {
    getDbValuesBetween: (parameters: {
      filePath: string;
      start: number;
      end: number;
      precision?: number;
    }) =>
      computeHandler<number[]>({
        domain: {slug: 'get-db-values-between', target: 'common'},
        data: parameters,
      }),

    getSilenceIntervals: (
      parameters: {
        filePath: string;
      } & AutoCutState['ui']['parameters']['silence'],
    ) =>
      computeHandler<number[][]>({
        domain: {slug: 'get-silence-intervals', target: 'common'},
        data: parameters,
      }),
    bigData: () =>
      computeHandler<string>({
        domain: {slug: 'big-data', target: 'common'},
      }),
  },
  timeline: {
    render: (parameters: {
      descriptor: RenderDescriptor;
      convertToMono?: boolean;
    }) =>
      computeHandler<any>({
        domain: {
          slug: 'render',
          target: autocutStoreVanilla().ui.host,
        },
        data: parameters,
      }),
    getInfos: () =>
      computeHandler<JSONTimeline>({
        domain: {
          slug: 'get-timeline-infos',
          target: autocutStoreVanilla().ui.host,
        },
      }),
    getDisposition: () =>
      computeHandler<{maxAudioTrackId: number; maxVideoTrackId: number}>({
        domain: {
          slug: 'get-timeline-disposition',
          target: 'common',
        },
      }),
    getPreProcessStats: () =>
      computeHandler<{
        width: number;
        height: number;
        duration: number;
        trackItemsCount: number;
      }>({
        domain: {
          slug: 'get-pre-process-stats',
          target: 'common',
        },
      }),
  },
  transcription: ({
    type,
    language,
    skipEmbedding = true,
    file,
  }: {
    type: TranscriptionType;
    language: {
      provider: TranscriptionProvider;
      model: string;
      value: string;
      location?: GoogleTranscriptionLocation;
    };
    skipEmbedding?: boolean;
    file: {
      path: string;
      start: number;
      end: number;
    };
  }) =>
    computeHandler<string>({
      domain: {
        slug: 'get-transcript',
        target: autocutStoreVanilla().ui.host,
      },
      data: {
        type,
        language,
        skipEmbedding,
        file,
      },
    }),
  process: {
    silence: (silenceParameters: AutoCutState['ui']['parameters']['silence']) =>
      computeHandler<void>({
        domain: {
          slug: 'silences',
          target: autocutStoreVanilla().ui.host,
        },
        data: silenceParameters,
      }),
    podcast: (podcastParameters: AutoCutState['ui']['parameters']['podcast']) =>
      computeHandler<void>({
        domain: {
          slug: 'podcast',
          target: autocutStoreVanilla().ui.host,
        },
        data: podcastParameters,
      }),
    zoom: (zoomParameters: AutoCutState['ui']['parameters']['zoom']) =>
      computeHandler<void>({
        domain: {
          slug: 'zoom',
          target: autocutStoreVanilla().ui.host,
        },
        data: zoomParameters,
      }),
    captions: (captionsParametersFilePath: string) =>
      computeHandler<void>({
        domain: {
          slug: 'captions',
          target: autocutStoreVanilla().ui.host,
        },
        data: {
          filePath: captionsParametersFilePath,
        },
      }),
    swearWords: (swearWordsParameters: {
      userOptions: {
        provider: TranscriptionProvider;
        model: string;
        value?: string;
        location?: GoogleTranscriptionLocation;
      };
      bleepFileName?: string;
      bleepFilePath?: string;
    }) =>
      computeHandler<void>({
        domain: {
          slug: 'swear-words',
          target: autocutStoreVanilla().ui.host,
        },
        data: swearWordsParameters,
      }),
  },
};

export const getComputeTaskProgress = async (requestId: string) => {
  return preload().computeNodeIpc.getTaskProgress(requestId).promise;
};

export const subscribeTaskProgress = (
  taskId: ComputeRequestId,
  callback: (steps: ProgressStep[]) => void,
  pollingIntervalMs = 1000,
): {unsubscribe: () => void} => {
  const pollingInterval = setInterval(async () => {
    const steps = await getComputeTaskProgress(taskId);

    if (!Array.isArray(steps)) {
      console.warn('Steps are not an array', steps);
      return;
    }

    if (steps.every(step => step.progress.finished)) {
      clearInterval(pollingInterval);
    }

    callback(steps);
  }, pollingIntervalMs);

  return {unsubscribe: () => clearInterval(pollingInterval)};
};
