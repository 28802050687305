import {
  ModeTitle,
  ModeTitleProps,
} from '@autocut/components/ModeTitle/ModeTitle';
import * as React from 'react';
import {IconType} from 'react-icons';
import {
  InvalidSelectionScreen,
  InvalidSelectionScreenProps,
} from '../InvalidSelectionScreen/InvalidSelectionScreen';

export type ModeWrapperProps = {
  Icon?: IconType;
  text: string;
  displayCondition?: boolean;
  invalidSelectionScreen?: Omit<InvalidSelectionScreenProps, 'Icon'>;
  children: React.ReactNode;
  customHomeButtonHandler?: ModeTitleProps['customHomeButtonHandler'];
};

export const ModeWrapper = ({
  Icon,
  text,
  displayCondition,
  invalidSelectionScreen,
  children,
  customHomeButtonHandler,
}: ModeWrapperProps) => {
  return (
    <>
      <ModeTitle
        Icon={Icon}
        text={text}
        customHomeButtonHandler={customHomeButtonHandler}
      />
      <div className="dashboardContainer">
        {displayCondition || displayCondition === undefined ? (
          children
        ) : invalidSelectionScreen ? (
          <InvalidSelectionScreen
            Icon={Icon}
            {...invalidSelectionScreen}
          />
        ) : (
          <p>Missing invalidSelectionScreen</p>
        )}
      </div>
    </>
  );
};
