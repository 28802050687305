import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {addXp} from '@autocut/utils/game/addXp';
import {autocutStoreVanilla, setAutocutStore} from '@autocut/utils/zustand';
import {IntlShape} from 'react-intl';
import {handleProcessBase} from '../handleProcessBase';
import {preload} from '@autocut/types/ElectronPreload';
import {compute} from '@autocut/utils/compute.utils';
import {CaptionsParameters} from '@autocut/types/CaptionsParameters';
import {CanvasFontParams, CaptionChunk} from '@autocut/types/Captions';
import {
  endProcessStep,
  ProcessProgress,
  updateProcessStep,
} from '@autocut/utils/process/progress';
import {measureTextOnCanvas} from '@autocut/pages/modes/captions/utils/measureTextOnCanvas';

const fontReferenceText = 'AutoCut';

const addCaptions = async (_: IntlShape, progress: ProcessProgress) => {
  try {
    const chunks = autocutStoreVanilla().onGoingProcess.captionChunks;
    if (!chunks) {
      throw new IncrementalError('No chunks found', 'addCaptions');
    }

    const {
      userPresets,
      languageOfTranscription,
      postProcessing,
      enableSoundPlay,
      utils,
      ...captionsParameters
    } = autocutStoreVanilla().ui.parameters.caption;

    const fontFamily = captionsParameters.text.font.fontFamily;
    const fontSize = captionsParameters.text.fontSize;
    const fontParameters: CanvasFontParams = {
      italic: false,
      fontSize: fontSize,
      fontFamily,
    };

    const fontReferenceData = measureTextOnCanvas(
      fontReferenceText,
      fontParameters,
      false,
    );

    const globalParameters: {
      parameters: Partial<CaptionsParameters>;
      chunks: CaptionChunk[];
      fontReferenceWidth: number;
    } = {
      parameters: captionsParameters,
      chunks,
      fontReferenceWidth: fontReferenceData.metrics.width,
    };

    const folderPath = preload().path.join(
      preload().os.tmpdir(),
      'AutoCut-DV/captions',
    );
    const fileName = `captions.json`;

    const filePath = preload().path.join(folderPath, fileName);

    if (!preload().fs.existsSync(folderPath)) {
      preload().fs.mkdirSync(folderPath, {recursive: true});
    }
    preload().fs.writeFileSync(
      filePath,
      JSON.stringify(globalParameters, null, 2),
    );
    endProcessStep(progress, 'captions_initialization');

    const captionsProcess = compute.process.captions(filePath);

    updateProcessStep(progress, 'captions_mainProcess', {
      progress: {
        computeTaskId: captionsProcess.requestId,
      },
      countFor: 11, // Number of steps in compute
    });

    await captionsProcess;

    endProcessStep(progress, 'captions_mainProcess');

    const chunksTotal = chunks.length;

    const xpGained = chunksTotal * 1.66;
    await addXp(xpGained);
    setAutocutStore('game.level.xpGained', xpGained);
  } catch (err: any) {
    throw new IncrementalError(err, 'addCaptions');
  }
};

export const handleCaptionsProcess = async (intl: IntlShape) => {
  await handleProcessBase(
    {
      executeProcess: addCaptions,
    },
    {
      processTitleNameKey: 'modes_captions_title',
      processSteps: [
        {
          id: 'captions_initialization',
          translationKey: 'progress_steps_captions_generation_initialization',
          progress: 1,
        },
        {
          id: 'captions_mainProcess',
          translationKey: 'progress_steps_captions_generation_mainProcess',
          progress: '',
        },
      ],
    },
  )(intl);
};
