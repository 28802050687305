import {useCanvasObject} from '../captionDrawHooks.utils';
import {CaptionCanvasObjectHook} from '../captionHooks.type';
import {useDrawText} from './useDrawText';

export const useTextCanvasObject: CaptionCanvasObjectHook = args => {
  const drawReturn = useDrawText(args);

  return useCanvasObject({
    name: 'highlightText',
    paths: {
      x: 'ui.parameters.caption.position.xPercentage',
      y: 'ui.parameters.caption.position.yPercentage',
    },
    ...drawReturn,
    ...args,
  });
};
