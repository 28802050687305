import Progress from '@autocut/components/Progress/Progress';
import {
  OldTranslatedMessage,
  OnlyOldTranslations,
} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import logLevel from '@autocut/enums/logLevel.enum';
import {AutocutModes} from '@autocut/enums/modes.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useLogger} from '@autocut/hooks/useLogger';
import React from 'react';
import {IconType} from 'react-icons';
import {useOldIntl} from '@autocut/hooks/useOldIntl';
import css from './InvalidSelectionScreen.module.scss';
import {InfoText} from '@autocut/components/InfoText/InfoText';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

const NB_CLIPS_WARNING_THRESHOLD = 100;

export type InvalidSelectionScreenProps = {
  Icon?: IconType;
  titleId: OnlyOldTranslations;
  titleDefaultMessage: string;
  texts: {
    id: OnlyOldTranslations;
    defaultMessage: string;
  }[];
  linkId?: string | undefined;
  customCta?: React.ReactNode;
};

export const InvalidSelectionScreen = ({
  Icon,
  texts,
  titleDefaultMessage,
  titleId,
  linkId = undefined,
  customCta = undefined,
}: InvalidSelectionScreenProps) => {
  const intl = useOldIntl();
  const {
    // progressPercent,
    // nbClipTotal,
    // isParsingSequence,
    // unsupportedScenario,
    mode,
  } = useAutoCutStore(state => ({
    // progressPercent: state.sequence.parsingProcess.progress.percentage, TODO : spécifité ppro
    // nbClipTotal: state.sequence.parsingProcess.progress.max,
    // isParsingSequence: state.sequence.parsingProcess.isLoading,
    // unsupportedScenario: state.sequence.errors.unsupportedScenario,
    mode: state.ui.process.mode,
  }));

  const {logMessage} = useLogger('InvalidSelectionScreen');

  return (
    <FlexContainer
      flexDirection="column"
      justifyContent="space-between"
      className={css.relativeContainer}
    >
      <FlexContainer
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        className={css.noClipContainer}
      >
        <FlexContainer
          justifyContent="center"
          gap={12}
          className={css.noClipTitle}
        >
          {!!Icon && (
            <div className={css.scissorsCircle}>
              <Icon size={16} />
            </div>
          )}
          <h2>
            <OldTranslatedMessage
              id={titleId}
              defaultMessage={titleDefaultMessage}
            />
          </h2>
        </FlexContainer>
        <FlexContainer
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          className={css.noClipText}
        >
          {texts.map(text => (
            <React.Fragment key={text.id}>
              <OldTranslatedMessage
                id={text.id}
                defaultMessage={text.defaultMessage}
              />
              <br />
            </React.Fragment>
          ))}
          {linkId && (
            <FlexContainer
              justifyContent="space-between"
              alignItems="center"
            >
              <OldTranslatedMessage
                id="text_noClip_tuto___text__"
                defaultMessage="Need help ? Feel free to watch our"
              />
              <a
                className={'tuto_link'}
                onClick={() => {
                  logMessage(
                    logLevel.info,
                    'Go to youtube tuto from no clip panel.',
                  );
                  // openLinkInBrowser( // TODO : open in browser function
                  //   intl.formatMessage({
                  //     id: linkId,
                  //     defaultMessage:
                  //       'https://www.youtube.com/watch?v=7MEK3rZQDHI',
                  //   })
                  // );
                }}
              >
                <OldTranslatedMessage
                  id="text_noClip_tuto_link"
                  defaultMessage="video tutorial"
                />
              </a>
            </FlexContainer>
          )}
          {customCta}
          {(mode.id === AutocutModes.Ai.id ||
            mode.id === AutocutModes.Legacy.id) &&
            // nbClipTotal > NB_CLIPS_WARNING_THRESHOLD && (
            100 > NB_CLIPS_WARNING_THRESHOLD && (
              <InfoText
                // @ts-ignore
                descriptionId="old_big_sequence_info_message"
                descriptionDefault="It looks like you have a lot of clips in your sequence !"
              />
            )}
          {/* {isParsingSequence ? ( */}
          {false ? (
            <Progress
              // progress={progressPercent}
              progress={0}
              width="80%"
              height="16px"
              innerText={intl.formatMessage({
                id: 'text_noClip_loading_bar',
                defaultMessage: 'Loading - Sequence analysis in progress',
              })}
            />
          ) : null}
          {/* {unsupportedScenario ? (
            <UnsupportedScenarioInfoText
              unsupportedScenarioId={
                unsupportedScenario as UnsupportedScenarioIds
              }
            />
          ) : null} */}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
