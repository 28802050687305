const translations = {
  progress: {
    footer: {
      warning:
        'Por favor, evite usar o DaVinci durante o trabalho do AutoCut para evitar possíveis problemas.',
    },
    steps: {
      global: {
        starting: 'Iniciando...',
        processing: 'Processando...',
        ending: 'Concluindo...',
      },
      dev: {
        ping: 'Pingando o DaVinci Resolve...',
        pong: 'Pongando o DaVinci Resolve...',
      },
      common: {
        getSilences: 'Identificando seções silenciosas...',
        getAudioInfos: 'Coletando detalhes de áudio...',
        readAudioFile: 'Carregando arquivo de áudio...',
        computeRMS: 'Analisando arquivo de áudio...',
        convertRMSToDB: 'Analisando arquivo de áudio...',
        exportDrt: 'Exportando linha do tempo...',
        extractDrt: 'Extraindo linha do tempo...',
        getXmlTimelineFolderPath: 'Lendo linha do tempo...',
        getXmlTimelineFullpath: 'Lendo linha do tempo...',
        loadXmlTimeline: 'Lendo linha do tempo...',
        writeFile: 'Salvando arquivo...',
        writeXml: 'Importando linha do tempo atualizada...',
        updateDrt: 'Importando linha do tempo atualizada...',
        importXml: 'Importando linha do tempo atualizada...',
      },
      podcast: {
        mainProcess: 'Editando podcast...',
        getVideoTracksWeight: 'Configurando faixas de vídeo...',
        getAudioTracks: 'Configurando faixas de áudio...',
        getPodcastInterval: 'Estimando duração do podcast...',
        getPodcastDuration: 'Calculando duração do podcast...',
        renders:
          'Renderizando áudio no DaVinci Resolve\n(pode levar alguns minutos)',
        getTalkingTimelines: 'Alocando tempos de fala...',
        getTransitions: 'Planejando transições de câmera...',
        getCutTimecodes: 'Preparando pontos de corte...',
        cutAtTimecodes: 'Executando cortes...',
      },
      silences: {
        mainProcess: 'Removendo silêncios...',
        getTimelineInfos: 'Buscando informações da linha do tempo...',
        renders:
          'Renderizando áudio no DaVinci Resolve\n(pode levar alguns minutos)',
        convertAudioToMono: 'Configurando Áudio...',
        getCutTimecodes: 'Configurando pontos de corte...',
        cutAtTimecodes: 'Executando cortes...',
        deleteSilentTrackItems: 'Deletando segmentos silenciosos...',
      },
      swearWords: {
        mainProcess: 'Censurando profanidades...',
        prepareTimeline: 'Preparando a linha do tempo...',
        getSwearWords: 'Analisando a transcrição...',
        cutAndApplyVolume: 'Aplicando filtro de volume...',
        applyBleeps: 'Inserindo bipes...',
      },
      zoom: {
        mainProcess: 'Aplicando efeitos de zoom...',
        getTimelineInfos: 'Buscando informações da linha do tempo...',
        getWavData:
          'Renderizando áudio no DaVinci Resolve\n(pode levar alguns minutos)',
        computeBestZoomIntervals: 'Calculando intervalos de zoom otimizados...',
        createVideoTrack: 'Criando novas faixas de vídeo...',
        appendAdjustmentLayers: 'Adicionar camadas de ajuste',
        createZoomFusionComps: 'Importando componentes de zoom...',
      },
      captions: {
        transcript: {
          mainProcess: 'Gerando transcrição...',
          get_language: 'Detectando idioma...',
          renderWav:
            'Renderizando áudio no DaVinci Resolve (pode levar alguns minutos)',
          split_chunks: 'Dividindo transcrição em segmentos...',
          uploadFile: 'Enviando arquivo...',
          getTranscript:
            'Transcrevendo áudio com IA (isso pode levar alguns minutos)',
          writeFile: 'Salvando transcrição...',
        },
        generation: {
          mainProcess: 'Adicionando legendas...',
          initialization: 'Inicializando legendagem...',
          readTranscript: 'Carregando transcrição...',
          getTimelineInfos: 'Buscando detalhes da linha do tempo...',
          appendAdjustmentLayer: 'Adicionando camada de ajuste...',
          importFusionComp: 'Importando componentes de legenda...',
        },
      },
    },
  },
  launchManager: {
    title: 'Configurar / Atualizar',
    steps: {
      preload: {
        title: 'Carregando',
      },
      update: {
        title: 'Verificando atualizações',
        modal: {
          title: 'AutoCut Atualizado',
          subtitle: 'O AutoCut foi atualizado',
          body: 'Atualizamos o AutoCut para melhorar a experiência. Por favor, reinicie o AutoCut usando o botão abaixo.',
          cta: 'Reiniciar AutoCut',
        },
      },
      python: {
        title: 'Instalando Python',
      },
      ffmpeg: {
        title: 'Instalando FFMPEG',
      },
      resources: {
        title: 'Instalando recursos',
      },
      computeIPC: {
        title: 'Conectando aos serviços de IA',
      },
      daVinciIPC: {
        title: 'Inicie o AutoCut no DaVinci.',
        subtitle: 'Precisa de ajuda para localizá-lo? Reinicie o DaVinci',
        stillNot:
          'Ainda não consegue encontrar após reiniciar o DaVinci & AutoCut?{br}Entre em contato conosco em <copy><embedTranslation>globals_contactMail</embedTranslation></copy>',
        ppro: 'Quer usar o AutoCut com Premiere Pro? <a>Baixar aqui</a>',
      },
    },
    footer: {
      discord: 'Participe da nossa comunidade no Discord!',
      learnMore: 'Aprenda dicas para maximizar os recursos do AutoCut!',
    },
    errors: {
      compute:
        'Não é possível conectar aos serviços de IA. Entre em contato com o suporte.',
      davinci:
        'Não é possível conectar ao DaVinci Resolve. Certifique-se de que está em execução e que o script AutoCut está ativo.',
      cta: 'Tente Novamente',
    },
  },
  homepage: {
    cards: {
      status: {
        trial: 'Teste Gratuito',
        upgrade: 'Atualize Agora',
        coming_soon: 'Em Breve!',
        version_not_supported: 'Versão do DaVinci Resolve não suportada',
        upgrade_to: 'Atualize para {plan}',
      },
    },
  },
  modes: {
    podcast: {
      title: 'Auto Podcast',
      steps: {
        select: {
          title:
            'Escolha uma linha do tempo com pelo menos 2 faixas de vídeo e áudio',
          label: 'Selecionar Linha do Tempo',
          descriptions: {
            '1': 'Navegue até a aba editar',
            '2': 'Abra a linha do tempo que deseja editar',
          },
          cta: 'Selecionar Linha do Tempo Ativa',
        },
        customization: {
          label: 'Configurações do Podcast',
          steps: {
            speakers: {
              title: 'Palestrantes',
              description:
                'Atribua a cada palestrante uma faixa de áudio e nomeie-os. Use uma faixa por palestrante. Para palestrantes compartilhados, use ambos os nomes (por exemplo, "Alice & Bob").',
              header: {
                audio: 'Faixa de Áudio',
                name: 'Nome do Palestrante',
              },
              textInputPlaceholder: 'Insira o nome do palestrante...',
              trackTemplate: 'Faixa A{index}',
              cta: 'Adicionar Palestrante',
            },
            cameras: {
              title: 'Câmeras',
              description:
                'Atribua a cada câmera uma faixa de vídeo e selecione os palestrantes visíveis. Uma faixa por câmera, vários palestrantes permitidos.',
              header: {
                audio: 'Faixa de Vídeo',
                name: 'Palestrante(s)',
              },
              speakerSelectPlaceholder: 'Selecione o(s) palestrante(s)...',
              trackTemplate: 'Faixa V{index}',
              cta: 'Adicionar Câmera',
            },
            cameraTime: {
              title: 'Duração da Câmera',
              description:
                'Defina a duração mínima e máxima que uma câmera permanece antes de alternar.',
              presets: {
                title: 'Predefinições Padrão',
                items: {
                  calm: 'Calmo',
                  paced: 'Ritmo',
                  energetic: 'Energético',
                  hyperactive: 'Hiperativo',
                },
              },
              minimumCamTime: {
                title: 'Duração Mínima',
                description:
                  'Tempo mínimo (segundos) que uma câmera permanece antes de alternar.',
                unit: 'segundos',
              },
              maximumCamTime: {
                title: 'Duração Máxima',
                description:
                  'Tempo máximo (segundos) que uma câmera permanece antes de alternar.',
                unit: 'segundos',
              },
            },
            silentVideoClips: {
              title: 'Manipulação de Clipes Não Utilizados',
              description:
                'Escolha remover ou desativar partes não utilizadas do podcast.',
              items: {
                remove: 'Remover',
                disable: 'Desativar',
              },
            },
          },
          cta: {
            disable:
              'Iniciar Edição de Podcast - Desativar Clipes Inutilizados',
            delete: 'Iniciar Edição de Podcast - Remover Clipes Inutilizados',
          },
        },
      },
      error: {
        validation: {
          minimumCamTime: {
            min: 'O tempo mínimo de câmera deve ser no mínimo 0 segundos',
            higherThanMax:
              'O tempo mínimo de câmera deve ser menor que o tempo máximo de câmera',
          },
          maximumCamTime: {
            min: 'O tempo máximo de câmera deve ser no mínimo 0 segundos',
          },
          deleteUnusedClips: {
            required:
              'Selecione uma opção para remover ou desativar clipes não utilizados',
          },
          speakers: {
            videoTracks: {
              min: 'Selecione pelo menos uma faixa de vídeo para cada palestrante',
            },
            audioTrack: {
              min: 'Atribua uma faixa de áudio a cada palestrante',
            },
          },
        },
      },
    },
    silence: {
      title: 'AutoCut Silences',
      steps: {
        noiseLevel: {
          title: 'Nível de Ruído',
          description:
            'Defina o nível de som para identificar silêncios, visível abaixo.',
        },
        silenceSize: {
          title: 'Duração do Silêncio',
          items: {
            silence: {
              title: 'Remover Silêncios Mais Longos Que',
              description: 'Duração mínima (ms) para remover silêncios.',
              unit: 'milissegundos',
            },
            talk: {
              title: 'Manter Conversas Mais Longas Que',
              description:
                'Duração mínima (ms) para manter segmentos de conversa.',
              unit: 'milissegundos',
            },
          },
        },
        paddingSize: {
          title: 'Margem',
          items: {
            before: {
              title: 'Margem Anterior',
              description:
                'Tempo silencioso antes do ruído para garantir início suave da fala.',
              unit: 'milissegundos',
            },
            after: {
              title: 'Margem Posterior',
              description:
                'Tempo silencioso após o ruído para garantir término suave da fala.',
              unit: 'milissegundos',
            },
          },
        },
        transition: {
          params: {
            title: 'Transições',
            warning: 'Transições requerem que os silêncios sejam removidos.',
            default: {
              title: 'Padrão',
              choices: {
                none: 'Nenhum',
              },
            },
            j_l_cut: {
              title: 'J-Cut & L-Cut',
              helper:
                'Adiciona margem ao redor dos cortes para transições suaves e cria uma nova faixa de transição.',
              choices: {
                j_cut: 'J-Cut',
                l_cut: 'L-Cut',
                both: 'Ambos',
              },
            },
            premiere_pro: {
              title: 'DaVinci Resolve',
              choices: {
                constant_power: 'Potência Constante',
              },
            },
          },
        },
        preview: {
          title: 'Pré-visualizar',
          description:
            'Visualize segmentos mantidos e removidos. Use setas para navegar ou centralize na posição do cursor.',
          legend: {
            kept: 'Mantido',
            deleted: 'Deletado',
            margin: 'Margens',
          },
          cta: 'Gerar Pré-visualização',
          center: 'Centralizar em torno do cursor',
        },
      },
      error: {
        validation: {
          noiseLevel: {
            negative: 'O nível de ruído deve ser um valor negativo',
            int: 'O nível de ruído deve ser um número',
          },
          minTimeOfSilence: {
            required: 'A duração mínima do silêncio é obrigatória',
            min: 'A duração mínima do silêncio deve ser pelo menos 0 ms',
          },
          minTimeOfTalk: {
            required: 'A duração mínima da conversa é obrigatória',
            min: 'A duração mínima da conversa deve ser pelo menos 0 ms',
          },
          marginBefore: {
            min: 'A margem antes deve ser pelo menos 0 ms',
          },
          marginAfter: {
            min: 'A margem depois deve ser pelo menos 0 ms',
          },
        },
      },
      transition: {
        params: {
          title: 'Transições',
          warning: 'Transições requerem que os silêncios sejam removidos.',
          default: {
            title: 'Padrão',
            choices: {
              none: 'Nenhum',
            },
          },
          progress: {
            insert: 'Adicionando novas faixas de áudio...',
            move: 'Ajustando áudio para transições...',
            handling: 'Aplicando transição: {transition}',
          },
        },
      },
    },
    swearWords: {
      title: 'Filtro de Profanidades Automático',
      parameters: {
        bleepSoundSelector: {
          mute: 'Mudo',
          helper: 'Selecione o efeito sonoro do bip',
        },
      },
      footerButton: 'Remover profanidades',
      infoText:
        'O Bipe de Palavras Ofensivas, graças à IA, analisa seu áudio e adiciona automaticamente {br} sons de bip sobre as profanidades.',
    },
    zoom: {
      title: 'AutoZoom',
      parameters: {
        preview: {
          title: 'Pré-visualização de Zoom',
          description: 'Defina o ponto focal para zoom',
          maximumZoom: 'Nível máximo de zoom',
          errorMessage: 'Erro ao gerar pré-visualização',
        },
        zoomTypes: 'Estilos de Zoom',
        defaultsPresets: {
          title: 'Predefinições Padrão',
          calm: 'Calmo',
          paced: 'Ritmo',
          energetic: 'Energético',
          hyperactive: 'Hiperativo',
        },
        zoomCoverage: 'Cobertura Total de Zoom',
        constraint: {
          title: 'Limitar Zoom a Clipes Individuais',
          helper:
            'Se ativado, os efeitos de zoom não ultrapassarão os limites dos clipes. Se desativado, o zoom cobre toda a seleção.',
        },
        maximumZoomDuration: 'Duração Máxima do Zoom',
        smoothZooms: {
          title: 'Zooms Suaves',
          coverage: 'Cobertura de Zoom Suave',
          nervousness: 'Velocidade e intensidade do zoom',
        },
        dynamicZooms: {
          title: 'Zooms Dinâmicos',
          coverage: 'Cobertura de Zoom Dinâmico',
        },
      },
      types: {
        smooth: 'Suave',
        dynamic: 'Dinâmico',
      },
      footerButton: 'Aplicar Zoom',
      infoText:
        'O AutoZoom adiciona automaticamente efeitos de zoom com base nos níveis de áudio.',
    },
    captions: {
      title: 'AutoCaptions',
      steps: {
        language: {
          button: 'Abrir Editor de Legendas (Estilo & Transcrição)',
        },
        customization: {
          button: 'Adicionar Legendas',
          parts: {
            style: {
              title: 'Estilo de Legenda',
              modal: {
                title: 'Editar Estilo',
                apply: 'Aplicar Estilo',
                save: 'Aplicar e Salvar como Predefinição',
                text: {
                  title: 'Fonte',
                  font: 'Nome da Fonte',
                  help: 'Como adicionar uma nova família de fontes?',
                  color: 'Cor do Texto',
                },
                animation: {
                  title: 'Animação',
                  wordBox: 'Caixa de Texto',
                },
                outline: {
                  title: 'Contorno',
                  width: 'Largura do Contorno',
                },
                box: {
                  title: 'Caixa de Texto',
                  paddingX: 'Margem Horizontal',
                  paddingY: 'Margem Vertical',
                  opacity: 'Opacidade da Caixa',
                  radius: 'Raio da Cantoneira da Caixa',
                  disabled:
                    'A fonte selecionada não possui suporte à funcionalidade de caixa para este idioma.',
                  tips: 'Escolha uma fonte que suporte o idioma selecionado para essa funcionalidade.',
                },
              },
            },
            preview: {
              title: 'Pré-visualizar',
              modal: {
                title: 'Posição & Tamanho',
                apply: 'Aplicar Estilo',
                fontSize: 'Tamanho da Fonte',
                nbLines: 'Número de Linhas',
                maxWidth: 'Largura Máxima',
                help: 'A pré-visualização é uma funcionalidade em desenvolvimento e pode não ser precisa.',
              },
            },
            transcription: {
              title: 'Editar Transcrição',
              modal: {
                save: 'Salvar',
                select: 'Selecione legendas para editar',
                placeholder:
                  'Escolha o segmento de transcrição para editar ou personalizar',
                formatNumbers: 'Formatar Números',
                checkTranscript: {
                  title: 'Transcrição Revisada?',
                  description: 'Você ainda não revisou a transcrição.',
                  dontShowAgain: 'Não me lembre novamente',
                },
              },
              tooltips: {
                lowConfidence: 'Palavras com baixa confiança',
                merge: 'Mergar segmentos',
                split: 'Dividir segmentos',
              },
              emojis: {
                animated: 'Animado',
                static: 'Estático',
              },
            },
            presets: {
              noPresetSelected: 'Selecione um pré-visualização',
              noPreviewAvailable: 'Nenhuma Pré-visualização Disponível',
              title: 'Predefinições',
              delete: {
                title: 'Excluir Predefinições',
                cancel: 'Cancelar',
                confirm: 'Excluir Predefinição',
              },
              search: {
                title: 'Predefinições da Comunidade',
                input: 'Buscar Predefinições',
                cancel: 'Cancelar',
                confirm: 'Importar Predefinição',
                usage: 'Usado por {count} usuários',
                error:
                  'Erro ao copiar predefinição. Entre em contato através do Discord ou em contact@autocut.fr',
              },
              create: {
                title: 'Criar novo preset',
                placeholder: 'Nome do preset (mínimo de duas palavras)',
                community: 'Compartilhar com a comunidade',
                public: 'Público',
                cancel: 'Cancelar',
                confirm: 'Criar preset',
                alreadyExists: 'O preset já existe. Escolha um nome diferente.',
              },
            },
          },
        },
      },
      error: {
        validation: {
          userPresets: {
            missing:
              'As predefinições do usuário estão ausentes. Redefina suas configurações no menu superior.',
            titleTooShort: 'O título deve ter pelo menos 5 caracteres',
            titleTooLong: 'O título deve ter no máximo 25 caracteres',
            titleNoSpace: 'O título deve conter pelo menos duas palavras',
          },
          position: {
            xPercentage: {
              min: 'A posição X deve ser pelo menos 0',
              max: 'A posição X deve ser no máximo 1',
            },
            yPercentage: {
              min: 'A posição Y deve ser pelo menos 0',
              max: 'A posição Y deve ser no máximo 1',
            },
            emojiXPercentage: {
              min: 'A posição X do Emoji deve ser pelo menos 0',
              max: 'A posição X do Emoji deve ser no máximo 1',
            },
            emojiYPercentage: {
              min: 'A posição Y do Emoji deve ser pelo menos 0',
              max: 'A posição Y do Emoji deve ser no máximo 1',
            },
          },
          text: {
            fontSize: {
              int: 'O tamanho da fonte deve ser um número',
              min: 'O tamanho da fonte deve ser pelo menos 1',
            },
            font: {
              missing:
                'A fonte selecionada está incompleta. Re-selecione-a ou escolha outra.',
            },
          },
          outline: {
            width: {
              int: 'A largura do contorno deve ser um número',
              min: 'A largura do contorno deve ser pelo menos 0',
            },
          },
          formating: {
            nbLines: {
              int: 'O número de linhas deve ser um número',
              min: 'O número de linhas deve ser pelo menos 1',
            },
            maxWidth: {
              int: 'A largura máxima deve ser um número',
              min: 'A largura máxima deve ser pelo menos 0',
            },
          },
          textBox: {
            xPadding: {
              int: 'A margem horizontal deve ser um número',
              min: 'A margem horizontal deve ser pelo menos 0',
            },
            yPadding: {
              int: 'A margem vertical deve ser um número',
              min: 'A margem vertical deve ser pelo menos 0',
            },
            opacity: {
              int: 'A opacidade deve ser um número',
              min: 'A opacidade deve ser pelo menos 0',
              max: 'A opacidade não pode exceder 100',
            },
            radius: {
              int: 'O raio deve ser um número',
              min: 'O raio deve ser pelo menos 0',
            },
          },
        },
      },
    },
    general: {
      steps: {
        select: {
          title: 'Definir Pontos de Entrada/Saída',
          descriptions: {
            '1': 'Ao não definir Pontos de Entrada/Saída, o AutoCut aplicará a toda a linha do tempo.',
            '2': 'Silencie manualmente clipes ou faixas para excluí-los da análise de áudio.',
          },
          cta: 'Confirmar Pontos de Entrada/Saída',
        },
        transcription: {
          title: 'Selecione o Idioma do Clipe de Áudio',
        },
        customization: {
          title: 'Personalizar Configurações',
        },
      },
    },
  },
  auth: {
    login: {
      title: 'Digite a Chave de Licença para Ativar o AutoCut',
      placeholder: 'Insira sua chave de licença',
    },
    trial: {
      title: 'Iniciar Teste Gratuito',
      steps: {
        email: {
          title: 'Digite o Seu Endereço de Email',
          subtitle: 'Receba uma licença de teste por email',
          placeholder: 'email@exemplo.com',
          cta: 'Próximo',
        },
        sendingEmail: {
          title: 'Enviando Email',
          success: 'Email enviado com sucesso para {email}',
        },
      },
    },
    errors: {
      no_key: 'É necessária uma chave de licença',
      is_key_valid_error:
        'Falha na validação da chave. Por favor, tente novamente mais tarde.',
      invalid_response: 'Recebeu uma resposta inesperada do servidor.',
      cant_read_host_error: 'Não foi possível recuperar o nome do computador.',
      cant_read_uuid_error: 'Não foi possível recuperar o UUID do computador.',
    },
  },
  modals: {
    usageStats: {
      title: 'Estatísticas de Uso',
      select: {
        label: 'Escolha um modo',
      },
      stats: {
        thisMonth: 'Este Mês',
        allTime: 'Todos os Tempos',
        mostUsed: 'Recurso Mais Usado',
      },
    },
    endProcess: {
      title: 'Processo Concluído',
      time: 'Concluído em {minutes} minutos e {seconds} segundos!',
      timeSaved:
        'Tempo Economizado: <b>{minutesSaved}</b> minutos e <b>{secondsSaved}</b> segundos',
      timeSavedAllTime:
        'Tempo Total Economizado com AutoCut: {hourSaved} horas e {minutesSaved} minutos! 🎉',
      feedback: 'Valorizamos seu feedback sobre os resultados:',
      review: 'Avalie o AutoCut!',
      captions: {
        title: 'Legendas Adicionadas!',
        footer:
          'Precisa ajustar legendas ou transcrição? Retorne ao Editor de Legendas.',
        button: 'Voltar ao Editor de Transcrição',
        text_1: 'Legendas animadas foram adicionadas! 🎉',
        text_2: 'Edite as legendas se necessário:',
        list_1: 'Coloque o cursor da linha do tempo nas legendas desejadas',
        list_2: 'Vá para a página da Fusion',
        list_3: 'Edite legendas manualmente no nó TextPlus',
        text_3: 'Para mais dicas, assista a este <a>tutorial</a>.',
      },
      nextMode: {
        silence: {
          text: 'Para um áudio suave, use o AutoCut Silences para eliminar pausas constrangedoras sem esforço.',
          button: 'Ir para AutoCut Silences',
        },
        podcast: {
          text: 'Melhore seu podcast com o AutoCut Podcast para uma edição simplificada e transições de câmera automáticas.',
          button: 'Ir para AutoCut Podcast',
        },
        repeat: {
          text: 'Engaje seu público! Use o AutoCut Repeat para manter apenas as partes mais envolventes do seu conteúdo.',
          button: 'Ir para AutoCut Repeat',
        },
        zoom: {
          text: 'Dê vida aos seus vídeos com o AutoZoom! Adicione efeitos de zoom dinâmicos que cativam seu público.',
          button: 'Ir para AutoZoom',
        },
        caption: {
          text: 'Conecte-se com os espectadores usando o AutoCaptions. Adicione legendas estilosas e sincronizadas para aprimorar a narrativa.',
          button: 'Ir para AutoCaptions',
        },
        resize: {
          text: 'Otimize seus vídeos para qualquer plataforma com o AutoResize.',
          button: 'Ir para AutoResize',
        },
        broll: {
          text: 'Enriqueça sua narrativa com o AutoB-Roll, aproveitando a IA para adicionar filmagens de forma integrada.',
          button: 'Ir para AutoB-Roll',
        },
        swear: {
          word: {
            text: 'Mantenha um diálogo profissional com o AutoProfanity Filter. Censure automaticamente a linguagem indesejada.',
            button: 'Ir para AutoProfanity Filter',
          },
        },
      },
    },
    discord: {
      title: 'Discord',
    },
    unsupportedVersion: {
      title: 'Versão não suportada do DaVinci Resolve',
      currentVersion: 'Versão do DaVinci Resolve detectada: {version}',
      text: 'O AutoCut requer a versão 18.6 ou superior do DaVinci Resolve.',
      update: 'Atualize em: <a>Website da DaVinci</a>',
    },
  },
  errors: {
    modal: {
      title: {
        noSelectedAudioClip: 'Nenhum clipe de áudio selecionado',
        noTimelineFound: 'Nenhuma linha do tempo encontrada',
        subsequenceNotReachable: 'Subsequência Inacessível',
        unknown: 'Erro Desconhecido Ocorreu',
        noSpace: 'Erro de Armazenamento Insuficiente',
        exportUnknown: 'Erro de Exportação no DaVinci Resolve',
        invalidInputData: 'Erro na geração de arquivo temporário',
        fileNotFound: 'Erro de Leitura de Arquivo',
        scriptNotLoaded: 'Erro ao Carregar o Script AutoCut',
        ffmpegError: 'Erro de leitura de arquivo.',
        exportCancelled: 'Exportação Cancelada',
        timelineMuted: 'Nenhas Faixas Audíveis',
        noAudibleAudio: 'Nenhum Áudio Audível Encontrado',
        offline: 'Status Offline Detectado',
        resourceTimeout: 'Tempo Esgotado para Recursos',
        backupNotFound: 'Backup Não Encontrado',
        setCursorOutInterval: 'Fora dos Pontos In/Out',
        emptyPath: 'Erro de Análise de Seleção',
        sequenceMismatch: 'Operação Incapaz de Completar',
      },
      text: {
        noSelectedAudioClip:
          'Nenhum clipe de áudio existe entre seus pontos de entrada e saída selecionados',
        noTimelineFound: 'Nenhuma sequência ativa foi encontrada',
        subsequenceNotReachable:
          'Não é possível acessar as subsequências selecionadas.',
        unknown: 'Erro AutoCut com causa desconhecida.',
        noSpace: 'O armazenamento do seu dispositivo é insuficiente.',
        exportUnknown: 'Uma falta de espaço em disco causou esse erro.',
        invalidInputData: 'A geração de arquivo temporário encontrou um erro.',
        fileNotFound: 'Verifique se {filename} está no local: {filepath}',
        scriptNotLoaded:
          'Feche todas as outras extensões, DaVinci Resolve e tente novamente.',
        ffmpegError: 'O arquivo {filepath} está possivelmente corrompido.',
        exportCancelled:
          'A análise de áudio parou se a codificação for cancelada. Tente novamente.',
        timelineMuted: 'As faixas de áudio selecionadas estão silenciosas.',
        noAudibleAudio: 'O áudio detectável não está disponível.',
        offline: 'Os servidores estão inacessíveis.',
        resourceTimeout: 'Não foi possível baixar os recursos em 30 segundos',
        backupNotFound:
          'Não é possível encontrar a sequência de backup para restaurar.',
        setCursorOutInterval:
          'A seção selecionada está fora dos pontos In/Out.',
        emptyPath:
          'Os clipes selecionados podem não ter caminhos no seu computador.',
        sequenceMismatch:
          'Evite alterações de sequência enquanto AutoCut processa.',
      },
      tips: {
        noSelectedAudioClip:
          'Por favor, configure corretamente seus pontos de entrada e saída',
        noTimelineFound: 'Por favor, selecione uma linha do tempo válida',
        subsequenceNotReachable:
          'Certifique-se de que as subsequências na linha do tempo compartilhem nomes idênticos com as sequências originais no painel do projeto.',
        unknown:
          'Tente modificar a seleção/configurações. Problemas persistentes? Entre em contato conosco.',
        noSpace: 'Libere espaço e reinicie o AutoCut.',
        exportUnknown: 'Libere espaço e reinicie o AutoCut.',
        invalidInputData:
          'Tente novamente com a mesma seleção. Entre em contato conosco com seu arquivo se o problema persistir.',
        fileNotFound:
          'Tente novamente com a mesma seleção. Entre em contato conosco com seu arquivo se o problema persistir.',
        scriptNotLoaded: 'Problema persistente? Entre em contato conosco.',
        ffmpegError: 'Problema persistente? Entre em contato conosco.',
        exportCancelled: ' ',
        timelineMuted: 'Desmute pelo menos uma faixa.',
        noAudibleAudio:
          'Certifique-se de que os clipes estejam ativos e audíveis. Problema persistente? Entre em contato conosco no Discord ou em contact@autocut.fr.',
        offline: 'Verifique a conexão com a internet e tente novamente.',
        resourceTimeout:
          'Verifique a conexão com a internet e tente novamente.',
        backupNotFound:
          'Verifique a pasta "AutoCut-Backup" na raiz do projeto para verificar a presença de backup.',
        setCursorOutInterval:
          'Remova os pontos In/Out para acessar esta seção da transcrição.',
        noSoundDetected:
          'Nenhum som detectado. Verifique se o idioma combina com os clipes, se as faixas não estão mudas e se os pontos In/Out incluem clipes de áudio. {br}Problema persistente? Entre em contato conosco no Discord ou em contact@autocut.fr.',
        emptyPath:
          'Certifique-se de que os clipes sejam mídias atômicas ou subsequências.',
        sequenceMismatch:
          'Evite mudanças de sequência e modificações até a finalização do processo AutoCut.',
      },
    },
  },

  globals: {
    contactMail: 'contact@autocut.com',
    defaults: {
      components: {
        ProcessModal: {
          defaultStepName: 'Processando',
        },
        KebabMenu: {
          account: 'Gerenciar Chave de Licença',
          usageStatistics: 'Estatísticas de Uso',
          bug: 'Relatar um Erro',
          review: 'Avaliar AutoCut',
          tuto: 'Assistir ao Tutorial',
          logout: 'Sair',
          settings: 'Redefinir Configurações',
          featureRequest: 'Solicitar um Recurso',
          academy: 'Centro de Ajuda',
        },
        LicenseInfos: {
          timeLeft: '{daysLeft} dias restantes',
          info: 'Licença {licenceType}',
          trialButton: 'Saiba Mais',
          paidButton: 'Gerenciar Licença',
        },
        PromoBanner: {
          promo: 'Economize até 20% nas próximas 24 horas!',
          shop: 'Compre Agora',
        },
        FileInput: {
          uploadScreenshot: 'Carregar Captura de Tela de Problemas',
        },
        LinkToUserFileInput: {
          sendLogLinkToUserFile: {
            title: 'Forneça um link para seu arquivo de vídeo e áudio.',
            description:
              '(Recomendado: SwissTransfer: https://www.swisstransfer.com/)',
            helper:
              "Para enviar via SwissTransfer, acesse swisstransfer.com, clique em 'Carregar Arquivos', obtenha um link e cole aqui.",
            end: 'Necessário para resolução de problemas e análise.',
          },
        },
        Select: {
          'no-more': 'Sem mais opções',
          'select-all': 'Selecionar Tudo',
          placeholder: 'Escolher uma opção',
        },
        PreviewFrame: {
          previewOnIndicator: 'Pré-visualizar Centralizado no Indicador',
        },
        FontNotFoundModal: {
          title: 'Fonte não encontrada',
          description:
            'A fonte não foi encontrada no seu sistema, por favor selecione uma nova fonte para adicionar legendas.',
          help: 'Você pode adicionar uma nova família de fontes seguindo este tutorial.',
        },
      },
    },
    misc: {
      retry: 'Tentar Novamente',
    },
    units: {
      second: 'Segundos',
      millisecond: 'Milissegundos',
      percentage: '%',
      px: 'Píxeis',
    },
  },
  old: {
    text: {
      errorSelection:
        'Seleção inválida.{br}Selecione faixas diretamente de arquivos de áudio/vídeo.{br}Se uma sequência estiver selecionada, divida os vídeos dentro dela.',
      loading: 'Executando cortes...',
      Step4: {
        AutoZoom: {
          ratio: 'Coeficiente Máximo de Zoom',
          step: 'Percentual de Vídeo com Zoom',
        },
        Title: 'AutoZoom (Beta)',
      },
      Step5: {
        Backup: 'Ativar Backup',
        Title: 'Pré-visualizar',
      },
      autocutv2:
        'AutoCut v2 oferece opções avançadas para detectar silêncios de forma otimizada.',
      autocutAi:
        'AutoCut AI detecta silêncios automaticamente com configuração mínima.',
      autocutPodcast:
        'AutoCut Podcast edita rapidamente vídeos de podcasts com base em sua configuração de câmera.',
      autocutRepeat:
        'AutoCut Repeat remove tomadas ruins, mantendo apenas as melhores partes.',
      autocutCaptions:
        'AutoCaptions adiciona rapidamente legendas estilizadas aos seus vídeos.',
      Step3: {
        Remove: 'Remover',
        Keep: 'Manter',
        CenterButton: 'Centralizar no Indicador',
        Previsualisation: {
          __text__: 'Pré-visualizar:',
          loading: 'Carregando...',
        },
        Noise: {
          __text__: 'Nível de Ruído',
          helper:
            'Defina o nível de som para classificar o silêncio. As alterações aparecem na pré-visualização abaixo.',
          unit: 'Decibéis',
        },
        DeleteSilence: 'Deletar Silêncios',
        KeepSilence: {
          Yes: 'Sim',
          No: 'Não',
        },
        Title: 'Ajustar Nível de Ruído',
      },
      Step2: {
        After: 'Margem Posterior',
        Before: 'Margem Anterior',
        Title: 'Margem',
      },
      Step2Ai: {
        Title: 'Verificar Pré-visualização',
      },
      Step1: {
        Talks: 'Manter Conversas Maiores Que',
        Silences: 'Deletar Silêncios Maiores Que',
        Title: 'Durações de Silêncio',
      },
      Step1Ai: {
        Silences: 'Selecionar Agressividade de IA',
        Title: 'Agressividade de IA',
      },
      Step1Podcast: {
        Title: 'Palestrantes',
      },
      step6: {
        selected: {
          clips: 'Cliques Selecionados',
        },
      },
      NoInternet: 'É necessária uma conexão com a internet.',
      noClip: {
        title: 'Selecione clipes para cortar na sua linha do tempo',
        no_selection:
          'Se o AutoCut não estiver respondendo, tente reiniciar o DaVinci Resolve.',
        tuto: {
          __text__: 'Precisa de ajuda? Assista ao nosso',
          link: ' tutorial em vídeo',
        },
        loading: {
          bar: 'Carregando - Analisando sequência...',
        },
      },
      needPProUpdate: 'O DaVinci Resolve deve estar na versão 15 ou posterior.',
      No_Key: 'Insira uma chave de licença',
      Login: {
        SubmitButton: 'Ativar',
        SubmitButton2: 'Ativar',
        PasteButton: 'Colar',
      },
      Welcome: {
        Title: 'Bem-vindo',
        freeTrial: 'Iniciar Teste Gratuito',
        UseLicence: 'Usar uma Chave de Licença',
        BuyLicence: 'Comprar uma Chave de Licença',
      },
      UseLicence: {
        Title: 'Usar uma Chave de Licença',
        Text: 'Insira sua chave de licença para começar a usar o AutoCut!',
      },
      GetLicence: {
        Title: 'Obter uma Licença de Teste Gratuito por Email',
        Select: 'Como você descobriu o AutoCut?',
        Text: 'Digite seu email para receber uma licença de teste gratuito',
        Button: 'Solicitar Licença Gratuita',
      },
      UseNewLicence: {
        Title: 'Chave de Licença Enviada para {s}!',
        Text: 'Verifique seu email, insira a chave de licença e comece a usar o AutoCut. Se não recebido, verifique a pasta de spam.',
        Resend: 'Não recebeu o email?',
        ResendButton: 'Reenviar para {mail}',
        ResendSucces: 'Email enviado com sucesso!',
      },
      NoFFMPEG: 'Ocorreu um erro no FFMPEG. Entre em contato com o suporte.',
      legend: {
        kept: 'Mantido',
        deleted: 'Deletado',
        margin: 'Margens',
      },
      yes: 'Sim',
      no: 'Não',
      undo: {
        modal: 'Não está satisfeito com os resultados?',
      },
      since: {
        your: {
          last: {
            visit: 'Desde sua última visita',
          },
        },
      },
      not: {
        supported: {
          modal: {
            corrupted: 'Formato de arquivo não suportado: {filename}',
            speed: 'Velocidade de clipe não suportada. Use velocidade padrão.',
            multi: {
              audio:
                'Todas as faixas de áudio devem ser selecionadas em um arquivo.',
              stream: 'Arquivo contém múltiplos streams de áudio.',
            },
            '51_stereo': 'Áudio 5.1 detectado em estéreo pelo DaVinci Resolve.',
            audio: {
              layout: 'Layout de áudio não suportado no arquivo.',
            },
            remote: {
              file: 'O arquivo {filename} não está acessível.',
            },
          },
        },
        maintained: {
          modal:
            'Atualize para uma versão mais recente do DaVinci Resolve para usar o AutoCut.',
        },
      },
      end: {
        not: {
          supported: {
            modal: {
              corrupted: 'Escolha clipes diferentes para cortar.',
              speed:
                'Redefina as velocidades dos clipes ou escolha clipes diferentes.',
              multi: {
                audio:
                  'Certifique-se de que a ordem de áudio padrão do DaVinci Resolve seja usada para todas as faixas.',
                stream: 'Escolha clipes diferentes para cortar.',
              },
              '51_stereo':
                'Selecione clipes diferentes ou ajuste os canais da sequência.',
              audio: {
                layout:
                  'Selecione clipes diferentes ou forneça uma captura de tela da sequência se o problema persistir.',
              },
              remote: {
                file: 'Certifique-se de que os arquivos estão armazenados localmente, não na nuvem.',
              },
            },
          },
        },
      },
      use: {
        paid: {
          licence: 'Insira sua chave de licença para ativar o AutoCut.',
        },
      },
      no_audios: 'O áudio será analisado para detectar e remover silêncios.',
      step1: {
        podcast: {
          title: 'Durações de Exibição de Câmera',
          minimum: 'Tempo Mínimo de Câmera',
          maximum: 'Tempo Máximo de Câmera',
        },
      },
      silences: {
        __text__: 'Silêncios',
        step: {
          delete: {
            __text__: 'Remover {mode}',
            keep: {
              spaces: 'Remover {mode}, manter espaços',
            },
          },
          keep: 'Manter {mode}',
          mute: 'Silenciar {mode}',
        },
      },
      repetitions: 'Repetições',
      podcast: {
        add: {
          speaker: 'Adicionar Palestrante',
          camera: 'Adicionar Câmera',
        },
        track: 'Faixa',
        camera: {
          title: 'Câmeras',
          helper:
            'Especifique palestrantes visíveis por câmera. Vários palestrantes permitidos por câmera.',
        },
        speaker: 'Palestrante',
        all: {
          speakers: 'Todos os Palestrantes',
        },
      },
    },
    help: {
      bug: 'Relatar um Erro',
      review: 'Avaliar AutoCut',
      multipiste: 'Precisa de ajuda multi-faixa? Assista ao nosso tutorial',
      tuto: 'Assistir ao Tutorial',
      academy: 'Centro de Ajuda',
      buy: 'Comprar Chave de Licença',
      account: 'Gerenciar Chave de Licença',
      logout: 'Sair',
      reset: {
        settings: 'Restaurar Configurações',
      },
      transcription: {
        provider: {
          step: 'A qualidade da transcrição varia conforme o modelo escolhido.',
        },
      },
    },
    bugBtnDialog: 'Envie um email para o suporte: contact@autocut.com',
    first: {
      launch: {
        error: 'Erro ao iniciar o AutoCut. Por favor, reinicie.',
      },
    },
    helper: {
      Step4: {
        '1': 'Ative o AutoZoom para aplicar efeitos de zoom aos clipes.',
        '2': 'Coeficiente de zoom com base nos valores especificados e volume do clipe.',
        '3': 'Selecione a porcentagem de zoom desejada.',
      },
      Step5: 'Backup permite desfazer todas as alterações feitas após o corte.',
      Step1: {
        '1': 'Duração do silêncio para remoção (segundos).',
        '2': 'Duração do segmento de conversa para retenção (segundos).',
        podcast: {
          minium:
            'Tempo mínimo de exibição antes da troca de câmera (segundos).',
          maximum:
            'Tempo máximo de exibição antes da troca de câmera (segundos).',
        },
      },
      Step1Ai:
        'Selecione o nível de agressividade (1-4) para remoção de silêncios pela IA.',
      Step2: {
        '1': 'Duração do silêncio antes da conversa.',
        '2': 'Duração do silêncio após a conversa.',
      },
      Step3: {
        '1': 'Defina o nível de som para classificar os silêncios. Visualize as alterações abaixo.',
        '2': 'Revise os segmentos mantidos e deletados. Use setas para navegar; centralize no cursor.',
        '3': 'Os silêncios serão cortados sem serem removidos.',
      },
      Save: {
        Default: 'Salva configurações e as carrega na próxima inicialização.',
      },
      Undo: {
        Cut: 'Reverte o último corte, restaurando o estado anterior do clipe.',
      },
      speakers: {
        podcast: {
          __text__:
            'Adicione e nomeie palestrantes, atribuindo suas faixas de áudio.',
          audio: {
            tracks:
              'Selecione a faixa de áudio para cada palestrante. Uma faixa por palestrante.',
          },
          video: {
            tracks:
              'Selecione as faixas de vídeo para cada palestrante. Vários palestrantes por câmera permitidos.',
          },
        },
      },
    },
    warn: {
      Step5: 'Cuidado: Desativar o backup impede o desfazer.',
    },
    title: {
      Step5: {
        Backup: 'Ativar Backup',
      },
      undo: {
        modal: 'Feito!',
      },
      not: {
        supported: {
          modal: {
            corrupted: 'Um formato de clipe não é suportado',
            speed: 'Clipes com velocidade padrão apenas',
            multi: {
              audio:
                'O AutoCut pode ter dificuldades com faixas de áudio múltiplas',
              stream: 'Múltiplos streams de áudio em um clipe',
            },
            '51_stereo': 'Layout de áudio 5.1 mostrado como estéreo',
            audio: {
              layout: 'Layout de áudio do clipe não suportado',
            },
            remote: {
              file: 'Clipe ausente do dispositivo',
            },
          },
        },
        maintained: {
          modal:
            'Atualizações do AutoCut não disponíveis para versões do DaVinci Resolve anteriores a 2021.',
        },
      },
      use: {
        trial: {
          licence: 'Revise o email e insira a chave de licença',
        },
        paid: {
          licence: 'Ativar Chave de Licença',
        },
      },
      no_audios: 'Nenhum áudio selecionado',
      banner: {
        podcast: {
          beta: 'Obrigado por testar o modo Podcast.',
        },
      },
      silences: {
        step: 'Gerenciar {mode}',
      },
      podcast: {
        silences: {
          step: 'Remover/Silenciar Silêncios',
        },
      },
    },
    generate: {
      preview: 'Criar uma Pré-visualização',
    },
    silence: {
      link: {
        tuto: 'https://www.youtube.com/watch?v=7MEK3rZQDHI',
      },
      button: {
        delete: {
          j_cut: 'J-Cut & Deletar Silêncios',
          l_cut: 'L-Cut & Deletar Silêncios',
          both: 'J&L-Cut & Deletar Silêncios',
          constant: {
            power: 'Cortar, Deletar Silêncios & Aplicar Transição de Áudio',
          },
          none: 'Cortar & Deletar {mode}',
          keep: {
            spaces: {
              none: 'Cortar {mode} & Manter Espaços',
            },
          },
        },
        keep: {
          none: 'Cortar & Reter {mode}',
        },
        mute: {
          none: 'Cortar & Silenciar {mode}',
        },
      },
    },
    podcast: {
      link: {
        tuto: 'https://youtu.be/EgsUlSLsK8Y?si=BHwFFuuLjw8DsHYX',
      },
      getting: {
        tracks: 'Recuperando faixas...',
        silences: 'Recuperando silêncios...',
      },
      edit: {
        track: 'Editando Faixa {indexTrackInfo}: {current} de {max}',
      },
      final: {
        message: {
          __text__: 'Sucesso! Podcast editado com sucesso',
          error: 'Erro ao editar podcast',
        },
      },
      button: {
        disable: 'Editar & Silenciar Clipe Inutilizado',
        delete: 'Editar & Remover Clipe Inutilizado',
      },
      not: {
        available: {
          title: 'Escolha uma sequência com mais de 2 faixas de vídeo e áudio',
        },
      },
      minimum: {
        higher: {
          maximum: 'O tempo mínimo da câmera deve ser menor que o máximo.',
        },
      },
      empty: {
        audio: {
          track: 'As faixas de áudio não podem estar vazias.',
        },
        video: {
          track: 'As faixas de vídeo não podem estar vazias.',
        },
      },
      preset: {
        title: 'Predefinições Padrão',
        calm: 'Calmo',
        paced: 'Ritmo',
        energetic: 'Energético',
        hyperactive: 'Hiperativo',
      },
    },
    error: {
      UseLicence: {
        __text__: 'Desvincule chaves de outros dispositivos através do',
        link: 'portal do cliente do AutoCut.',
      },
      modal: {
        title: {
          subsequenceNotReachable: 'Subsequência Inalcançável',
          unknown: 'Ocorreu um Erro Desconhecido',
          noSpace: 'Erro de Armazenamento Insuficiente',
          exportUnknown: 'Erro de Exportação no DaVinci Resolve',
          invalidInputData: 'Erro ao gerar arquivo temporário',
          fileNotFound: 'Erro ao Ler o Arquivo',
          scriptNotLoaded: 'Erro ao Carregar Script AutoCut',
          ffmpegError: 'Erro ao ler o arquivo.',
          exportCancelled: 'Exportação Cancelada',
          timelineMuted: 'Sem Faixas Audíveis',
          noAudibleAudio: 'Nenhum Áudio Audível Encontrado',
          offline: 'Status Offline Detectado',
          resourceTimeout: 'Tempo Limite de Recurso',
          backupNotFound: 'Backup Não Encontrado',
          setCursorOutInterval: 'Fora de Intervalos de Entrada/Saída',
          emptyPath: 'Erro de Análise de Seleção',
          sequenceMismatch: 'Operação Não Pôde Ser Concluída',
        },
        text: {
          subsequenceNotReachable:
            'Não é possível acessar as subsequências selecionadas.',
          unknown: 'Erro no AutoCut com causa desconhecida.',
          noSpace: 'O armazenamento do dispositivo é insuficiente.',
          exportUnknown: 'Falta de armazenamento em disco causou este erro.',
          invalidInputData: 'Erro ao gerar arquivo temporário.',
          fileNotFound: 'Verifique se {filename} está no local: {filepath}',
          scriptNotLoaded:
            'Feche todas as outras extensões, DaVinci Resolve e tente novamente.',
          ffmpegError: 'O arquivo {filepath} pode estar corrompido.',
          exportCancelled:
            'Análise de áudio interrompida se a codificação foi cancelada. Tente novamente.',
          timelineMuted: 'As faixas de áudio selecionadas estão silenciadas.',
          noAudibleAudio: 'Áudio detectável indisponível.',
          offline: 'Os servidores estão inacessíveis.',
          resourceTimeout: 'Não foi possível baixar recursos em 30 segundos',
          backupNotFound:
            'Não é possível encontrar a sequência de backup para restaurar.',
          setCursorOutInterval:
            'Seção selecionada está fora dos pontos de Entrada/Saída.',
          emptyPath:
            'Os clipes selecionados podem não ter caminhos no seu computador.',
          sequenceMismatch:
            'Evite alterar sequências enquanto o AutoCut processa.',
        },
        tips: {
          subsequenceNotReachable:
            'Certifique-se de que as subsequências na linha do tempo compartilhem nomes idênticos com as sequências originais no painel do projeto.',
          unknown:
            'Tente modificar seleção/configurações. Problemas persistem? Entre em contato conosco.',
          noSpace: 'Libere espaço e relance o AutoCut.',
          exportUnknown: 'Libere espaço e relance o AutoCut.',
          invalidInputData:
            'Tente novamente com a mesma seleção. Entre em contato conosco com seu arquivo se o problema persistir.',
          fileNotFound:
            'Tente novamente com a mesma seleção. Entre em contato conosco com seu arquivo se o problema persistir',
          scriptNotLoaded: 'Problema persiste? Entre em contato conosco.',
          ffmpegError: 'Problema persiste? Entre em contato conosco.',
          exportCancelled: ' ',
          timelineMuted: 'Ative pelo menos uma faixa.',
          noAudibleAudio:
            'Certifique-se de que os clipes estão ativos e audíveis. Problema persiste? Entre em contato conosco pelo Discord ou em contact@autocut.fr.',
          offline: 'Verifique a conexão à internet e tente novamente.',
          resourceTimeout: 'Verifique a conexão à internet e tente novamente.',
          backupNotFound:
            'Verifique o contêiner "AutoCut-Backup" na raiz do projeto para presença de backup.',
          setCursorOutInterval:
            'Remova os pontos de Entrada/Saída para acessar esta seção da transcrição.',
          noSoundDetected:
            'Nenhum som detectado. Verifique se o idioma corresponde aos clipes, as faixas não estão silenciadas e os pontos de Entrada/Saída incluem clipes de som. {br}Problema persiste? Entre em contato conosco pelo Discord ou em contact@autocut.fr.',
          emptyPath:
            'Garanta que os clipes são mídias atômicas ou subsequências.',
          sequenceMismatch:
            'Evite alterações e modificações na sequência até a conclusão do processo do AutoCut.',
        },
        lost: {
          cep: {
            title: 'Erro de Conexão Perdida',
            text: {
              '1': 'Conexão com o DaVinci Resolve perdida. Reinicie a extensão.',
              '2': 'Falha? Reinicie o DaVinci Resolve.',
            },
          },
        },
      },
      language: {
        not: {
          supported: 'Suporte de idioma indisponível para este modo.',
        },
      },
    },
    retour: 'A chave não existe!',
    countdown: {
      days: 'Dias de Teste Restantes',
    },
    saveas: {
      default: {
        settings: 'Salvar Parâmetros Atuais',
      },
    },
    button: {
      no_silences: 'Nenhum Silêncio Encontrado',
      no_audios: 'Clipe de Áudio Selecionado Ausente',
      SavingBackup: 'Salvando Backup do Projeto...',
      RestoringBackup: 'Restaurando Projeto...',
      ExportingAudio: 'Exportando Áudio...',
      UploadingAudio: 'Enviando Áudio...',
      SearchingSilences: 'Buscando Silêncios...',
      CuttingStep: {
        Audio:
          'Realizando Cortes de Áudio na Faixa {numTrack}: {current} de {max}',
        Video:
          'Realizando Cortes de Vídeo na Faixa {numTrack}: {current} de {max}',
      },
      PostCuttingStep: 'Cortes Concluídos...',
      PreDeletingStep: 'Buscando Cliques de Silêncio para Deletar...',
      PostDeletingStep: 'Silêncios Removidos...',
      finalizing: 'Finalizando...',
      DeletingStart: 'Deletando Silêncios...',
      AssociatingStep: 'Realizando Associação {current} de {max}',
      Success: 'Processo Bem-sucedido!',
      Failure: 'Falha... Problema Encontrado',
      NoSilences: 'Nenhum Silêncio Detectado',
      undo: {
        modal: 'Reverter',
      },
      not: {
        supported: {
          modal: 'Fechar',
        },
      },
      PreZoomingStep: 'Obtendo Subclips para Zoom...',
      ZoomingStep: 'Adicionando Zoom: {current} de {max}',
      Autozoom: 'Preparando AutoZoom...',
      no_video: {
        to_zoom: 'Nenhum Vídeo para Zoom.',
      },
      getSubclip: {
        step: 'Recuperando Subclip para AutoZoom...',
      },
      checkSilenceSuppression: {
        step: 'Verificando Remoção de Silêncios...',
      },
      back: 'Voltar',
      DeletingStep: {
        delete: {
          Audio:
            'Deletando Silêncio na Faixa de Áudio {numTrack}: {current} de {max}',
          Video:
            'Deletando Silêncio na Faixa de Vídeo {numTrack}: {current} de {max}',
        },
        mute: {
          Audio:
            'Silenciando Silêncio na Faixa de Áudio {numTrack}: {current} de {max}',
          Video:
            'Silenciando Silêncio na Faixa de Vídeo {numTrack}: {current} de {max}',
        },
      },
      skip: 'Pular',
      save: 'Salvar',
    },
    warning: {
      DontTouchTimeline: 'Evite Editar a Linha do Tempo Durante o Processo',
      undo: {
        modal: 'Metadados ausentes detectados na análise do arquivo de áudio.',
      },
      modal: {
        title: {
          ffmpeg: 'Possível Problema no FFMPEG',
        },
        text: {
          ffmpeg: 'Metadados ausentes detectados no arquivo {filename}.',
        },
        tips: {
          ffmpeg:
            'Considere recodificar o arquivo se os problemas persistirem.',
        },
      },
      beta: {
        version:
          'Versão beta do DaVinci Resolve detectada. O AutoCut pode apresentar problemas. Testar em uma versão estável recente do DaVinci Resolve é recomendável.',
      },
    },
    preview: {
      loading: {
        selection: {
          validation: 'Validando seleção...',
          parsing: {
            step: 'Recuperando dados: clipe {current} de {max}...',
          },
        },
        silence: {
          detection: {
            step: 'Detectando silêncio: clipe {current} de {max}...',
          },
        },
        drawing: 'Gerando pré-visualização...',
      },
      frame: {
        no_sequence: 'Comece uma sequência para visualizar uma prévia',
      },
    },
    contact: {
      us_title: 'Desculpe pelo transtorno',
      us_text: {
        '2': 'Para problemas não relacionados à edição (conta, chave, pagamento, etc.):',
        __text__:
          'Se o AutoCut não estiver funcionando corretamente e você gostaria de relatar o problema:',
      },
      us_button: 'Relatar Problema',
      us_contact: 'Contate-nos:',
      us_copy: 'Informações de contato copiadas!',
    },
    switch: {
      to_send: {
        log: {
          button: 'Alternativamente, envie um arquivo de log',
        },
      },
    },
    send: {
      log: {
        title: 'Pedimos desculpas pelo problema',
        details: 'Este arquivo de log mostra como seu mídia é processada.',
        select:
          'Escolha um arquivo de log diário para ajudar a resolver o problema.',
        text: {
          area: 'Descreva seu problema em detalhes:',
        },
        button: 'Enviar Arquivo de Log',
        success: {
          title: 'Arquivo Recebido! Investigaremos.',
        },
        thank: {
          text: 'Continue sua edição.',
          text2: 'Atualizaremos você assim que o problema for resolvido.',
        },
        message: {
          error: 'A mensagem deve ter pelo menos 10 caracteres',
        },
        screen: {
          timeline: {
            __text__:
              'Inclua uma captura de tela do seu cronograma do DaVinci Resolve.',
            before: 'Inclua uma captura de tela antes do corte.',
            after: 'Inclua uma captura de tela após o corte.',
          },
          error: {
            message: 'Inclua uma captura de tela da mensagem de erro.',
          },
        },
        trial: {
          mail: 'Forneça o e-mail associado ao teste.',
        },
        other:
          'Forneça qualquer informação adicional para ajudar a resolver o problema.',
      },
    },
    select: {
      issue: {
        title: 'Selecione Seu Problema Abaixo.',
        subtitle: 'Isso nos ajuda a resolver seu problema rapidamente.',
      },
      one: {
        audios: 'Selecione um ou mais clipes de áudio para usar o AutoCut.',
      },
    },
    upload: {
      screenshot: 'Enviar uma Captura de Tela',
    },
    modal: {
      feedback: {
        title: 'Feedback',
        input: {
          feedback: {
            title: 'O que você acha do AutoCut?',
            placeholder: 'Insira seu feedback...',
          },
          improve: {
            title: 'Sugestões de melhoria?',
            placeholder: 'Insira suas sugestões...',
          },
        },
        button: {
          text: 'Enviar',
        },
        sent: {
          title: 'Obrigado pelo Seu Feedback!',
          text: 'Tem ideias de funcionalidades? Deixe-nos saber!',
          button: {
            text: 'Solicitar Recurso',
          },
        },
      },
      bug: {
        report: {
          title: 'Relatar Erro',
          description: {
            placeholder: 'Descreva o problema...',
            error: 'A descrição do erro deve ter pelo menos 20 caracteres.',
          },
          reproduce: {
            placeholder: 'Passos para reproduzir o problema...',
            error:
              'Os detalhes da reprodução devem ter pelo menos 20 caracteres.',
          },
          blocking: 'Este é um problema bloqueante?',
          button: {
            text: {
              __text__: 'Enviar Relatório de Erro',
              success: 'Obrigado pelo seu relato!',
            },
          },
          link: {
            error: 'Forneça um link de arquivo válido.',
          },
        },
      },
    },
    issue: {
      undo: {
        modal: 'Encontrou problemas com os cortes?',
      },
    },
    star: {
      review: {
        title: 'Deixe uma Avaliação no AutoCut!',
        captions: {
          title: 'Avalie o AutoCaptions!',
        },
        text: 'Compartilhe sua experiência com o AutoCut!',
      },
    },
    review: {
      title: 'Forneça Mais Detalhes',
      text: 'Satisfeito com os cortes? Dê feedback para ajudar a melhorar o AutoCut!',
      form: {
        placeholder: 'Insira seu feedback...',
      },
      button: 'Enviar',
      thank: {
        title: 'Obrigado pelo Seu Feedback!',
        text: 'Continue com sua edição.',
      },
    },
    exchange: {
      title: 'Ganhe 7 Dias Extras de Teste!',
      text: 'Avalie o AutoCut no Adobe Exchange para receber 7 dias de teste adicionais!',
      button: 'Avalie o AutoCut no Adobe Exchange',
      info: "Instalado diretamente em nosso site? Não há necessidade de reinstalar. Basta clicar em 'Grátis' para começar a avaliar.",
    },
    licence: {
      trial: 'Teste',
      paid: 'Pago',
      info: 'Licença {licenceType}',
      time: {
        left: '{daysLeft} Dias Restantes',
      },
    },
    trial: {
      promo: {
        text: 'Economize até 20% em 24 Horas',
      },
      resend: {
        error:
          'Erro ao reenviar chave de teste. Por favor, tente novamente mais tarde.',
      },
      expired: {
        modal: {
          title: 'Teste Expirado.',
          text1: 'Seu teste expirou. Assine para continuar usando o AutoCut.',
          text2: 'Visite seu painel do AutoCut em:',
          text3: 'Assine para uma chave de licença.',
          text4: 'Para suporte, entre em contato: {supportEmail}',
          button: 'Assinar com Desconto de 20%',
        },
      },
      title: 'Teste Gratuito',
      stepper: {
        onboarding: 'Ajude-nos a Melhorar o AutoCut',
        email: 'Insira Seu Email',
        sending: {
          email: 'Enviando Email',
        },
        login: 'Insira a Chave de Licença',
      },
      onboarding: {
        subtitle:
          'Compartilhe insights para ajudar o desenvolvimento de novos recursos do DaVinci Resolve',
        discover: 'Como você encontrou o AutoCut?',
        job: {
          status: 'Status Atual de Emprego',
        },
        type: {
          creation: 'Tipos de Projetos do DaVinci Resolve',
        },
      },
      sending: {
        email: {
          success: 'Email enviado com sucesso para {email}',
        },
      },
      task: {
        captions: 'Use AutoCaptions: <link>Saiba Mais</link>',
        podcast: 'Use AutoCut Podcast: <link>Saiba Mais</link>',
        zoom: 'Use AutoZoom: <link>Saiba Mais</link>',
        repeat: 'Use AutoCut Repeat: <link>Saiba Mais</link>',
        broll: 'Use AutoB-Roll: <link>Saiba Mais</link>',
        swear: {
          word: 'Use AutoProfanity Filter: <link>Saiba Mais</link>',
        },
        preset:
          'Crie uma nova predefinição para AutoCaptions, AutoZoom, ou AutoResize',
        testimonial:
          'Deixe um Testemunho (usando o mesmo email): <link>Dar Testemunho</link>',
      },
    },
    shop: {
      now: 'Compre Agora',
    },
    back: {
      to_autocut: 'Retornar ao AutoCut',
    },
    'discover-option-youtube-video': 'Vídeo do YouTube',
    'discover-option-youtube-comment': 'Comentário do YouTube',
    'discover-option-reddit': 'Reddit',
    'discover-option-google': 'Google',
    'discover-option-facebook': 'Facebook',
    'discover-option-friend': 'Amigo',
    'discover-option-school': 'Escola',
    'discover-option-other': 'Outro',
    link: {
      not: {
        supported: {
          modal: {
            __text__: 'Para mais informações e soluções, visite ',
            no_solution: 'Para esclarecimentos de erros, veja ',
            end: ', nosso site de documentação e tutoriais',
          },
        },
      },
    },
    text1: {
      use: {
        trial: {
          licence: 'Um email foi enviado para ({email})',
        },
      },
    },
    text2: {
      use: {
        trial: {
          licence:
            'Verifique sua pasta de spam ou promoções se o email estiver faltando.',
        },
      },
    },
    placeholder: {
      use: {
        licence: 'Insira Sua Chave de Licença',
      },
    },
    "can't": {
      reach: {
        server: {
          error:
            'Não é possível acessar o servidor. Por favor, tente novamente mais tarde.',
        },
      },
      read: {
        uuid: {
          error: 'Não é possível ler o UUID do computador.',
        },
        host: {
          error: 'Não é possível ler o nome do computador.',
        },
      },
    },
    asking: {
      key: {
        error:
          'Erro ao verificar a chave com o servidor. Por favor, tente novamente mais tarde.',
      },
    },
    sending: {
      log: {
        file: {
          error:
            'Falha ao enviar o log diário. Por favor, tente novamente mais tarde.',
        },
      },
    },
    is_key: {
      valid: {
        error:
          'Erro ao validar a chave. Por favor, tente novamente mais tarde.',
      },
    },
    base: {
      error: {
        message: 'Ocorreu um erro ',
      },
    },
    invalid: {
      response: {
        error: 'Recebeu uma resposta inesperada do servidor.',
      },
    },
    description: {
      banner: {
        podcast: {
          beta: 'Em desenvolvimento; para problemas ou sugestões, entre em contato conosco em contact@autocut.com',
        },
      },
    },
    header: {
      trial: {
        button: 'Saiba Mais',
      },
      paid: {
        button: 'Gerenciar',
      },
      speakers: {
        name: 'Nome do Palestrante',
        track: 'Faixa de Áudio do Palestrante',
      },
      cameras: {
        name: {
          __text__: 'Faixa de Vídeo da Câmera',
          helper:
            'Selecione a faixa de vídeo para cada câmera, uma faixa por câmera.',
        },
        speakers: {
          __text__: 'Palestrante(s) Visível(is)',
          helper:
            'Selecione quais palestrantes são visíveis nesta câmera. Vários palestrantes permitidos.',
        },
      },
    },
    game: {
      saved: {
        hours: 'Economizou {hourSavedText} hora(s),',
      },
      have: {
        a_break: 'Tire uma merecida pausa!',
      },
    },
    onboarding: {
      skip: 'Pular Integração',
      close: 'Fechar',
      previous: 'Anterior',
      next: 'Seguinte ({current}/{max})',
      onboarding: {
        step1: {
          title: 'Bem-vindo ao AutoCut!',
          body: 'Durante seu primeiro uso, o AutoCut irá guiá-lo através de vários recursos para uma edição sem complicações. Vamos começar!',
        },
        step2: {
          title: 'Chave de Teste Gratuito',
          body: 'Explore todos os recursos com uma chave de teste, válida por 14 dias.',
        },
        step3: {
          title: 'Chave de Licença',
          body: 'Insira sua chave licenciada (de teste ou paga) para acessar todas as ferramentas do AutoCut.',
        },
        step4: {
          title: 'Adquira uma Chave de Licença',
          body: 'Compre uma chave de licença através do botão, que redireciona você para nosso site com detalhes de compra.',
        },
      },
      modeSelection: {
        step1: {
          title: 'Modos do AutoCut',
          body: 'Escolha um modo que se encaixe nas suas necessidades de edição entre as opções disponíveis.',
        },
        step2: {
          title: 'Precisa de Ajuda?',
          body: 'Passe o mouse sobre os elementos da interface para ver dicas e aprender mais.',
        },
      },
      legacy: {
        step1: {
          title: 'AutoCut V2',
          body: 'O AutoCut V2 detecta silêncios com base nos níveis de ruído do áudio. É necessário uma configuração inicial.',
        },
        step2: {
          title: 'Nível de Ruído',
          body: 'Use o controle deslizante para definir o nível mínimo de ruído classificado como silêncio.',
        },
        step3: {
          title: 'Próximos Passos',
          body: 'Navegue pelas etapas usando as setas e explore mais recursos do AutoCut.',
        },
        step4: {
          title: 'Gerando Pré-visualização',
          body: 'Exporte o áudio antes de processar. Clique em "Gerar Pré-visualização" para visualizar os cortes de áudio.',
        },
        step6: {
          title: 'Hora de Cortar',
          body: 'Revise a pré-visualização e prossiga com os cortes se estiver satisfeito.',
        },
        step7: {
          title: 'Hora de Cortar',
          body: 'Selecione suas opções de corte preferidas.',
        },
        step8: {
          title: 'Hora de Cortar',
          body: 'Relaxe e deixe o AutoCut cuidar da edição!',
        },
      },
      podcast: {
        step1: {
          title: 'AutoCut Podcast',
          body: 'Edite rapidamente seus vídeos de podcast com o AutoCut Podcast.',
        },
        step2: {
          title: 'Palestrantes',
          body: 'Adicione palestrantes usando o botão "Adicionar Palestrante". Atribua uma faixa por palestrante.',
        },
        step3: {
          title: 'Palestrantes',
          body: 'Configure o arranjo de cada palestrante conforme necessário.',
        },
        step4: {
          title: 'Nome do Palestrante',
          body: 'Atribua um nome a cada palestrante para identificação.',
        },
        step5: {
          title: 'Seleção de Faixa de Vídeo',
          body: 'Selecione as faixas de vídeo que exibem cada palestrante.',
        },
        step6: {
          title: 'Seleção de Faixa de Áudio',
          body: 'Atribua uma faixa de áudio a cada palestrante. Apenas uma faixa por palestrante.',
        },
        step7: {
          title: 'Hora de Editar',
          body: 'Defina palestrantes e prossiga com a edição.',
        },
        step8: {
          title: 'Hora de Editar',
          body: 'Use a seta para selecionar opções de edição.',
        },
        step9: {
          title: 'Hora de Editar',
          body: 'Relaxe e deixe o AutoCut gerenciar o processo de edição.',
        },
      },
      title: 'Bem-vindo ao AutoCut',
      subtitle: 'Sua Extensão Definitiva para o DaVinci Resolve',
    },
    start: {
      transcription: 'Iniciar Transcrição',
    },
    no_more: {
      credits: 'Créditos Esgotados',
    },
    file: {
      too: {
        long: 'Arquivo de áudio é muito longo',
      },
      sent: 'Arquivo carregado, aguardando transcrição...',
    },
    please: {
      wait1: 'Aguardando pela transcrição do áudio...',
      wait2: 'Este processo pode levar alguns minutos, dependendo da duração.',
      wait3: 'Enviando arquivo:',
    },
    language: {
      selection: {
        step: {
          title: {
            __text__: 'Idioma do Áudio',
            helper: 'Especifique o idioma do clipe de áudio',
          },
        },
        french: {
          __text__: 'Francês',
          beta: 'Francês (Beta)',
        },
        danish: 'Dinamarquês',
        dutch: 'Holandês',
        english: {
          __text__: 'Inglês',
          united: {
            states: 'Inglês - Estados Unidos',
          },
        },
        flemish: 'Flemish',
        german: {
          __text__: 'Alemão',
          beta: 'Alemão (Beta)',
        },
        hindi: {
          __text__: 'Hindi',
          beta: 'Hindi (Beta)',
        },
        italian: 'Italiano',
        japanese: 'Japonês',
        korean: 'Coreano',
        norwegian: {
          __text__: 'Norueguês',
          nynorsk: 'Norueguês Nynorsk',
        },
        polish: 'Polonês',
        portuguese: {
          __text__: 'Português',
          brazil: 'Português - Brasil',
          'brazil—beta': 'Português - Brasil (Beta)',
        },
        'portuguese—beta': 'Português (Beta)',
        spanish: {
          __text__: 'Espanhol',
          latin: {
            america: 'Espanhol - América Latina',
            'america—beta': 'Espanhol - América Latina (Beta)',
          },
        },
        'spanish—beta': 'Espanhol (Beta)',
        swedish: 'Sueco',
        tamil: 'Tâmil',
        chinese: 'Chinês',
        russian: 'Russo',
        turkish: 'Turco',
        catalan: 'Catalão',
        arabic: {
          __text__: 'Árabe',
          algeria: 'Árabe - Argélia',
          bahrain: 'Árabe - Bahrein',
          egypt: 'Árabe - Egito',
          iraq: 'Árabe - Iraque',
          israel: 'Árabe - Israel',
          jordan: 'Árabe - Jordânia',
          kuwait: 'Árabe - Kuwait',
          lebanon: 'Árabe - Líbano',
          mauritania: 'Árabe - Mauritânia',
          morocco: 'Árabe - Marrocos',
          oman: 'Árabe - Omã',
          qatar: 'Árabe - Catar',
          saudi: {
            arabia: 'Árabe - Arábia Saudita',
          },
          palestine: 'Árabe - Palestina',
          syria: 'Árabe - Síria',
          tunisia: 'Árabe - Tunísia',
          united: {
            arab: {
              emirates: 'Árabe - Emirados Árabes Unidos',
            },
          },
          yemen: 'Árabe - Iémen',
        },
        indonesian: 'Indonésio',
        finnish: 'Finlandês',
        vietnamese: 'Vietnamita',
        hebrew: 'Hebraico',
        ukrainian: 'Ucraniano',
        greek: 'Grego',
        malay: 'Malaio',
        czech: 'Tcheco',
        romanian: 'Romeno',
        hungarian: 'Húngaro',
        thai: 'Tailandês',
        urdu: 'Urdu',
        croatian: 'Croata',
        bulgarian: 'Búlgaro',
        lithuanian: 'Lituano',
        latin: 'Latim',
        maori: 'Maori',
        malayalam: 'Malaiala',
        welsh: 'Galês',
        slovak: 'Eslovaco',
        telugu: 'Telugu',
        persian: 'Persa',
        latvian: 'Letão',
        bengali: 'Bengali',
        serbian: 'Sérvio',
        azerbaijani: 'Azerbaijano',
        slovenian: 'Esloveno',
        kannada: 'Canarim',
        estonian: 'Estoniano',
        macedonian: 'Macedônio',
        breton: 'Bretão',
        basque: 'Basco',
        icelandic: 'Islandês',
        armenian: 'Armênio',
        nepali: 'Nepalês',
        mongolian: 'Mongol',
        bosnian: 'Bósnio',
        kazakh: 'Cazaque',
        albanian: 'Albanês',
        swahili: 'Suaíli',
        galician: 'Galego',
        marathi: 'Marathi',
        punjabi: 'Punjab',
        sinhala: 'Sinhala',
        khmer: 'Khmer',
        shona: 'Shona',
        yoruba: 'Iorubá',
        somali: 'Somali',
        afrikaans: 'Africâner',
        occitan: 'Occitano',
        georgian: 'Georgiano',
        belarusian: 'Bielorrusso',
        tajik: 'Tajique',
        sindhi: 'Sindi',
        gujarati: 'Gujarati',
        amharic: 'Amárico',
        yiddish: 'Ídiche',
        lao: 'Lao',
        uzbek: 'Usbeque',
        faroese: 'Feroês',
        haitian: {
          creole: 'Crioulo Haitiano',
        },
        pashto: 'Pashto',
        turkmen: 'Turcomano',
        maltese: 'Maltês',
        sanskrit: 'Sânscrito',
        luxembourgish: 'Luxemburguês',
        burmese: 'Birmanês',
        tibetan: 'Tibetano',
        tagalog: 'Tagalo',
        malagasy: 'Malgaxe',
        assamese: 'Assamês',
        tatar: 'Tártaro',
        hawaiian: 'Havaiano',
        lingala: 'Lingala',
        hausa: 'Hauçá',
        bashkir: 'Basquir',
        javanese: 'Javanês',
        sundanese: 'Sundanês',
      },
    },
    big: {
      sequence: {
        info: {
          message:
            'Sua sequência tem muitos clipes! Pode demorar mais para carregar. Selecione apenas os clipes necessários e corte-os em uma sequência separada.',
        },
      },
    },
    homepage: {
      title: 'Página Principal',
      comming: {
        soon: 'Muito em Breve!',
      },
    },
    autozoom: {
      dynamism: {
        __text__: 'Dinamismo',
        normal: 'Normal',
        high: 'Alto',
        extreme: 'Extremo',
        custom: 'Personalizado:',
      },
      button: {
        __text__: 'Aplicar Zoom',
        message: {
          step1: 'Preparando AutoZoom...',
          step2: 'Extraindo Dados de Áudio...',
          step3: 'Calculando Intervalos de Zoom...',
          step4: 'Aplicando Zoom {current} de {max}...',
        },
      },
      success: 'Sucesso! Efeitos de zoom aplicados com sucesso.',
      info: {
        text: 'O AutoZoom adiciona efeitos de zoom dinâmicos aos clipes selecionados com base nos níveis de áudio.',
      },
      invalid: {
        selection: {
          screen: {
            title: 'Selecione clipes de áudio e vídeo na sua linha do tempo',
            text1:
              'Selecione clipes da linha do tempo para aplicar o AutoZoom.',
          },
        },
      },
      anchor: {
        title: 'Defina o Ponto de Ancoragem',
        helper:
          'O ponto de ancoragem é onde o zoom se concentra. Caixas coloridas mostram os efeitos máximos e mínimos de zoom.',
      },
      dual: {
        range: {
          title: 'Defina o Intervalo do Coeficiente de Zoom',
          helper:
            'Os níveis de zoom são baseados nesses valores e no volume máximo do clipe.',
        },
      },
      preview: {
        on_indicator: 'Pré-visualizar Centralizada no Indicador',
        error: {
          message: 'Erro ao carregar pré-visualização',
          button: 'Tentar Novamente',
        },
      },
      min: {
        zoom: {
          title: 'Duração Mínima do Zoom',
          helper: 'Os efeitos de zoom não serão mais curtos que esta duração.',
        },
      },
      max: {
        zoom: {
          title: 'Duração Máxima do Zoom',
          helper: 'Os efeitos de zoom não excederão esta duração.',
        },
      },
      constraint: {
        zoom: {
          title: 'Limitar Zoom aos Clipes',
          helper:
            'Se ativado, os efeitos de zoom são limitados a clipes individuais. Se desativado, eles se estendem por toda a seleção.',
        },
      },
      zoom: {
        types: {
          __text__: 'Estilos de Zoom',
          helper:
            'Escolha entre Suave (zoom gradual) e Dinâmico (zoom imediato).',
        },
        coverage: {
          __text__: 'Cobertura Total de Zoom',
          helper: 'Porcentagem de zoom aplicada.',
        },
      },
      smooth: {
        __text__: 'Suave',
        zoom: {
          __text__: 'Zooms Suaves',
          coverage: 'Cobertura de Zoom Suave',
          nervousness: {
            __text__: 'Nervosismo',
            helper:
              'Valores mais baixos para zooms mais lentos e gentis; valores mais altos para zooms rápidos e intensos.',
          },
        },
      },
      dynamic: {
        __text__: 'Dinâmico',
        zoom: {
          __text__: 'Zooms Dinâmicos',
          coverage: 'Cobertura de Zoom Dinâmico',
        },
      },
    },
    zoom: {
      preset: {
        title: 'Predefinições Padrão',
        calm: 'Calmo',
        paced: 'Acelerado',
        energetic: 'Energético',
        hyperactive: 'Hiperativo',
      },
    },
    repeat: {
      info: {
        banner:
          'AutoCut Repeat remove segmentos repetitivos. Use a tesoura para alternar entre cortar ou manter partes para precisão.',
      },
      text: {
        step: {
          edit: {
            transcript: 'Corrigir Transcrição',
          },
        },
      },
      invalid: {
        selection: {
          screen: {
            title:
              'Selecione um clipe de áudio (e opcionalmente vídeo) da linha do tempo para o AutoCut Repeat.',
            text1:
              'Selecione um clipe da linha do tempo para o AutoCut Repeat.',
          },
        },
      },
      select: {
        algorithm: 'Escolha o Algoritmo',
      },
      precise: {
        algorithm: {
          info: 'Nosso novo algoritmo eficiente processa aproximadamente um minuto de vídeo por minuto.',
        },
      },
      fast: {
        algorithm: 'Rápido',
      },
      toggle: {
        cut: {
          button: {
            text: 'Alternar Corte',
          },
        },
      },
      play: {
        button: {
          text: 'Reproduzir',
        },
      },
    },
    captions: {
      step: {
        customization: 'Personalizar Legendas',
        script: 'Selecionar Clipes e Idioma',
        transcription: 'Editar Transcrição',
      },
      script: {
        transcript: {
          part: 'Transcrição',
        },
        sequence: {
          part: 'Sequência',
          name: 'Nome',
          track: {
            clip: {
              unit: {
                __text__: '{nbClips} Cliques | {from} → {to}',
                detail: '{clipName} | {from} → {to}',
              },
            },
          },
        },
      },
      transcript: {
        action: 'Adicionar Legendas',
        coming: {
          soon: 'Editor de Legendas em breve! Atualmente, edite legendas via o painel Essential Graphics. Selecione legendas na linha do tempo e modifique no editor de painéis.',
        },
        transcription: {
          part: 'Transcrição',
        },
        info: 'Bem-vindo ao Editor de Legendas – opcionalmente edite legendas diretamente na linha do tempo do DaVinci Resolve após adicioná-las.',
        add: {
          emojis: {
            cta: 'Criar Emojis',
          },
        },
      },
      text: {
        noClip: {
          title: 'Selecione Clipes para Legendas',
          no_selection:
            'Se o AutoCut não responder, tente reiniciar o DaVinci Resolve.',
        },
      },
      process: {
        transcript: {
          button: 'Processar',
        },
      },
      customization: {
        info: 'AutoCaption está em beta e espera-se estabilizar até 10 de novembro.',
        back: {
          button: 'Excluir Transcrição e Retornar',
        },
        example: {
          __text__:
            'Pré-visualizar <opacity50><i>Arraste para ajustar a posição da legenda/emoji</i></opacity50>',
          text: 'AutoCaption adiciona legendas visíveis na pré-visualização. Personalize opções dentro de AutoCaptions; edite transcrição se necessário. Legendas são instantaneamente adicionadas à linha do tempo.',
        },
        animation: {
          __text__: 'Animação',
          helper: 'Controle os efeitos de animação do texto',
          color: {
            __text__: 'Destaque com <color>Áudio</color>',
            text: {
              color: 'Cor do Texto',
            },
            before: {
              highlight: {
                color: 'Cor de Destaque Prévio',
              },
            },
            after: {
              highlight: {
                color: 'Cor de Destaque Posterior',
              },
            },
          },
          advanced: {
            color: {
              options: 'Opções Avançadas de Cor',
            },
          },
          word: {
            box: 'Texto com <color>Caixa</color>',
          },
          bold: {
            with: {
              text: 'Texto está em <b>Negrito</b>',
            },
          },
          floating: {
            text: 'Texto está Flutuante',
          },
        },
        transition: {
          __text__: 'Transição',
          helper:
            'Gerencie transições de legenda; duração pode não corresponder ao exemplo.',
          effect: {
            zoomIn: 'Ampliar',
            blurIn: 'Desfoque Entrando',
          },
        },
        outline: {
          __text__: 'Contorno',
          helper: 'Gerencie estilos de contorno',
          color: 'Cor do Contorno',
          width: 'Largura do Contorno',
        },
        font: {
          __text__: 'Fonte',
          helper: 'Gerencie estilos de fonte',
          font: 'Nome da Fonte',
          help: 'Como adicionar uma nova família de fontes?',
          size: 'Tamanho da Fonte',
          weight: 'Peso da Fonte',
          color: 'Cor da Fonte',
        },
        formating: {
          __text__: 'Formatação de Texto',
          helper: 'Controle opções de formatação de texto',
          ponctuation: 'Remover Pontuação',
          uppercase: 'Maiúsculas',
          bold: 'Negrito',
          italic: 'Itálico',
          glow: {
            __text__: 'Brilho',
            color: 'Cor do Brilho',
            intensity: 'Intensidade do Brilho',
          },
        },
        transform: {
          __text__: 'Transformar',
          x: 'Posição X',
          y: 'Posição Y',
          helper: 'Ajuste a posição e rotação da legenda',
          position: {
            __text__: 'Posição Vertical',
            emoji: 'Posição Vertical do Emoji',
            info: 'Mova o texto na pré-visualização',
          },
          rotation: 'Rotação',
        },
        multiple: {
          lines: 'Linhas Múltiplas',
        },
        box: {
          __text__: 'Caixas',
          helper: 'Opções de estilo para caixas de texto',
        },
        boxes: {
          padding: {
            x: 'Margem Horizontal',
            y: 'Margem Vertical',
          },
          opacity: 'Opacidade da Caixa',
          radius: 'Raio das Cantoneiras',
          color: 'Cor da Caixa',
        },
        emojis: {
          __text__: 'Emojis',
          helper: 'Emojis gerados por IA',
          size: 'Tamanho do Emoji',
        },
        shadow: 'Sombra',
        transcript: 'Transcrição',
        action: 'Avançar',
        select: {
          chunk: 'Escolha segmento de transcrição para editar ou personalizar',
        },
        delete: {
          caption: {
            modal: {
              title: 'Confirmar Remoção de Legenda',
              cancel: 'Não, Mantenha',
              confirm: 'Sim, Excluir',
            },
          },
        },
        get: {
          presets: {
            number: {
              error:
                'Erro ao recuperar número de predefinições. Entre em contato através do Discord ou em contact@autocut.com',
            },
            error:
              'Erro ao recuperar predefinições. Entre em contato através do Discord ou em contact@autocut.com',
          },
          public: {
            presets: {
              error:
                'Erro ao recuperar predefinições públicas. Entre em contato através do Discord ou em contact@autocut.com',
            },
          },
        },
        presets: {
          title: 'Predefinições',
          save: {
            new: {
              preset: 'Salvar Configurações Atuais como Predefinição',
            },
          },
          view: {
            more: 'Ver Mais',
            less: 'Ver Menos',
          },
        },
        save: {
          preset: {
            info: {
              text: 'Salve essas configurações como uma predefinição para uso futuro!',
            },
          },
        },
      },
      editor: {
        title: 'Editor de Legendas',
        description: {
          title: 'Selecionar Legendas para Editar',
        },
        chunk: {
          low: {
            confidence: {
              tooltip: 'Palavras com baixa confiança',
            },
          },
          merge: {
            tooltip: 'Unir Segmentos',
          },
          split: {
            tooltip: 'Separar Segmentos',
          },
        },
        sound: {
          play: 'Habilitar Reprodução de Som',
        },
      },
      fontSelect: {
        notFoundFontModal: {
          title: 'Fonte Ausente',
          message:
            'A fonte <i><b>{fontLabel}</b></i> não está disponível. Fonte padrão será usada.',
          helper: 'Adicione novas fontes usando este tutorial.',
        },
      },
    },
    version: {
      not: {
        supported: 'Versão não Suportada',
      },
    },
    transcript: {
      usage: {
        unit: 'Minutos',
      },
      upgrade: {
        button: 'Atualize para IA para {mode}',
      },
      too: {
        short: {
          error:
            'A transcrição pode ser muito curta ou o idioma não é suportado. Se o problema persistir, entre em contato com contact@autocut.com.',
        },
      },
    },
    feature: {
      request: {
        __text__: 'Solicitação de Recurso',
        form: {
          placeholder:
            'Descreva sua solicitação de recurso para melhor consideração.',
        },
        screenshot: 'Anexe capturas de tela ou vídeos para clareza:',
        button: 'Enviar Solicitação de Recurso',
        success: {
          title: 'Enviado! 🥳',
          body: 'Obrigado por ajudar o AutoCut a melhorar! Revisaremos sua solicitação em breve.',
        },
        error: {
          title: 'Ops, algo deu errado... 🤕',
          body: 'Lamentamos o transtorno. Por favor, tente novamente mais tarde ou contate-nos em contact@autocut.com.',
        },
      },
    },
    with: {
      emojis: 'Com Emojis',
    },
    resize: {
      invalid: {
        selection: {
          screen: {
            title: 'Defina Pontos de Entrada/Saída na Sua Linha do Tempo',
            text1:
              'Para usar o AutoResize, defina Pontos de Entrada/Saída na sua linha do tempo.',
            cta: 'Defina Pontos de Entrada/Saída para Toda a Sequência',
          },
        },
      },
      text: {
        info: 'O AutoResize ajusta e reajusta automaticamente sua sequência para formatos de redes sociais otimizados.',
      },
      in_out: {
        step: {
          title: 'Pontos de Entrada/Saída',
          helper:
            'Certifique-se de que os Pontos de Entrada/Saída correspondem à seção desejada.',
        },
      },
      preset: {
        step: {
          title: 'Predefinições',
          helper:
            'Escolha uma predefinição para redimensionar sua sequência ou criar uma personalizada. Visualize as dimensões abaixo.',
        },
        undefined: 'Selecione uma predefinição.',
      },
      watermark: {
        step: {
          title: "Marca d'água",
          helper:
            "Adicione uma marca d'água à sua sequência no canto inferior direito.",
          text: "Envie Sua Marca d'água",
        },
      },
      button: 'Gerar Clipe Social',
      reframe: {
        undefined: 'Selecione a velocidade de "Seguir Sujeito".',
        step: {
          title: 'Seguir Sujeito',
          helper: 'Escolha a velocidade para que a câmera siga o sujeito.',
          slower: 'Lento',
          default: 'Normal',
          faster: 'Rápido',
        },
      },
      backup: 'Fazendo Backup de Sua Sequência...',
      create: {
        new: {
          sequence: 'Criando Nova Sequência...',
        },
      },
      resizing: {
        sequence: 'Redimensionando Nova Sequência...',
      },
      adding: {
        watermark: "Adicionando Marca d'água...",
      },
      cleaning: {
        sequence: 'Limpando Sequência...',
      },
    },
    broll: {
      invalid: {
        selection: {
          screen: {
            title: 'Selecione Clipes Base para B-Roll',
            text1:
              'Para AutoB-Roll, selecione pelo menos um clipe com áudio incluído.',
          },
        },
      },
      text: {
        info: 'AutoB-Roll usa IA para analisar áudio e adicionar automaticamente B-Rolls relevantes do Pexels.',
      },
      duration: {
        step: {
          title: 'Durações de B-Roll',
          minimum: {
            title: 'Duração Mínima de B-Roll',
            helper: 'Duração mínima para cada B-Roll.',
          },
          maximum: {
            title: 'Duração Máxima de B-Roll',
            helper: 'Duração máxima para cada B-Roll.',
          },
        },
      },
      choice: {
        step: {
          title: 'Seleção de B-Roll',
        },
      },
      no_broll: {
        screen: {
          title: 'Transcrição processada, mas nenhum B-Roll foi encontrado.',
          text1: 'Para usar AutoB-Roll, selecione um clipe de áudio com falas.',
          text2:
            'Verifique o idioma da transcrição e certifique-se de que as faixas de áudio não estão silenciadas.',
        },
      },
      button: {
        __text__: 'Adicionar B-Rolls',
        next: 'Próximo',
      },
      exporting: 'Exportando Clipes de Áudio Selecionados...',
      analyse: 'Analisando Áudio...',
      getting: {
        broll: 'Buscando B-Rolls... (pode levar alguns minutos)',
      },
      transcript: {
        finished: 'Transcrição Gerada',
      },
      step: {
        parameters: 'Configurações de B-Roll',
        language: 'Selecionar Clipes e Idioma',
        brolls: 'Editar Seleção de B-Roll',
      },
      adding: {
        broll: 'Adicionando B-Rolls...',
      },
      restore: 'Restaurando Sua Sequência...',
      backup: 'Salvando Backup de Sua Sequência...',
      credit: {
        modal: {
          title: 'Créditos de B-Roll:',
          provided: {
            by: 'Fornecido por Pexels',
          },
          link: 'Link',
          button: 'Copiar para Área de Transferência',
        },
      },
      search: {
        modal: {
          title: 'Selecione B-Roll',
          input: {
            placeholder: 'Procurar B-Rolls',
          },
          searching: 'Procurando B-Rolls...',
        },
      },
    },
    discordCta: {
      title: 'Discord',
    },
    double: {
      click: {
        to_edit: 'Clique Duplo para Editar',
      },
    },
    swear: {
      words: {
        invalid: {
          selection: {
            screen: {
              title: 'Selecione Clipes para Filtragem de Profanidades',
              text1: 'Selecione um clipe com áudio para AutoProfanity Filter.',
            },
          },
        },
        text: {
          info: 'AutoProfanity Filter usa IA para detectar e censurar automaticamente profanidades com sons de censura.',
        },
        mute: 'Silenciar',
        button: 'Remover Profanidades',
        audio: {
          export: 'Exportando Áudio...',
          analyse: 'Analisando Áudio...',
          insert: 'Inserindo Censuras...',
          filter: 'Aplicando Ajustes de Volume...',
        },
        script: {
          transcript: {
            part: 'Transcrição',
          },
        },
      },
    },
    bleep: {
      selection: {
        step: {
          title: 'Selecione o efeito sonoro de censura',
          helper: 'Selecione o efeito sonoro de censura',
        },
      },
    },
    create: {
      format: {
        modal: {
          title: 'Criar Novo Formato',
          button: {
            __text__: 'Criar',
            disabled: 'Nome em Uso',
          },
          name: 'Nome',
          width: 'Largura',
          height: 'Altura',
        },
      },
    },
    process: {
      modal: {
        warning:
          'Por favor, evite fazer alterações no DaVinci Resolve para evitar problemas.',
        generating: {
          emojis: 'Criando emojis a partir da transcrição...',
        },
        formatting: {
          numbers: 'Formatando números na transcrição...',
        },
      },
    },
    backup: {
      modal: {
        title: 'Backup do AutoCut',
        text: {
          '1': 'Backups ocorrem antes do processamento. Encontre-os na pasta "AutoCut-Backup".',
          '2': 'Use-os conforme necessário.',
        },
      },
    },
    confirm: {
      modal: {
        title: 'Tem Certeza?',
        confirm: {
          label: 'Confirmar',
        },
        cancel: {
          label: 'Cancelar',
        },
      },
    },
    input: {
      speaker: {
        name: {
          placeholder: 'Digite o Nome do Palestrante...',
        },
      },
    },
    login: {
      resend: {
        email: 'Não recebeu? Reenviar email para {trialEmail}',
      },
    },
    typeofcreation: {
      option: {
        interview: 'Entrevistas',
        podcast: 'Podcasts',
        youtube: 'Vídeos do YouTube',
        short: 'TikTok/Shorts/Reels',
        internal: 'Vídeos Internos',
        other: 'Outro',
      },
    },
    jobstatus: {
      option: {
        content: {
          creator: 'Criador de Conteúdo',
        },
        video: {
          editor: {
            professional: 'Editor de Vídeo Profissional',
            semi: {
              professional: 'Editor de Vídeo Semi-Profissional',
            },
            beginner: 'Editor de Vídeo Iniciante',
          },
        },
        student: 'Estudante',
        other: 'Outro',
      },
    },
    progress: {
      captions: {
        modal: {
          message: {
            initialization: 'Configurando Legendas...',
          },
        },
      },
    },
    stat: {
      undo: {
        modal: 'Ótimo trabalho! Concluído em {minutes} min {seconds} seg.',
      },
    },
    cancelCutModal: {
      timesaved: {
        this: {
          cut: 'Tempo Economizado: <b>{minutesSaved} {minutesSaved, plural, one {minuto} other {minutos}}</b> e <b>{secondsSaved} {secondsSaved, plural, one {segundo} other {segundos}}</b>.',
        },
        all: {
          cuts: 'Tempo Total Economizado: Um enorme <b>{hourSaved} {hourSaved, plural, one {hora} other {horas}}</b> e <b>{minutesSaved} {minutesSaved, plural, one {minuto} other {minutos}}</b>! 🎉',
        },
      },
    },
    selected: {
      clips: {
        helper:
          'Desative faixas de áudio da detecção de silêncios para manter música de fundo ou sons de jogos. Para deixar silêncios intocados em clipes específicos, não selecione esses clipes.',
        video: 'Faixas de Vídeo Selecionadas:',
        audio: 'Faixas de Áudio Selecionadas:',
      },
    },
    free: {
      trial: {
        usage: {
          exceeded: {
            title: 'Limite do Teste Gratuito Alcançado',
            cta: 'Assine Agora',
            text: 'Excedeu 1000 minutos de recursos de IA.{br}Assine para continuar usando AutoCut IA.',
          },
        },
      },
    },
    paid: {
      usage: {
        exceeded: {
          title: 'Limite de Transcrição Alcançado',
          text: 'Excedeu 6000 minutos de recursos de IA.{br}Entre em contato conosco para assistência.',
        },
      },
    },
    validation: {
      error: {
        ai_transitions:
          'Selecione um tipo de transição válido (Veja <a><b><embedTranslation>text_transition_step_title</embedTranslation></b></a>).',
        ai_silences:
          'Selecione uma opção de tratamento de silêncio válida (Veja <a><b><embedTranslation>title_silences_step</embedTranslation></b></a>).',
        broll: {
          min: {
            max: {
              duration:
                'O tempo máximo de B-Roll deve ser maior que o mínimo (Veja <a><b><embedTranslation>broll_duration_step_title</embedTranslation></b></a>).',
            },
          },
          minimumBRollTime:
            'O tempo mínimo de B-Roll deve ser positivo (Veja <a><b><embedTranslation>broll_duration_step_minimum_title</embedTranslation></b></a>).',
          maximumBRollTime:
            'O tempo máximo de B-Roll deve ser positivo (Veja <a><b><embedTranslation>broll_duration_step_maximum_title</embedTranslation></b></a>).',
        },
        caption: {
          position:
            'As legendas devem permanecer dentro da área de pré-visualização (Veja <a><b><embedTranslation>captions_customization_transform_position</embedTranslation></b></a>).',
          emoji: {
            position:
              'Os emojis devem permanecer dentro da área de pré-visualização (Veja <a><b><embedTranslation>captions_customization_transform_position_emoji</embedTranslation></b></a>).',
          },
          text: {
            fontSize:
              'O tamanho da fonte deve ser um número positivo (Veja <a><b><embedTranslation>captions_customization_font_size</embedTranslation></b></a>).',
          },
          outline: {
            width:
              'A largura do contorno deve ser um número positivo (Veja <a><b><embedTranslation>captions_customization_outline_width</embedTranslation></b></a>).',
          },
          transition: {
            effects:
              'Escolha um efeito de transição de legenda válido (Veja <a><b><embedTranslation>captions_customization_transition</embedTranslation></b></a>).',
          },
          emojis: {
            size: 'O tamanho do emoji deve ser positivo (Veja <a><b><embedTranslation>captions_customization_font_size</embedTranslation></b></a>).',
          },
          formating: {
            nbLines:
              'Pelo menos uma linha deve ser selecionada para formatação (Veja <a><b><embedTranslation>captions_customization_formating_nbLines</embedTranslation></b></a>).',
          },
          title: {
            too: {
              short: 'O título deve ter pelo menos 5 caracteres.',
              long: 'O título não pode exceder 25 caracteres.',
            },
            no_space:
              'O título deve conter pelo menos duas palavras separadas por espaço.',
          },
        },
        font: {
          fontFamily:
            'Nome de família da fonte é necessário (Veja <a><b><embedTranslation>captions_customization_font_font</embedTranslation></b></a>).',
          label:
            'Selecione uma fonte (Veja <a><b><embedTranslation>captions_customization_font_font</embedTranslation></b></a>).',
        },
        languageOfTranscription:
          'Selecione um idioma de transcrição (Veja <a><b><embedTranslation>language_selection_step_title_helper</embedTranslation></b></a>).',
        cameras: {
          speakerIds:
            'Cada câmera deve ter pelo menos um palestrante atribuído (Veja <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
          label:
            'Cada câmera deve ter uma faixa de vídeo atribuída (Veja <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
        },
        podcast: {
          minimum: {
            cam: {
              time: 'O tempo mínimo da câmera deve ser positivo (Veja <a><b><embedTranslation>text_step1_podcast_minimum</embedTranslation></b></a>).',
            },
          },
          maximum: {
            cam: {
              time: 'O tempo máximo da câmera deve ser positivo (Veja <a><b><embedTranslation>text_step1_podcast_maximum</embedTranslation></b></a>).',
            },
          },
          deleteUnusedClips:
            'Escolha se deseja remover ou silenciar silêncios (Veja <a><b><embedTranslation>title_podcast_silences_step</embedTranslation></b></a>).',
          speakers: {
            cameras:
              'Cada palestrante deve ser atribuído a uma câmera (Veja <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
            audio:
              'Cada palestrante deve ter uma faixa de áudio atribuída (Veja <a><b><embedTranslation>header_speakers_track</embedTranslation></b></a>).',
          },
          cameras: {
            __text__:
              'Cada câmera deve ter sua própria faixa (Veja <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
            length:
              'Pelo menos duas câmeras separadas são necessárias (Veja <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
          },
        },
        resize: {
          autoReframePreset: {
            required:
              'Predefinição de Auto Reframe é necessária (Veja <a><b><embedTranslation>resize_reframe_step_title</embedTranslation></b></a>).',
            invalid:
              'Opções de predefinição: mais lento, padrão, ou mais rápido (Veja <a><b><embedTranslation>resize_reframe_step_title</embedTranslation></b></a>).',
          },
          watermark:
            "Formatos válidos de marca d'água: JPEG, PNG, ou WebP. (Veja <a><b><embedTranslation>resize_watermark_step_title</embedTranslation></b></a>).",
          preset: {
            name: 'Nome da predefinição é necessário.',
            width: 'A largura deve ser pelo menos 1px.',
            height: 'A altura deve ser pelo menos 1px.',
          },
        },
        rgb: 'Valores RGB devem estar entre 0 e 255.',
        silence: {
          noiseLevel: {
            type: 'O nível de ruído deve ser um número (Veja <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>).',
            nonpositive:
              'O nível de ruído não pode ser positivo (Veja <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>).',
            integer:
              'O nível de ruído deve ser inteiro (Veja <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>).',
          },
          minTimeOfSilence: {
            type: 'O tempo de silêncio deve ser um número (Veja <a><b><embedTranslation>text_Step1_Silences</embedTranslation></b></a>).',
          },
          maxTimeOfSilence: {
            type: 'O tempo de silêncio deve ser um número (Veja <a><b><embedTranslation>text_Step1_Talks</embedTranslation></b></a>).',
          },
          marginBefore: {
            type: 'A margem antes deve ser um número (Veja <a><b><embedTranslation>text_Step2_Before</embedTranslation></b></a>).',
          },
          marginAfter: {
            type: 'A margem depois deve ser um número (Veja <a><b><embedTranslation>text_Step2_After</embedTranslation></b></a>).',
          },
          transitions:
            'Selecione um tipo de transição válido (Veja <a><b><embedTranslation>text_transition_step_title</embedTranslation></b></a>).',
          silences:
            'Selecione uma opção de tratamento de silêncio válida (Veja <a><b><embedTranslation>title_silences_step</embedTranslation></b></a>).',
        },
        swearWord: {
          bleepFile:
            'Selecione um efeito sonoro de censura (Veja <a><b><embedTranslation>bleep_selection_step_title_helper</embedTranslation></b></a>).',
        },
        textBox: {
          xPadding:
            'A margem horizontal deve ser um número (Veja <a><b><embedTranslation>captions_customization_boxes_padding_x</embedTranslation></b></a>).',
          yPadding:
            'A margem vertical deve ser um número (Veja <a><b><embedTranslation>captions_customization_boxes_padding_y</embedTranslation></b></a>).',
          opacity: {
            min: 'A opacidade deve ser de pelo menos 0% (Veja <a><b><embedTranslation>captions_customization_boxes_opacity</embedTranslation></b></a>).',
            max: 'A opacidade não pode ultrapassar 100% (Veja <a><b><embedTranslation>captions_customization_boxes_opacity</embedTranslation></b></a>).',
          },
        },
        anchor:
          'A âncora deve estar dentro da área de pré-visualização (Veja <a><b><embedTranslation>autozoom_anchor_title</embedTranslation></b></a>).',
        zoom: {
          autoZoomMinCoef: {
            min: 'O coeficiente mínimo de zoom deve ser maior que 1 (Veja <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
            max: 'O coeficiente mínimo de zoom deve ser menor que 2 (Veja <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
          },
          autoZoomMaxCoef: {
            min: 'O coeficiente máximo de zoom deve ser maior que 1 (Veja <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
            max: 'O coeficiente máximo de zoom deve ser menor que 2 (Veja <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
          },
          minZoomTime:
            'A duração mínima do zoom deve ser positiva em segundos.',
          maxZoomTime:
            'A duração máxima do zoom deve ser positiva em segundos.',
          dynamicZoomPercentage: {
            min: 'A porcentagem de zoom deve estar acima de 0%',
            max: 'A porcentagem de zoom deve estar abaixo de 100%',
          },
          coef: 'O coeficiente máximo de zoom deve ser maior que o mínimo (Veja <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
          time: 'O tempo máximo de zoom deve ser maior que o tempo mínimo.',
          dynamicMinZoomIntensity:
            'A intensidade do zoom dinâmico deve estar entre 0-100% (Veja <a><b><embedTranslation>autozoom_dynamic_zoom_coverage</embedTranslation></b></a>).',
          smoothZoomPercentage:
            'A cobertura de zoom suave deve estar entre 0-100% (Veja <a><b><embedTranslation>autozoom_smooth_zoom_coverage</embedTranslation></b></a>).',
          nervousness:
            'O nível de nervosismo deve estar entre 0-100% (Veja <a><b><embedTranslation>autozoom_smooth_zoom_nervousness</embedTranslation></b></a>).',
          totalZoomPercentage:
            'A cobertura total de zoom deve estar entre 0-100% (Veja <a><b><embedTranslation>autozoom_zoom_coverage</embedTranslation></b></a>).',
          types: {
            at_least: {
              one: {
                true: 'Selecione pelo menos um tipo de zoom (Veja <a><b><embedTranslation>autozoom_zoom_types</embedTranslation></b></a>).',
              },
            },
          },
        },
        repeat: {
          algorithm: 'Algoritmo selecionado inválido.',
        },
      },
    },
    key: {
      expired: {
        modal: {
          title: 'Problema de Pagamento',
          button: {
            recover: 'Recuperar Assinatura',
          },
          to_continue:
            'Sua assinatura foi <b>{status}</b> devido a problemas de pagamento. Para continuar:',
          deactivated: 'desativada',
          deleted: 'deletada',
          recover: {
            list: {
              click:
                'Clique no botão abaixo para recuperar sua assinatura ou verifique seu email para detalhes de pagamento.',
              re_activated:
                'Sua chave será <b>reativada imediatamente</b> após a atualização do pagamento.',
            },
          },
          new: {
            list: {
              website:
                'Visite o AutoCut: <span>https://www.autocut.com/#pricing</span>',
              subscribe: 'Compre uma nova chave de licença',
            },
          },
          support:
            'Precisa de ajuda? Entre em contato conosco em: <span>contact@autocut.com</span>',
          thanks: 'Obrigado!',
        },
        moda: {
          button: {
            new: 'Comprar Nova Assinatura',
          },
        },
      },
    },
    gaming: {
      trial: {
        modal: {
          title: 'Inicie sua Jornada no AutoCut',
          header: {
            days: {
              left: 'Comece seu teste de 14 dias com {completedElements} recursos adicionais. {daysLeft} dias restantes para explorar!',
            },
            more: {
              days: 'Continue com mais <b>{bonusDays} dias</b> para aproveitar ao máximo o AutoCut.',
            },
          },
          timeline: {
            left: 'Teste Gratuito de 14 Dias',
            right: 'Teste Gratuito de 24 Dias',
          },
          task: {
            list: {
              title: 'Progresso das Tarefas: {completed}/{maximum} Concluídas',
            },
          },
        },
      },
    },
    reload: {
      autocut: {
        modal: {
          title: 'Recarga Necessária',
          cta: 'Fechar Todas as Extensões',
          text: 'Várias instâncias do AutoCut detectadas. Clique abaixo para fechá-las e evitar problemas.',
        },
      },
    },
    'key-already-activated':
      'A chave está ativa em outro dispositivo. Desvincule na página do AutoCut (https://www.autocut.com/en/) e tente novamente.',
    'key-does-not-exist':
      'Chave não encontrada. Por favor, verifique sua chave e tente novamente.',
    unsupported: {
      version: {
        modal: {
          title: {
            __text__: 'Versão do DaVinci Resolve não suportada',
            '24.4.0': 'Desça para o DaVinci Resolve 24.3 ou atualize para 24.5',
          },
          cta: 'Reconhecer',
          text: {
            __text__:
              'Versão não suportada do DaVinci Resolve ({version}) detectada. A experiência pode ser afetada. Use a <b>última versão estável</b> para o desempenho ideal do AutoCut.',
            '24.4.0':
              'Versão não suportada do DaVinci Resolve ({version}). Atualize para 24.5 ou desça para 24.3 para usar o AutoCut.',
          },
        },
      },
      rendering: {
        engine: {
          title: 'Motor de Renderização Não Suportado',
          modal: {
            text: 'O motor de renderização atual não é suportado. AutoCaptions e AutoZoom não funcionarão.{br}{br}Selecione aceleração de GPU nas configurações do projeto.',
          },
        },
      },
    },
    handshake: {
      restart: {
        modal: {
          title: 'Reinício Necessário',
          cta: 'Fechar Todas as Extensões',
          text: 'Múltiplas instâncias do AutoCut detectadas. Clique abaixo para fechá-las e evitar problemas.',
        },
      },
    },
    disabled: {
      features: {
        info: 'A combinação de idioma/fonte selecionada desativa: <b>{features}</b>. Esses recursos não estarão disponíveis em legendas.',
        tips: 'Possível problema de suporte de caracteres. Altere a fonte ou o idioma para habilitar esses recursos.',
      },
    },
    upgrade: {
      to: 'Atualize para {plan}',
    },
    deepgram: {
      error: {
        retry: 'Tente transcrição com um modelo IA diferente.',
        modal: {
          title: 'Erro de Tradução',
          cta: 'Junte-se ao Discord',
          text: 'Ocorreu um erro durante a tradução do áudio. Pode ser devido à qualidade do áudio ou suporte ao idioma.{br}Reporte através do Discord ou por email contact@autocut.com.',
        },
      },
    },
    completing: {
      preset: {
        preview: 'Finalizando pré-visualização da predefinição... Quase lá.',
      },
    },
    chapters: {
      preview: {
        error:
          'Erro ao gerar a pré-visualização. Ajuste os carimbos de tempo ou reinicie o AutoCut se o problema persistir.',
      },
      step: {
        language: 'Selecionar Clipes e Idioma',
        editor: 'Editor de Capítulos',
      },
      button: {
        chapters: 'Adicionar Capítulos',
      },
      editor: {
        chapters: {
          title: 'Capítulos Detectados',
        },
        parameters: {
          title: 'Configurações de Capítulo',
        },
        download: {
          youtube: {
            chapters: {
              file: {
                download: 'Baixar Capítulos do YouTube',
                success: 'Chapter.txt salvo no projeto do Premiere Pro',
              },
            },
          },
        },
      },
      invalid: {
        selection: {
          screen: {
            title: 'Selecione Clipes para Base de Capítulo',
            text1: 'Selecione pelo menos um clipe com áudio incluso.',
          },
        },
      },
      process: {
        transcript: {
          button: 'Gerar Capítulos',
        },
      },
      adding: {
        chapters: 'Adicionando Capítulos...',
      },
      backup: 'Fazendo Backup da Sua Sequência...',
      analyse: 'Analisando Áudio...',
      getting: {
        chapters: 'Gerando Capítulos...',
      },
      transcript: {
        finished: 'Capítulos Gerados',
      },
      disabled: {
        language: 'O idioma selecionado não suporta detecção de capítulos.',
        file: {
          too: {
            long: 'Clipes ultrapassam o comprimento máximo. Use clipes com menos de {limit} minutos.',
          },
        },
      },
    },
    time: {
      badge: {
        error: {
          wrong: {
            format: 'Formato de tempo incorreto. Use HH:MM:SS.',
          },
        },
      },
    },
    emoji: {
      picker: {
        animated: {
          option: 'Animado',
        },
        static: {
          option: 'Estático',
        },
      },
    },
    viral: {
      clips: {
        invalid: {
          selection: {
            screen: {
              title:
                'Selecione clipes da linha do tempo para Detecção de Cliques Virais',
              text1: 'Selecione pelo menos um clipe com áudio incluso.',
            },
          },
        },
        disabled: {
          language: 'Detecção de Cliques Virais não é suportada neste idioma.',
          file: {
            too: {
              long: 'Clipes são muito longos. Selecione clipes com menos de {limit} minutos.',
            },
          },
        },
        process: {
          transcript: {
            button: 'Gerar Cliques Virais',
          },
        },
        button: {
          process: 'Criar Cliques',
        },
        selector: {
          title: '{number} Potenciais Cliques Virais Detectados',
        },
        virality: {
          score: 'Pontuação de Viralidade',
        },
        step: {
          language: 'Selecionar Clipes e Idioma',
          selector: 'Selecionar Cliques Virais',
        },
        backup: 'Fazendo Backup da Sua Sequência...',
        analyse: 'Analisando Áudio...',
        getting: {
          clips: 'Gerando Cliques Virais...',
        },
        transcript: {
          finished: 'Cliques Virais Gerados',
        },
        adding: {
          creating: {
            clips: 'Criando Cliques...',
          },
        },
      },
      score: 'Pontuação Viral',
    },
    mode: {
      description: {
        silence: 'Remover silêncios da linha do tempo',
        ai: 'Remover automaticamente silêncios da linha do tempo',
        podcast: 'Editar podcasts instantaneamente',
        repeat: 'Remover repetições de tomadas com IA',
        zoom: 'Adicionar automaticamente efeitos de zoom',
        captions: 'Adicionar legendas animadas usando IA',
        resize: 'Redimensionar sequências para diferentes plataformas',
        broll: 'Adicionar automaticamente filmagens de B-Roll com IA',
        profanity: 'Censurar automaticamente linguagem indesejada',
        chapter: 'Detectar e adicionar marcadores de capítulo automaticamente',
        viral:
          'Identificar facilmente clipes de potencial viral em vídeos longos',
      },
    },
    formatted: {
      numbers: {
        title: 'Números Formatados',
        modal: {
          cta: 'Fechar Modal',
          text: '<b>{number} palavras</b> foram convertidas em números na transcrição.',
        },
      },
    },
    transcription: {
      provider: {
        step: {
          title: 'Escolha o Modelo de Transcrição',
          model: {
            '1': 'Modelo 1 (padrão)',
            '2': 'Modelo 2',
          },
        },
      },
    },
  },
} as const;

export default translations;
