import {AUTOCUT_CONSTANTS} from '@autocut/constants/configs';
import {preload} from '@autocut/types/ElectronPreload';
import {CURRENT_ENV} from '@autocut/utils/currentEnv.utils';
import {
  getBleepResourceConfig,
  getGlobalResourceConfig,
  requestResourceOnInit,
} from '@autocut/utils/downloadableRessources.utils';
import {getResourceFolderPath} from '@autocut/utils/electron/app.electron.utils';
import axios from 'axios';

export const resourcesConfigs = {
  //CAPTIONS
  lua_scripts: requestResourceOnInit({
    fileName: 'lua_scripts',
    downloadMethod: async () => {
      const zipFilePath =
        (await preload().resources.download(
          AUTOCUT_CONSTANTS[CURRENT_ENV].LUA_SERVER_ADDRESS +
            '/autocut-lua-remoteContent.zip',
        )) || '';
      const unzipPath = preload().path.join(getResourceFolderPath(), 'lua');
      await preload().resources.unzip(zipFilePath, unzipPath);

      return unzipPath;
    },
    existCheck: async () => {
      const versionFilePath = preload().path.join(
        getResourceFolderPath(),
        'lua',
        'remoteContent',
        'version',
      );
      if (!preload().fs.existsSync(versionFilePath))
        return {isExist: false, outputFilePath: versionFilePath};
      const version = preload()
        .fs.readFileSync(versionFilePath, 'utf-8')
        .trim();

      const response = await axios.get(
        AUTOCUT_CONSTANTS[CURRENT_ENV].LUA_SERVER_ADDRESS + '/version',
      );
      const data = response.data;

      const outdated = data.toString() !== version.toString();
      if (outdated) {
        console.log('lua_scripts outdated');
        return {isExist: false, outputFilePath: versionFilePath};
      }
      return {isExist: true, outputFilePath: versionFilePath};
    },
    scope: 'global',
  }),
  da_vinci_lua_script: requestResourceOnInit({
    fileName: 'da_vinci_lua_script',
    downloadMethod: async () => {
      console.log('da_vinci_lua_script downloadMethod');

      const isWindows = preload().os.platform() === 'win32';

      const zipFilePath = await preload().resources.download(
        AUTOCUT_CONSTANTS[CURRENT_ENV].LUA_SERVER_ADDRESS +
          '/autocut-lua-script.zip',
      );
      const unzipPath = isWindows
        ? preload().path.join(
            'C:',
            'ProgramData',
            'Blackmagic Design',
            'DaVinci Resolve',
            'Fusion',
            'Scripts',
            'Utility',
          )
        : preload().path.join(
            '/',
            'Library',
            'Application Support',
            'Blackmagic Design',
            'DaVinci Resolve',
            'Fusion',
            'Scripts',
            'Utility',
          );
      await preload().resources.unzip(zipFilePath, unzipPath);

      return unzipPath;
    },
    existCheck: async () => {
      const isWindows = preload().os.platform() === 'win32';
      const scriptFilePath = isWindows
        ? preload().path.join(
            'C:',
            'ProgramData',
            'Blackmagic Design',
            'DaVinci Resolve',
            'Fusion',
            'Scripts',
            'Utility',
            'AutoCut.lua',
          )
        : preload().path.join(
            '/',
            'Library',
            'Application Support',
            'Blackmagic Design',
            'DaVinci Resolve',
            'Fusion',
            'Scripts',
            'Utility',
            'AutoCut.lua',
          );
      if (!preload().fs.existsSync(scriptFilePath))
        return {isExist: false, outputFilePath: scriptFilePath};
      const scriptContent = preload().fs.readFileSync(scriptFilePath, 'utf-8');
      const version = scriptContent
        .split(/\r?\n/)[0]
        .match(/# version:(\d*)/)?.[1];

      if (!version) return {isExist: false, outputFilePath: scriptFilePath};

      const response = await axios.get(
        AUTOCUT_CONSTANTS[CURRENT_ENV].LUA_SERVER_ADDRESS + '/version',
      );
      const data = response.data;

      const outdated = data.toString() !== version.toString();
      if (outdated) {
        console.log('da_vinci_lua_script outdated');
        return {isExist: false, outputFilePath: scriptFilePath};
      }
      return {isExist: true, outputFilePath: scriptFilePath};
    },
    scope: 'global',
  }),
  bleep_sound_1_wav: getBleepResourceConfig(1),
  bleep_sound_2_wav: getBleepResourceConfig(2),
  wav_epr: requestResourceOnInit(
    getGlobalResourceConfig({
      resourceName: 'wav_epr',
      fileName: 'WAV_48_kHz_16_bits.epr',
    }),
  ),
} as const;
