import React, {
  CSSProperties,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import {CheckBoxCommonProps} from '../../CheckBox';

import css from './Switch.module.scss';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {Text} from '../../../Text/Text';
import {colors} from '@autocut/designSystem/colors';

export type SwitchProps = {
  leftLabel?: React.ReactNode;
  rightLabel?: React.ReactNode;
};

export type SwitchRefElement = HTMLDivElement & {
  blink: () => void;
};

export const Switch = forwardRef<
  SwitchRefElement,
  SwitchProps & CheckBoxCommonProps
>(({size, leftLabel, rightLabel, onChange, checked}, ref) => {
  const id = useMemo(() => {
    return Math.random().toString(36).substring(7);
  }, []);

  const divRef = useRef<HTMLDivElement>(null);

  // Exposes the ref to the parent component
  useImperativeHandle(ref, () => ({
    ...(divRef.current as HTMLDivElement),
    blink: () => {
      if (!divRef.current) return;
      divRef.current.classList.add(css.blinking);

      setTimeout(() => {
        divRef.current?.classList.remove(css.blinking);
      }, 1500);
    },
  }));

  return (
    <FlexContainer
      gap={Spacing.s2}
      alignItems="center"
      ref={divRef}
    >
      {leftLabel && (
        <Text
          variant="textXs"
          color={colors.gray300}
        >
          {leftLabel}
        </Text>
      )}
      <div
        className={css.container}
        style={
          {
            '--height': `${size}px`,
            '--width': `${size * 2}px`,
          } as CSSProperties
        }
      >
        <input
          id={id}
          className={css.root}
          type="checkbox"
          onClick={() => {
            onChange && onChange(!checked);
          }}
          onChange={() => null} // suppress warning
          checked={checked}
        />
        <label
          className={css.label}
          htmlFor={id}
        />
        <div className={css.dot} />
      </div>
      {rightLabel && (
        <Text
          variant="textXs"
          color={colors.gray300}
        >
          {rightLabel}
        </Text>
      )}
    </FlexContainer>
  );
});
