export enum Spacing {
  s1 = 'var(--spacing-s1)',
  s2 = 'var(--spacing-s2)',
  s3 = 'var(--spacing-s3)',
  s4 = 'var(--spacing-s4)',
  s5 = 'var(--spacing-s5)',
  s6 = 'var(--spacing-s6)',
  s7 = 'var(--spacing-s7)',
  s8 = 'var(--spacing-s8)',
  s9 = 'var(--spacing-s9)',
  s10 = 'var(--spacing-s10)',
  s12 = 'var(--spacing-s12)',
  s13 = 'var(--spacing-s13)',
  s14 = 'var(--spacing-s14)',
  s15 = 'var(--spacing-s15)',
  s16 = 'var(--spacing-s16)',
  s17 = 'var(--spacing-s17)',
  s18 = 'var(--spacing-s18)',
  s19 = 'var(--spacing-s19)',
  s20 = 'var(--spacing-s20)',
  s24 = 'var(--spacing-s24)',
  s32 = 'var(--spacing-s32)',
  s40 = 'var(--spacing-s40)',
  s48 = 'var(--spacing-s48)',
  s56 = 'var(--spacing-s56)',
  s64 = 'var(--spacing-s64)',
}

export enum SpacingPx {
  p4 = Spacing.s1,
  p8 = Spacing.s2,
  p12 = Spacing.s3,
  p16 = Spacing.s4,
  p20 = Spacing.s5,
  p24 = Spacing.s6,
  p28 = Spacing.s7,
  p32 = Spacing.s8,
  p40 = Spacing.s10,
  p48 = Spacing.s12,
  p64 = Spacing.s16,
  p80 = Spacing.s20,
  p96 = Spacing.s24,
  p128 = Spacing.s32,
  p160 = Spacing.s40,
  p192 = Spacing.s48,
  p224 = Spacing.s56,
  p256 = Spacing.s64,
}
