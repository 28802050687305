import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {FontSelect} from '@autocut/pages/modes/captions/customization/parts/CustomizationModal/Parts/TextCustomization/FontSelect';
import {rgbToHex} from '@autocut/utils/color.utils';
import {loadFontForCanvas} from '@autocut/utils/fonts.utils';
import {random} from 'lodash';
import React, {useEffect, useRef, useState} from 'react';

const FontMeasure = () => {
  const textCanvasRef = useRef<HTMLCanvasElement>(null);
  const overlayCanvasRef = useRef<HTMLCanvasElement>(null);
  const textToMesureFont = useAutoCutStore(
    state => state.ui.parameters.caption.text.font,
  );

  const [refresh, setRefresh] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [randomColor, setRandomColor] = useState('');
  const [textToMesure, setTextToMesure] = useState('AutoCut');
  const [textToMesureFontSize, setTextToMesureFontSize] = useState(14);
  const [textSize, setTextSize] = useState<TextMetrics>();

  useEffect(() => {
    const drawCanvas = async () => {
      const textCanvas = textCanvasRef.current;
      const textContext = textCanvas?.getContext('2d');
      if (!textCanvas || !textContext) return;

      const overlayCanvas = overlayCanvasRef.current;
      const overlayContext = overlayCanvas?.getContext('2d');
      if (!overlayCanvas || !overlayContext) return;

      setIsLoading(true);

      const randomColor = rgbToHex(
        {
          r: random(0, 255),
          g: random(0, 255),
          b: random(0, 255),
        },
        true,
      );

      await loadFontForCanvas(textToMesureFont);

      const fontParam = `${textToMesureFontSize}px ${textToMesureFont.fontFamily}`;

      textContext.font = fontParam;
      textContext.textAlign = 'center';
      const metrics = textContext.measureText(textToMesure);
      setTextSize(metrics);

      const zoom = 1.5;

      //TEXT
      textCanvas.width = metrics.width * zoom;
      textCanvas.height = ((metrics.width * zoom) / 16) * 9;

      textContext.clearRect(0, 0, textCanvas.width, textCanvas.height);

      textContext.fillStyle = 'white';
      textContext.fillRect(0, 0, textCanvas.width, textCanvas.height);

      textContext.fillStyle = 'black';
      textContext.font = fontParam;

      textContext.fillText(
        textToMesure,
        textCanvas.width / 2 - metrics.width / 2,
        textCanvas.height / 2 + metrics.actualBoundingBoxAscent / 2,
      );

      textContext.fillStyle = randomColor;
      const anchorSize = Math.min(10, textToMesureFontSize);
      textContext.fillRect(0, 0, anchorSize, anchorSize);
      textContext.fillRect(
        textCanvas.width - anchorSize,
        textCanvas.height - anchorSize,
        textCanvas.width,
        textCanvas.height,
      );

      //OVERLAY
      overlayCanvas.width = overlayCanvas.clientWidth;
      overlayCanvas.height = overlayCanvas.clientHeight;

      const ratioX = overlayCanvas.width / textCanvas.width;
      const ratioY = overlayCanvas.height / textCanvas.height;

      const textX = (textCanvas.width / 2 - metrics.width / 2) * ratioX;
      const textY =
        (textCanvas.height / 2 + metrics.actualBoundingBoxAscent / 2) * ratioY;

      overlayContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);

      //Accent overlay
      overlayContext.lineWidth = 2;
      overlayContext.strokeStyle = 'gold';
      overlayContext.fillStyle = 'gold';

      overlayContext.font = '12px Arial';
      const accentText = `${metrics.width.toFixed(
        1,
      )} x ${metrics.actualBoundingBoxAscent.toFixed(1)}px`;
      const accentMetrics = overlayContext.measureText(accentText);

      overlayContext.strokeRect(
        textX,
        textY - metrics.actualBoundingBoxAscent * ratioY,
        metrics.width * ratioX,
        metrics.actualBoundingBoxAscent * ratioY,
      );
      overlayContext.fillRect(
        textX + (metrics.width * ratioX) / 2 - accentMetrics.width / 2,
        textY -
          metrics.actualBoundingBoxAscent * ratioY -
          accentMetrics.actualBoundingBoxAscent -
          accentMetrics.actualBoundingBoxDescent -
          3,
        accentMetrics.width * 1.2,
        accentMetrics.actualBoundingBoxAscent +
          accentMetrics.actualBoundingBoxDescent +
          5,
      );
      overlayContext.fillStyle = 'black';
      overlayContext.fillText(
        accentText,
        textX +
          (metrics.width * ratioX) / 2 -
          accentMetrics.width / 2 +
          accentMetrics.width * 0.1,
        textY - metrics.actualBoundingBoxAscent * ratioY - 3,
      );

      //Descent overlay
      const descentText = `${metrics.width.toFixed(
        1,
      )} x ${metrics.actualBoundingBoxDescent.toFixed(1)}px`;
      const descentMetrics = overlayContext.measureText(descentText);
      overlayContext.strokeStyle = 'lime';
      overlayContext.fillStyle = 'lime';
      overlayContext.strokeRect(
        textX,
        textY + metrics.actualBoundingBoxDescent * ratioY,
        metrics.width * ratioX,
        -metrics.actualBoundingBoxDescent * ratioY,
      );
      overlayContext.fillRect(
        textX + (metrics.width * ratioX) / 2 - descentMetrics.width / 2,
        textY + metrics.actualBoundingBoxDescent * ratioY,
        descentMetrics.width * 1.2,
        descentMetrics.actualBoundingBoxAscent +
          descentMetrics.actualBoundingBoxDescent +
          5,
      );
      overlayContext.fillStyle = 'black';
      overlayContext.fillText(
        descentText,
        textX +
          (metrics.width * ratioX) / 2 -
          descentMetrics.width / 2 +
          descentMetrics.width * 0.1,
        textY +
          metrics.actualBoundingBoxDescent * ratioY +
          descentMetrics.actualBoundingBoxAscent +
          descentMetrics.actualBoundingBoxDescent,
      );

      setRandomColor(randomColor);
      setIsLoading(false);
    };

    void drawCanvas();
  }, [textToMesure, textToMesureFont, textToMesureFontSize]);

  return (
    <FlexContainer gap={8}>
      <FlexContainer
        flexDirection="column"
        gap={8}
        style={{flexGrow: 0, flexShrink: 0}}
      >
        <input
          type="text"
          value={textToMesure}
          onChange={e => setTextToMesure(e.target.value)}
        />
        <FontSelect />
        <input
          type="number"
          placeholder="14"
          value={textToMesureFontSize}
          onChange={e =>
            setTextToMesureFontSize(parseInt(e.target.value || '0'))
          }
        />
        <input
          type="range"
          min={1}
          max={400}
          step={1}
          value={textToMesureFontSize}
          onChange={e =>
            setTextToMesureFontSize(parseInt(e.target.value || '0'))
          }
        />
        {textSize ? (
          <div>
            <b>Width:</b> {textSize.width.toFixed(2)}px
            <br />
            <b>Height:</b>{' '}
            {(
              textSize.actualBoundingBoxAscent +
              textSize.actualBoundingBoxDescent
            ).toFixed(2)}
            px
            <br />
            {(
              textSize.fontBoundingBoxAscent + textSize.fontBoundingBoxDescent
            ).toFixed(2)}
            px
          </div>
        ) : null}
      </FlexContainer>
      <div
        style={{
          flexGrow: 1,
          flexShrink: 1,
          aspectRatio: 16 / 9,
          position: 'relative',
          background: randomColor,
        }}
        key={refresh}
      >
        <canvas
          ref={textCanvasRef}
          style={{
            width: '100vw',
            aspectRatio: 16 / 9,
            position: 'absolute',
          }}
        />
        <canvas
          ref={overlayCanvasRef}
          style={{
            width: '100vw',
            aspectRatio: 16 / 9,
            position: 'absolute',
          }}
        />
      </div>
    </FlexContainer>
  );
};

export default FontMeasure;
