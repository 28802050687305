import {preload} from '@autocut/types/ElectronPreload';
import {JSONTimeline} from '@autocut/types/JSONTimeline';
import {IncrementalError} from './errors/IncrementalError';

export const getTimelineInfos = async (): Promise<JSONTimeline> => {
  const timelineInfosJsonFilepath =
    await preload().computeNodeIpc.startTask<string>({
      domain: {
        slug: 'get-timeline-infos',
        target: 'davinci',
      },
      priority: 0,
    }).promise;

  try {
    const data = preload().fs.readFileSync(timelineInfosJsonFilepath, 'utf8');
    const timelineInfos = JSON.parse(data);

    return timelineInfos;
  } catch (err: any) {
    throw new IncrementalError(err, 'getTimelineInfos');
  }
};
