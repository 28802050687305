import {LANGUAGE_TO_MODEL} from '@autocut/constants/languageToModel';

export const getTranscriptionLanguage = ({
  languageOfTranscriptionValue,
  usedModel = 0,
}: {
  languageOfTranscriptionValue: string;
  usedModel?: number;
}) => {
  const correspondingLanguage = LANGUAGE_TO_MODEL[languageOfTranscriptionValue];
  const language =
    correspondingLanguage[usedModel % correspondingLanguage?.length || 0];

  return {
    value: languageOfTranscriptionValue,
    ...language,
  };
};
