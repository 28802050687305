import {colors} from '@autocut/designSystem/colors';
import {IconXClose} from '@autocut/designSystem/components/atoms/Icon/general/IconXClose';
import {IconMicrophone01} from '@autocut/designSystem/components/atoms/Icon/media/IconMicrophone01';
import {Input} from '@autocut/designSystem/components/atoms/Input/Input';
import {
  Select,
  SelectOption,
} from '@autocut/designSystem/components/atoms/Select/Select';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import {Divider} from '@autocut/designSystem/components/atoms/Divider/Divider';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {IconPlus} from '@autocut/designSystem/components/atoms/Icon/general/IconPlus';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {setAutocutStore} from '@autocut/utils/zustand';
import {useEffect, useMemo, useState} from 'react';

import css from './CamerasStep.module.scss';
import {PaddedSection} from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import {useIntl} from 'react-intl';
import {IconCamera02} from '@autocut/designSystem/components/atoms/Icon/images/IconCamera02';
import {initCameras} from './utils';
import { TranslatedMessage } from '@autocut/components/TranslatedMessage/TranslatedMessage';

const SELECT_COLORS = [
  '#16a2c4c8', // Previously 7f for the opacity
  '#f13ad7c8',
  '#FF8B00c8',
  '#0066ffc8',
  '#41c45bc8',
  '#8b4becc8',
  '#FFC400c8',
  '#c7292ac8',
  '#ffffffc8',
];

export type Camera = {
  videoTrack: number | undefined;
  speakers: {name: string; audioTrack: number}[];
};

export type CamerasStepProps = {
  maxVideoTrackId: number;
};

export const CamerasStep = ({maxVideoTrackId}: CamerasStepProps) => {
  const intl = useIntl();
  const {speakers} = useAutoCutStore(state => state.ui.parameters.podcast);

  const [cameras, setCameras] = useState<Camera[]>(initCameras());

  useEffect(() => {
    const speakersAudioTrackIds = speakers.map(speaker => speaker.audioTrack);

    const tempCameras = [...cameras];
    for (const camera of tempCameras) {
      camera.speakers = camera.speakers.filter(speaker =>
        speakersAudioTrackIds.includes(speaker.audioTrack),
      );
    }

    setCameras([
      ...tempCameras.sort((a, b) => (a.videoTrack || 0) - (b.videoTrack || 0)),
    ]);
  }, [speakers]);

  const usedVideoTracksIds = useMemo(() => {
    return cameras
      .map(camera => camera.videoTrack)
      .filter(videoTrackId => videoTrackId !== undefined);
  }, [cameras]);

  const availableVideoTracksIds: number[] = useMemo(() => {
    return Array.from({length: maxVideoTrackId}, (_, i) => i + 1);
  }, [maxVideoTrackId]);

  const handleAddCamera = () =>
    setCameras([...cameras, {videoTrack: undefined, speakers: []}]);

  const handleRemoveCameraByIndex = (index: number) => {
    const deletedCamera = [...cameras][index];
    setCameras(previousCameras =>
      previousCameras.filter((camera, currentIndex) => currentIndex != index),
    );

    if (deletedCamera.videoTrack !== undefined) {
      setAutocutStore(
        'ui.parameters.podcast.speakers',
        speakers.map(speaker => ({
          ...speaker,
          videoTracks: speaker.videoTracks.filter(
            videoTrack => videoTrack !== deletedCamera.videoTrack,
          ),
        })),
      );
    }
  };

  const handleVideoTrackUpdate = (
    index: number,
    videoTrack: number | undefined,
  ) => {
    const previousCamera = [...cameras][index];

    // No change, nothing to do
    if (previousCamera.videoTrack === videoTrack) return;

    setCameras(previousCameras =>
      previousCameras.map((camera, i) =>
        i === index ? {...camera, videoTrack} : camera,
      ),
    );

    // No speakers, so no speakers to update
    if (previousCamera.speakers.length === 0) return;

    const cameraSpeakerAudioTracksIds = previousCamera.speakers.map(
      speaker => speaker.audioTrack,
    );

    for (const cameraSpeakerAudioTrackId of cameraSpeakerAudioTracksIds) {
      const speaker = speakers.find(
        speaker => speaker.audioTrack === cameraSpeakerAudioTrackId,
      );

      if (!speaker) continue;

      // Remove the previous video track from the speaker
      if (previousCamera.videoTrack !== undefined) {
        speaker.videoTracks = speaker.videoTracks.filter(
          videoTrackId => videoTrackId !== previousCamera.videoTrack,
        );
      }

      // Add the new video track to the speaker
      if (videoTrack !== undefined) {
        speaker.videoTracks.push(videoTrack);
      }

      setAutocutStore(
        'ui.parameters.podcast.speakers',
        speakers.map(unchangedSpeaker =>
          unchangedSpeaker.audioTrack === speaker.audioTrack
            ? speaker
            : unchangedSpeaker,
        ),
      );
    }
  };

  const handleSpeakersUpdate = (
    index: number,
    selectedSpeakersAudioTrackIds: number[],
  ) => {
    const previousCamera = [...cameras][index];
    const orderedPreviousCameraSpeakersAudioIds = previousCamera.speakers
      .map(speaker => speaker.audioTrack)
      .sort();
    const orderedSelectedSpeakersAudioIds =
      selectedSpeakersAudioTrackIds.sort();

    // No change, nothing to do
    if (
      orderedPreviousCameraSpeakersAudioIds === orderedSelectedSpeakersAudioIds
    )
      return;

    setCameras(previousCameras =>
      previousCameras.map((camera, i) =>
        i === index
          ? {
              ...camera,
              speakers: selectedSpeakersAudioTrackIds.map(audioTrack => ({
                name:
                  speakers.find(speaker => speaker.audioTrack === audioTrack)
                    ?.name ?? '',
                audioTrack,
              })),
            }
          : camera,
      ),
    );

    if (previousCamera.videoTrack === undefined) return;

    const notSelectedSpeakerAudioTracksIds = speakers
      .map(speaker => speaker.audioTrack as number)
      .filter(index => !selectedSpeakersAudioTrackIds.includes(index));

    for (const selectedSpeakerAudioTrackId of selectedSpeakersAudioTrackIds) {
      const speaker = speakers.find(
        speaker => speaker.audioTrack === selectedSpeakerAudioTrackId,
      );
      if (!speaker) continue;

      if (!speaker.videoTracks.includes(previousCamera.videoTrack)) {
        speaker.videoTracks.push(previousCamera.videoTrack);
      }

      setAutocutStore(
        'ui.parameters.podcast.speakers',
        speakers.map(unchangedSpeaker =>
          unchangedSpeaker.audioTrack === selectedSpeakerAudioTrackId
            ? speaker
            : unchangedSpeaker,
        ),
      );
    }

    for (const notSelectedSpeakerAudioTrackId of notSelectedSpeakerAudioTracksIds) {
      const speaker = speakers.find(
        speaker => speaker.audioTrack === notSelectedSpeakerAudioTrackId,
      );

      if (!speaker) continue;

      speaker.videoTracks = speaker.videoTracks.filter(
        videoTrack => videoTrack !== previousCamera.videoTrack,
      );

      setAutocutStore(
        'ui.parameters.podcast.speakers',
        speakers.map(unchangedSpeaker =>
          unchangedSpeaker.audioTrack === notSelectedSpeakerAudioTrackId
            ? speaker
            : unchangedSpeaker,
        ),
      );
    }
  };

  return (
    <FormSection
      className={css.root}
      title={intl.formatMessage({
        id: 'modes_podcast_steps_customization_steps_cameras_title',
        defaultMessage: 'Cameras',
      })}
      description={intl.formatMessage({
        id: 'modes_podcast_steps_customization_steps_cameras_description',
        defaultMessage:
          'Add your cameras by assigning each a video track and selecting wich speakers are visible on screen. Only one video track can be selected per camera. You can select any number of speakers per camera.',
      })}
    >
      <PaddedSection>
        <FlexContainer
          flexDirection="column"
          gap={Spacing.s2}
        >
          <FlexContainer
            alignItems="flex-end"
            gap={Spacing.s2}
            className={css.header}
          >
            <Text
              className={css.audioTrack}
              variant={'textXs'}
              color={colors.gray300}
            >
              <TranslatedMessage
                id="modes_podcast_steps_customization_steps_cameras_header_audio"
                defaultMessage="Video track"
              />
            </Text>
            <Text
              variant={'textXs'}
              color={colors.gray300}
            >
              <TranslatedMessage
                id="modes_podcast_steps_customization_steps_cameras_header_name"
                defaultMessage="Speaker(s)"
              />
            </Text>
          </FlexContainer>
          <FlexContainer
            flexDirection="column"
            gap={Spacing.s4}
          >
            <FlexContainer
              flexDirection="column"
              justifyContent="center"
              gap={Spacing.s2}
            >
              {cameras.map((camera, index) => (
                <>
                  <CameraItem
                    key={`cameraItem-${index}`}
                    camera={camera}
                    onRemove={() => handleRemoveCameraByIndex(index)}
                    onAudioTrackUpdate={audioTrackId =>
                      handleVideoTrackUpdate(index, audioTrackId)
                    }
                    onSpeakersUpdate={selected =>
                      handleSpeakersUpdate(index, selected)
                    }
                    availableVideoTracks={availableVideoTracksIds
                      .filter(
                        videoTrackId =>
                          !usedVideoTracksIds.includes(videoTrackId) ||
                          (camera.videoTrack !== undefined &&
                            camera.videoTrack === videoTrackId),
                      )
                      .map(videoTrackId => ({
                        value: videoTrackId.toString(),
                        label: intl.formatMessage(
                          {
                            id: 'modes_podcast_steps_customization_steps_cameras_trackTemplate',
                            defaultMessage: 'Track V{index}',
                          },
                          {index: videoTrackId},
                        ),
                      }))}
                    availableSpeakers={speakers
                      .filter(speaker => speaker.audioTrack !== undefined)
                      .map((speaker, i) => ({
                        value: (speaker.audioTrack as number).toString(),
                        label:
                          speaker.name === ''
                            ? intl.formatMessage(
                                {
                                  id: 'modes_podcast_steps_customization_steps_speakers_trackTemplate',
                                  defaultMessage: 'Track AX',
                                },
                                {index: speaker.audioTrack},
                              )
                            : speaker.name,
                        color: SELECT_COLORS[i % SELECT_COLORS.length],
                      }))}
                  />
                  {index !== cameras.length - 1 && <Divider />}
                </>
              ))}
            </FlexContainer>
            <Button
              variant="secondary.dashed"
              onClick={handleAddCamera}
            >
              <FlexContainer
                alignItems="center"
                justifyContent="center"
                gap={Spacing.s1}
              >
                <IconPlus
                  size={18}
                  color="white"
                />
                <TranslatedMessage
                  id="modes_podcast_steps_customization_steps_cameras_cta"
                  defaultMessage="Add a speaker"
                />
              </FlexContainer>
            </Button>
          </FlexContainer>
        </FlexContainer>
      </PaddedSection>
    </FormSection>
  );
};

export type CameraItemProps = {
  camera: Camera;
  onRemove: () => void;
  onAudioTrackUpdate: (audioTrack: number | undefined) => void;
  onSpeakersUpdate: (selectedSpeakerIndexes: number[]) => void;
  availableVideoTracks: SelectOption[];
  availableSpeakers: SelectOption[];
};

const CameraItem = ({
  camera,
  onRemove,
  onAudioTrackUpdate,
  onSpeakersUpdate,
  availableVideoTracks,
  availableSpeakers,
}: CameraItemProps) => {
  const intl = useIntl();

  return (
    <FlexContainer
      className={css.item}
      alignItems="center"
      gap={Spacing.s6}
    >
      <FlexContainer
        className={css.item}
        alignItems="center"
        gap={Spacing.s2}
      >
        <IconXClose
          className={`${css.remove} ${css.noShrink}`}
          onClick={onRemove}
          size={18}
          color="white"
        />
        <IconCamera02
          className={css.noShrink}
          size={18}
          color={colors.gray500}
        />
      </FlexContainer>
      <FlexContainer
        alignItems="center"
        gap={Spacing.s2}
        flexGrow={true}
      >
        <Select
          variant="track"
          className={css.noShrink}
          options={availableVideoTracks}
          selected={
            typeof camera.videoTrack === 'number'
              ? camera.videoTrack.toString()
              : camera.videoTrack
          }
          onChange={value =>
            onAudioTrackUpdate(
              typeof value === 'string' ? parseInt(value) : undefined,
            )
          }
          placeholder={intl.formatMessage(
            {
              id: 'modes_podcast_steps_customization_steps_cameras_trackTemplate',
              defaultMessage: 'Track VX',
            },
            {index: 'X'},
          )}
        />
        <Select
          filterUsedOptions
          clearable
          allowMultiple
          allowSelectAll
          options={availableSpeakers}
          selected={camera.speakers.map(speaker =>
            speaker.audioTrack.toString(),
          )}
          onChange={value =>
            onSpeakersUpdate(value.map(index => parseInt(index)))
          }
          style={{flex: 1}}
          placeholder={intl.formatMessage({
            id: 'modes_podcast_steps_customization_steps_cameras_speakerSelectPlaceholder',
            defaultMessage: 'Select speaker(s)...',
          })}
        />
      </FlexContainer>
    </FlexContainer>
  );
};
