import {useEffect, useRef, useState} from 'react';

import {Badge} from '@autocut/designSystem/components/atoms/Badge/Badge';

import css from './VolumeCard.module.scss';

export type VolumeBadgeProps = {
  cursorPosition: number;
  maxWidth: number;
  getValue: () => number;
};

export const VolumeBadge = ({
  cursorPosition,
  maxWidth,
  getValue,
}: VolumeBadgeProps) => {
  const [leftMargin, setLeftMargin] = useState(0);
  const [dBValue, setDBValue] = useState('-60');
  const volumeRef = useRef(null);

  useEffect(() => {
    if (volumeRef == null || volumeRef.current == null) {
      return;
    }

    const volumeElement = volumeRef.current as any;

    if (cursorPosition <= volumeElement.offsetWidth / 2) {
      setLeftMargin(0);
      return;
    }

    if (cursorPosition >= maxWidth - volumeElement.offsetWidth / 2) {
      setLeftMargin(maxWidth - volumeElement.offsetWidth);
      return;
    }

    setLeftMargin(cursorPosition - volumeElement.offsetWidth / 2);
  }, [volumeRef, cursorPosition]);

  useEffect(() => {
    setDBValue(getValue().toString());
  }, [cursorPosition]);

  return (
    <Badge
      ref={volumeRef}
      className={css.root}
      style={{
        left: leftMargin,
      }}
    >
      {dBValue}dB
    </Badge>
  );
};
