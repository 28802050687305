import React, {useState} from 'react';
import css from './header.module.scss';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {preload} from '@autocut/types/ElectronPreload';
import {VersionDisplay} from '../VersionDisplay/VersionDisplay';

import LogoUrl from '@autocut/assets/images/logo.svg';
import {setAutocutStore} from '@autocut/utils/zustand';
import {Button} from '../Button/Button';
import {KebabMenu} from '../KebabMenu';
import {LicenceInfos} from '../LicenceInfos/LicenceInfos';
import {SelectLang} from '../SelectLang/SelectLang';
import {useNavigate} from 'react-router-dom';
import {CURRENT_ENV, Env} from '@autocut/utils/currentEnv.utils';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {HeaderButton} from './HeaderButton';
const Logo = new URL(LogoUrl, import.meta.url).href;

import {IoMdClose} from 'react-icons/io';
import {GoPin} from 'react-icons/go';
import {FaMinus} from 'react-icons/fa';

export const Header = () => {
  const navigate = useNavigate();
  const [isWindowPinned, setIsWindowPinned] = useState(true);

  const {isLogged} = useAutoCutStore(state => ({
    isLogged: state.user.isLogged,
  }));

  const isMac = navigator.userAgent.toUpperCase().indexOf('MAC') >= 0;
  const showDevButton = CURRENT_ENV && CURRENT_ENV !== Env.Production;

  return (
    <FlexContainer
      flexDirection={isMac ? 'row' : 'row-reverse'}
      className={css.headerRootContainer}
    >
      <FlexContainer
        flexDirection={isMac ? 'row' : 'row-reverse'}
        flexWrap="nowrap"
        alignItems="center"
        gap={8}
        className={css.headerButtons}
      >
        <HeaderButton
          colors={{mac: '#ff6159', windows: '#ff6159'}}
          onClick={() => window.close()}
          icon={IoMdClose}
          isMac={isMac}
        />
        <HeaderButton
          colors={{mac: '#ffbd2e', windows: 'transparent'}}
          onClick={() => preload().electron.minimize()}
          icon={FaMinus}
          isMac={isMac}
        />
        <HeaderButton
          colors={{mac: '#2eb2ff', windows: 'transparent'}}
          onClick={() =>
            setIsWindowPinned(preload().electron.togglePinWindow())
          }
          icon={GoPin}
          forcedIcon={isWindowPinned}
          isMac={isMac}
        />
      </FlexContainer>

      <FlexContainer
        alignItems="center"
        justifyContent="center"
        style={{
          minWidth: isMac ? 476 : 440,
          maxWidth: `calc(100vw - ${isMac ? '168px' : '240px'}`, // MacOS : 168px = 84px (left for buttons) + 84px (right for centering) | Windows : 240px = 120px (left for buttons) + 120px (right for centering)
          width: '100%',
        }}
      >
        <FlexContainer
          flexDirection={isMac ? 'row' : 'row-reverse'}
          flexWrap="nowrap"
          alignItems="center"
          className={css.header}
          gap={24}
        >
          <FlexContainer
            alignItems="center"
            flexGrow={true}
            flexWrap="wrap"
            justifyContent="space-between"
            gap={8}
          >
            <FlexContainer
              flexDirection="row"
              flexWrap="nowrap"
              alignItems="center"
              gap={8}
              className={css.applicationLogoAndNameContainer}
            >
              <FlexContainer
                flexDirection="row"
                flexWrap="nowrap"
                alignItems="center"
                gap={8}
                className={css.applicationLogoContainer}
                onClick={() => {
                  setAutocutStore('ui.process.isPaused', false);
                  setAutocutStore('ui.process.isProcessing', false);
                  navigate('/homepage');
                }}
              >
                <img
                  src={Logo}
                  className={css.autocutLogo}
                  alt="autocut_logo"
                />
              </FlexContainer>
              <FlexContainer
                flexDirection="column"
                alignItems="flex-start"
                gap={1}
                style={{transform: 'translateY(6px)'}}
              >
                <p className={css.applicationName}>
                  {CURRENT_ENV === Env.Staging
                    ? 'AutoStg'
                    : CURRENT_ENV === Env.Development
                      ? 'AutoDev'
                      : 'Autocut'}
                </p>
                <VersionDisplay />
              </FlexContainer>
            </FlexContainer>

            <FlexContainer
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="space-between"
              alignItems="center"
              gap={8}
              className={css.rightContainer}
              style={{margin: '0 auto'}}
            >
              <FlexContainer
                flexDirection="row"
                flexWrap="nowrap"
                alignItems="center"
                gap={8}
              >
                {showDevButton && (
                  <Button
                    size="sm"
                    compact
                    buttonType={
                      location.pathname === '/dev' ? 'primary' : 'secondary'
                    }
                    color="#e65019"
                    onClickFunction={() => {
                      if (location.pathname === '/dev') {
                        navigate(-1);
                      } else {
                        navigate('/dev');
                      }
                    }}
                  >
                    {'</>'}
                  </Button>
                )}
                {isLogged && <LicenceInfos />}
                <SelectLang />
                <KebabMenu />
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
