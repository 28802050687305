import React from 'react';
import {ModeProvider} from './contexts/ModeProvider';
import {LaunchManager} from '@autocut/pages/LaunchManager/LaunchManager';
import css from './App.module.scss';

import '@autocut/designSystem/styles/index';
import FlexContainer from './designSystem/components/molecules/FlexContainer';
import ModalManager from '@autocut/modals/ModalManager';
import {Header} from './components/Header/Header';
import SafeApp from '@autocut/SafeApp';

export default function App() {
  const [isInitialized, setIsInitialized] = React.useState(false);

  return (
    <>
      <ModalManager />
      <FlexContainer
        flexDirection="column"
        className={css.rootContainer}
      >
        <Header />

        <ModeProvider>
          {!isInitialized ? (
            <LaunchManager onComplete={() => setIsInitialized(true)} />
          ) : (
            <SafeApp />
          )}
        </ModeProvider>
      </FlexContainer>
    </>
  );
}
