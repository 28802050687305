import React, {CSSProperties, useMemo} from 'react';
import css from './ParameterLayout.module.css';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import {
  ParameterGroupContext,
  ParametersGroupLayout,
} from './ParametersGroupLayout';

export type ParameterLayoutProps = {
  title?: string | JSX.Element;
  alignLeft?: boolean;
  grow?: boolean;
  height?: CSSProperties['height'];
  children?: React.ReactNode;
  wrap?: boolean;
};

const ParameterLayout = (props: ParameterLayoutProps) => {
  const {isInGroup} = React.useContext(ParameterGroupContext);

  return isInGroup ? (
    <Content {...props} />
  ) : (
    <div className={css.paramContainer}>
      <Content {...props} />
    </div>
  );
};

const Content = ({
  title,
  children,
  alignLeft,
  grow,
  height,
  wrap = false,
}: ParameterLayoutProps) => {
  const childrenNodes = useMemo(() => {
    //Filter children to remove nodes that are not ParameterLayout component
    const childrenArray = React.Children.toArray(children);
    const parameterNodes = childrenArray.filter(
      (child: any) => child?.type?.name !== ParametersGroupLayout.name,
    );

    return parameterNodes;
  }, [children]);

  const subParametersGroupLayout = useMemo(() => {
    //Filter children to remove nodes that are not ParameterLayout component
    const childrenArray = React.Children.toArray(children);
    const parameterNodes = childrenArray.filter(
      (child: any) =>
        child?.type?.name === ParametersGroupLayout.name &&
        (child.props.status !== false || child.props.hiddenContent !== null),
    );
    return parameterNodes;
  }, [children]);

  const wrapClass = wrap ? css.wrap : '';
  return (
    <>
      {title && (
        <Text
          variant="textXs.medium"
          className={`${css.title} ${wrapClass}`}
        >
          {title}
        </Text>
      )}
      {!!childrenNodes.length && (
        <div
          className={`${css.content} ${wrapClass}`}
          style={
            {
              '--content-align': alignLeft ? 'flex-start' : 'flex-end',
              '--grow-factor': grow ? '1' : '0',
              '--custom-height': height,
            } as CSSProperties
          }
        >
          {childrenNodes}
        </div>
      )}

      {subParametersGroupLayout.length ? (
        <div
          className={css.subGroup}
          style={
            {
              '--is-sub-group': '1',
            } as CSSProperties
          }
        >
          {subParametersGroupLayout}
        </div>
      ) : null}
    </>
  );
};
ParameterLayout.Content = Content;

export default ParameterLayout;
