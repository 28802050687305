import {LaunchStepHandler} from '@autocut/pages/LaunchManager/LaunchManager';
import {preload, waitForPreload} from '@autocut/types/ElectronPreload';

export const preloadWait: LaunchStepHandler = async (
  setProgress,
): Promise<boolean> => {
  await waitForPreload('preloadWait setup');
  await setProgress?.(100);
  try {
    preload().logger.init();
  } catch (error) {
    console.error(error);
  }
  return !!preload().os;
};
