import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {CanvasObject} from '@autocut/pages/modes/captions/utils/canvas/classes/canvas.class.utils';
import {useCanvasObjectModifier} from '@autocut/pages/modes/captions/utils/canvas/modifier.canvas.utils';

export const ZOOM_IN_ANIMATION_LENGTH_FRAME = 8;

const zoomKeyframes = [
  {frame: 0, percent: 93},
  {frame: 4, percent: 98},
  {frame: 8, percent: 100},
];

export const useZoomInCanvasObjectModifier = (props?: {
  loop?: {enabled: boolean; loopLengthInFrames: number};
}) => {
  const {enabled} = useAutoCutStore(state => ({
    enabled:
      state.ui.parameters.caption.transition.enabled &&
      state.ui.parameters.caption.transition.effects.includes('zoomIn'),
  }));

  const zoomDrawModifier = (
    canvasObject: Omit<CanvasObject, 'id'>,
  ): Omit<CanvasObject, 'id'> => ({
    ...canvasObject,
    draw(ctx, x, y, {frame, ...args}) {
      const animationFrame = props?.loop?.enabled
        ? frame % props.loop.loopLengthInFrames
        : frame;

      const zoomLastFrame = zoomKeyframes.reduce((last, current) =>
        current.frame <= animationFrame ? current : last,
      );
      const zoomNextFrame = zoomKeyframes.reduce((last, current) =>
        last.frame > animationFrame ? last : current,
      );

      const numFrameZoom = zoomNextFrame.frame - zoomLastFrame.frame;
      const zoomFrame = animationFrame - zoomLastFrame.frame;
      const zoomProgress = zoomFrame / numFrameZoom;
      const zoomDiff = zoomNextFrame.percent - zoomLastFrame.percent;
      const newZoom = zoomLastFrame.percent + zoomDiff * zoomProgress;

      ctx.save();
      ctx.translate(x, y);
      ctx.scale(newZoom / 100, newZoom / 100);
      canvasObject.draw(ctx, 0, 0, {
        frame,
        ...args,
      });
      ctx.restore();
    },
    customCacheCheck: () => false,
  });

  const zoomModifier = (canvasObject: Omit<CanvasObject, 'id'>) => {
    return zoomDrawModifier(canvasObject);
  };

  return useCanvasObjectModifier({
    modifier: zoomModifier,
    enabled,
  });
};
