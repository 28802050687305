import {useEffect, useRef, useState} from 'react';
import {
  drawDbLimit,
  drawDbValues,
  drawMousePosition,
  drawRegions,
  getHoveredDbValue,
  resetCanvas,
} from './utils';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {VolumeBadge} from '../VolumeCard/VolumeCard';

import css from './PreviewCanvas.module.scss';

export type PreviewCanvasProps = {
  dbValues: number[];
  valueCount: number;
  silences: number[][];
  startTime: number;
  endTime: number;
};

export const PreviewCanvas = ({
  dbValues,
  valueCount,
  silences,
  startTime,
  endTime,
}: PreviewCanvasProps) => {
  const silenceParameters = useAutoCutStore(
    state => state.ui.parameters.silence,
  );

  const [displayVolumBadge, setDisplayVolumeBadge] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [canvasCtx, setCanvasCtx] = useState<CanvasRenderingContext2D | null>(
    null,
  );

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!canvasRef.current) return;

    setCanvasCtx(canvasRef.current.getContext('2d'));
  }, [canvasRef]);

  useEffect(() => {
    if (!canvasCtx) return;

    drawPreview();
  }, [canvasCtx, silences]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [canvasRef, containerRef, canvasCtx]);

  const handleResize = () => {
    if (!canvasRef.current) return;

    canvasRef.current.width = containerRef.current?.offsetWidth || 0;

    drawPreview();
  };

  const drawPreview = () => {
    if (canvasRef.current === null || canvasCtx === null) return;

    const canvas = canvasRef.current as HTMLCanvasElement;

    resetCanvas({ctx: canvasCtx, canvas});

    drawRegions({
      ctx: canvasCtx,
      canvas,
      silences,
      margins: {
        marginAfter: silenceParameters.marginAfter / (endTime - startTime),
        marginBefore: silenceParameters.marginBefore / (endTime - startTime),
      },
    });
    drawDbValues({ctx: canvasCtx, canvas, dbValues, valueCount});
    drawDbLimit({
      ctx: canvasCtx,
      canvas,
      dbLimit: silenceParameters.noiseLevel,
    });
  };

  return (
    <div
      ref={containerRef}
      className={css.root}
    >
      {displayVolumBadge && (
        <VolumeBadge
          maxWidth={canvasRef.current?.width || 0}
          cursorPosition={cursorPosition}
          getValue={() =>
            getHoveredDbValue({
              cursorPosition,
              dbValues,
              canvas: canvasRef.current as HTMLCanvasElement,
            })
          }
        />
      )}
      <canvas
        onMouseOut={() => {
          setDisplayVolumeBadge(false);
          drawPreview();
        }}
        onMouseMove={event => {
          drawPreview();
          drawMousePosition({
            ctx: canvasCtx as CanvasRenderingContext2D,
            canvas: canvasRef.current as HTMLCanvasElement,
            offsetX: event.nativeEvent.offsetX,
          });
          setCursorPosition(event.nativeEvent.offsetX);
          setDisplayVolumeBadge(true);
        }}
        height={200}
        width={2000}
        ref={canvasRef}
      />
    </div>
  );
};
