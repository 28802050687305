import {CanvasFontParams} from '@autocut/types/Captions';
import {generateCanvasFontParam} from './generateCanvasFontParam';

//Ensure that you import the font to the document using the loadFontForCanvas function before using this one
export const measureTextOnCanvas = (
  text: string,
  params: CanvasFontParams,
  allCaps: boolean,
) => {
  const textCanvas = document.createElement('canvas');
  const textContext = textCanvas.getContext('2d')!;

  const fontParam = generateCanvasFontParam(params);

  textContext.font = fontParam;
  const metrics = textContext.measureText(allCaps ? text.toUpperCase() : text);

  return {
    canvas: textCanvas,
    metrics: {
      width: metrics.width,
      actualBoundingBoxAscent: metrics.actualBoundingBoxAscent,
      actualBoundingBoxDescent: metrics.actualBoundingBoxDescent,
      height:
        metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent,
      fontBoundingBox:
        metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent,
    },
  };
};
