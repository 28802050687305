import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {preload} from '@autocut/types/ElectronPreload';
import {useEffect, useState} from 'react';
import {host, isHost} from '@autocut/utils/host';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {AutoCutState} from '@autocut/utils/zustand';

export const UnsupportedVersionModal = () => {
  const hostName = useAutoCutStore(state => state.ui.host);
  const [hostVersion, setHostVersion] = useState<string | null>(null);

  useEffect(() => {
    void host.misc.hostVersion().then(version => {
      if (checkVersion(hostName, version)) {
        return;
      }

      const formattedVersion = `${version[0]}.${version[1]}`;
      setHostVersion(formattedVersion);
    });
  }, []);

  const checkVersion = (
    hostName: AutoCutState['ui']['host'],
    version: number[],
  ) => {
    if (hostName === 'davinci') {
      return version[0] >= 19 || (version[0] >= 18 && version[1] >= 6);
    }
    if (hostName === 'ppro') {
      return version[0] >= 22;
    }

    return false;
  };

  if (!hostVersion) return null;

  return (
    <Modal
      style={{
        zIndex: 1000,
      }}
      title={
        <p
          style={{
            color: 'white',
            fontSize: '20px',
            lineHeight: '40px',
            fontWeight: 'bold',
          }}
        >
          <TranslatedMessage
            id={'modals_unsupportedVersion_title'}
            defaultMessage="Unsupported DaVinci Resolve version"
          />
        </p>
      }
      closeOnClickOutside={false}
      withCloseButton={false}
    >
      <p
        style={{
          padding: '16px',
          fontSize: '13px',
          lineHeight: '40px',
          fontWeight: 'bold',
        }}
      >
        {!!hostVersion && (
          <TranslatedMessage
            id={'modals_unsupportedVersion_currentVersion'}
            defaultMessage="We detected that you are using DaVinci Resolve {version}"
            values={{
              version: hostVersion,
            }}
          />
        )}
        <TranslatedMessage
          id={'modals_unsupportedVersion_text'}
          defaultMessage="AutoCut is only compatible with DaVinci Resolve with 18.6 and higher."
        />
        <TranslatedMessage
          id={'modals_unsupportedVersion_update'}
          defaultMessage="You can update it there: <a>DaVinci Website</a>"
          values={{
            a: text => (
              <a
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  void preload().electron.openLinkInBrowser(
                    isHost('davinci')
                      ? 'https://www.blackmagicdesign.com/support/family/davinci-resolve-and-fusion'
                      : 'https://creativecloud.adobe.com/apps/download/premiere-pro',
                  );
                }}
              >
                {text}
              </a>
            ),
          }}
        />
      </p>
    </Modal>
  );
};
