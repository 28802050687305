import {autocutStoreVanilla, setAutocutStore} from '@autocut/utils/zustand';

export const handleAddSpeaker = () => {
  const speakers = autocutStoreVanilla().ui.parameters.podcast.speakers;

  setAutocutStore('ui.parameters.podcast.speakers', [
    ...speakers,
    {name: '', videoTracks: [], audioTrack: undefined},
  ]);
};

export const handleRemoveSpeakerByIndex = (index: number) => {
  const speakers = autocutStoreVanilla().ui.parameters.podcast.speakers;

  setAutocutStore(
    'ui.parameters.podcast.speakers',
    speakers.filter((_, i) => i !== index),
  );
};

export const handleAudioTrackUpdate = (
  index: number,
  audioTrack: number | undefined,
) => {
  const speakers = autocutStoreVanilla().ui.parameters.podcast.speakers;

  setAutocutStore(
    'ui.parameters.podcast.speakers',
    speakers.map((speaker, i) =>
      i === index ? {...speaker, audioTrack} : speaker,
    ),
  );
};

export const handleNameUpdate = (index: number, name: string) => {
  const speakers = autocutStoreVanilla().ui.parameters.podcast.speakers;

  setAutocutStore(
    'ui.parameters.podcast.speakers',
    speakers.map((speaker, i) => (i === index ? {...speaker, name} : speaker)),
  );
};
