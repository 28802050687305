import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

import {useState} from 'react';
import {MdModeEditOutline} from 'react-icons/md';
import {addModalToQueue} from '@autocut/utils/modal/modals.utils';
import {ModalType} from '@autocut/enums/modals.enum';
import {setLocalStorage} from '@autocut/utils/localStorage.utils';
import {CheckBox} from '@autocut/designSystem/components/atoms/CheckBox/CheckBox';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {colors} from '@autocut/designSystem/colors';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {InfoText} from '@autocut/components/InfoText/InfoText';
import {CaptionsTranscriptStepContainer} from './CaptionsTranscriptStepContainer';
import {CaptionsTranscriptEditor} from './CaptionsTranscriptEditorStep/CaptionsTranscriptEditorStep';

export const CheckTranscriptModal = ({
  isOpen,
  onClose,
  onValidateTranscript,
  onOpenModal,
}: {
  isOpen: boolean;
  onClose: () => void;
  onValidateTranscript: () => Promise<void>;
  onOpenModal: () => void;
}) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      title={
        <TranslatedMessage
          id={
            'modes_captions_steps_customization_parts_transcription_modal_checkTranscript_title'
          }
          defaultMessage={'Have you checked the transcript?'}
        />
      }
      closeModalFunction={onClose}
      footer={
        <FlexContainer
          flexDirection="column"
          gap={8}
          style={{marginTop: 8}}
        >
          <FlexContainer
            gap={4}
            alignItems="center"
            onClick={() => {
              setDontShowAgain(prev => !prev);
            }}
            style={{cursor: 'pointer'}}
          >
            <CheckBox
              size={16}
              checked={dontShowAgain}
              onChange={() => {
                setDontShowAgain(prev => !prev);
              }}
            />
            <TranslatedMessage
              id={
                'modes_captions_steps_customization_parts_transcription_modal_checkTranscript_dontShowAgain'
              }
              defaultMessage="Don't show this message again"
            />
          </FlexContainer>

          <Button
            onClick={async () => {
              setLocalStorage('dontShowCheckTranscriptModal', dontShowAgain);
              onClose();
              await onValidateTranscript();
            }}
            color={colors.primary600}
          >
            <TranslatedMessage
              id={'modes_captions_steps_customization_button'}
              defaultMessage="Add captions"
            />
          </Button>
        </FlexContainer>
      }
      icon={<MdModeEditOutline size={24} />}
    >
      <FlexContainer
        flexDirection="column"
        gap={8}
      >
        <InfoText style="info">
          <TranslatedMessage
            id={
              'modes_captions_steps_customization_parts_transcription_modal_checkTranscript_description'
            }
            defaultMessage={'We noticed that you did not edit the transcript.'}
          />
        </InfoText>

        <CaptionsTranscriptStepContainer
          title={
            <TranslatedMessage
              id={
                'modes_captions_steps_customization_parts_transcription_title'
              }
              defaultMessage="Transcription"
            />
          }
          onClick={() => {
            onOpenModal();
            onClose();
            addModalToQueue(ModalType.EditTranscript);
          }}
        >
          <CaptionsTranscriptEditor />
        </CaptionsTranscriptStepContainer>
      </FlexContainer>
    </Modal>
  );
};
