import {LaunchStepHandler} from '@autocut/pages/LaunchManager/LaunchManager';
import {preload} from '@autocut/types/ElectronPreload';

export const checkForUpdates: LaunchStepHandler = async (
  setProgress,
): Promise<boolean> => {
  const TIMEOUT = 30000;

  let updateInfos: ReturnType<
    ReturnType<typeof preload>['updater']['getInfos']
  > = {status: 'checking'};
  let i = 0;
  do {
    updateInfos = await preload().updater.getInfos();
    if (updateInfos.status === 'up-to-date') {
      await setProgress?.(100);
      return true;
    } else if (updateInfos.status === 'downloading') {
      await setProgress?.(50);
    } else if (updateInfos.status === 'ready') {
      await setProgress?.(100);
      return false;
    } else if (updateInfos.status === 'error') {
      //If we have an error during the update, we allow using the app. The error will be send to Sentry
      await setProgress?.(100);
      return true;
    }
    await new Promise(resolve => setTimeout(resolve, 100));
    i++;
  } while (
    ['checking', 'downloading'].includes(updateInfos.status) &&
    i < TIMEOUT
  );
  return true;
};
