import {IconComponent, IconComponentProps} from '../Icon';

export const IconMouse = (props: IconComponentProps) => (
  <IconComponent
    {...props}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 9V6m0 16a7 7 0 0 1-7-7V9a7 7 0 0 1 14 0v6a7 7 0 0 1-7 7Z"
    />
  </IconComponent>
);
