import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {manageError} from '@autocut/utils/manageError';
import {IntlShape} from 'react-intl';
import {autocutStoreVanilla} from '../zustand';
import {StatType} from '@autocut/enums/statType.enum';
import {sendStats} from '../stats.utils';
import {getModeStatTrait} from '../modes.utils';

export const handleProcessError = async (err: unknown, intl: IntlShape) => {
  const autocutState = autocutStoreVanilla();
  const mode = autocutState.ui.process.mode;
  const statCategory = getModeStatTrait(mode);

  const formattedError = new IncrementalError(
    err instanceof IncrementalError
      ? err
      : `An error has occured during process (${mode.id}): ` + err,
    `handleProcessError(${mode.id})`,
  );

  manageError({
    error: formattedError,
  });

  await sendStats({
    type: StatType.ERROR,
    value: 1,
    traits: {
      type: 'PROCESS_ERROR',
      mode: statCategory,
      fatal: 1,
    },
  });
};
