import {AutocutMode, AutocutModes} from '@autocut/enums/modes.enum';
import {fetchUsage} from '@autocut/utils/transcript/fetchUsage.utils';
import React, {useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {HomepageCard} from './HomepageCard';
import {useIntl} from 'react-intl';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {isKeyTypeEqualsOrGreaterThan} from '@autocut/utils/keys/keyType.utils';

export const HomepageCardBase = ({mode}: {mode: AutocutMode}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const license = useAutoCutStore(state => state.user.license);
  const [usage, setUsage] = React.useState<{percentage: number}>();

  const isTrial = !isKeyTypeEqualsOrGreaterThan(
    license?.type === 'trial' ? 'ai' : license?.key_type || 'basic',
    mode.minKeyType,
  );

  const disabled = useMemo(
    () =>
      !license?.features?.includes(mode.id) ||
      (license?.type !== 'trial' &&
        (usage?.percentage || 0) >= 0.99 &&
        isTrial) ||
      !mode.available,
    [license?.features, mode.id, usage],
  );

  const additionalText = useMemo(
    () =>
      mode.available
        ? disabled || isTrial
          ? intl.formatMessage(
              {id: 'upgrade_to' as any, defaultMessage: 'Upgrade to {plan}'},
              {
                plan: mode.minKeyType.toLocaleUpperCase(),
              },
            )
          : mode.status || undefined
        : intl.formatMessage({
            id: 'homepage_comming_soon' as any,
            defaultMessage: 'Coming soon',
          }),
    [disabled, intl],
  );

  const needUpgrade = mode.available && disabled;

  useEffect(() => {
    const refreshUsage = async () => setUsage(await fetchUsage());

    if (!usage && mode.minKeyType === 'ai' && mode.available)
      void refreshUsage();
  }, [usage]);

  return (
    <HomepageCard
      disabled={disabled}
      upgrade={needUpgrade}
      // showTrialbanner={!disabled && isTrial}
      showTrialbanner={false}
      additionalText={additionalText}
      text={mode.name}
      description={mode.description}
      thumbnail={mode.thumbnail}
      onClick={() => {
        console.log('Navigating to ', mode.path);
        navigate(mode.path);
      }}
    />
  );
};

export const HomepageCardLegacy = () => (
  <HomepageCardBase mode={AutocutModes.Legacy} />
);
export const HomepageCardPodcast = () => (
  <HomepageCardBase mode={AutocutModes.Podcast} />
);
export const HomepageCardAutoZoom = () => (
  <HomepageCardBase mode={AutocutModes.Zoom} />
);
export const HomepageCardRepeat = () => (
  <HomepageCardBase mode={AutocutModes.Repeat} />
);
export const HomepageCardCaptions = () => (
  <HomepageCardBase mode={AutocutModes.Captions} />
);
export const HomepageCardChapter = () => (
  <HomepageCardBase mode={AutocutModes.Chapters} />
);
export const HomepageCardResize = () => (
  <HomepageCardBase mode={AutocutModes.Resize} />
);
export const HomepageCardBRoll = () => (
  <HomepageCardBase mode={AutocutModes.BRoll} />
);
export const HomepageCardSwearWords = () => (
  <HomepageCardBase mode={AutocutModes.SwearWords} />
);
export const HomepageCardViralClips = () => (
  <HomepageCardBase mode={AutocutModes.ViralClips} />
);
