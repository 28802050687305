import {useCanvasObject} from '../captionDrawHooks.utils';
import {CaptionCanvasObjectHook} from '../captionHooks.type';
import {useDrawWordBoxes} from './useDrawWordBoxes';

export const useWordBoxesCanvasObject: CaptionCanvasObjectHook = args => {
  const drawReturn = useDrawWordBoxes(args);

  return useCanvasObject({
    name: 'highlightBoxes',
    paths: {
      x: 'ui.parameters.caption.position.xPercentage',
      y: 'ui.parameters.caption.position.yPercentage',
      enabled: 'ui.parameters.caption.animations.wordBox.enabled',
    },
    ...drawReturn,
    ...args,
  });
};
