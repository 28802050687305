import css from './UsageStatsModal.module.css';

import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Select} from '@autocut/designSystem/components/atoms/Select/Select';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {AutocutModeIds, AutocutModes} from '@autocut/enums/modes.enum';
import {autocutApi} from '@autocut/utils/http.utils';
import {useEffect, useMemo, useState} from 'react';
import {FaArrowTrendDown, FaArrowTrendUp} from 'react-icons/fa6';
import {useIntl} from 'react-intl';

const UsageStatsModal = () => {
  const intl = useIntl();

  const modes = useMemo(
    () =>
      Object.values(AutocutModes).map(mode => ({
        value: mode.id,
        label: mode.name,
      })),
    [],
  );

  const [selectedMode, setSelectedMode] = useState<{
    value: string;
    label: string;
  }>(modes[0]);

  const [data, setData] = useState<{
    [key in AutocutModeIds]?: {
      allTimeUsage: number;
      lastMonthUsage: number;
      usage: number;
    };
  }>({});

  const {thisMonth, lastMonth, allTime, mostUsed} = useMemo(() => {
    const modeData = data[selectedMode.value as AutocutModeIds];
    if (!modeData) {
      return {
        thisMonth: 0,
        lastMonth: 0,
        allTime: 0,
        mostUsed: false,
      };
    }

    return {
      thisMonth: modeData?.usage,
      lastMonth: modeData?.lastMonthUsage,
      allTime: modeData?.allTimeUsage,
      mostUsed:
        modeData?.allTimeUsage ===
        Math.max(...Object.values(data).map(mode => mode.allTimeUsage)),
    };
  }, [data, selectedMode.value]);

  useEffect(() => {
    void autocutApi.get('/statistics/user-stats').then(res => {
      setData(res.data);
    });
  }, []);

  return (
    <Modal
      title={intl.formatMessage({
        id: 'modals_usageStats_title',
        defaultMessage: 'Usage statistics',
      })}
      fullWidth
      shouldScroll={false}
    >
      <FlexContainer
        style={{width: '100%'}}
        gap={16}
        flexDirection="column"
      >
        <Select
          options={modes}
          onChange={id => {
            const mode = modes.find(mode => mode.value === id);
            if (mode) {
              setSelectedMode(mode);
            }
          }}
          selected={selectedMode.value}
          placeholder={intl.formatMessage({
            id: 'modals_usageStats_select_label',
            defaultMessage: 'Select a mode',
          })}
        />
        <FlexContainer
          gap={8}
          justifyContent="space-evenly"
          alignItems="center"
          style={{
            overflow: 'auto',
            marginTop: 12,
            marginBottom: 18,
          }}
        >
          <div className={css.valueContainer}>
            <p className={css.value}>{thisMonth}</p>
            <div className={css.valueInfosContainer}>
              <div
                className={css.valueProgress}
                style={{color: thisMonth < lastMonth ? 'red' : 'greenyellow'}}
              >
                <p
                  style={{color: thisMonth < lastMonth ? 'red' : 'greenyellow'}}
                >
                  {thisMonth < lastMonth ? '' : '+'}
                  {(((thisMonth - lastMonth) / (lastMonth || 1)) * 100).toFixed(
                    0,
                  )}
                  %
                </p>
                {thisMonth < lastMonth ? (
                  <FaArrowTrendDown />
                ) : (
                  <FaArrowTrendUp />
                )}
              </div>
              <p className={css.valueLabel}>
                <TranslatedMessage
                  id="modals_usageStats_stats_thisMonth"
                  defaultMessage="This month"
                />
              </p>
            </div>
          </div>
          <div className={css.separator} />
          <div className={css.valueContainer}>
            <p className={css.value}>{allTime}</p>
            <div className={css.valueInfosContainer}>
              <div className={css.valueProgress}>
                <p style={{color: 'gold'}}>
                  {mostUsed ? (
                    <>
                      🥇{' '}
                      <TranslatedMessage
                        id="modals_usageStats_stats_mostUsed"
                        defaultMessage="Most used"
                      />
                    </>
                  ) : (
                    <br />
                  )}
                </p>
              </div>
              <p className={css.valueLabel}>
                <TranslatedMessage
                  id="modals_usageStats_stats_allTime"
                  defaultMessage="All time"
                />
              </p>
            </div>
          </div>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};

export default UsageStatsModal;
