import {ModalType} from '@autocut/enums/modals.enum';
import {AutocutModeIds} from '@autocut/enums/modes.enum';
import {StatType} from '@autocut/enums/statType.enum';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {isEqual} from 'lodash';
import {IntlShape} from 'react-intl';
import {autocutStoreVanilla, setAutocutStore} from '../zustand';
import {StatMode} from '@autocut/enums/statCategory.enum';
import {calculateXpGained} from '../game/game.utils';
import {addXp} from '../game/addXp';
import {sendStats} from '../stats.utils';
import {
  getParametersByMode,
  getModeStatTrait,
  getDefaultParametersByMode,
} from '../modes.utils';

export const END_MODAL: Record<AutocutModeIds, ModalType> = {
  ai: ModalType.EndProcess,
  broll: ModalType.EndProcess,
  caption: ModalType.EndCaptionsProcess,
  chapters: ModalType.EndProcess,
  podcast: ModalType.EndProcess,
  repeat: ModalType.EndProcess,
  resize: ModalType.EndProcess,
  silence: ModalType.EndProcess,
  zoom: ModalType.EndProcess,
  swear_word: ModalType.EndProcess,
  viral_clips: ModalType.EndProcess,
};

export const handleProcessEnd = async (intl: IntlShape) => {
  try {
    const autocutStates = autocutStoreVanilla();
    const startProcessDate =
      autocutStates.onGoingProcess.startDate || new Date();
    const mode = autocutStates.ui.process.mode;
    const parameters = getParametersByMode(mode);
    const statCategory = getModeStatTrait(mode);
    // const selectionInfos = autocutStates.sequence.infos;

    setAutocutStore('ui.openedModalName', END_MODAL[mode.id as AutocutModeIds]);

    const end = new Date();

    const timeTakenMS = end.getTime() - startProcessDate.getTime();
    const minutesTaken = Math.floor(timeTakenMS / 60000);
    const secondsTaken = Math.floor((timeTakenMS % 60000) / 1000);

    setAutocutStore('ui.process.durationProcessMinutes', minutesTaken);
    setAutocutStore('ui.process.durationProcessSeconds', secondsTaken);

    // TODO : xp (relatif à sequence et sequenceInfos)
    // const xpGainedTemp = calculateXpGained(
    //   timeTakenMS,
    //   mode.id,
    //   (selectionInfos as Sequence).nbClipsAudio
    // );

    // if (xpGainedTemp !== 0) {
    //   await addXp(xpGainedTemp);
    //   setAutocutStore('game.level.xpGained', xpGainedTemp);
    // }

    await sendStats({
      type: StatType.PROCESS_ENDED,
      value: mode.getStatValue(autocutStates) || 1,
      traits: {
        mode: statCategory,
        ...(!isEqual(parameters, getDefaultParametersByMode(mode))
          ? {
              modifiedParameters: 1,
            }
          : {}),
        ...(!!mode.getStatsTraits ? mode.getStatsTraits() : {}),
      },
    });
  } catch (err: any) {
    throw new IncrementalError(err, 'handleProcessEnd');
  }
};
