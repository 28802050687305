import {useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';
import {ModeWrapper} from '../ModeWrapper';
import {IconMicrophone01} from '@autocut/designSystem/components/atoms/Icon/media/IconMicrophone01';
import StepperRouter, {
  getPreviousStepPath,
} from '@autocut/components/Stepper/StepperRouter/StepperRouter';
import {SelectionScreen} from '@autocut/components/SelectionScreen/SelectionScreen';
import podcastThumbnail from '@autocut/assets/images/modes/podcast.png?url';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {colors} from '@autocut/designSystem/colors';
import {PodcastCustomization} from './customization/PodcastCustomization';
import { TranslatedMessage } from '@autocut/components/TranslatedMessage/TranslatedMessage';

export const PodcastMode = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <ModeWrapper
      Icon={IconMicrophone01}
      text={intl.formatMessage({
        id: 'modes_podcast_title',
        defaultMessage: 'Auto Podcast',
      })}
      customHomeButtonHandler={() => {
        const path = getPreviousStepPath(location);

        navigate(path);
      }}
    >
      <StepperRouter
        basePath="/cut/podcast"
        steps={[
          {
            label: intl.formatMessage({
              id: 'modes_podcast_steps_select_label',
              defaultMessage: 'Select timeline',
            }),
            path: 'timeline',
            element: (
              <SelectionScreen
                title={{
                  id: 'modes_podcast_steps_select_title',
                  defaultMessage:
                    'Select a timeline with at least 2 video and audio tracks',
                }}
                texts={[
                  {
                    id: 'modes_podcast_steps_select_descriptions_1',
                    defaultMessage: 'Go to the edit tab',
                  },
                  {
                    id: 'modes_podcast_steps_select_descriptions_2',
                    defaultMessage: 'Navigate to the timeline you want to edit',
                  },
                ]}
                image={{src: podcastThumbnail, alt: 'Podcast mode'}}
                cta={
                  <Button
                    onClick={() => navigate('./customization')}
                    color={colors.primary600}
                  >
                    <TranslatedMessage id="modes_podcast_steps_select_cta" />
                  </Button>
                }
              />
            ),
          },
          {
            label: intl.formatMessage({
              id: 'modes_podcast_steps_customization_label',
              defaultMessage: 'Customize',
            }),
            path: 'customization',
            element: <PodcastCustomization />,
          },
        ]}
      />
    </ModeWrapper>
  );
};
