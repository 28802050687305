import {TranslationKeys} from '@autocut/contexts/LocaleContext';
import {z as zod} from 'zod';

export const silenceValidationSchema = zod.object({
  noiseLevel: zod
    .number({invalid_type_error: 'validation_error_silence_noiseLevel_type'})
    .nonpositive({
      message:
        'modes_silence_error_validation_noiseLevel_negative' as TranslationKeys,
    })
    .int({
      message:
        'modes_silence_error_validation_noiseLevel_int' as TranslationKeys,
    })
    .default(-35),
  minTimeOfSilence: zod
    .number({
      required_error:
        'modes_silence_error_validation_minTimeOfSilence_required' as TranslationKeys,
    })
    .min(
      0,
      'modes_silence_error_validation_minTimeOfSilence_min' as TranslationKeys,
    )
    .default(0),
  minTimeOfTalk: zod
    .number({
      required_error:
        'modes_silence_error_validation_minTimeOfTalk_required' as TranslationKeys,
    })
    .min(
      0,
      'modes_silence_error_validation_minTimeOfTalk_min' as TranslationKeys,
    )
    .default(0),
  marginBefore: zod
    .number({
      invalid_type_error: 'validation_error_silence_marginBefore_type',
    })
    .min(
      0,
      'modes_silence_error_validation_marginBefore_min' as TranslationKeys,
    )
    .default(0),
  marginAfter: zod
    .number({invalid_type_error: 'validation_error_silence_marginAfter_type'})
    .min(
      0,
      'modes_silence_error_validation_marginAfter_min' as TranslationKeys,
    ),
  transistions: zod
    .enum(['none', 'j_cut', 'l_cut', 'both', 'constant_power'], {
      invalid_type_error: 'validation_error_silence_transitions',
    })
    .default('none'),
  silences: zod
    .enum(['delete', 'keep', 'mute', 'delete_keep_spaces'], {
      invalid_type_error: 'validation_error_silence_silences',
    })
    .default('delete'),
});
