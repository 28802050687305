import Select, {SingleValue} from 'react-select';

import selectCustomStyle from './SelectStyle';
import {SelectVariant} from './SelectVariants';

export interface SelectItem {
  value: string;
  label: string;
}

interface CustomSelectTextProps<CustomSelectItem extends SelectItem> {
  options: CustomSelectItem[];
  initialValue?: CustomSelectItem;
  handleSelectChange: (newValue: SingleValue<CustomSelectItem>) => void;
  isClearable?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  customization?: SelectVariant;
  portalTarget?: HTMLElement | null;
}

export const CustomSelectText = <CustomSelectItem extends SelectItem>({
  options,
  initialValue,
  handleSelectChange,
  isClearable,
  isSearchable,
  isDisabled,
  isLoading,
  customization,
  portalTarget,
}: CustomSelectTextProps<CustomSelectItem>) => {
  return (
    <Select
      isMulti={false}
      isClearable={isClearable}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      isLoading={isLoading}
      options={options}
      value={initialValue}
      onChange={(newValue, actionMeta) => {
        if (actionMeta.action === 'clear') {
          handleSelectChange({value: '', label: ''} as any);
          return;
        }
        handleSelectChange(newValue);
      }}
      styles={selectCustomStyle(customization)}
      menuPortalTarget={portalTarget}
    />
  );
};
