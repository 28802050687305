import React, {useMemo} from 'react';
import css from './VersionDisplay.module.scss';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {AutoCutState} from '@autocut/utils/zustand';

type VersionDisplayProps = React.HTMLAttributes<HTMLParagraphElement>;

const compressVersion = (version: string) =>
  (version || '').replace('v', '').split('.').join('');
export const formatVersion = (ui: AutoCutState['ui']) =>
  `${compressVersion(ui.versions.aea)}.${compressVersion(ui.versions.front)}.${compressVersion(ui.versions.compute)}.${ui.host[0]}-${compressVersion(ui.versions.hostClient)}.${compressVersion(ui.versions.hostServer)}`;

export const VersionDisplay: React.FC<VersionDisplayProps> = props => {
  const {ui} = useAutoCutStore(state => ({
    ui: state.ui,
  }));

  const version = useMemo(() => formatVersion(ui), [ui]);

  return (
    <p
      className={css.applicationVersion}
      {...props}
    >
      <i>v{version}</i>
    </p>
  );
};
