import React from 'react';
import {OldTranslatedMessage} from '../TranslatedMessage/TranslatedMessage';
import {NeedingTranscriptionModes} from '@autocut/types/Transcription';
import {Button} from '../Button/Button';

export const RetryTranscriptionButton = ({
  modeId,
  usedModel = 0,
}: {
  modeId: NeedingTranscriptionModes;
  usedModel?: number;
}) => {
  return null;
  // const {handleProcessTranscript, error, loading} =
  //   // useHandleProcessTranscript(modeId);

  // if (error) return null;

  // return (
  //   <Button
  //     onClickFunction={async () => handleProcessTranscript(usedModel)}
  //     disabled={loading}
  //     buttonType="primary"
  //   >
  //     <OldTranslatedMessage
  //       id="deepgram_error_retry"
  //       defaultMessage="Retry with an other AI"
  //     />
  //   </Button>
  // );
};
