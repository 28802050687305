import {useEffect, useState} from 'react';
import {LoaderInfinity} from '../LoaderInfinity/LoaderInfinity';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {
  calculatePreviewInterval,
  calculateSilencesInPreview,
  getDbValues,
  getPreviewIntervalOnCurrentTimecode,
  getSilenceIntervals,
  PRECISION,
  renderTimeline,
} from './utils';
import {PreviewCanvas} from './PreviewCanvas/PreviewCanvas';
import TimeRuler from './TimeRuler/TimeRuler';
import {PreviewLegend} from './PreviewLegend/PreviewLegend';
import {IconChevronRight} from '@autocut/designSystem/components/atoms/Icon/arrows/IconChevronRight';
import {IconChevronLeft} from '@autocut/designSystem/components/atoms/Icon/arrows/IconChevronLeft';

import PreviewPlaceholderUrl from '@autocut/assets/images/preview_placeholder.png';
const PreviewPlaceholder = new URL(PreviewPlaceholderUrl, import.meta.url).href;

import css from './PreviewAudio.module.scss';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {TranslatedMessage} from '../TranslatedMessage/TranslatedMessage';

export type PreviewAudioProps = {};

export const PreviewAudio = ({}: PreviewAudioProps) => {
  const parameters = useAutoCutStore(state => state.ui.parameters.silence);

  const [audioPath, setAudioPath] = useState<string | undefined>(undefined);
  const [duration, setDuration] = useState<number>(0);
  const [dbValues, setDbValues] = useState<number[]>([]);
  const [startTime, setStartTime] = useState<number>(0);
  const [endTime, setEndTime] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inPoint, setInPoint] = useState<number>(0);
  const [silenceIntervals, setSilenceIntervals] = useState<number[][]>([]);
  const [silenceIntervalsInPreview, setSilenceIntervalsInPreview] = useState<
    number[][]
  >([]);

  useEffect(() => {
    if (silenceIntervals.length === 0) {
      return;
    }
    setSilenceIntervalsInPreview(
      calculateSilencesInPreview(silenceIntervals, startTime, endTime),
    );
  }, [silenceIntervals, startTime, endTime]);

  const canGoPrevious = startTime !== 0;
  const canGoNext = endTime < duration;

  useEffect(() => {
    if (!parameters || !audioPath) return;

    void updateSilenceIntervals(audioPath);
  }, [parameters, audioPath]);

  const updateSilenceIntervals = async (path: string) => {
    setSilenceIntervals(await getSilenceIntervals(path));
  };

  const handleInitPreview = async () => {
    setIsLoading(true);

    const {resultAudioPath, duration, inPoint} = await renderTimeline();

    setInPoint(inPoint);

    setAudioPath(resultAudioPath);
    setDuration(duration);

    setDbValues(await getDbValues(resultAudioPath, startTime, endTime));

    setIsLoading(false);
  };

  const handleMovePreview = async (
    direction: 'previous' | 'next' | 'onCurrentTimecode',
  ) => {
    setIsLoading(true);

    const [newStartTime, newEndTime] =
      direction === 'onCurrentTimecode'
        ? await getPreviewIntervalOnCurrentTimecode({duration, inPoint})
        : calculatePreviewInterval({startTime, endTime}, duration, direction);

    if (newStartTime !== startTime || newEndTime !== endTime) {
      setDbValues(await getDbValues(audioPath, newStartTime, newEndTime));

      setStartTime(newStartTime);
      setEndTime(newEndTime);
    }

    setIsLoading(false);
  };

  return (
    <FlexContainer
      className={css.root}
      flexDirection="column"
      gap={Spacing.s2}
    >
      <FlexContainer
        flexDirection="column"
        gap={Spacing.s2}
      >
        <TimeRuler
          from={inPoint + startTime}
          to={inPoint + endTime}
        />
        {isLoading ? (
          <FlexContainer
            alignItems="center"
            justifyContent="center"
          >
            <LoaderInfinity height={200} />
          </FlexContainer>
        ) : (
          <div className={css.canvasContainer}>
            {!audioPath ? (
              <FlexContainer
                className={css.placeholderContainer}
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  variant="secondary"
                  size="lg"
                  onClick={handleInitPreview}
                >
                  <TranslatedMessage
                    id="modes_silence_steps_preview_cta"
                    defaultMessage="Generate the preview"
                  />
                </Button>
                <img
                  className={css.image}
                  src={PreviewPlaceholder}
                  alt="preview"
                />
              </FlexContainer>
            ) : (
              <PreviewCanvas
                dbValues={dbValues}
                valueCount={10 / PRECISION}
                startTime={startTime}
                endTime={endTime}
                silences={silenceIntervalsInPreview}
              />
            )}
          </div>
        )}
      </FlexContainer>
      <PreviewLegend />
      <FlexContainer
        alignItems="center"
        gap={Spacing.s2}
      >
        {!audioPath ? (
          <div className={css.buttonPlaceholder} />
        ) : (
          <>
            {canGoPrevious && (
              <Button
                className={css.buttonPrevious}
                disabled={startTime === 0}
                variant="secondary.dashed"
                onClick={() => handleMovePreview('previous')}
              >
                <FlexContainer
                  alignItems="center"
                  justifyContent="center"
                >
                  <IconChevronLeft size={20} />
                </FlexContainer>
              </Button>
            )}
            <Button
              variant="secondary.dashed"
              onClick={() => handleMovePreview('onCurrentTimecode')}
              style={{
                ...(canGoPrevious
                  ? {borderTopLeftRadius: 0, borderBottomLeftRadius: 0}
                  : {}),
                ...(canGoNext
                  ? {borderTopRightRadius: 0, borderBottomRightRadius: 0}
                  : {}),
              }}
            >
              <TranslatedMessage
                id="modes_silence_steps_preview_center"
                defaultMessage="Center on indicator"
              />
            </Button>
            {canGoNext && (
              <Button
                className={css.buttonNext}
                variant="secondary.dashed"
                onClick={() => handleMovePreview('next')}
              >
                <FlexContainer
                  alignItems="center"
                  justifyContent="center"
                >
                  <IconChevronRight size={20} />
                </FlexContainer>
              </Button>
            )}
          </>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};
