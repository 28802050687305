class ParameterReset {
  private observers: any[] = [];

  public attach(observer: () => void): void {
    this.observers.push(observer);
  }

  public detach(observer: () => void): void {
    this.observers = this.observers.filter(
      (subscriber: () => void) => subscriber !== observer,
    );
  }

  public reset() {
    this.observers.forEach((observer: () => void) => observer());
  }
}

export const parameterReset = new ParameterReset();
