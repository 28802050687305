import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {handleProcessBase} from '@autocut/utils/process/handleProcessBase';
import {compute} from '@autocut/utils/compute.utils';
import {
  endProcessStep,
  updateProcessStep,
} from '@autocut/utils/process/progress';
import {Button} from '@autocut/components/Button/Button';
import {InfoText} from '@autocut/components/InfoText/InfoText';
import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {TranscriptLanguageStep} from '@autocut/components/TranscriptLanguageStep/TranscriptLanguageStep';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import BleepSoundChoiceStep from './Parts/BleepSoundChoiceStep/BleepSoundChoiceStep';
import {BleepFileEnum} from '@autocut/enums/bleepFile.enum';
import {resourcesConfigs} from '@autocut/enums/resources.enum';
import {preload} from '@autocut/types/ElectronPreload';
import {getResourceFolderPath} from '@autocut/utils/electron/app.electron.utils';
import {LANGUAGE_TO_MODEL} from '@autocut/constants/languageToModel';

export const SwearWordsCustomization = () => {
  const {parameters} = useAutoCutStore(state => ({
    parameters: state.ui.parameters.swear_word,
  }));

  const handleClick = handleProcessBase(
    {
      executeProcess: async (_, progress) => {
        let bleepFilePath;
        let bleepFileName;
        if (parameters.bleepFile !== BleepFileEnum.Muted) {
          const bleepRessource =
            resourcesConfigs[
              parameters.bleepFile as 'bleep_sound_1_wav' | 'bleep_sound_2_wav'
            ];
          bleepFileName = bleepRessource.fileName;
          const {isExist, outputFilePath} = await bleepRessource.existCheck();
          if (isExist) {
            bleepFilePath = outputFilePath;
          } else {
            bleepFilePath = await bleepRessource.downloadMethod();
          }
        }

        const correspondingLanguage =
          LANGUAGE_TO_MODEL[parameters.languageOfTranscription.value];
        const userOptions = correspondingLanguage[0]; // TODO : Implémenter le switch de provider de stt en cas d'erreur du premier

        const swearWordsProcess = compute.process.swearWords({
          userOptions: {
            ...userOptions,
            value: parameters.languageOfTranscription.value,
          },
          bleepFilePath,
          bleepFileName,
        });

        updateProcessStep(progress, 'swearWords_mainProcess', {
          progress: {
            computeTaskId: swearWordsProcess.requestId,
          },
          countFor: 5,
        });

        const result = await swearWordsProcess;

        endProcessStep(progress, 'swearWords_mainProcess');

        return result;
      },
    },
    {
      processTitleNameKey: 'modes_swearWords_title',
      processSteps: [
        {
          id: 'swearWords_mainProcess',
          translationKey: 'progress_steps_swearWords_mainProcess',
          progress: '',
        },
      ],
    },
  );

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({buttonRef, isLoading, isDisabled}) => (
            <Button
              ref={buttonRef}
              isLoading={isLoading}
              disabled={isDisabled}
              onClickFunction={handleClick}
            >
              <TranslatedMessage
                id="modes_swearWords_footerButton"
                defaultMessage="Remove profanities"
              />
            </Button>
          )}
        />
      }
    >
      <div
        style={{
          marginBottom: 16,
        }}
      >
        <InfoText
          style="info"
          onClose={() => {
            return;
          }}
        >
          <TranslatedMessage
            id="modes_swearWords_infoText"
            defaultMessage="Swear Words Bleeper, thanks to AI, analyzes your audio and automatically adds {br} bleeping sounds on top of profanities."
          />
        </InfoText>
      </div>

      <TranscriptLanguageStep modeId="swear_word" />
      <BleepSoundChoiceStep />
    </ModeLayout>
  );
};
