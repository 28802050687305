import {ZoomPresetStep} from './Parts/ZoomPresetStep/ZoomPresetStep';

import {Divider} from '@autocut/designSystem/components/atoms/Divider/Divider';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {InfoText} from '@autocut/components/InfoText/InfoText';
import {PreviewZoomStep} from './Parts/PreviewZoomStep/PreviewZoomStep';
import {ParametersGroupLayout} from '@autocut/components/ParametersGroupLayout/ParametersGroupLayout';
import {ZoomCoverageStep} from './Parts/ZoomCoverageStep/ZoomCoverageStep';
import {ZoomTypesStep} from './Parts/ZoomTypesStep/ZoomTypesStep';
import {SmoothZoomStep} from './Parts/SmoothZoomStep/SmoothZoomStep';
import {DynamicZoomStep} from './Parts/DynamicZoomStep/DynamicZoomStep';
import {ZoomConstraintStep} from './Parts/ZoomConstraintStep/ZoomConstraintStep';
import {handleTrialTask} from '@autocut/utils/game/trialGamfication.util';
import {handleProcessBase} from '@autocut/utils/process/handleProcessBase';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {Button} from '@autocut/components/Button/Button';
import {compute} from '@autocut/utils/compute.utils';
import {useIntl} from 'react-intl';
import {
  endProcessStep,
  updateProcessStep,
} from '@autocut/utils/process/progress';

export const ZoomCustomizationStep = () => {
  const intl = useIntl();
  const {parameters, isPreviewProcessing} = useAutoCutStore(state => ({
    parameters: state.ui.parameters.zoom,
    isPreviewProcessing: state.onGoingProcess.preview.isProcessing,
  }));

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({buttonRef, isLoading, isDisabled}) => (
            <Button
              ref={buttonRef}
              disabled={isDisabled || isLoading}
              isLoading={isPreviewProcessing || isLoading}
              onClickFunction={async () => {
                await handleProcessBase(
                  {
                    executeProcess: async (_, progress) => {
                      const podcastProcess = compute.process.zoom(parameters);

                      updateProcessStep(progress, 'zoom_mainProcess', {
                        progress: {
                          computeTaskId: podcastProcess.requestId,
                        },
                        countFor: 14, // Number of steps in compute
                      });

                      const result = await podcastProcess;

                      endProcessStep(progress, 'zoom_mainProcess');

                      return result;
                    },
                  },
                  {
                    processTitleNameKey: 'modes_silence_title',
                    processSteps: [
                      {
                        id: 'zoom_mainProcess',
                        translationKey: 'progress_steps_zoom_mainProcess',
                        progress: '',
                      },
                    ],
                  },
                )(intl);
                await handleTrialTask('zoom');
              }}
            >
              <TranslatedMessage
                id="modes_zoom_footerButton"
                defaultMessage="Zoom"
              />
            </Button>
          )}
        />
      }
    >
      <InfoText
        style="info"
        descriptionDefault="AutoZoom will automatically add zoom to each clip selected based on decibels."
        descriptionId="modes_zoom_infoText"
      />

      <PreviewZoomStep />

      <Divider />

      <ZoomPresetStep />

      <FlexContainer
        style={{paddingTop: Spacing.s4, paddingBottom: Spacing.s4}}
      >
        <ParametersGroupLayout
          withPaddedSection={false}
          contentContainerStyle={{
            borderLeft: 'initial',
            paddingLeft: 'initial',
          }}
        >
          <ZoomTypesStep />
          <ZoomCoverageStep />
        </ParametersGroupLayout>
      </FlexContainer>

      <SmoothZoomStep />
      <DynamicZoomStep />
      <ZoomConstraintStep />
    </ModeLayout>
  );
};
