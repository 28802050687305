import css from './DiscordCta.module.scss';

import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {DiscordIcon} from './DiscordIcon/DiscordIcon';
import React from 'react';
import {preload} from '@autocut/types/ElectronPreload';

export const DiscordCta = () => {
  return (
    <div
      className={css.container}
      onClick={() => {
        void preload().electron.openLinkInBrowser('https://discord.gg/autocut');
      }}
    >
      <div className={css.text}>
        <TranslatedMessage
          id="modals_discord_title"
          defaultMessage="Discord"
        />
      </div>
      <DiscordIcon
        color="white"
        size={16}
        style={{transform: 'rotate(90deg)'}}
      />
    </div>
  );
};
