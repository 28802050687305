import type {CaptionsParameters} from '@autocut/types/CaptionsParameters';

export const removeUnwantedKeysFromCaptionsParameters = (
  presetParams: CaptionsParameters,
): CaptionsParameters => {
  const presetParamsCopy = JSON.parse(JSON.stringify(presetParams));

  delete presetParamsCopy.language;
  delete presetParamsCopy.position;
  delete presetParamsCopy.text.fontSize;
  delete presetParamsCopy.needBackup;
  delete presetParamsCopy.formating?.nbLines;
  delete presetParamsCopy.formating?.maxWidth;
  delete presetParamsCopy.emojis?.enabled;
  delete presetParamsCopy.userPresets;

  return presetParamsCopy as CaptionsParameters;
};
