import {clamp} from '@autocut/utils/math.utils';
import {parameterReset} from '@autocut/utils/observers/parameterReset';
import {MouseEvent, RefObject, useEffect, useRef, useState} from 'react';

export const useRelativeMousePosition = (
  ref: RefObject<HTMLElement>, //The "graphic" element reference
  parameters?: {
    clickRef?: RefObject<HTMLElement>; //The "logic" element reference (the one that will be clicked), if empty, the "graphic" element will be used
    callback?: (pos: {x: number; y: number; boundingBox: DOMRect}) => void;
    defaultValue?: {x: number; y: number};
  },
) => {
  const clickRef = useRef<boolean>(false);
  const [x, setX] = useState(parameters?.defaultValue?.x || 0);
  const [xClicked, setXClicked] = useState(parameters?.defaultValue?.x || 0);
  const [y, setY] = useState(parameters?.defaultValue?.y || 0);
  const [yClicked, setYClicked] = useState(parameters?.defaultValue?.y || 0);
  const [boundingBox, setBoundingBox] = useState<DOMRect>(
    ref.current?.getBoundingClientRect() || new DOMRect(),
  );
  const [mouseIn, setMouseIn] = useState<boolean>();

  const reset = () => {
    if (!ref.current) return;

    const bbox = ref.current.getBoundingClientRect();

    setX(bbox.width / 2);
    setY(bbox.height / 2);
    setXClicked(bbox.width / 2);
    setYClicked(bbox.height / 2);
  };

  useEffect(() => {
    parameterReset.attach(reset);

    return () => {
      parameterReset.detach(reset);
    };
  }, []);

  useEffect(() => {
    if (!ref.current) return;

    const usedRef = parameters?.clickRef?.current ? parameters?.clickRef : ref;

    if (!usedRef.current) return;

    const refBoundingBox = ref.current.getBoundingClientRect();
    setBoundingBox(refBoundingBox);

    usedRef.current.onmousedown = function (this, e) {
      refreshMousePosition(e as unknown as MouseEvent<Element, MouseEvent>);
      clickRef.current = true;
    };
    usedRef.current.onmouseup = function (this, e) {
      refreshMousePosition(e as unknown as MouseEvent<Element, MouseEvent>);
      clickRef.current = false;
    };
    usedRef.current.onmousemove = function (this, e) {
      refreshMousePosition(e as unknown as MouseEvent<Element, MouseEvent>);
    };
    usedRef.current.onmouseleave = function (this, e) {
      refreshMousePosition(e as unknown as MouseEvent<Element, MouseEvent>);
    };
  }, [ref.current]);

  const refreshMousePosition = (event: MouseEvent<Element, MouseEvent>) => {
    if (!ref.current) return;

    const refBoundingBox = ref.current.getBoundingClientRect();
    setBoundingBox(refBoundingBox);

    const newX = clamp(
      event.clientX - refBoundingBox.left,
      0,
      refBoundingBox.width,
    );
    const newY = clamp(
      event.clientY - refBoundingBox.top,
      0,
      refBoundingBox.height,
    );
    setX(newX);
    setY(newY);

    setMouseIn(
      !(
        newX <= 0 ||
        newY <= 0 ||
        newX >= refBoundingBox.width ||
        newY >= refBoundingBox.height
      ),
    );

    if (!clickRef.current) return;

    setXClicked(newX);
    setYClicked(newY);
    parameters?.callback?.({
      x: newX,
      y: newY,
      boundingBox: refBoundingBox,
    });
  };

  return {
    x,
    xClicked,
    y,
    yClicked,
    boundingBox,
    mouseIn,
    isClicked: clickRef.current || false,
    reset,
  };
};
