import {Bezier} from 'bezier-js';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import chroma from 'chroma-js';
import {drawDebugRectangle} from '@autocut/pages/modes/captions/utils/canvas/canvas.utils';
import {CanvasObject} from '@autocut/pages/modes/captions/utils/canvas/classes/canvas.class.utils';
import {useCanvasObjectModifier} from '@autocut/pages/modes/captions/utils/canvas/modifier.canvas.utils';

export type FloatingBezierConfig = {
  bezier: Bezier;
  startFrame: number;
  endFrame: number;
};

export const FLOATING_ANIMATION_LENGTH_FRAME = 120;
export const FLOATING_ANIMATION_WIDTH = 1920;
export const FLOATING_ANIMATION_HEIGHT = 1080;
const DEBUG_SCALE = 20;
const FLOATING_BEZIER_WIDTH = 20;
const FLOATING_BEZIER_HEIGHT = 24.5;
const floatingBeziers = [
  {
    bezier: Bezier.quadraticFromPoints(
      {x: 0, y: 0},
      {x: 2.79, y: 3.79},
      {x: 5.39, y: 7},
      0.5,
    ),
    startFrame: 0,
    endFrame: 12,
  },
  {
    bezier: Bezier.quadraticFromPoints(
      {x: 5.39, y: 7},
      {x: 7.5, y: 8.6},
      {x: 10, y: 10},
      0.5,
    ),
    startFrame: 12,
    endFrame: 24,
  },
  {
    bezier: Bezier.quadraticFromPoints(
      {x: 10, y: 10},
      {x: 6.5, y: 7.89},
      {x: 2, y: 6.6},
      0.5,
    ),
    startFrame: 24,
    endFrame: 36,
  },
  {
    bezier: Bezier.quadraticFromPoints(
      {x: 2, y: 6.6},
      {x: -3.7, y: 8.7},
      {x: -10, y: 11},
      0.5,
    ),
    startFrame: 36,
    endFrame: 48,
  },
  {
    bezier: Bezier.quadraticFromPoints(
      {x: -10, y: 11},
      {x: -3.89, y: 4.39},
      {x: 3.6, y: -2.79},
      0.5,
    ),
    startFrame: 48,
    endFrame: 60,
  },
  {
    bezier: Bezier.quadraticFromPoints(
      {x: 3.6, y: -2.79},
      {x: 7.89, y: -6.6},
      {x: 10, y: -10},
      0.5,
    ),
    startFrame: 60,
    endFrame: 72,
  },
  {
    bezier: Bezier.quadraticFromPoints(
      {x: 10, y: -10},
      {x: 3.89, y: -12.6},
      {x: -2.29, y: -13.5},
      0.5,
    ),
    startFrame: 72,
    endFrame: 84,
  },
  {
    bezier: Bezier.quadraticFromPoints(
      {x: -2.29, y: -13.5},
      {x: -7.1, y: -12.1},
      {x: -9.6, y: -10},
      0.5,
    ),
    startFrame: 84,
    endFrame: 96,
  },
  {
    bezier: Bezier.quadraticFromPoints(
      {x: -9.6, y: -10},
      {x: -6.2, y: -8.29},
      {x: -3.29, y: -6.79},
      0.5,
    ),
    startFrame: 96,
    endFrame: 108,
  },
  {
    bezier: Bezier.quadraticFromPoints(
      {x: -3.29, y: -6.79},
      {x: -1.5, y: -3.6},
      {x: 0, y: 0},
      0.5,
    ),
    startFrame: 108,
    endFrame: FLOATING_ANIMATION_LENGTH_FRAME,
  },
];
const rotationKeyframes = [
  {frame: 0, deg: 0},
  {frame: 12, deg: 0.5},
  {frame: 36, deg: -0.3},
  {frame: 60, deg: 0.6},
  {frame: 84, deg: -0.3},
  {frame: 108, deg: 0.1},
  {frame: 120, deg: 0.0},
];
const setFloatingScale = (x: number, size: number) =>
  (x / FLOATING_ANIMATION_WIDTH) * size;

export const useFloatingCanvasObjectModifier = ({debug}: {debug?: boolean}) => {
  const {enabled} = useAutoCutStore(state => ({
    enabled:
      state.ui.parameters.caption.animations.enabled &&
      state.ui.parameters.caption.animations.floatingText,
  }));

  const drawDebug = (ctx: CanvasRenderingContext2D, x: number, y: number) => {
    const canvasWidth = ctx.canvas.width;
    const canvasHeight = ctx.canvas.height;

    const baseColor = chroma('#13b8ea');

    floatingBeziers.forEach(({bezier}) => {
      const bezierPoints = bezier
        .getLUT(10)
        .map(point => ({
          ...point,
          x: point.x * DEBUG_SCALE,
          y: point.y * DEBUG_SCALE,
        }))
        .map(point => ({
          ...point,
          x: setFloatingScale(point.x, canvasWidth) + x,
          y: setFloatingScale(point.y, canvasHeight) + y,
        }));
      //PATH
      ctx.strokeStyle = baseColor.luminance(0.8).hex();
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.moveTo(bezierPoints[0].x, bezierPoints[0].y);
      bezierPoints.forEach(({x, y}) => {
        ctx.lineTo(x, y);
      });
      ctx.stroke();
      ctx.closePath();

      //PATH NODES
      ctx.fillStyle = baseColor.luminance(0.2).hex();
      bezierPoints.forEach(({x, y}) => {
        ctx.beginPath();
        ctx.arc(x, y, 2, 0, Math.PI * 2);
        ctx.fill();
        ctx.closePath();
      });

      //MAIN NODES
      ctx.strokeStyle = baseColor.luminance(0.5).hex();
      ctx.lineWidth = 3;
      ctx.beginPath();
      ctx.arc(bezierPoints[0].x, bezierPoints[0].y, 5, 0, Math.PI * 2);
      ctx.fill();
      ctx.stroke();
      ctx.closePath();
      ctx.beginPath();
      ctx.arc(
        bezierPoints[bezierPoints.length - 1].x,
        bezierPoints[bezierPoints.length - 1].y,
        5,
        0,
        Math.PI * 2,
      );
      ctx.stroke();
      ctx.closePath();

      drawDebugRectangle(
        ctx,
        x - (FLOATING_BEZIER_WIDTH * (DEBUG_SCALE / 2)) / 2,
        y - (FLOATING_BEZIER_HEIGHT * (DEBUG_SCALE / 4)) / 2,
        FLOATING_BEZIER_WIDTH * (DEBUG_SCALE / 2),
        FLOATING_BEZIER_HEIGHT * (DEBUG_SCALE / 4),
        baseColor.luminance(0.2).hex(),
        `Floating path (x${DEBUG_SCALE})`,
      );
    });
  };

  const floatingDrawModifier = (
    canvasObject: Omit<CanvasObject, 'id'>,
  ): Omit<CanvasObject, 'id'> => ({
    ...canvasObject,
    draw(ctx, x, y, {frame, ...args}) {
      const animationFrame = frame % FLOATING_ANIMATION_LENGTH_FRAME;
      const debugScale = debug ? DEBUG_SCALE : 1;

      const curvePart = floatingBeziers.find(
        part =>
          part.startFrame <= animationFrame && part.endFrame >= animationFrame,
      );
      let newX = x;
      let newY = y;
      if (curvePart) {
        const nbFrames = curvePart.endFrame - curvePart.startFrame;
        const curveFrame = animationFrame - curvePart.startFrame;
        const newPoint = curvePart.bezier.get(curveFrame / nbFrames);
        newX += setFloatingScale(newPoint.x * debugScale, ctx.canvas.width);
        newY += setFloatingScale(newPoint.y * debugScale, ctx.canvas.height);
      }
      if (debug) {
        drawDebug(ctx, x, y);
      }

      const rotationLastFrame = rotationKeyframes.reduce((last, current) =>
        current.frame <= animationFrame ? current : last,
      );
      const rotationNextFrame = rotationKeyframes.reduce((last, current) =>
        last.frame > animationFrame ? last : current,
      );

      const numFrameRotation =
        rotationNextFrame.frame - rotationLastFrame.frame;
      const rotationFrame = animationFrame - rotationLastFrame.frame;
      const rotationProgress = rotationFrame / numFrameRotation;
      const rotationDiff = rotationNextFrame.deg - rotationLastFrame.deg;
      const newRotation =
        rotationLastFrame.deg + rotationDiff * rotationProgress;

      ctx.save();
      ctx.translate(newX, newY);
      ctx.rotate((newRotation * Math.PI) / 180);
      canvasObject.draw(ctx, 0, 0, {
        frame,
        ...args,
      });
      ctx.restore();
    },
    customCacheCheck: () => false,
  });

  const floatingModifier = (canvasObject: Omit<CanvasObject, 'id'>) => {
    return floatingDrawModifier(canvasObject);
  };

  return useCanvasObjectModifier({
    modifier: floatingModifier,
    enabled,
  });
};
