import React, {useRef, useState} from 'react';

import css from './EmojiSelector.module.scss';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {BsEmojiSmile} from 'react-icons/bs';
import {FiMinus} from 'react-icons/fi';
import {EmojiPickerVariant} from './EmojiPicker/EmojiPickerVariant';
import {Emoji} from './EmojiPicker/EmojiPicker';
import PopupPortal from '@autocut/designSystem/components/atoms/PopupPortal/PopupPortal';

export const EmojiSelector = ({
  selectedEmoji,
  onEmojiSelect,
  onDeleteEmoji,
  disabled,
}: {
  selectedEmoji?: {url: string; name: string};
  onEmojiSelect: (emoji: {url: string; name: string; size: number}) => void;
  onDeleteEmoji: () => void;
  disabled?: boolean;
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <>
      <FlexContainer
        ref={ref}
        className={css.iconContainer}
        gap={8}
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        flexWrap="nowrap"
        onClick={() => setIsOpened(!isOpened)}
        style={
          disabled
            ? {
                userSelect: 'none',
                pointerEvents: 'none',
              }
            : {}
        }
      >
        <FlexContainer
          alignItems="center"
          justifyContent="center"
          className={css.icon}
        >
          {selectedEmoji ? (
            <Emoji
              emoji={selectedEmoji}
              style={{}}
            />
          ) : (
            <BsEmojiSmile />
          )}
          {selectedEmoji && (
            <div
              className={css.deleteEmoji}
              onClick={event => {
                event.stopPropagation();
                onDeleteEmoji();
                setIsOpened(false);
              }}
            >
              <FiMinus />
            </div>
          )}
        </FlexContainer>
      </FlexContainer>

      {isOpened && (
        <PopupPortal
          target={ref}
          position="bottom-right"
          onClickOutside={() => setIsOpened(false)}
        >
          <EmojiPickerVariant
            onEmojiClick={emoji => {
              onEmojiSelect(emoji);
              setIsOpened(false);
            }}
            width="360px"
            maxHeight="200px"
          />
        </PopupPortal>
      )}
    </>
  );
};
