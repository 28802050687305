import React, {useState} from 'react';
import {HiOutlineKey} from 'react-icons/hi';
import {useLocation, useNavigate} from 'react-router-dom';
import {Email} from './Email/Email';
import {Login} from '../Login/Login';
import {useIntl} from 'react-intl';
import {SendingEmail} from './SendingEmail/SendingEmail';
import {ModeWrapper} from '@autocut/pages/modes/ModeWrapper';
import StepperRouter, {
  getPreviousStepPath,
} from '@autocut/components/Stepper/StepperRouter/StepperRouter';

export const Trial = () => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const [trialEmail, setTrialEmail] = useState<string>('');

  return (
    <ModeWrapper
      Icon={HiOutlineKey}
      text={intl.formatMessage({
        id: 'auth_trial_title',
        defaultMessage: 'Get a free trial',
      })}
      customHomeButtonHandler={() => {
        const path = getPreviousStepPath(location);
        navigate(path);
      }}
    >
      <StepperRouter
        basePath="/onboarding/trial"
        steps={[
          {
            label: intl.formatMessage({
              id: 'auth_trial_steps_email_title',
              defaultMessage: 'Enter your email address',
            }),
            path: 'email',
            element: <Email setTrialEmail={setTrialEmail} />,
          },
          {
            label: intl.formatMessage({
              id: 'auth_trial_steps_sendingEmail_title',
              defaultMessage: 'Sending the email',
            }),
            path: 'sending-email',
            element: <SendingEmail email={trialEmail} />,
          },
          {
            label: intl.formatMessage({
              id: 'auth_login_title',
              defaultMessage: 'Use your licence key',
            }),
            path: 'login',
            element: <Login trialEmail={trialEmail} />,
          },
        ]}
      />
    </ModeWrapper>
  );
};
