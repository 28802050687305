import {useEffect} from 'react';

import React from 'react';
import {useLocation} from 'react-router-dom';
import {AutocutModes, AutocutModesKeys} from '@autocut/enums/modes.enum';
import {setAutocutStore} from '@autocut/utils/zustand';
import {getModeById} from '@autocut/utils/modes.utils';

export const ModeProvider = ({children}: {children: React.ReactNode}) => {
  const location = useLocation();

  useEffect(() => {
    const modeId = (location.pathname.split('/').pop() ||
      'legacy') as (typeof AutocutModes)[AutocutModesKeys]['id'];
    const mode = getModeById(modeId);
    if (mode) {
      setAutocutStore('ui.process.mode', mode);
    }
  }, [location]);

  return (
    <div
      style={{
        padding: '16px 16px 0 ',
        flexGrow: 1,
        flexShrink: 1,
        height: '100vh',
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      {children}
    </div>
  );
};
