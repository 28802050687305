import {IconComponent, IconComponentProps} from '../Icon';

export const IconVolumeMax = (props: IconComponentProps) => (
  <IconComponent
    {...props}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M19.7 5a12 12 0 0 1 2.3 7c0 2.6-.8 5-2.3 7m-4-11a7 7 0 0 1 1.3 4 7 7 0 0 1-1.3 4m-6-10.6L6.4 8.5 6 9a1 1 0 0 1-.3 0l-.5.1H3.6l-1 .1a1 1 0 0 0-.5.4l-.1 1v2.8l.1 1a1 1 0 0 0 .4.5l1.1.1h2.2a1 1 0 0 1 .3.1l.4.4 3.1 3.1c.5.5.7.7.9.7a.5.5 0 0 0 .4-.2l.1-1V5.9l-.1-1a.5.5 0 0 0-.4-.2c-.2 0-.4.2-.9.7Z"
    />
  </IconComponent>
);
