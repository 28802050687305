import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import logLevel from '@autocut/enums/logLevel.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useLogger} from '@autocut/hooks/useLogger';
import {NeedingTranscriptionModes} from '@autocut/types/Transcription';
import {setAutocutStore} from '@autocut/utils/zustand';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {CustomSelectText} from '../CustomSelectText/CustomSelectText';
import {StepLayout} from '../StepLayout';
import {getLanguages, TranscriptLanguage} from '@autocut/utils/languages.utils';

export type TranscriptLanguageStepProps = {
  modeId?: NeedingTranscriptionModes;
};

export const TranscriptLanguageStep = ({
  modeId = 'caption',
}: TranscriptLanguageStepProps) => {
  const {logMessage} = useLogger('TranscriptLanguageStep');
  const intl = useIntl();

  const languageValues = React.useMemo(() => getLanguages(intl), [intl]);

  const {languageOfTranscription} = useAutoCutStore(state => ({
    languageOfTranscription: state.ui.parameters[modeId]
      .languageOfTranscription as TranscriptLanguage,
  }));

  useEffect(() => {
    if (languageOfTranscription) {
      const foundLanguage = languageValues.find(
        language => language.value === languageOfTranscription.value,
      );

      if (foundLanguage) {
        setAutocutStore(
          `ui.parameters.${modeId}.languageOfTranscription`,
          foundLanguage as any,
        );
      }
    }
  }, []);

  // useEffect(() => {
  //   setAutocutStore(
  //     'ui.currentTranscription.isProviderAvailable',
  //     isProviderAvailable(modeId)
  //   );
  // }, [languageOfTranscription, modeParameters, modeId]);

  return (
    <StepLayout
      title={intl.formatMessage({
        id: 'modes_general_steps_transcription_title',
        defaultMessage: 'Select audio clip language',
      })}
      alwaysOpen
    >
      <FlexContainer
        flexDirection="column"
        gap={8}
      >
        <div>
          <CustomSelectText
            isSearchable
            options={languageValues}
            handleSelectChange={option => {
              logMessage(
                logLevel.notice,
                `audio language changed to ${option?.value}`,
              );
              if (option) {
                console.log(option);
                setAutocutStore(
                  `ui.parameters.${modeId}.languageOfTranscription`,
                  option as any,
                );
              }
            }}
            initialValue={languageOfTranscription}
          />
        </div>
      </FlexContainer>
    </StepLayout>
  );
};
