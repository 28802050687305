import {Suspense, useEffect} from 'react';

import css from './EndProcessModal.module.css';
import {useIntl} from 'react-intl';
import {IoCheckmarkSharp} from 'react-icons/io5';
import {setAutocutStore} from '@autocut/utils/zustand';
import {useLogger} from '@autocut/hooks/useLogger';
import {closeModal} from '@autocut/utils/modal/closeModal';
import {ModalType} from '@autocut/enums/modals.enum';
import {useNavigate} from 'react-router-dom';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {AutocutModeIds} from '@autocut/enums/modes.enum';
import {Button} from '@autocut/components/Button/Button';
import ConfettiBackground from '@autocut/components/ConfettiBackground/ConfettiBackground';
import Modal, {ModalProps} from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import logLevel from '@autocut/enums/logLevel.enum';
import {getModeById} from '@autocut/utils/modes.utils';
import {preload} from '@autocut/types/ElectronPreload';

const defaultEndProcessConfig = {
  goToNextMode: 'silence',
  restoreSequence: true,
  rateAutoCut: true,
  timeTaken: true,
  timeSaved: true,
};

export const EndProcessModal = ({children, ...props}: Partial<ModalProps>) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {logMessage} = useLogger('EndprocessModal');

  const {
    mode,
    durationProcessMinutes: minutesTaken,
    durationProcessSeconds: secondsTaken,
    xpGained,
    currentProgress,
    license,
    displayTrialTaskModal,
  } = useAutoCutStore(state => ({
    mode: state.ui.process.mode,
    durationProcessMinutes: state.ui.process.durationProcessMinutes,
    durationProcessSeconds: state.ui.process.durationProcessSeconds,
    xpGained: state.game.level.xpGained,
    currentProgress: state.game.level.currentProgress,
    license: state.user.license,
    displayTrialTaskModal: state.game.trial.displayModal,
  }));

  const minutesSaved = Math.floor((xpGained * 60) / 100);
  const secondsSaved = Math.floor(((xpGained * 60) / 100 - minutesSaved) * 60);

  const displayNextMode =
    mode.endProcessConfig?.goToNextMode === false ||
    (typeof mode.endProcessConfig?.goToNextMode === 'string' &&
      !license?.features?.includes(
        mode.endProcessConfig.goToNextMode as AutocutModeIds,
      ))
      ? false
      : mode.endProcessConfig.goToNextMode;

  const displayRateAutoCut =
    typeof mode.endProcessConfig?.rateAutoCut === 'boolean'
      ? mode.endProcessConfig.rateAutoCut
      : defaultEndProcessConfig.rateAutoCut;

  const displayTimeTaken =
    typeof mode.endProcessConfig?.timeTaken === 'boolean'
      ? mode.endProcessConfig.timeTaken
      : defaultEndProcessConfig.timeTaken;

  const displayTimeSaved =
    typeof mode.endProcessConfig?.timeSaved === 'boolean'
      ? mode.endProcessConfig.timeSaved
      : defaultEndProcessConfig.timeSaved;

  useEffect(() => {
    logMessage(logLevel.info, 'Process ended. Redirecting to homepage.');
    navigate('/homepage');
  }, []);

  const handleNextMode = () => {
    navigate(
      getModeById(displayNextMode as AutocutModeIds)?.path || '/homepage',
    );

    setAutocutStore('ui.process.isPaused', false);
    closeModal();
  };

  return (
    <Modal
      fullWidth
      title={intl.formatMessage({
        id: 'modals_endProcess_title',
        defaultMessage: 'Process completed!',
      })}
      icon={
        <div className={css.iconContainer}>
          <IoCheckmarkSharp
            color="white"
            strokeWidth={2}
          />
        </div>
      }
      closeModalFunction={
        displayTrialTaskModal
          ? () => setAutocutStore('ui.openedModalName', ModalType.GamingTrial)
          : closeModal
      }
      extraChildren={
        <Suspense fallback={<div />}>
          <ConfettiBackground />
        </Suspense>
      }
      footer={
        <FlexContainer
          className={css.footerContainer}
          justifyContent="space-between"
          gap="8px"
          style={{width: '100%'}}
        >
          {displayNextMode && (
            <Button
              onClickFunction={handleNextMode}
              buttonType="primary"
              size="md"
            >
              <TranslatedMessage
                id={
                  `modals_endProcess_nextMode_${displayNextMode}_button` as any
                }
                defaultMessage="modals_endProcess_nextMode_silence_button"
              />
            </Button>
          )}
        </FlexContainer>
      }
      {...props}
    >
      <FlexContainer
        flexDirection="column"
        alignItems="flex-start"
        gap="12px"
      >
        {(displayTimeSaved || displayTimeTaken) && (
          <div className={css.timeInfos}>
            {displayTimeTaken && (
              <p>
                <TranslatedMessage
                  id={'modals_endProcess_time'}
                  defaultMessage="Process completed in {minutes} minutes and {seconds} seconds !"
                  values={{
                    minutes: minutesTaken,
                    seconds: secondsTaken,
                  }}
                />
              </p>
            )}
            {displayTimeSaved && (
              <>
                <p>
                  <TranslatedMessage
                    id={'modals_endProcess_timeSaved'}
                    defaultMessage="You saved: <b>{minutesSaved}</b> minute(s) and <b>{secondsSaved}</b> second(s)"
                    values={{
                      minutesSaved,
                      secondsSaved,
                    }}
                  />
                </p>
                <p>
                  <TranslatedMessage
                    id={'modals_endProcess_timeSavedAllTime'}
                    defaultMessage="Since using AutoCut, you saved : {hourSaved} hour(s) and {minutesSaved} minute(s) ! 🎉"
                    values={{
                      hourSaved: currentProgress?.level,
                      minutesSaved: ~~((currentProgress.xp * 60) / 100),
                    }}
                  />
                </p>
              </>
            )}
          </div>
        )}
        {children}
        {displayRateAutoCut && (
          <FlexContainer flexDirection="column">
            <p>
              <TranslatedMessage
                id={'modals_endProcess_feedback'}
                defaultMessage="Feel free to give us feedback on the quality of the result:"
              />
            </p>
            <Button
              buttonType="tertiary"
              compact
              onClickFunction={() => {
                logMessage(logLevel.info, 'Open Senja in browser');

                void preload().electron.openLinkInBrowser(
                  'https://senja.io/p/autocut/r/ikmgDE',
                );
              }}
            >
              <TranslatedMessage
                id={'modals_endProcess_review'}
                defaultMessage="Grade AutoCut !"
              />
            </Button>
          </FlexContainer>
        )}

        {displayNextMode && (
          <p>
            <TranslatedMessage
              id={`modals_endProcess_nextMode_${displayNextMode}_text` as any}
              defaultMessage="If you want, you can now use AutoCut Silences for automatically removing silences."
            />
          </p>
        )}
      </FlexContainer>
    </Modal>
  );
};
