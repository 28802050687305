import logLevel from '@autocut/enums/logLevel.enum';
import {ResourceManagerElementConfig} from '@autocut/types/ResourceManager';
import {downloadFile} from './files.utils';
import {autocutApi} from './http.utils';
import {logger} from './logger';
import {preload} from '@autocut/types/ElectronPreload';
import {getResourceFolderPath} from '@autocut/utils/electron/app.electron.utils';

const logMessage = async (
  elementName: string,
  level: logLevel,
  message = 'log',
  objects = {},
) => {
  logger(
    `defaultResourceManagerState [${elementName}]`,
    level,
    message,
    objects,
  );
};

export const getRessourceSignedUrl = async (fileName: string) => {
  const signedUrlResponse = await autocutApi.post(
    `/downloadableRessources/fileUrl`,
    {
      fileName,
    },
  );
  const signedUrl = signedUrlResponse.data;

  return signedUrl as string;
};

// ressourceName should correspond to a key in resourcesConfigs
export const downloadRessource =
  (ressourceName: string) =>
  (fileName: string) =>
  async (outputFilePath: string) => {
    const signedUrl = await getRessourceSignedUrl(fileName);

    if (!preload().fs.existsSync(outputFilePath)) {
      preload().fs.mkdirSync(preload().path.dirname(outputFilePath), {
        recursive: true,
      });
    }

    await downloadFile(signedUrl, outputFilePath, true);
    await logMessage(ressourceName, logLevel.info, `${fileName} downloaded`);

    return outputFilePath;
  };

export const getGlobalResourceConfig = ({
  fileName,
  resourceName,
  additionalData = {},
}: {
  fileName: string;
  resourceName: string;
  additionalData?: any;
}): ResourceManagerElementConfig => ({
  fileName,
  downloadMethod: async () => {
    const filePath = preload().path.join(
      getResourceFolderPath(),
      'global',
      fileName,
    );
    return downloadRessource(resourceName)(fileName)(filePath);
  },
  existCheck: async () => {
    const filePath = preload().path.join(
      getResourceFolderPath(),
      'global',
      fileName,
    );

    return {
      isExist: preload().fs.existsSync(filePath),
      outputFilePath: filePath,
    };
  },
  scope: 'global',
  additionalData,
});

export const requestResourceOnInit = (
  resourceConfig: ResourceManagerElementConfig,
): ResourceManagerElementConfig => ({
  ...resourceConfig,
  requestOnInit: true,
});

export const getBleepResourceConfig = (
  index: number,
): ResourceManagerElementConfig => ({
  fileName: `bleep_sound_${index}.wav`,
  downloadMethod: async () => {
    const filePath = preload().path.join(
      getResourceFolderPath(),
      'audio',
      `bleep_sound_${index}.wav`,
    );

    return downloadRessource(`bleep_sound_${index}_wav`)(
      `bleep_sound_${index}.wav`,
    )(filePath);
  },
  existCheck: async () => {
    const filePath = preload().path.join(
      getResourceFolderPath(),
      'audio',
      `bleep_sound_${index}.wav`,
    );

    return {
      isExist: await preload().fs.existsSync(filePath),
      outputFilePath: filePath,
    };
  },
  scope: 'global',
  requestOnInit: true,
});
