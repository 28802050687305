import {REPEAT_ALGORITHM} from '@autocut/enums/repeatAlgorithm.enum';
import {z as zod} from 'zod';
import {languageOfTranscriptionValidationSchema} from './languageOfTranscription.validationSchema';
import {transcriptionProviderValidationSchema} from './transcriptionProvider.validationSchema';

export const repeatValidationSchema = zod.object({
  languageOfTranscription: languageOfTranscriptionValidationSchema,
  transcriptionProvider: transcriptionProviderValidationSchema,
  transistions: zod.enum(['none', 'j_cut', 'l_cut', 'both', 'constant_power'], {
    invalid_type_error: 'validation_error_silence_transitions',
  }),
  silences: zod.enum(['delete', 'keep', 'mute', 'delete_keep_spaces'], {
    invalid_type_error: 'validation_error_silence_silences',
  }),
  selectedAlgorithm: zod.enum(
    [REPEAT_ALGORITHM.FAST, REPEAT_ALGORITHM.PRECISE],
    {
      invalid_type_error: 'validation_error_repeat_algorithm',
    },
  ),
});
