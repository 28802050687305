import {CaptionsParameters} from '@autocut/types/CaptionsParameters';
import {AutoCutApiError} from '@autocut/utils/errors/AutoCutApiError';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {autocutApi} from '@autocut/utils/http.utils';
import {manageError} from '@autocut/utils/manageError';
import {CaptionsPreset} from '@autocut/validationSchema/caption.validationSchema';
import {AnimatedCanvasType} from '../canvas/classes/animatedCanvas.class.utils';
import {DraggableCanvas} from '../canvas/classes/draggableCanvas.class.utils';
import {removeUnwantedKeysFromCaptionsParameters} from '../removeUnwantedKeysFromCaptionsParameters';
import {encodeCaptionParams, decodeCaptionParams} from './applyPresets';
import {uploadMedia} from './uploadMedia';

export const createCaptionsPreset = async ({
  captionsParameters,
  title,
  isPublic = false,
  canvas,
}: {
  captionsParameters: CaptionsParameters;
  title: string;
  isPublic?: boolean;
  canvas?: AnimatedCanvasType<DraggableCanvas>;
}) => {
  const {data: alreadyExistingPreset} = await autocutApi.get(
    `/captions-presets/exists/${title}`,
  );

  if (alreadyExistingPreset) {
    throw new Error('preset-already-exists');
  }

  const formattedParams =
    removeUnwantedKeysFromCaptionsParameters(captionsParameters);

  const encodedParams = encodeCaptionParams(formattedParams);

  if (isPublic) {
    if (canvas) {
      await uploadMedia(canvas, title);
    } else {
      throw new Error('Canvas is required for public presets');
    }
  }

  const res = await autocutApi
    .post(`/captions-presets`, {
      title,
      params: encodedParams,
      isPublic,
    })
    .catch((error: AutoCutApiError | IncrementalError) => {
      manageError({
        error: new IncrementalError(error, 'createCaptionsPreset'),
      });
      return null;
    });

  if (res?.status === 201) {
    const captionsPreset: CaptionsPreset[] = res.data.map(
      ({
        id,
        title,
        params,
        videoUrl,
        thumbnailUrl,
      }: Omit<CaptionsPreset, 'params'> & {params: string}) => {
        const decodedParams = decodeCaptionParams(params);
        return {id, title, params: decodedParams, videoUrl, thumbnailUrl};
      },
    );

    return captionsPreset;
  }

  return null;
};
