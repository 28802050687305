const AUTOCUT_VERSION = '1.4' as const;
const AUTOCUT_CONTACT = 'contact@autocut.fr' as const;

const WINDOWS_FFMPEG_RELATIVE_PATH = '/exes/win/ffmpeg.exe' as const;
const MAC_FFMPEG_RELATIVE_PATH = '/exes/mac/ffmpeg' as const;

const WINDOWS_AI_RELATIVE_PATH = '/exes/win/autoAi/autoAi.exe' as const;
const MAC_AI_RELATIVE_PATH = '/exes/mac/autoAi/autoAi' as const;

const WINDOWS_HOSTNAME_COMMAND = 'C:\\Windows\\System32\\hostname.exe' as const;
const MAC_HOSTNAME_COMMAND = 'hostname' as const;

const WINDOWS_UUID_COMMAND =
  '(Get-CimInstance -Class Win32_ComputerSystemProduct).UUID';
const MAC_UUID_COMMAND = 'ioreg -ad2 -c IOPlatformExpertDevice' as const;

const MAC_LOG_FOLDER = '/Users/Shared/' as const;

const GET_MAC_ID_COMMAND = String.raw`ioreg -d2 -c IOPlatformExpertDevice | awk -F\" '/IOPlatformSerialNumber/{print $(NF-1)}'`;

const OS_MAC = 'MAC' as const;
const OS_WINDOWS = 'WINDOWS' as const;
const ERROR_NO_AUDIO = 0 as const;
const ERROR_MORE_THAN_ONE_GROUP = 1 as const;
const ERROR_MULTIPLE_AUDIO = 2 as const;

const PREVIEW_DURATION = 10; // In second as consts

const PERCENTAGE_NEED_TO_ZOOM = 0.15 as const;

const WAV_EXPORT_PRESET_NAME = 'wav_preset.epr' as const;

const SENTRY_DSN =
  'https://c1071a1f349b4330801f541a00a7066e@o4504764662087680.ingest.sentry.io/4505516338380800' as const;

export {
  AUTOCUT_CONTACT,
  AUTOCUT_VERSION,
  ERROR_MORE_THAN_ONE_GROUP,
  ERROR_MULTIPLE_AUDIO,
  ERROR_NO_AUDIO,
  GET_MAC_ID_COMMAND,
  MAC_AI_RELATIVE_PATH,
  MAC_FFMPEG_RELATIVE_PATH,
  MAC_HOSTNAME_COMMAND,
  MAC_LOG_FOLDER,
  MAC_UUID_COMMAND,
  OS_MAC,
  OS_WINDOWS,
  PERCENTAGE_NEED_TO_ZOOM,
  PREVIEW_DURATION as PREVIEW_DURATION,
  SENTRY_DSN,
  WAV_EXPORT_PRESET_NAME,
  WINDOWS_AI_RELATIVE_PATH,
  WINDOWS_FFMPEG_RELATIVE_PATH,
  WINDOWS_HOSTNAME_COMMAND,
  WINDOWS_UUID_COMMAND,
};
