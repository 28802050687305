import {TranslationKeys} from '@autocut/contexts/LocaleContext';
import {z as zod} from 'zod';

export const fontValidationSchema = zod.object({
  fontFamily: zod
    .string({
      required_error: 'modes_captions_error_validation_text_font_missing',
    })
    .min(1, 'modes_captions_error_validation_text_font_missing'),
  postscriptName: zod.string({
    message:
      'modes_captions_error_validation_text_font_missing' as TranslationKeys,
  }),
  fontStyle: zod.string({
    message:
      'modes_captions_error_validation_text_font_missing' as TranslationKeys,
  }),
  lineGap: zod.number({
    message:
      'modes_captions_error_validation_text_font_missing' as TranslationKeys,
  }),
  ascent: zod.number({
    message:
      'modes_captions_error_validation_text_font_missing' as TranslationKeys,
  }),
  descent: zod.number({
    message:
      'modes_captions_error_validation_text_font_missing' as TranslationKeys,
  }),
  unitsPerEm: zod.number({
    message:
      'modes_captions_error_validation_text_font_missing' as TranslationKeys,
  }),
  bbox: zod.object({
    minX: zod.number({
      message:
        'modes_captions_error_validation_text_font_missing' as TranslationKeys,
    }),
    minY: zod.number({
      message:
        'modes_captions_error_validation_text_font_missing' as TranslationKeys,
    }),
    maxX: zod.number({
      message:
        'modes_captions_error_validation_text_font_missing' as TranslationKeys,
    }),
    maxY: zod.number({
      message:
        'modes_captions_error_validation_text_font_missing' as TranslationKeys,
    }),
  }),
  label: zod
    .string({
      required_error: 'modes_captions_error_validation_text_font_missing',
    })
    .min(1, 'validation_error_font_label'),
  supportsArabic: zod.boolean().optional(),
  supportsHebrew: zod.boolean().optional(),
});
