import React, {useEffect} from 'react';
import css from './Carousel.module.scss';

type CarouselProps = {
  items: React.ReactNode[];
  hideButtons?: boolean;
  page?: number;
  onPageChange?: (page: number) => void;
} & React.HTMLAttributes<HTMLDivElement>;

export const Carousel = ({
  items,
  hideButtons,
  page,
  onPageChange,
  className,
  ...props
}: CarouselProps) => {
  const [currentItem, setCurrentItem] = React.useState(0);

  const changePage = (page: number) => {
    setCurrentItem(page);

    onPageChange?.(page);
  };

  useEffect(() => {
    if (page !== undefined) {
      setCurrentItem(page);
    }
  }, [page]);

  const isFirstPage = currentItem === 0;
  const isLastPage = currentItem === items.length - 1;

  return (
    <div
      className={`${css.carousel} ${className}`}
      {...props}
    >
      <div
        className={css.container}
        style={{
          transform: `translateX(-${currentItem * 100}%)`,
          transition: 'transform 0.5s ease-out',
        }}
      >
        {items.map((item, index) => (
          <div
            key={index}
            className={css.item}
          >
            {item}
          </div>
        ))}
      </div>

      <div
        className={[css.button, css.left].join(' ')}
        onClick={() => {
          if (isFirstPage || hideButtons) return;

          changePage(currentItem - 1);
        }}
        style={{
          left: 0,
          opacity: isFirstPage || hideButtons ? 0 : 1,
          cursor: isFirstPage || hideButtons ? 'default' : 'pointer',
        }}
      >
        {'◀︎'}
      </div>
      <div
        className={[css.button, css.right].join(' ')}
        onClick={() => {
          if (isLastPage || hideButtons) return;

          changePage(currentItem + 1);
        }}
        style={{
          right: 0,
          opacity: isLastPage || hideButtons ? 0 : 1,
          cursor: isLastPage || hideButtons ? 'default' : 'pointer',
        }}
      >
        {'▶︎'}
      </div>
    </div>
  );
};
