import {z as zod} from 'zod';
import {languageOfTranscriptionValidationSchema} from './languageOfTranscription.validationSchema';
import {transcriptionProviderValidationSchema} from './transcriptionProvider.validationSchema';
import {BleepFileEnum} from '@autocut/enums/bleepFile.enum';

export const swearWordValidationSchema = zod.object({
  languageOfTranscription: languageOfTranscriptionValidationSchema,
  transcriptionProvider: transcriptionProviderValidationSchema,
  bleepFile: zod.enum(
    [BleepFileEnum.Muted, BleepFileEnum.BleepSound1, BleepFileEnum.BleepSound2],
    {
      invalid_type_error: 'validation_error_swearWord_bleepFile',
    },
  ),
});
