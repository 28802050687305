import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import React from 'react';
import {ExampleCaptionText} from './ExampleCaptionText';

const TextPreview = () => {
  return (
    <FlexContainer
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <ExampleCaptionText
        key={`captions-text-preview`}
        boundingBox={{
          height: 80,
          width: 540,
        }}
        height={80}
        width={500}
      />
    </FlexContainer>
  );
};

export default TextPreview;
