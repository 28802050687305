import {useEffect, useState} from 'react';

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';

import css from './PreviewLegend.module.scss';

export const PreviewLegend = () => {
  return (
    <FlexContainer justifyContent="space-around">
      <Item color="green" />
      <Item color="greenLight" />
      <Item color="red" />
      <Line />
    </FlexContainer>
  );
};

type Props = {
  color: 'red' | 'green' | 'greenLight';
};

const Item = ({color}: Props) => {
  const [backgroundColor, setBackgroundColor] = useState('');

  useEffect(() => {
    if (color == 'green') {
      setBackgroundColor('rgba(0, 255, 0, 0.25)');
    } else if (color == 'greenLight') {
      setBackgroundColor('rgba(0, 255, 0, 0.42)');
    } else if (color == 'red') {
      setBackgroundColor('rgba(255, 0, 0, 0.75)');
    } else {
      setBackgroundColor('rgba(0,0,0,1)');
    }
  }, []);
  return (
    <FlexContainer
      className={css.item}
      alignItems="center"
    >
      <div
        className={css.cube}
        style={{
          backgroundColor: backgroundColor,
        }}
      />
      <Text variant="textSm">
        {color === 'green' && (
          <TranslatedMessage
            id={'modes_silence_steps_preview_legend_kept'}
            defaultMessage="Kept"
          />
        )}
        {color === 'greenLight' && (
          <TranslatedMessage
            id={'modes_silence_steps_preview_legend_margins' as any}
            defaultMessage="Margins"
          />
        )}
        {color === 'red' && (
          <TranslatedMessage
            id={'modes_silence_steps_preview_legend_deleted'}
            defaultMessage="Deleted"
          />
        )}
      </Text>
    </FlexContainer>
  );
};

const Line = () => {
  return (
    <FlexContainer
      className={css.item}
      alignItems="center"
    >
      <div className={css.cube}>
        <div className={css.line} />
      </div>
      <Text variant="textSm">
        <TranslatedMessage
          id={'modes_silence_steps_noiseLevel_title'}
          defaultMessage="Noise level"
        />
      </Text>
    </FlexContainer>
  );
};
