import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {CanvasObject} from '@autocut/pages/modes/captions/utils/canvas/classes/canvas.class.utils';
import {useCanvasObjectModifier} from '@autocut/pages/modes/captions/utils/canvas/modifier.canvas.utils';

export const BLUR_IN_ANIMATION_LENGTH_FRAME = 8;

const blurKeyframes = [
  {frame: 0, radius: 6},
  {frame: 4, radius: 2},
  {frame: 8, radius: 0},
];

export const useBlurInCanvasObjectModifier = (props?: {
  loop?: {enabled: boolean; loopLengthInFrames: number};
}) => {
  const {enabled} = useAutoCutStore(state => ({
    enabled:
      state.ui.parameters.caption.transition.enabled &&
      state.ui.parameters.caption.transition.effects.includes('blurIn'),
  }));

  const blurDrawModifier = (
    canvasObject: Omit<CanvasObject, 'id'>,
  ): Omit<CanvasObject, 'id'> => ({
    ...canvasObject,
    draw(ctx, x, y, {frame, ...args}) {
      const animationFrame = props?.loop?.enabled
        ? frame % props.loop.loopLengthInFrames
        : frame;

      const blurLastFrame = blurKeyframes.reduce((last, current) =>
        current.frame <= animationFrame ? current : last,
      );
      const blurNextFrame = blurKeyframes.reduce((last, current) =>
        last.frame > animationFrame ? last : current,
      );

      const numFrameBlur = blurNextFrame.frame - blurLastFrame.frame;
      const blurFrame = animationFrame - blurLastFrame.frame;
      const blurProgress = blurFrame / numFrameBlur;
      const blurDiff = blurNextFrame.radius - blurLastFrame.radius;
      const newBlur = blurLastFrame.radius + blurDiff * blurProgress;

      ctx.save();
      ctx.filter = `blur(${newBlur}px)`;
      canvasObject.draw(ctx, x, y, {
        frame,
        ...args,
      });
      ctx.restore();
    },
    customCacheCheck: () => false,
  });

  const blurModifier = (canvasObject: Omit<CanvasObject, 'id'>) => {
    return blurDrawModifier(canvasObject);
  };

  return useCanvasObjectModifier({
    modifier: blurModifier,
    enabled,
  });
};
