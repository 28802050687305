import {IncrementalError} from '@autocut/utils/errors/IncrementalError';

const debounceWaitingList: ((
  value: Awaited<any> | PromiseLike<Awaited<any>>,
) => void)[] = [];

export const debounce = <T, Args extends Array<any>>(
  func: (...args: Args) => T,
  wait = 250,
  getReturnIfOverriden = false,
) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: Args): Promise<Awaited<T>> =>
    new Promise((resolve, reject) => {
      if (getReturnIfOverriden) debounceWaitingList.push(resolve);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(async () => {
        try {
          const res = await func(...args);
          debounceWaitingList.forEach(resolve => resolve(res));
          debounceWaitingList.length = 0;
          resolve(res);
        } catch (err: any) {
          reject(new IncrementalError(err, 'debounce'));
        }
      }, wait);
    });
};
