import {TranslationKeys} from '@autocut/contexts/LocaleContext';
import {z as zod} from 'zod';

export const speakerValidationSchema = zod.object({
  name: zod.string(),
  videoTracks: zod.array(
    zod
      .number()
      .min(
        1,
        'modes_podcast_error_validation_speakers_videoTracks_min' as TranslationKeys,
      ),
  ),
  audioTrack: zod
    .number()
    .min(
      1,
      'modes_podcast_error_validation_speakers_audioTrack_min' as TranslationKeys,
    )
    .optional(),
});

export const podcastValidationSchema = zod
  .object({
    minimumCamTime: zod
      .number()
      .min(
        0,
        'modes_podcast_error_validation_minimumCamTime_min' as TranslationKeys,
      )
      .default(10),
    maximumCamTime: zod
      .number()
      .min(
        0,
        'modes_podcast_error_validation_maximumCamTime_min' as TranslationKeys,
      )
      .default(3),
    deleteUnusedClips: zod
      .boolean({
        required_error:
          'modes_podcast_error_validation_deleteUnusedClips_required' as TranslationKeys,
      })
      .default(false),
    speakers: zod.array(speakerValidationSchema).default([
      {
        name: '',
        videoTracks: [],
        audioTrack: undefined,
      },
    ]),
  })
  .refine(
    schema => schema.minimumCamTime < schema.maximumCamTime,
    'modes_podcast_error_validation_minimumCamTime_higherThanMax' as TranslationKeys,
  );

export type PodcastParameters = zod.infer<typeof podcastValidationSchema>;
export type Speaker = zod.infer<typeof speakerValidationSchema>;
