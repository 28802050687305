import chroma from 'chroma-js';
import {SelectVariant, getVariant} from './SelectVariants';

export const SELECT_COLORS = [
  '#16a2c47f',
  '#f13ad77f',
  '#FF8B007f',
  '#0066ff7f',
  '#41c45b7f',
  '#8b4bec7f',
  '#FFC4007f',
  '#c7292a7f',
  '#ffffff7f',
];

const selectCustomStyle = (variantName: SelectVariant = 'default') => {
  const variant = getVariant(variantName);

  const styles = {
    container: (provided: any, state: any) => ({
      ...provided,
      width: '100%',
      borderRadius: '5px',
      ...variant.container(state),
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      justifyContent: 'space-between',

      padding: '6px 16px',
      minHeight: 'unset',

      border: '1px solid #94a3b8',
      borderRadius: '8px',
      backgroundColor: 'transparent',

      color: 'white',
      '&:focus': {
        outline: '#e33d00',
        border: '1px #E64E19 solid',
      },
      ':hover': {
        border: '1px #E64E19 solid',
      },
      ...variant.control(state),
    }),

    // ========= Icons =========
    // dropdown icon
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: '#667085',
      padding: '0',
      ':hover': {
        cursor: 'pointer',
        color: '#E64E19',
      },
      ...variant.dropdownIndicator(state),
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      padding: '0',
      display: 'flex',
      flexDirection: 'row-reverse',
      ...variant.indicatorsContainer(state),
    }),
    // bar between dropdown and delete icons
    indicatorSeparator: (provided: any, state: any) => ({
      //...provided,
      height: '100%',
      width: '4px',
      ...variant.indicatorSeparator(state),
    }),
    // cross to delete selected option
    clearIndicator: (provided: any, state: any) => ({
      ...provided,
      padding: '0',
      color: 'white',
      ':hover': {
        color: '#E64E19',
      },
      ...variant.clearIndicator(state),
    }),

    // ========= Actual value =========
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      padding: '0',
      margin: '0',
      marginRight: '24px',
      lineHeight: '12px',
      fontSize: '12px',
      ...variant.valueContainer(state),
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      color: 'white',
      ...variant.input(state),
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      fontSize: '12px',
      ...variant.placeholder(state),
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: 'white',
      fontSize: '12px',
      padding: '0',
      margin: '0',
      ...variant.singleValue(state),
    }),

    // ========= Dropdown =========
    menu: (provided: any, state: any) => ({
      ...provided,
      border: 'none',
      backgroundColor: 'transparent',
      zIndex: 500,
      ...variant.menu(state),
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '5px',
      backgroundColor: '#21242C',
      zIndex: 4500,
      ...variant.menuList(state),
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      padding: '6px 16px',
      marginBottom: '1px',
      color: 'white',
      backgroundColor: state.isSelected ? '#FFFFFF10' : '#FFFFFF08',
      ':hover': {
        backgroundColor: '#E65019',
        cursor: 'pointer',
      },
      ':active': {
        backgroundColor: '#8a2e0e',
      },
      ...variant.option(state),
    }),

    // ========= Multi values =========
    multiValue: (provided: any, state: any) => {
      const color =
        state.data.index !== undefined
          ? chroma(
              SELECT_COLORS[
                state.data.index >= SELECT_COLORS.length
                  ? SELECT_COLORS.length - 1
                  : state.data.index
              ],
            )
          : undefined;

      return {
        ...provided,
        marginRight: '4px',
        padding: '2px',
        borderRadius: '4px',
        ...(color ? {backgroundColor: color.css()} : {}),
        ...variant.multiValue(state),
      };
    },

    multiValueLabel: (provided: any, state: any) => {
      return {
        ...provided,
        color: 'white',
        ...variant.multiValueLabel(state),
      };
    },

    multiValueRemove: (provided: any, state: any) => {
      const color =
        state.data.index !== undefined
          ? chroma(SELECT_COLORS[state.data.index % SELECT_COLORS.length])
          : undefined;

      return {
        ...provided,
        color: 'white',
        ':hover': {
          ...(color ? {color: color.css()} : {}),
          backgroundColor: 'white',
        },
        ...variant.multiValueRemove(state),
      };
    },
  };

  return styles;
};

export default selectCustomStyle;
