const translations = {
  progress: {
    footer: {
      warning:
        'Por favor, absténgase de usar DaVinci durante el trabajo en AutoCut para evitar posibles problemas.',
    },
    steps: {
      global: {
        starting: 'Iniciando...',
        processing: 'Procesando...',
        ending: 'Finalizando...',
      },
      dev: {
        ping: 'Enviando ping a DaVinci Resolve...',
        pong: 'Recibiendo pong de DaVinci Resolve...',
      },
      common: {
        getSilences: 'Identificando secciones silenciosas...',
        getAudioInfos: 'Recopilando detalles de audio...',
        readAudioFile: 'Cargando archivo de audio...',
        computeRMS: 'Analizando archivo de audio...',
        convertRMSToDB: 'Analizando archivo de audio...',
        exportDrt: 'Exportando línea de tiempo...',
        extractDrt: 'Extrayendo línea de tiempo...',
        getXmlTimelineFolderPath: 'Leyendo línea de tiempo...',
        getXmlTimelineFullpath: 'Leyendo línea de tiempo...',
        loadXmlTimeline: 'Leyendo línea de tiempo...',
        writeFile: 'Guardando archivo...',
        writeXml: 'Importando línea de tiempo actualizada...',
        updateDrt: 'Importando línea de tiempo actualizada...',
        importXml: 'Importando línea de tiempo actualizada...',
      },
      podcast: {
        mainProcess: 'Editando podcast...',
        getVideoTracksWeight:
          'Configurando pistas de video de los hablantes...',
        getAudioTracks: 'Configurando pistas de audio...',
        getPodcastInterval: 'Estimando duración del podcast...',
        getPodcastDuration: 'Calculando duración del podcast...',
        renders:
          'Renderizando audio en DaVinci Resolve\n(puede tardar unos minutos)',
        getTalkingTimelines: 'Asignando tiempos de habla...',
        getTransitions: 'Planeando transiciones de cámara...',
        getCutTimecodes: 'Preparando puntos de corte...',
        cutAtTimecodes: 'Ejecutando cortes...',
      },
      silences: {
        mainProcess: 'Eliminando silencios...',
        getTimelineInfos: 'Obteniendo información de la línea de tiempo...',
        renders:
          'Renderizando audio en DaVinci Resolve\n(puede tardar unos minutos)',
        convertAudioToMono: 'Convirtiendo audio a mono...',
        getCutTimecodes: 'Configurando puntos de corte...',
        cutAtTimecodes: 'Realizando cortes...',
        deleteSilentTrackItems: 'Eliminando segmentos silenciosos...',
      },
      swearWords: {
        mainProcess: 'Censurando groserías...',
        prepareTimeline: 'Preparando la línea de tiempo...',
        getSwearWords: 'Analizando la transcripción...',
        cutAndApplyVolume: 'Aplicando filtro de volumen...',
        applyBleeps: 'Insertando pitidos...',
      },
      zoom: {
        mainProcess: 'Aplicando efectos de zoom...',
        getTimelineInfos: 'Obteniendo información de la línea de tiempo...',
        getWavData:
          'Renderizando audio en DaVinci Resolve\n(puede tardar unos minutos)',
        computeBestZoomIntervals: 'Calculando intervalos de zoom óptimos...',
        createVideoTrack: 'Creando nuevas pistas de video...',
        appendAdjustmentLayers: 'Agregar capas de ajuste',
        createZoomFusionComps: 'Importando componentes de zoom...',
      },
      captions: {
        transcript: {
          mainProcess: 'Generando transcripción...',
          get_language: 'Detectando idioma...',
          renderWav:
            'Renderizando audio en DaVinci Resolve (puede tardar unos minutos)',
          split_chunks: 'Dividiendo transcripción en segmentos...',
          uploadFile: 'Subiendo archivo...',
          getTranscript:
            'Transcribiendo audio usando IA (esto puede tardar unos minutos)',
          writeFile: 'Guardando transcripción...',
        },
        generation: {
          mainProcess: 'Añadiendo subtítulos...',
          initialization: 'Inicializando subtitulación...',
          readTranscript: 'Cargando transcripción...',
          getTimelineInfos: 'Obteniendo detalles de la línea de tiempo...',
          appendAdjustmentLayer: 'Añadiendo capa de ajuste...',
          importFusionComp: 'Importando componentes de subtítulos...',
        },
      },
    },
  },
  launchManager: {
    title: 'Configuración / Actualización',
    steps: {
      preload: {
        title: 'Cargando',
      },
      update: {
        title: 'Buscando actualizaciones',
        modal: {
          title: 'AutoCut Actualizado',
          subtitle: 'AutoCut ha sido actualizado',
          body: 'Hemos actualizado AutoCut para una experiencia mejorada. Por favor, reinicie AutoCut usando el botón de abajo.',
          cta: 'Reiniciar AutoCut',
        },
      },
      python: {
        title: 'Instalando Python',
      },
      ffmpeg: {
        title: 'Instalando FFMPEG',
      },
      resources: {
        title: 'Instalando recursos',
      },
      computeIPC: {
        title: 'Conectando a servicios de IA',
      },
      daVinciIPC: {
        title: 'Lanza AutoCut en DaVinci',
        subtitle: '¿No puedes encontrar AutoCut? Reinicia DaVinci.',
        stillNot:
          '¿Todavía no lo encuentras después de reiniciar DaVinci y AutoCut?{br}Contáctanos en <copy><embedTranslation>globals_contactMail</embedTranslation></copy>',
        ppro: '¿Quieres usar AutoCut con Premiere Pro? <a>Descárgalo aquí</a>',
      },
    },
    footer: {
      discord: '¡Únete a nuestra comunidad de Discord!',
      learnMore: '¡Aprende consejos para maximizar las funciones de AutoCut!',
    },
    errors: {
      compute:
        'No se puede conectar a los servicios de IA. Por favor, contacta al soporte.',
      davinci:
        'No se puede conectar a DaVinci Resolve. Asegúrate de que esté ejecutándose y que el script de AutoCut esté activo.',
      cta: 'Intentar de nuevo',
    },
  },
  homepage: {
    cards: {
      status: {
        trial: 'Prueba Gratis',
        upgrade: 'Actualizar Ahora',
        coming_soon: '¡Próximamente!',
        version_not_supported: 'Versión de DaVinci Resolve No Soportada',
        upgrade_to: 'Actualizar a {plan}',
      },
    },
  },
  modes: {
    podcast: {
      title: 'Auto Podcast',
      steps: {
        select: {
          title:
            'Elige una línea de tiempo con al menos 2 pistas de video y audio',
          label: 'Seleccionar Línea de Tiempo',
          descriptions: {
            '1': 'Navega a la pestaña Editar',
            '2': 'Abre la línea de tiempo que deseas editar',
          },
          cta: 'Seleccionar Línea de Tiempo Activa',
        },
        customization: {
          label: 'Configuraciones del Podcast',
          steps: {
            speakers: {
              title: 'Hablantes',
              description:
                'Asigna a cada hablante una pista de audio y nómbralos. Usa una pista por hablante. Para hablantes compartidos, usa ambos nombres (por ejemplo, "Alice & Bob").',
              header: {
                audio: 'Pista de Audio',
                name: 'Nombre del Hablante',
              },
              textInputPlaceholder: 'Ingresa el nombre del hablante...',
              trackTemplate: 'Pista A{index}',
              cta: 'Añadir Hablante',
            },
            cameras: {
              title: 'Cámaras',
              description:
                'Asigna a cada cámara una pista de video y selecciona los hablantes visibles. Una pista por cámara, múltiples hablantes permitidos.',
              header: {
                audio: 'Pista de Video',
                name: 'Hablante(s)',
              },
              speakerSelectPlaceholder: 'Selecciona hablante(s)...',
              trackTemplate: 'Pista V{index}',
              cta: 'Añadir Cámara',
            },
            cameraTime: {
              title: 'Duración de Cámara',
              description:
                'Establece la duración mínima y máxima que una cámara permanece antes de cambiar.',
              presets: {
                title: 'Preconfiguraciones Predeterminadas',
                items: {
                  calm: 'Calma',
                  paced: 'Ritmo',
                  energetic: 'Enérgico',
                  hyperactive: 'Hiperactivo',
                },
              },
              minimumCamTime: {
                title: 'Duración Mínima',
                description:
                  'Tiempo mínimo (segundos) que una cámara permanece antes de cambiar.',
                unit: 'segundos',
              },
              maximumCamTime: {
                title: 'Duración Máxima',
                description:
                  'Tiempo máximo (segundos) que una cámara permanece antes de cambiar.',
                unit: 'segundos',
              },
            },
            silentVideoClips: {
              title: 'Manejo de Clips No Usados',
              description:
                'Elige remover o deshabilitar partes no usadas del podcast.',
              items: {
                remove: 'Remover',
                disable: 'Deshabilitar',
              },
            },
          },
          cta: {
            disable:
              'Comenzar Edición de Podcast - Deshabilitar Clips No Usados',
            delete: 'Comenzar Edición de Podcast - Remover Clips No Usados',
          },
        },
      },
      error: {
        validation: {
          minimumCamTime: {
            min: 'El tiempo mínimo de cámara debe ser al menos 0 segundos',
            higherThanMax:
              'El tiempo mínimo de cámara debe ser menor que el tiempo máximo de cámara',
          },
          maximumCamTime: {
            min: 'El tiempo máximo de cámara debe ser al menos 0 segundos',
          },
          deleteUnusedClips: {
            required:
              'Selecciona una opción para remover o deshabilitar clips no usados',
          },
          speakers: {
            videoTracks: {
              min: 'Selecciona al menos una pista de video para cada hablante',
            },
            audioTrack: {
              min: 'Asigna una pista de audio a cada hablante',
            },
          },
        },
      },
    },
    silence: {
      title: 'AutoCut Silencios',
      steps: {
        noiseLevel: {
          title: 'Umbral de Ruido',
          description:
            'Establece el nivel de sonido para identificar silencios, visible abajo.',
        },
        silenceSize: {
          title: 'Duración del Silencio',
          items: {
            silence: {
              title: 'Eliminar Silencios Más Largos Que',
              description: 'Duración mínima (ms) para eliminar silencios.',
              unit: 'milisegundos',
            },
            talk: {
              title: 'Mantener Charlas Más Largas Que',
              description:
                'Duración mínima (ms) para retener segmentos de charla.',
              unit: 'milisegundos',
            },
          },
        },
        paddingSize: {
          title: 'Relleno',
          items: {
            before: {
              title: 'Relleno Antes',
              description:
                'Tiempo silencioso antes del ruido para asegurar un inicio suave de la charla.',
              unit: 'milisegundos',
            },
            after: {
              title: 'Relleno Después',
              description:
                'Tiempo silencioso después del ruido para asegurar un final suave de la charla.',
              unit: 'milisegundos',
            },
          },
        },
        transition: {
          params: {
            title: 'Transiciones',
            warning:
              'Las transiciones requieren que se eliminen los silencios.',
            default: {
              title: 'Predeterminado',
              choices: {
                none: 'Ninguno',
              },
            },
            j_l_cut: {
              title: 'J-Corte & L-Corte',
              helper:
                'Añade relleno alrededor de los cortes para transiciones suaves y crea una nueva pista de transición.',
              choices: {
                j_cut: 'J-Corte',
                l_cut: 'L-Corte',
                both: 'Ambos',
              },
            },
            premiere_pro: {
              title: 'DaVinci Resolve',
              choices: {
                constant_power: 'Potencia Constante',
              },
            },
          },
        },
        preview: {
          title: 'Vista Previa',
          description:
            'Mira los segmentos retenidos y eliminados. Usa las flechas para navegar o centra en la posición del cursor.',
          legend: {
            kept: 'Retenido',
            deleted: 'Eliminado',
            margin: 'Márgenes',
          },
          cta: 'Generar Vista Previa',
          center: 'Centrar alrededor del cursor',
        },
      },
      error: {
        validation: {
          noiseLevel: {
            negative: 'El nivel de ruido debe ser un valor negativo',
            int: 'El nivel de ruido debe ser un número',
          },
          minTimeOfSilence: {
            required: 'Se requiere la duración mínima del silencio',
            min: 'La duración mínima del silencio debe ser al menos 0 ms',
          },
          minTimeOfTalk: {
            required: 'Se requiere la duración mínima de la charla',
            min: 'La duración mínima de la charla debe ser al menos 0 ms',
          },
          marginBefore: {
            min: 'El relleno antes debe ser al menos 0 ms',
          },
          marginAfter: {
            min: 'El relleno después debe ser al menos 0 ms',
          },
        },
      },
      transition: {
        params: {
          title: 'Transiciones',
          warning: 'Las transiciones requieren que se eliminen los silencios.',
          default: {
            title: 'Predeterminado',
            choices: {
              none: 'Ninguno',
            },
          },
          progress: {
            insert: 'Añadiendo nuevas pistas de audio...',
            move: 'Ajustando audio para transiciones...',
            handling: 'Aplicando transición: {transition}',
          },
        },
      },
    },
    swearWords: {
      title: 'Filtro de Groserías Automático',
      parameters: {
        bleepSoundSelector: {
          mute: 'Silencio',
          helper: 'Selecciona el efecto de sonido de beep',
        },
      },
      footerButton: 'Eliminar groserías',
      infoText:
        'El Bleep de Palabras Groseras, gracias a la IA, analiza tu audio y agrega automáticamente {br} sonidos de beep sobre las groserías.',
    },
    zoom: {
      title: 'AutoZoom',
      parameters: {
        preview: {
          title: 'Vista Previa de Zoom',
          description: 'Establece el punto focal para el zoom',
          maximumZoom: 'Nivel máximo de zoom',
          errorMessage: 'Error al generar la vista previa',
        },
        zoomTypes: 'Estilos de Zoom',
        defaultsPresets: {
          title: 'Preconfiguraciones Predeterminadas',
          calm: 'Calma',
          paced: 'Ritmo',
          energetic: 'Enérgico',
          hyperactive: 'Hiperactivo',
        },
        zoomCoverage: 'Cobertura Total de Zoom',
        constraint: {
          title: 'Limitar Zoom a Clips Individuales',
          helper:
            'Si está habilitado, los efectos de zoom no cruzarán los límites de los clips. Si está deshabilitado, el zoom abarcará toda la selección.',
        },
        maximumZoomDuration: 'Duración Máxima de Zoom',
        smoothZooms: {
          title: 'Zooms Suaves',
          coverage: 'Cobertura de Zoom Suave',
          nervousness: 'Velocidad e intensidad del zoom',
        },
        dynamicZooms: {
          title: 'Zooms Dinámicos',
          coverage: 'Cobertura de Zoom Dinámico',
        },
      },
      types: {
        smooth: 'Suave',
        dynamic: 'Dinámico',
      },
      footerButton: 'Aplicar Zoom',
      infoText:
        'AutoZoom añade automáticamente efectos de zoom basados en niveles de audio.',
    },
    captions: {
      title: 'AutoCaptions',
      steps: {
        language: {
          button: 'Abrir Editor de Subtítulos (Estilo & Transcripción)',
        },
        customization: {
          button: 'Añadir Subtítulos',
          parts: {
            style: {
              title: 'Estilo de Subtítulo',
              modal: {
                title: 'Editar Estilo',
                apply: 'Aplicar Estilo',
                save: 'Aplicar y Guardar como Preconfiguración',
                text: {
                  title: 'Fuente',
                  font: 'Nombre de la Fuente',
                  help: '¿Cómo añadir una nueva familia de fuentes?',
                  color: 'Color del Texto',
                },
                animation: {
                  title: 'Animación',
                  wordBox: 'Caja de Texto',
                },
                outline: {
                  title: 'Contorno',
                  width: 'Grosor del Contorno',
                },
                box: {
                  title: 'Caja de Texto',
                  paddingX: 'Relleno Horizontal',
                  paddingY: 'Relleno Vertical',
                  opacity: 'Opacidad de la Caja',
                  radius: 'Radio de Esquina de la Caja',
                  disabled:
                    'La fuente seleccionada no soporta la función de caja para este idioma.',
                  tips: 'Elige una fuente que soporte el idioma seleccionado para esta función.',
                },
              },
            },
            preview: {
              title: 'Vista Previa',
              modal: {
                title: 'Posición y Tamaño',
                apply: 'Aplicar Estilo',
                fontSize: 'Tamaño de Fuente',
                nbLines: 'Número de Líneas',
                maxWidth: 'Ancho Máximo',
                help: 'La función de vista previa está en desarrollo y puede no ser precisa.',
              },
            },
            transcription: {
              title: 'Editar Transcripción',
              modal: {
                save: 'Guardar',
                select: 'Selecciona subtítulos para editar',
                placeholder:
                  'Elige segmento de transcripción para editar o personalizar',
                formatNumbers: 'Formatear Números',
                checkTranscript: {
                  title: '¿Transcripción Revisada?',
                  description: 'Aún no has revisado la transcripción.',
                  dontShowAgain: 'No recordarme de nuevo',
                },
              },
              tooltips: {
                lowConfidence: 'Palabras con baja confianza',
                merge: 'Mergear fragmentos',
                split: 'Dividir fragmentos',
              },
              emojis: {
                animated: 'Animado',
                static: 'Estático',
              },
            },
            presets: {
              noPresetSelected:
                'Selecciona una preconfiguración para previsualizar',
              noPreviewAvailable: 'No hay Vista Previa Disponible',
              title: 'Preconfiguraciones',
              delete: {
                title: 'Eliminar Preconfiguraciones',
                cancel: 'Cancelar',
                confirm: 'Eliminar Preconfiguración',
              },
              search: {
                title: 'Preconfiguraciones de la Comunidad',
                input: 'Buscar Preconfiguraciones',
                cancel: 'Cancelar',
                confirm: 'Importar Preconfiguración',
                usage: 'Usado por {count} usuarios',
                error:
                  'Error al copiar la preconfiguración. Contáctanos vía Discord o en contact@autocut.fr',
              },
              create: {
                title: 'Crear nuevo preset',
                placeholder: 'Nombre del preset (mínimo dos palabras)',
                community: 'Compartir con la comunidad',
                public: 'Público',
                cancel: 'Cancelar',
                confirm: 'Crear preset',
                alreadyExists:
                  'El preset ya existe. Elija un nombre diferente.',
              },
            },
          },
        },
      },
      error: {
        validation: {
          userPresets: {
            missing:
              'Faltan preconfiguraciones de usuario. Resetea tus configuraciones en el menú superior.',
            titleTooShort: 'El título debe tener al menos 5 caracteres',
            titleTooLong: 'El título debe tener como máximo 25 caracteres',
            titleNoSpace: 'El título debe contener al menos dos palabras',
          },
          position: {
            xPercentage: {
              min: 'La posición X debe ser al menos 0',
              max: 'La posición X debe ser como máximo 1',
            },
            yPercentage: {
              min: 'La posición Y debe ser al menos 0',
              max: 'La posición Y debe ser como máximo 1',
            },
            emojiXPercentage: {
              min: 'La posición X del emoji debe ser al menos 0',
              max: 'La posición X del emoji debe ser como máximo 1',
            },
            emojiYPercentage: {
              min: 'La posición Y del emoji debe ser al menos 0',
              max: 'La posición Y del emoji debe ser como máximo 1',
            },
          },
          text: {
            fontSize: {
              int: 'El tamaño de la fuente debe ser un número',
              min: 'El tamaño de la fuente debe ser al menos 1',
            },
            font: {
              missing:
                'La fuente seleccionada está incompleta. Re-selecciónala o elige otra.',
            },
          },
          outline: {
            width: {
              int: 'El grosor del contorno debe ser un número',
              min: 'El grosor del contorno debe ser al menos 0',
            },
          },
          formating: {
            nbLines: {
              int: 'El número de líneas debe ser un número',
              min: 'El número de líneas debe ser al menos 1',
            },
            maxWidth: {
              int: 'El ancho máximo debe ser un número',
              min: 'El ancho máximo debe ser al menos 0',
            },
          },
          textBox: {
            xPadding: {
              int: 'El relleno horizontal debe ser un número',
              min: 'El relleno horizontal debe ser al menos 0',
            },
            yPadding: {
              int: 'El relleno vertical debe ser un número',
              min: 'El relleno vertical debe ser al menos 0',
            },
            opacity: {
              int: 'La opacidad debe ser un número',
              min: 'La opacidad debe ser al menos 0',
              max: 'La opacidad no puede exceder 100',
            },
            radius: {
              int: 'El radio debe ser un número',
              min: 'El radio debe ser al menos 0',
            },
          },
        },
      },
    },
    general: {
      steps: {
        select: {
          title: 'Establecer Puntos In/Out',
          descriptions: {
            '1': 'Sin establecer Puntos In/Out, AutoCut se aplicará a toda la línea de tiempo.',
            '2': 'Silencia manualmente clips o pistas para excluirlos del análisis de audio.',
          },
          cta: 'Confirmar Puntos In/Out',
        },
        transcription: {
          title: 'Seleccionar Idioma del Clip de Audio',
        },
        customization: {
          title: 'Personalizar Configuraciones',
        },
      },
    },
  },
  auth: {
    login: {
      title: 'Ingresa la Clave de Licencia para Activar AutoCut',
      placeholder: 'Ingresa tu clave de licencia',
    },
    trial: {
      title: 'Iniciar Prueba Gratis',
      steps: {
        email: {
          title: 'Ingresa Tu Dirección de Email',
          subtitle: 'Recibe una licencia de prueba vía email',
          placeholder: 'correo@ejemplo.com',
          cta: 'Siguiente',
        },
        sendingEmail: {
          title: 'Enviando Email',
          success: 'Email enviado exitosamente a {email}',
        },
      },
    },
    errors: {
      no_key: 'Se requiere una clave de licencia',
      is_key_valid_error:
        'La validación de la clave falló. Por favor, intenta de nuevo más tarde.',
      invalid_response: 'Se recibió una respuesta inesperada del servidor.',
      cant_read_host_error:
        'No se puede recuperar el nombre de la computadora.',
      cant_read_uuid_error: 'No se puede recuperar el UUID de la computadora.',
    },
  },
  modals: {
    usageStats: {
      title: 'Estadísticas de Uso',
      select: {
        label: 'Elige un modo',
      },
      stats: {
        thisMonth: 'Este Mes',
        allTime: 'Todo el Tiempo',
        mostUsed: 'Función Más Usada',
      },
    },
    endProcess: {
      title: 'Proceso Completado',
      time: '¡Completado en {minutes} minutos y {seconds} segundos!',
      timeSaved:
        'Tiempo Ahorrado: <b>{minutesSaved}</b> minutos y <b>{secondsSaved}</b> segundos',
      timeSavedAllTime:
        '¡Tiempo Total Ahorrado con AutoCut: {hourSaved} horas y {minutesSaved} minutos! 🎉',
      feedback: 'Valoramos tu opinión sobre los resultados:',
      review: '¡Califica AutoCut!',
      captions: {
        title: '¡Subtítulos Añadidos!',
        footer:
          '¿Necesitas ajustar subtítulos o transcripción? Regresa al Editor de Subtítulos.',
        button: 'Volver al Editor de Transcripción',
        text_1: '¡Se han añadido subtítulos animados! 🎉',
        text_2: 'Edita los subtítulos si es necesario:',
        list_1:
          'Coloca el cursor de la línea de tiempo en los subtítulos deseados',
        list_2: 'Ve a la página de Fusion',
        list_3: 'Edita los subtítulos manualmente en el nodo TextPlus',
        text_3: 'Para más consejos, mira este <a>tutorial</a>.',
      },
      nextMode: {
        silence: {
          text: 'Para un audio suave, utiliza AutoCut Silences para eliminar pausas incómodas sin esfuerzo.',
          button: 'Ir a AutoCut Silences',
        },
        podcast: {
          text: 'Mejora tu podcast con AutoCut Podcast para una edición simplificada y transiciones automáticas de cámara.',
          button: 'Ir a AutoCut Podcast',
        },
        repeat: {
          text: '¡Involucra a tu audiencia! Usa AutoCut Repeat para conservar solo las partes más atractivas de tu contenido.',
          button: 'Ir a AutoCut Repeat',
        },
        zoom: {
          text: '¡Dale vida a tus videos con AutoZoom! Agrega efectos de zoom dinámicos que cautiven a tu audiencia.',
          button: 'Ir a AutoZoom',
        },
        caption: {
          text: 'Conéctate con los espectadores usando AutoCaptions. Agrega subtítulos elegantes y sincronizados para mejorar la narrativa.',
          button: 'Ir a AutoCaptions',
        },
        resize: {
          text: 'Optimiza tus videos para cualquier plataforma con AutoResize.',
          button: 'Ir a AutoResize',
        },
        broll: {
          text: 'Enriquece tu narrativa con AutoB-Roll, aprovechando la IA para añadir metraje de archivo sin problemas.',
          button: 'Ir a AutoB-Roll',
        },
        swear: {
          word: {
            text: 'Mantén un diálogo profesional con AutoProfanity Filter. Censura automáticamente el lenguaje no deseado.',
            button: 'Ir a AutoProfanity Filter',
          },
        },
      },
    },
    discord: {
      title: 'Discord',
    },
    unsupportedVersion: {
      title: 'Versión de DaVinci Resolve No Soportada',
      currentVersion: 'Versión detectada de DaVinci Resolve: {version}',
      text: 'AutoCut requiere la versión 18.6 o superior de DaVinci Resolve.',
      update: 'Actualizar en: <a>Sitio Web de DaVinci</a>',
    },
  },
  errors: {
    modal: {
      title: {
        noSelectedAudioClip: 'No se ha seleccionado clip de audio',
        noTimelineFound: 'No se encontró ninguna línea de tiempo',
        subsequenceNotReachable: 'Subsecuencia inaccesible',
        unknown: 'Ocurrió un error desconocido',
        noSpace: 'Error de almacenamiento insuficiente',
        exportUnknown: 'Error de exportación en DaVinci Resolve',
        invalidInputData: 'Error en la generación de archivos temporales',
        fileNotFound: 'Error de lectura del archivo',
        scriptNotLoaded: 'Error de carga del script AutoCut',
        ffmpegError: 'Error de lectura de archivo.',
        exportCancelled: 'Exportación cancelada',
        timelineMuted: 'Sin pistas audibles',
        noAudibleAudio: 'No se encontró audio audible',
        offline: 'Estado fuera de línea detectado',
        resourceTimeout: 'Tiempo de espera de recurso',
        backupNotFound: 'Copia de seguridad no encontrada',
        setCursorOutInterval: 'Fuera de los puntos de entrada/salida',
        emptyPath: 'Error en el análisis de selección',
        sequenceMismatch: 'Operación no se puede completar',
      },
      text: {
        noSelectedAudioClip:
          'No existe clip de audio entre los puntos de entrada y salida seleccionados',
        noTimelineFound: 'No se ha encontrado ninguna secuencia activa',
        subsequenceNotReachable:
          'No se pueden alcanzar las subsecuencias seleccionadas.',
        unknown: 'Error de AutoCut con causa desconocida.',
        noSpace: 'El almacenamiento de su dispositivo es insuficiente.',
        exportUnknown:
          'Una escasez de almacenamiento en disco causó este error.',
        invalidInputData:
          'Se produjo un error en la generación de archivos temporales.',
        fileNotFound:
          'Verifique que {filename} está en la ubicación: {filepath}',
        scriptNotLoaded:
          'Cierre todas las demás extensiones, DaVinci Resolve y vuelva a intentarlo.',
        ffmpegError: 'El archivo {filepath} está posiblemente dañado.',
        exportCancelled:
          'El análisis de audio se detuvo si la codificación fue cancelada. Intente nuevamente.',
        timelineMuted: 'Las pistas de audio seleccionadas están en silencio.',
        noAudibleAudio: 'El audio detectable no está disponible.',
        offline: 'Los servidores son inaccesibles.',
        resourceTimeout: 'No se pudieron descargar los recursos en 30 segundos',
        backupNotFound:
          'No se puede encontrar la secuencia de copia de seguridad para restaurar.',
        setCursorOutInterval:
          'La sección seleccionada está fuera de los puntos de entrada/salida.',
        emptyPath:
          'Los clips seleccionados pueden no tener rutas en su computadora.',
        sequenceMismatch:
          'Evite cambios de secuencia mientras AutoCut procesa.',
      },
      tips: {
        noSelectedAudioClip:
          'Por favor, configure correctamente sus puntos de entrada y salida',
        noTimelineFound: 'Por favor, seleccione una línea de tiempo válida',
        subsequenceNotReachable:
          'Asegúrese de que las subsecuencias en la línea de tiempo compartan nombres idénticos con las secuencias originales en el panel del proyecto.',
        unknown:
          'Intente modificar selección/configuraciones. ¿Problemas persistentes? Contáctenos.',
        noSpace: 'Libere espacio y reinicie AutoCut.',
        exportUnknown: 'Libere espacio y reinicie AutoCut.',
        invalidInputData:
          'Intente nuevamente con la misma selección. Contáctenos con su archivo si el problema persiste.',
        fileNotFound:
          'Intente nuevamente con la misma selección. Contáctenos con su archivo si el problema persiste.',
        scriptNotLoaded: '¿Problema persistente? Contáctenos.',
        ffmpegError: '¿Problema persistente? Contáctenos.',
        exportCancelled: ' ',
        timelineMuted: 'Desmutee al menos una pista.',
        noAudibleAudio:
          'Asegúrese de que los clips estén activos y sean audibles. ¿Problema persistente? Contáctenos en Discord o en contact@autocut.fr.',
        offline: 'Verifique la conexión a Internet y vuelva a intentarlo.',
        resourceTimeout:
          'Verifique la conexión a Internet y vuelva a intentarlo.',
        backupNotFound:
          'Verifique la carpeta "AutoCut-Backup" en la raíz del proyecto para la presencia de la copia de seguridad.',
        setCursorOutInterval:
          'Elimine los puntos de entrada/salida para acceder a esta sección de transcripción.',
        noSoundDetected:
          'No se detectó sonido. Verifique que el idioma coincida con los clips, que las pistas no estén en silencio y que los puntos de entrada/salida incluyan clip de sonido. {br}¿Problema persistente? Contáctenos en Discord o en contact@autocut.fr.',
        emptyPath:
          'Asegúrese de que los clips sean medios atómicos o subsecuencias.',
        sequenceMismatch:
          'Evite cambios y modificaciones de secuencia hasta que se complete el proceso de AutoCut.',
      },
    },
  },

  globals: {
    contactMail: 'contact@autocut.com',
    defaults: {
      components: {
        ProcessModal: {
          defaultStepName: 'Procesando',
        },
        KebabMenu: {
          account: 'Gestionar Clave de Licencia',
          usageStatistics: 'Estadísticas de Uso',
          bug: 'Reportar un Error',
          review: 'Calificar AutoCut',
          tuto: 'Ver Tutorial',
          logout: 'Cerrar Sesión',
          settings: 'Restablecer Configuraciones',
          featureRequest: 'Solicitar una Función',
          academy: 'Centro de Ayuda',
        },
        LicenseInfos: {
          timeLeft: 'Quedan {daysLeft} días',
          info: 'Licencia {licenceType}',
          trialButton: 'Más Información',
          paidButton: 'Gestionar Licencia',
        },
        PromoBanner: {
          promo: '¡Ahorra hasta un 20% en las próximas 24 horas!',
          shop: 'Comprar Ahora',
        },
        FileInput: {
          uploadScreenshot: 'Subir Captura de Pantalla del Problema',
        },
        LinkToUserFileInput: {
          sendLogLinkToUserFile: {
            title: 'Proporciona un enlace a tu archivo de video y audio.',
            description:
              '(Recomendado: SwissTransfer: https://www.swisstransfer.com/)',
            helper:
              "Para subir vía Swisstransfer, visita swisstransfer.com, haz clic en 'Subir Archivos', obtén un enlace y pégalo aquí.",
            end: 'Necesario para la solución de problemas y resolución.',
          },
        },
        Select: {
          'no-more': 'No hay más opciones',
          'select-all': 'Seleccionar Todo',
          placeholder: 'Elige una opción',
        },
        PreviewFrame: {
          previewOnIndicator: 'Vista Previa Centrada en el Indicador',
        },
        FontNotFoundModal: {
          title: 'Fuente no encontrada',
          description:
            'La fuente no se encontró en su sistema, por favor seleccione una nueva fuente para añadir subtítulos.',
          help: 'Puede añadir una nueva familia de fuentes siguiendo este tutorial.',
        },
      },
    },
    misc: {
      retry: 'Reintentar',
    },
    units: {
      second: 'Segundos',
      millisecond: 'Milisegundos',
      percentage: '%',
      px: 'Píxeles',
    },
  },
  old: {
    text: {
      errorSelection:
        'Selección inválida.{br}Selecciona pistas directamente desde archivos de audio/video.{br}Si se selecciona una secuencia, divide los videos dentro de ella.',
      loading: 'Ejecutando cortes...',
      Step4: {
        AutoZoom: {
          ratio: 'Coeficiente Máximo de Zoom',
          step: 'Porcentaje de Video con Zoom',
        },
        Title: 'AutoZoom (Beta)',
      },
      Step5: {
        Backup: 'Activar Respaldo',
        Title: 'Vista Previa',
      },
      autocutv2:
        'AutoCut v2 ofrece opciones avanzadas para una detección óptima de silencios.',
      autocutAi:
        'AutoCut AI detecta silencios automáticamente con una configuración mínima.',
      autocutPodcast:
        'AutoCut Podcast edita rápidamente videos de podcast basados en tu configuración de cámara.',
      autocutRepeat:
        'AutoCut Repeat elimina tomas malas, manteniendo solo las mejores partes.',
      autocutCaptions:
        'AutoCaptions añade rápidamente subtítulos estilizados a tus videos.',
      Step3: {
        Remove: 'Eliminar',
        Keep: 'Mantener',
        CenterButton: 'Centrar en el Indicador',
        Previsualisation: {
          __text__: 'Vista Previa:',
          loading: 'Cargando...',
        },
        Noise: {
          __text__: 'Nivel de Ruido',
          helper:
            'Establece el nivel de sonido para clasificar silencios. Los cambios aparecen en la vista previa abajo.',
          unit: 'Decibelios',
        },
        DeleteSilence: 'Eliminar Silencios',
        KeepSilence: {
          Yes: 'Sí',
          No: 'No',
        },
        Title: 'Ajustar Nivel de Ruido',
      },
      Step2: {
        After: 'Relleno Después',
        Before: 'Relleno Antes',
        Title: 'Relleno',
      },
      Step2Ai: {
        Title: 'Verificar Vista Previa',
      },
      Step1: {
        Talks: 'Mantener Charlas Más Largas Que',
        Silences: 'Eliminar Silencios Más Largos Que',
        Title: 'Duraciones de Silencios',
      },
      Step1Ai: {
        Silences: 'Seleccionar Agresividad de IA',
        Title: 'Agresividad de IA',
      },
      Step1Podcast: {
        Title: 'Hablantes',
      },
      step6: {
        selected: {
          clips: 'Clips Seleccionados',
        },
      },
      NoInternet: 'Se requiere una conexión a internet.',
      noClip: {
        title: 'Selecciona clips para cortar en tu línea de tiempo',
        no_selection:
          'Si AutoCut no responde, intenta reiniciar DaVinci Resolve.',
        tuto: {
          __text__: '¿Necesitas ayuda? Mira nuestro',
          link: ' tutorial en video',
        },
        loading: {
          bar: 'Cargando - Analizando secuencia...',
        },
      },
      needPProUpdate: 'DaVinci Resolve debe ser versión 15 o superior.',
      No_Key: 'Ingresa una clave de licencia',
      Login: {
        SubmitButton: 'Activar',
        SubmitButton2: 'Activar',
        PasteButton: 'Pegar',
      },
      Welcome: {
        Title: 'Bienvenido',
        freeTrial: 'Iniciar Prueba Gratis',
        UseLicence: 'Usar una Clave de Licencia',
        BuyLicence: 'Comprar una Clave de Licencia',
      },
      UseLicence: {
        Title: 'Usar una Clave de Licencia',
        Text: 'Ingresa tu clave de licencia para comenzar a usar AutoCut!',
      },
      GetLicence: {
        Title: 'Obtener una Licencia de Prueba Gratis vía Email',
        Select: '¿Cómo encontraste AutoCut?',
        Text: 'Ingresa tu email para recibir una licencia de prueba gratis',
        Button: 'Solicitar Licencia Gratis',
      },
      UseNewLicence: {
        Title: '¡Clave de Licencia Enviada a {s}!',
        Text: 'Revisa tu email, ingresa la clave de licencia y comienza a usar AutoCut. Si no la recibiste, revisa la carpeta de spam.',
        Resend: '¿No recibiste el email?',
        ResendButton: 'Reenviar a {mail}',
        ResendSucces: '¡Email enviado exitosamente!',
      },
      NoFFMPEG: 'Ocurrió un error con FFMPEG. Contacta al soporte.',
      legend: {
        kept: 'Retenido',
        deleted: 'Eliminado',
        margin: 'Márgenes',
      },
      yes: 'Sí',
      no: 'No',
      undo: {
        modal: '¿No estás satisfecho con los resultados?',
      },
      since: {
        your: {
          last: {
            visit: 'Desde tu última visita',
          },
        },
      },
      not: {
        supported: {
          modal: {
            corrupted: 'Formato de archivo no soportado: {filename}',
            speed:
              'Velocidad de clip no soportada. Usa la velocidad predeterminada.',
            multi: {
              audio:
                'Todas las pistas de audio deben estar seleccionadas en un archivo.',
              stream: 'El archivo contiene múltiples flujos de audio.',
            },
            '51_stereo': 'Audio 5.1 detectado en estéreo por DaVinci Resolve.',
            audio: {
              layout: 'Disposición de audio no soportada en el archivo.',
            },
            remote: {
              file: 'El archivo {filename} no es accesible.',
            },
          },
        },
        maintained: {
          modal:
            'Actualiza a una versión más reciente de DaVinci Resolve para usar AutoCut.',
        },
      },
      end: {
        not: {
          supported: {
            modal: {
              corrupted: 'Elige clips diferentes para cortar.',
              speed:
                'Restablece las velocidades de los clips o elige clips diferentes.',
              multi: {
                audio:
                  'Asegura el orden de audio predeterminado de DaVinci Resolve para todas las pistas.',
                stream: 'Elige clips diferentes para cortar.',
              },
              '51_stereo':
                'Selecciona clips diferentes o ajusta los canales de la secuencia.',
              audio: {
                layout:
                  'Selecciona clips diferentes o proporciona una captura de pantalla de la secuencia si el problema persiste.',
              },
              remote: {
                file: 'Asegura que los archivos estén almacenados localmente, no en la nube.',
              },
            },
          },
        },
      },
      use: {
        paid: {
          licence: 'Ingresa tu clave de licencia para activar AutoCut.',
        },
      },
      no_audios: 'El audio será analizado para detectar y eliminar silencios.',
      step1: {
        podcast: {
          title: 'Duraciones de Visualización de Cámara',
          minimum: 'Tiempo Mínimo de Cámara',
          maximum: 'Tiempo Máximo de Cámara',
        },
      },
      silences: {
        __text__: 'Silencios',
        step: {
          delete: {
            __text__: 'Eliminar {mode}',
            keep: {
              spaces: 'Eliminar {mode}, mantener espacios',
            },
          },
          keep: 'Mantener {mode}',
          mute: 'Silenciar {mode}',
        },
      },
      repetitions: 'Repeticiones',
      podcast: {
        add: {
          speaker: 'Añadir Hablante',
          camera: 'Añadir Cámara',
        },
        track: 'Pista',
        camera: {
          title: 'Cámaras',
          helper:
            'Especifica los hablantes visibles por cámara. Múltiples hablantes permitidos por cámara.',
        },
        speaker: 'Hablante',
        all: {
          speakers: 'Todos los Hablantes',
        },
      },
    },
    help: {
      bug: 'Reportar un Error',
      review: 'Calificar AutoCut',
      multipiste: '¿Necesitas ayuda multitrack? Mira nuestro tutorial',
      tuto: 'Ver Tutorial',
      academy: 'Centro de Ayuda',
      buy: 'Comprar Clave de Licencia',
      account: 'Gestionar Clave de Licencia',
      logout: 'Cerrar Sesión',
      reset: {
        settings: 'Restaurar Configuraciones',
      },
      transcription: {
        provider: {
          step: 'La calidad de la transcripción varía según el modelo elegido.',
        },
      },
    },
    bugBtnDialog: 'Envía un email al soporte en: contact@autocut.com',
    first: {
      launch: {
        error:
          'Error de inicio durante el lanzamiento de AutoCut. Por favor, reinicia.',
      },
    },
    helper: {
      Step4: {
        '1': 'Habilita AutoZoom para aplicar efectos de zoom a los clips.',
        '2': 'Coeficiente de zoom basado en valores especificados y volumen del clip.',
        '3': 'Selecciona el porcentaje de zoom deseado.',
      },
      Step5:
        'El respaldo te permite deshacer todos los cambios realizados después del corte.',
      Step1: {
        '1': 'Duración del silencio para eliminación (segundos).',
        '2': 'Duración del segmento de charla para retención (segundos).',
        podcast: {
          minium:
            'Tiempo mínimo de visualización antes del cambio de cámara (segundos).',
          maximum:
            'Tiempo máximo de visualización antes del cambio de cámara (segundos).',
        },
      },
      Step1Ai:
        'Selecciona el nivel de agresividad (1-4) para la eliminación de silencios por IA.',
      Step2: {
        '1': 'Duración del silencio antes de la charla.',
        '2': 'Duración del silencio después de la charla.',
      },
      Step3: {
        '1': 'Establece el nivel de sonido para clasificar silencios. Previsualiza los cambios abajo.',
        '2': 'Revisa los segmentos retenidos y eliminados. Usa las flechas para navegar; centra en el cursor.',
        '3': 'Los silencios serán cortados sin ser eliminados.',
      },
      Save: {
        Default: 'Guarda las configuraciones y las carga al próximo inicio.',
      },
      Undo: {
        Cut: 'Revierte el último corte, restaurando el estado anterior del clip.',
      },
      speakers: {
        podcast: {
          __text__: 'Añade y nombra hablantes, asignando sus pistas de audio.',
          audio: {
            tracks:
              'Selecciona la pista de audio para cada hablante. Una pista por hablante.',
          },
          video: {
            tracks:
              'Selecciona las pistas de video para cada hablante. Múltiples hablantes permitidos por cámara.',
          },
        },
      },
    },
    warn: {
      Step5: 'Precaución: Deshabilitar el respaldo impide deshacer.',
    },
    title: {
      Step5: {
        Backup: 'Activar Respaldo',
      },
      undo: {
        modal: '¡Hecho!',
      },
      not: {
        supported: {
          modal: {
            corrupted: 'Un formato de clip no es soportado',
            speed: 'Clips solo con velocidad predeterminada',
            multi: {
              audio:
                'AutoCut puede tener problemas con múltiples pistas de audio',
              stream: 'Múltiples flujos de audio en un clip',
            },
            '51_stereo': 'Disposición de audio 5.1 mostrada en estéreo',
            audio: {
              layout: 'Disposición de audio no soportada del clip',
            },
            remote: {
              file: 'Clip ausente del dispositivo',
            },
          },
        },
        maintained: {
          modal:
            'Las actualizaciones de AutoCut no están disponibles para versiones de DaVinci Resolve anteriores a 2021.',
        },
      },
      use: {
        trial: {
          licence: 'Revisa el email e ingresa la clave de licencia',
        },
        paid: {
          licence: 'Activar Clave de Licencia',
        },
      },
      no_audios: 'No se ha seleccionado audio',
      banner: {
        podcast: {
          beta: 'Gracias por probar el modo Podcast.',
        },
      },
      silences: {
        step: 'Gestionar {mode}',
      },
      podcast: {
        silences: {
          step: 'Eliminar/Silenciar Silencios',
        },
      },
    },
    generate: {
      preview: 'Crear una Vista Previa',
    },
    silence: {
      link: {
        tuto: 'https://www.youtube.com/watch?v=7MEK3rZQDHI',
      },
      button: {
        delete: {
          j_cut: 'J-Corte & Eliminar Silencios',
          l_cut: 'L-Corte & Eliminar Silencios',
          both: 'J&L-Corte & Eliminar Silencios',
          constant: {
            power: 'Cortar, Eliminar Silencios & Aplicar Transición de Audio',
          },
          none: 'Cortar & Eliminar {mode}',
          keep: {
            spaces: {
              none: 'Cortar {mode} & Mantener Espacios',
            },
          },
        },
        keep: {
          none: 'Cortar & Retener {mode}',
        },
        mute: {
          none: 'Cortar & Silenciar {mode}',
        },
      },
    },
    podcast: {
      link: {
        tuto: 'https://youtu.be/EgsUlSLsK8Y?si=BHwFFuuLjw8DsHYX',
      },
      getting: {
        tracks: 'Recuperando pistas...',
        silences: 'Recuperando silencios...',
      },
      edit: {
        track: 'Editando Pista {indexTrackInfo}: {current} de {max}',
      },
      final: {
        message: {
          __text__: '¡Éxito! Podcast editado exitosamente',
          error: 'Error al editar el podcast',
        },
      },
      button: {
        disable: 'Editar & Silenciar Clips No Usados',
        delete: 'Editar & Remover Clips No Usados',
      },
      not: {
        available: {
          title: 'Elige una secuencia con 2+ pistas de video y audio',
        },
      },
      minimum: {
        higher: {
          maximum: 'El tiempo mínimo de cámara debe ser menor que el máximo.',
        },
      },
      empty: {
        audio: {
          track: 'Las pistas de audio no pueden estar vacías.',
        },
        video: {
          track: 'Las pistas de video no pueden estar vacías.',
        },
      },
      preset: {
        title: 'Preconfiguraciones Predeterminadas',
        calm: 'Calma',
        paced: 'Ritmo',
        energetic: 'Enérgico',
        hyperactive: 'Hiperactivo',
      },
    },
    error: {
      UseLicence: {
        __text__: 'Desvincula claves desde otros dispositivos a través del',
        link: 'portal del cliente de AutoCut.',
      },
      modal: {
        title: {
          subsequenceNotReachable: 'Subsecuencia Inaccesible',
          unknown: 'Ocurrió un Error Desconocido',
          noSpace: 'Error de Almacenamiento Insuficiente',
          exportUnknown: 'Error de Exportación en DaVinci Resolve',
          invalidInputData: 'Error al generar archivo temporal',
          fileNotFound: 'Error al Leer Archivo',
          scriptNotLoaded: 'Error al Cargar el Script de AutoCut',
          ffmpegError: 'Error al leer el archivo.',
          exportCancelled: 'Exportación Cancelada',
          timelineMuted: 'No hay Pistas Audible',
          noAudibleAudio: 'No se Encontró Audio Audible',
          offline: 'Estado Offline Detectado',
          resourceTimeout: 'Tiempo de Espera de Recursos',
          backupNotFound: 'Respaldo No Encontrado',
          setCursorOutInterval: 'Fuera de los Puntos In/Out',
          emptyPath: 'Error de Análisis de Selección',
          sequenceMismatch: 'Operación No Puede Completar',
        },
        text: {
          subsequenceNotReachable:
            'No se pueden alcanzar las subsecuencias seleccionadas.',
          unknown: 'Error de AutoCut con causa desconocida.',
          noSpace: 'El almacenamiento de tu dispositivo es insuficiente.',
          exportUnknown: 'Falta de almacenamiento en disco causó este error.',
          invalidInputData: 'Error al generar archivo temporal.',
          fileNotFound:
            'Verifica que {filename} esté en la ubicación: {filepath}',
          scriptNotLoaded:
            'Cierra todas las demás extensiones, DaVinci Resolve y reintenta.',
          ffmpegError: 'El archivo {filepath} posiblemente está corrupto.',
          exportCancelled:
            'El análisis de audio se detuvo si la codificación fue cancelada. Reintenta.',
          timelineMuted: 'Las pistas de audio seleccionadas están silenciadas.',
          noAudibleAudio: 'No hay audio detectable disponible.',
          offline: 'Los servidores no son accesibles.',
          resourceTimeout: 'No se pudieron descargar recursos en 30 segundos',
          backupNotFound:
            'No se puede encontrar la secuencia de respaldo para restaurar.',
          setCursorOutInterval:
            'La sección seleccionada está fuera de los puntos In/Out.',
          emptyPath:
            'Los clips seleccionados podrían no tener rutas en tu computadora.',
          sequenceMismatch:
            'Evita cambios en la secuencia mientras AutoCut procesa.',
        },
        tips: {
          subsequenceNotReachable:
            'Asegúrate de que las subsecuencias en la línea de tiempo compartan nombres idénticos con las secuencias originales en el panel del proyecto.',
          unknown:
            'Intenta modificar la selección/configuraciones. ¿Problemas persistentes? Contáctanos.',
          noSpace: 'Libera espacio y reinicia AutoCut.',
          exportUnknown: 'Libera espacio y reinicia AutoCut.',
          invalidInputData:
            'Reintenta con la misma selección. Contacta con tu archivo si el problema persiste.',
          fileNotFound:
            'Reintenta con la misma selección. Contacta con tu archivo si el problema persiste',
          scriptNotLoaded: '¿Problemas persistentes? Contáctanos.',
          ffmpegError: '¿Problemas persistentes? Contáctanos.',
          exportCancelled: ' ',
          timelineMuted: 'Desmute al menos una pista.',
          noAudibleAudio:
            'Asegúrate de que los clips estén activos y audibles. ¿Problemas persistentes? Contáctanos en Discord o en contact@autocut.fr.',
          offline: 'Verifica la conexión a internet e intenta de nuevo.',
          resourceTimeout:
            'Verifica la conexión a internet e intenta de nuevo.',
          backupNotFound:
            'Verifica la presencia del respaldo en la carpeta "AutoCut-Backup" en la raíz del proyecto.',
          setCursorOutInterval:
            'Elimina los puntos In/Out para acceder a esta sección de la transcripción.',
          noSoundDetected:
            'No se detectó sonido. Verifica que el idioma coincida con los clips, que las pistas de audio no estén silenciadas y que los puntos In/Out incluyan clips de sonido. {br}¿Problemas persistentes? Contáctanos en Discord o en contact@autocut.fr.',
          emptyPath:
            'Asegúrate de que los clips sean medios atómicos o subsecuencias.',
          sequenceMismatch:
            'Evita cambios y modificaciones en la secuencia hasta que el proceso de AutoCut haya finalizado.',
        },
        lost: {
          cep: {
            title: 'Error de Conexión Perdida',
            text: {
              '1': 'Conexión con DaVinci Resolve perdida. Reinicia la extensión.',
              '2': '¿Fallo? Reinicia DaVinci Resolve.',
            },
          },
        },
      },
      language: {
        not: {
          supported: 'Soporte de idioma no disponible para este modo.',
        },
      },
    },
    retour: '¡La clave no existe!',
    countdown: {
      days: 'Días de Prueba Restantes',
    },
    saveas: {
      default: {
        settings: 'Guardar Parámetros Actuales',
      },
    },
    button: {
      no_silences: 'No se Encontraron Silencios',
      no_audios: 'Clip de Audio Seleccionado Ausente',
      SavingBackup: 'Realizando Respaldo del Proyecto...',
      RestoringBackup: 'Restaurando Proyecto...',
      ExportingAudio: 'Exportando Audio...',
      UploadingAudio: 'Subiendo Audio...',
      SearchingSilences: 'Buscando Silencios...',
      CuttingStep: {
        Audio:
          'Realizando Cortes de Audio en la Pista {numTrack}: {current} de {max}',
        Video:
          'Realizando Cortes de Video en la Pista {numTrack}: {current} de {max}',
      },
      PostCuttingStep: 'Cortes Completados...',
      PreDeletingStep: 'Buscando Clips de Silencio para Eliminar...',
      PostDeletingStep: 'Silencios Eliminados...',
      finalizing: 'Finalizando...',
      DeletingStart: 'Eliminando Silencios...',
      AssociatingStep: 'Realizando Asociación {current} de {max}',
      Success: '¡Proceso Exitoso!',
      Failure: 'Falla... Se Encontró un Problema',
      NoSilences: 'No se Detectaron Silencios',
      undo: {
        modal: 'Revertir',
      },
      not: {
        supported: {
          modal: 'Cerrar',
        },
      },
      PreZoomingStep: 'Obtaining Sub-Clips for Zoom...',
      ZoomingStep: 'Añadiendo Zoom: {current} de {max}',
      Autozoom: 'Preparando AutoZoom...',
      no_video: {
        to_zoom: 'No hay Video para Zoom.',
      },
      getSubclip: {
        step: 'Recuperando Sub-Clip para AutoZoom...',
      },
      checkSilenceSuppression: {
        step: 'Verificando Eliminación de Silencios...',
      },
      back: 'Atrás',
      DeletingStep: {
        delete: {
          Audio:
            'Eliminación de Silencios en la Pista de Audio {numTrack}: {current} de {max}',
          Video:
            'Eliminación de Silencios en la Pista de Video {numTrack}: {current} de {max}',
        },
        mute: {
          Audio:
            'Silenciando Silencio en la Pista de Audio {numTrack}: {current} de {max}',
          Video:
            'Silenciando Silencio en la Pista de Video {numTrack}: {current} de {max}',
        },
      },
      skip: 'Saltar',
      save: 'Guardar',
    },
    warning: {
      DontTouchTimeline: 'Evita Editar la Línea de Tiempo Durante el Proceso',
      undo: {
        modal:
          'Se detectaron metadatos faltantes en el análisis del archivo de audio.',
      },
      modal: {
        title: {
          ffmpeg: 'Posible Problema con FFMPEG',
        },
        text: {
          ffmpeg: 'Se detectaron metadatos faltantes en el archivo {filename}.',
        },
        tips: {
          ffmpeg:
            'Considera re-codificar el archivo si los problemas persisten.',
        },
      },
      beta: {
        version:
          'Se detectó una versión beta de DaVinci Resolve. Espera posibles problemas con AutoCut. Se recomienda probar en una versión estable reciente de DaVinci Resolve.',
      },
    },
    preview: {
      loading: {
        selection: {
          validation: 'Validando selección...',
          parsing: {
            step: 'Recuperando datos: clip {current} de {max}...',
          },
        },
        silence: {
          detection: {
            step: 'Detectando silencio: clip {current} de {max}...',
          },
        },
        drawing: 'Generando vista previa...',
      },
      frame: {
        no_sequence: 'Inicia una secuencia para ver una vista previa',
      },
    },
    contact: {
      us_title: 'Lamentamos los inconvenientes',
      us_text: {
        '2': 'Para problemas no relacionados con la edición (cuenta, clave, pago, etc.):',
        __text__:
          'Si AutoCut no funciona correctamente y deseas reportar el problema:',
      },
      us_button: 'Reportar Problema',
      us_contact: 'Contáctanos:',
      us_copy: '¡Información de contacto copiada!',
    },
    switch: {
      to_send: {
        log: {
          button: 'Alternativamente, envía un archivo de log',
        },
      },
    },
    send: {
      log: {
        title: 'Pedimos Disculpas por el Problema',
        details: 'Este archivo de log muestra cómo se procesa tu medio.',
        select:
          'Elige un archivo de log diario para ayudar a resolver el problema.',
        text: {
          area: 'Describe tu problema en detalle:',
        },
        button: 'Enviar Archivo de Log',
        success: {
          title: '¡Archivo Recibido! Investigaremos.',
        },
        thank: {
          text: 'Continúa con tu edición.',
          text2: 'Te actualizaremos una vez resuelto.',
        },
        message: {
          error: 'El mensaje debe tener al menos 10 caracteres',
        },
        screen: {
          timeline: {
            __text__:
              'Incluye una captura de pantalla de tu línea de tiempo de DaVinci Resolve.',
            before: 'Incluye una captura de pantalla antes de cortar.',
            after: 'Incluye una captura de pantalla después de cortar.',
          },
          error: {
            message: 'Incluye una captura de pantalla del mensaje de error.',
          },
        },
        trial: {
          mail: 'Proporciona el email asociado con la prueba.',
        },
        other:
          'Proporciona cualquier información adicional para ayudar a resolver el problema.',
      },
    },
    select: {
      issue: {
        title: 'Selecciona Tu Problema Abajo.',
        subtitle: 'Esto nos ayuda a resolver tu problema rápidamente.',
      },
      one: {
        audios: 'Selecciona uno o más clips de audio para usar AutoCut.',
      },
    },
    upload: {
      screenshot: 'Enviar una Captura de Pantalla',
    },
    modal: {
      feedback: {
        title: 'Retroalimentación',
        input: {
          feedback: {
            title: '¿Tus Pensamientos sobre AutoCut?',
            placeholder: 'Ingresa tu retroalimentación...',
          },
          improve: {
            title: '¿Sugerencias para Mejorar?',
            placeholder: 'Ingresa tus sugerencias...',
          },
        },
        button: {
          text: 'Enviar',
        },
        sent: {
          title: '¡Gracias por tu Retroalimentación!',
          text: '¿Tienes ideas de funciones? ¡Háznoslo saber!',
          button: {
            text: 'Solicitar Función',
          },
        },
      },
      bug: {
        report: {
          title: 'Reporte de Error',
          description: {
            placeholder: 'Describe el problema...',
            error:
              'La descripción del error debe tener al menos 20 caracteres.',
          },
          reproduce: {
            placeholder: 'Pasos para reproducir el problema...',
            error:
              'Los detalles para reproducir deben tener al menos 20 caracteres.',
          },
          blocking: '¿Este es un problema que bloquea?',
          button: {
            text: {
              __text__: 'Enviar Reporte de Error',
              success: '¡Gracias por tu reporte!',
            },
          },
          link: {
            error: 'Proporciona un enlace de archivo válido.',
          },
        },
      },
    },
    issue: {
      undo: {
        modal: '¿Encontraste problemas con los cortes?',
      },
    },
    star: {
      review: {
        title: '¡Deja una Reseña de AutoCut!',
        captions: {
          title: '¡Califica AutoCaptions!',
        },
        text: '¡Comparte tu experiencia con AutoCut!',
      },
    },
    review: {
      title: 'Proporciona Más Detalles',
      text: '¿Contento con los cortes? ¡Da retroalimentación para ayudar a mejorar AutoCut!',
      form: {
        placeholder: 'Ingresa tu retroalimentación...',
      },
      button: 'Enviar',
      thank: {
        title: '¡Gracias por tu Retroalimentación!',
        text: 'Continúa con tu edición.',
      },
    },
    exchange: {
      title: '¡Obtén 7 Días Extra de Prueba!',
      text: '¡Califica AutoCut en Adobe Exchange para recibir 7 días adicionales de prueba!',
      button: 'Calificar AutoCut en Adobe Exchange',
      info: "¿Instalado directamente desde nuestro sitio? No es necesario reinstalar. Simplemente haz clic en 'Gratis' para comenzar a calificar.",
    },
    licence: {
      trial: 'Prueba',
      paid: 'Pagado',
      info: 'Licencia {licenceType}',
      time: {
        left: 'Quedan {daysLeft} Días',
      },
    },
    trial: {
      promo: {
        text: 'Ahorra hasta un 20% en 24 Horas',
      },
      resend: {
        error:
          'Error al reenviar la clave de prueba. Por favor, intenta de nuevo más tarde.',
      },
      expired: {
        modal: {
          title: 'Prueba Expirada.',
          text1:
            'Tu prueba ha expirado. Suscríbete para continuar usando AutoCut.',
          text2: 'Visita tu panel de AutoCut en:',
          text3: 'Suscríbete para una clave de licencia.',
          text4: 'Para soporte, contacta a: {supportEmail}',
          button: 'Suscribirse con 20% de Descuento',
        },
      },
      title: 'Prueba Gratis',
      stepper: {
        onboarding: 'Ayúdanos a Mejorar AutoCut',
        email: 'Ingresa Tu Email',
        sending: {
          email: 'Enviando Email',
        },
        login: 'Ingresa Clave de Licencia',
      },
      onboarding: {
        subtitle:
          'Comparte insights para ayudar a desarrollar nuevas funciones de DaVinci Resolve',
        discover: '¿Cómo encontraste AutoCut?',
        job: {
          status: 'Estado Actual del Trabajo',
        },
        type: {
          creation: 'Tipos de Proyectos de DaVinci Resolve',
        },
      },
      sending: {
        email: {
          success: 'Email enviado exitosamente a {email}',
        },
      },
      task: {
        captions: 'Usa AutoCaptions: <link>Más Información</link>',
        podcast: 'Usa AutoCut Podcast: <link>Más Información</link>',
        zoom: 'Usa AutoZoom: <link>Más Información</link>',
        repeat: 'Usa AutoCut Repeat: <link>Más Información</link>',
        broll: 'Usa AutoB-Roll: <link>Más Información</link>',
        swear: {
          word: 'Usa Filtro de Profanidades: <link>Más Información</link>',
        },
        preset:
          'Crea una nueva preconfiguración para AutoCaptions, AutoZoom, o AutoResize',
        testimonial:
          'Deja un Testimonial (usando el mismo email): <link>Dejar Testimonial</link>',
      },
    },
    shop: {
      now: 'Comprar Ahora',
    },
    back: {
      to_autocut: 'Volver a AutoCut',
    },
    'discover-option-youtube-video': 'Video de YouTube',
    'discover-option-youtube-comment': 'Comentario de YouTube',
    'discover-option-reddit': 'Reddit',
    'discover-option-google': 'Google',
    'discover-option-facebook': 'Facebook',
    'discover-option-friend': 'Amigo',
    'discover-option-school': 'Escuela',
    'discover-option-other': 'Otro',
    link: {
      not: {
        supported: {
          modal: {
            __text__: 'Para más información y soluciones, visita ',
            no_solution: 'Para aclaración de errores, consulta ',
            end: ', nuestro sitio de documentación y tutoriales',
          },
        },
      },
    },
    text1: {
      use: {
        trial: {
          licence: 'Se ha enviado un email a ({email})',
        },
      },
    },
    text2: {
      use: {
        trial: {
          licence: 'Revisa tu carpeta de spam o promociones si falta el email.',
        },
      },
    },
    placeholder: {
      use: {
        licence: 'Ingresa Tu Clave de Licencia',
      },
    },
    "can't": {
      reach: {
        server: {
          error:
            'No se puede alcanzar el servidor. Por favor, intenta de nuevo más tarde.',
        },
      },
      read: {
        uuid: {
          error: 'No se puede leer el UUID de la computadora.',
        },
        host: {
          error: 'No se puede leer el nombre de la computadora.',
        },
      },
    },
    asking: {
      key: {
        error:
          'Error al verificar la clave con el servidor. Por favor, intenta de nuevo más tarde.',
      },
    },
    sending: {
      log: {
        file: {
          error:
            'Error al enviar el log diario. Por favor, intenta de nuevo más tarde.',
        },
      },
    },
    is_key: {
      valid: {
        error:
          'Error al validar la clave. Por favor, intenta de nuevo más tarde.',
      },
    },
    base: {
      error: {
        message: 'Ocurrió un error ',
      },
    },
    invalid: {
      response: {
        error: 'Se recibió una respuesta inesperada del servidor.',
      },
    },
    description: {
      banner: {
        podcast: {
          beta: 'En desarrollo; para problemas o sugerencias, contáctanos en contact@autocut.com',
        },
      },
    },
    header: {
      trial: {
        button: 'Más Información',
      },
      paid: {
        button: 'Gestionar',
      },
      speakers: {
        name: 'Nombre del Hablante',
        track: 'Pista de Audio del Hablante',
      },
      cameras: {
        name: {
          __text__: 'Pista de Video de la Cámara',
          helper:
            'Selecciona la pista de video para cada cámara, una pista por cámara.',
        },
        speakers: {
          __text__: 'Hablante(s) Visible(s)',
          helper:
            'Selecciona qué hablantes son visibles en esta cámara. Múltiples hablantes permitidos.',
        },
      },
    },
    game: {
      saved: {
        hours: 'Horas Guardadas {hourSavedText},',
      },
      have: {
        a_break: '¡Toma un merecido descanso!',
      },
    },
    onboarding: {
      skip: 'Saltar Onboarding',
      close: 'Cerrar',
      previous: 'Anterior',
      next: 'Siguiente ({current}/{max})',
      onboarding: {
        step1: {
          title: '¡Bienvenido a AutoCut!',
          body: 'Durante tu primer uso, AutoCut te guiará a través de varias funciones para una edición sin problemas. ¡Empecemos!',
        },
        step2: {
          title: 'Clave de Prueba Gratis',
          body: 'Explora todas las funciones con una clave de prueba, válida por 14 días.',
        },
        step3: {
          title: 'Clave de Licencia',
          body: 'Ingresa tu clave licenciada (de prueba o pagada) para acceder a todas las herramientas de AutoCut.',
        },
        step4: {
          title: 'Comprar una Clave de Licencia',
          body: 'Compra una clave de licencia a través del botón, que te redirige a nuestro sitio con detalles de compra.',
        },
      },
      modeSelection: {
        step1: {
          title: 'Modos de AutoCut',
          body: 'Elige un modo que se ajuste a tus necesidades de edición de las opciones disponibles.',
        },
        step2: {
          title: '¿Necesitas Ayuda?',
          body: 'Pasa el cursor sobre los elementos de la interfaz para ver tooltips y aprender más.',
        },
      },
      legacy: {
        step1: {
          title: 'AutoCut V2',
          body: 'AutoCut V2 detecta silencios basados en niveles de ruido de audio. Se requiere una configuración inicial.',
        },
        step2: {
          title: 'Nivel de Ruido',
          body: 'Usa el control deslizante para establecer el nivel mínimo de ruido clasificado como silencio.',
        },
        step3: {
          title: 'Próximos Pasos',
          body: 'Navega a través de los pasos usando las flechas y explora más funciones de AutoCut.',
        },
        step4: {
          title: 'Generando Vista Previa',
          body: 'Exporta audio antes de procesar. Haz clic en "Generar Vista Previa" para visualizar los cortes de audio.',
        },
        step6: {
          title: 'Hora de Cortar',
          body: 'Revisa la vista previa y procede con los cortes si estás satisfecho.',
        },
        step7: {
          title: 'Hora de Cortar',
          body: 'Selecciona tus opciones de corte preferidas.',
        },
        step8: {
          title: 'Hora de Cortar',
          body: '¡Relájate y deja que AutoCut maneje la edición!',
        },
      },
      podcast: {
        step1: {
          title: 'AutoCut Podcast',
          body: 'Edita rápidamente tus videos de podcast con AutoCut Podcast.',
        },
        step2: {
          title: 'Hablantes',
          body: 'Añade hablantes usando el botón "Añadir Hablante". Asigna una pista por hablante.',
        },
        step3: {
          title: 'Hablantes',
          body: 'Configura la configuración de cada hablante según sea necesario.',
        },
        step4: {
          title: 'Nombre del Hablante',
          body: 'Asigna un nombre a cada hablante para su identificación.',
        },
        step5: {
          title: 'Selección de Pista de Video',
          body: 'Selecciona las pistas de video que muestran a cada hablante.',
        },
        step6: {
          title: 'Selección de Pista de Audio',
          body: 'Asigna una pista de audio a cada hablante. Solo una pista por hablante.',
        },
        step7: {
          title: 'Editar Tiempo',
          body: 'Define hablantes y procede con la edición.',
        },
        step8: {
          title: 'Editar Tiempo',
          body: 'Usa la flecha para seleccionar opciones de edición.',
        },
        step9: {
          title: 'Editar Tiempo',
          body: '¡Relájate y deja que AutoCut maneje el proceso de edición.',
        },
      },
      title: 'Bienvenido a AutoCut',
      subtitle: 'Tu Extensión Definitiva para DaVinci Resolve',
    },
    start: {
      transcription: 'Iniciar Transcripción',
    },
    no_more: {
      credits: 'Créditos Agotados',
    },
    file: {
      too: {
        long: 'El archivo de audio es demasiado largo',
      },
      sent: 'Archivo subido, esperando transcripción...',
    },
    please: {
      wait1: 'Esperando transcripción de audio...',
      wait2:
        'Este proceso puede tardar unos minutos, dependiendo de la longitud.',
      wait3: 'Subiendo archivo:',
    },
    language: {
      selection: {
        step: {
          title: {
            __text__: 'Idioma de Audio',
            helper: 'Especifica el idioma del clip de audio',
          },
        },
        french: {
          __text__: 'Francés',
          beta: 'Francés (Beta)',
        },
        danish: 'Danés',
        dutch: 'Neerlandés',
        english: {
          __text__: 'Inglés',
          united: {
            states: 'Inglés - Estados Unidos',
          },
        },
        flemish: 'Flamenco',
        german: {
          __text__: 'Alemán',
          beta: 'Alemán (Beta)',
        },
        hindi: {
          __text__: 'Hindi',
          beta: 'Hindi (Beta)',
        },
        italian: 'Italiano',
        japanese: 'Japonés',
        korean: 'Coreano',
        norwegian: {
          __text__: 'Noruego',
          nynorsk: 'Noruego Nynorsk',
        },
        polish: 'Polaco',
        portuguese: {
          __text__: 'Portugués',
          brazil: 'Portugués - Brasil',
          'brazil—beta': 'Portugués - Brasil (Beta)',
        },
        'portuguese—beta': 'Portugués (Beta)',
        spanish: {
          __text__: 'Español',
          latin: {
            america: 'Español - América Latina',
            'america—beta': 'Español - América Latina (Beta)',
          },
        },
        'spanish—beta': 'Español (Beta)',
        swedish: 'Sueco',
        tamil: 'Tamil',
        chinese: 'Chino',
        russian: 'Ruso',
        turkish: 'Turco',
        catalan: 'Catalán',
        arabic: {
          __text__: 'Árabe',
          algeria: 'Árabe - Argelia',
          bahrain: 'Árabe - Bahréin',
          egypt: 'Árabe - Egipto',
          iraq: 'Árabe - Iraq',
          israel: 'Árabe - Israel',
          jordan: 'Árabe - Jordania',
          kuwait: 'Árabe - Kuwait',
          lebanon: 'Árabe - Líbano',
          mauritania: 'Árabe - Mauritania',
          morocco: 'Árabe - Marruecos',
          oman: 'Árabe - Omán',
          qatar: 'Árabe - Qatar',
          saudi: {
            arabia: 'Árabe - Arabia Saudita',
          },
          palestine: 'Árabe - Palestina',
          syria: 'Árabe - Siria',
          tunisia: 'Árabe - Túnez',
          united: {
            arab: {
              emirates: 'Árabe - Emiratos Árabes Unidos',
            },
          },
          yemen: 'Árabe - Yemen',
        },
        indonesian: 'Indonesio',
        finnish: 'Finlandés',
        vietnamese: 'Vietnamita',
        hebrew: 'Hebreo',
        ukrainian: 'Ucraniano',
        greek: 'Griego',
        malay: 'Malayo',
        czech: 'Checo',
        romanian: 'Rumano',
        hungarian: 'Húngaro',
        thai: 'Tailandés',
        urdu: 'Urdu',
        croatian: 'Croata',
        bulgarian: 'Búlgaro',
        lithuanian: 'Lituano',
        latin: 'Latín',
        maori: 'Maorí',
        malayalam: 'Malayalam',
        welsh: 'Galés',
        slovak: 'Eslovaco',
        telugu: 'Telugu',
        persian: 'Persa',
        latvian: 'Letón',
        bengali: 'Bengalí',
        serbian: 'Serbio',
        azerbaijani: 'Azerbaiyano',
        slovenian: 'Esloveno',
        kannada: 'Canarés',
        estonian: 'Estonio',
        macedonian: 'Macedonio',
        breton: 'Bretón',
        basque: 'Vasco',
        icelandic: 'Islandés',
        armenian: 'Armenio',
        nepali: 'Nepalí',
        mongolian: 'Mongol',
        bosnian: 'Bosnio',
        kazakh: 'Kazajo',
        albanian: 'Albanés',
        swahili: 'Swahili',
        galician: 'Gallego',
        marathi: 'Marathi',
        punjabi: 'Panyabí',
        sinhala: 'Cingalés',
        khmer: 'Khmer',
        shona: 'Shona',
        yoruba: 'Yoruba',
        somali: 'Somalí',
        afrikaans: 'Afrikaans',
        occitan: 'Occitano',
        georgian: 'Georgiano',
        belarusian: 'Bielorruso',
        tajik: 'Tayiko',
        sindhi: 'Sindhi',
        gujarati: 'Gujarati',
        amharic: 'Amárico',
        yiddish: 'Yidis',
        lao: 'Laosiano',
        uzbek: 'Uzbeko',
        faroese: 'Feroés',
        haitian: {
          creole: 'Criollo Haitiano',
        },
        pashto: 'Pachto',
        turkmen: 'Turcomano',
        maltese: 'Maltés',
        sanskrit: 'Sánscrito',
        luxembourgish: 'Luxemburgués',
        burmese: 'Birmán',
        tibetan: 'Tibetano',
        tagalog: 'Tagalo',
        malagasy: 'Malgache',
        assamese: 'Asamés',
        tatar: 'Tártaro',
        hawaiian: 'Hawaiano',
        lingala: 'Lingala',
        hausa: 'Hausa',
        bashkir: 'Bashkir',
        javanese: 'Javanés',
        sundanese: 'Sundanés',
      },
    },
    big: {
      sequence: {
        info: {
          message:
            '¡Tu secuencia tiene muchos clips! Puede tardar más en cargar. Selecciona solo los clips necesarios y córtalos en una secuencia separada.',
        },
      },
    },
    homepage: {
      title: 'Página Principal',
      comming: {
        soon: '¡Próximamente!',
      },
    },
    autozoom: {
      dynamism: {
        __text__: 'Dinamismo',
        normal: 'Normal',
        high: 'Alto',
        extreme: 'Extremo',
        custom: 'Personalizado:',
      },
      button: {
        __text__: 'Aplicar Zoom',
        message: {
          step1: 'Preparando AutoZoom...',
          step2: 'Extrayendo Datos de Audio...',
          step3: 'Calculando Intervalos de Zoom...',
          step4: 'Aplicando Zoom {current} de {max}...',
        },
      },
      success: '¡Éxito! Efectos de zoom aplicados exitosamente.',
      info: {
        text: 'AutoZoom añade efectos de zoom dinámicos a los clips seleccionados basados en niveles de audio.',
      },
      invalid: {
        selection: {
          screen: {
            title: 'Selecciona clips de audio y video en tu línea de tiempo',
            text1:
              'Selecciona clips de la línea de tiempo para aplicar AutoZoom.',
          },
        },
      },
      anchor: {
        title: 'Establecer Punto de Anclaje',
        helper:
          'El punto de anclaje es donde se enfoca el zoom. Las cajas coloreadas muestran los efectos de zoom máximos y mínimos.',
      },
      dual: {
        range: {
          title: 'Definir Rango de Coeficiente de Zoom',
          helper:
            'Los niveles de zoom se basan en estos valores y el volumen máximo del clip.',
        },
      },
      preview: {
        on_indicator: 'Vista Previa Centrada en el Indicador',
        error: {
          message: 'Error al cargar la vista previa',
          button: 'Reintentar',
        },
      },
      min: {
        zoom: {
          title: 'Duración Mínima de Zoom',
          helper: 'Los efectos de zoom no serán más cortos que esta duración.',
        },
      },
      max: {
        zoom: {
          title: 'Duración Máxima de Zoom',
          helper: 'Los efectos de zoom no excederán esta duración.',
        },
      },
      constraint: {
        zoom: {
          title: 'Limitar Zoom a Clips',
          helper:
            'Si está habilitado, los efectos de zoom se confinarán a clips individuales. Si está deshabilitado, abarcarán toda la selección.',
        },
      },
      zoom: {
        types: {
          __text__: 'Estilos de Zoom',
          helper:
            'Elige entre Suave (zoom gradual) y Dinámico (zoom inmediato).',
        },
        coverage: {
          __text__: 'Cobertura Total de Zoom',
          helper: 'Porcentaje de zoom aplicado.',
        },
      },
      smooth: {
        __text__: 'Suave',
        zoom: {
          __text__: 'Zooms Suaves',
          coverage: 'Cobertura de Zoom Suave',
          nervousness: {
            __text__: 'Nerviosismo',
            helper:
              'Valores bajos para zooms más lentos y suaves; valores altos para zooms más rápidos e intensos.',
          },
        },
      },
      dynamic: {
        __text__: 'Dinámico',
        zoom: {
          __text__: 'Zooms Dinámicos',
          coverage: 'Cobertura de Zoom Dinámico',
        },
      },
    },
    zoom: {
      preset: {
        title: 'Preconfiguraciones Predeterminadas',
        calm: 'Calma',
        paced: 'Ritmo',
        energetic: 'Enérgico',
        hyperactive: 'Hiperactivo',
      },
    },
    repeat: {
      info: {
        banner:
          'AutoCut Repeat elimina segmentos repetitivos. Usa las tijeras para alternar entre cortar o mantener partes para mayor precisión.',
      },
      text: {
        step: {
          edit: {
            transcript: 'Corregir Transcripción',
          },
        },
      },
      invalid: {
        selection: {
          screen: {
            title:
              'Selecciona un clip de audio de la línea de tiempo (y opcionalmente video) para AutoCut Repeat.',
            text1:
              'Selecciona un clip de audio de la línea de tiempo (y opcionalmente video) para AutoCut Repeat.',
          },
        },
      },
      select: {
        algorithm: 'Elegir Algoritmo',
      },
      precise: {
        algorithm: {
          info: 'Nuestro nuevo algoritmo eficiente procesa aproximadamente un minuto de video por minuto.',
        },
      },
      fast: {
        algorithm: 'Rápido',
      },
      toggle: {
        cut: {
          button: {
            text: 'Alternar Corte',
          },
        },
      },
      play: {
        button: {
          text: 'Reproducir',
        },
      },
    },
    captions: {
      step: {
        customization: 'Personalizar Subtítulos',
        script: 'Seleccionar Clips e Idioma',
        transcription: 'Editar Transcripción',
      },
      script: {
        transcript: {
          part: 'Transcripción',
        },
        sequence: {
          part: 'Secuencia',
          name: 'Nombre',
          track: {
            clip: {
              unit: {
                __text__: '{nbClips} Clips | {from} → {to}',
                detail: '{clipName} | {from} → {to}',
              },
            },
          },
        },
      },
      transcript: {
        action: 'Añadir Subtítulos',
        coming: {
          soon: '¡El Editor de Subtítulos próximamente! Actualmente, edita subtítulos a través del panel de Gráficos Esenciales. Selecciona subtítulos en la línea de tiempo y modifícalos en la pestaña de edición del panel.',
        },
        transcription: {
          part: 'Transcripción',
        },
        info: 'Bienvenido al Editor de Subtítulos – edita opcionalmente subtítulos directamente en la línea de tiempo de DaVinci Resolve después de añadirlos.',
        add: {
          emojis: {
            cta: 'Crear Emojis',
          },
        },
      },
      text: {
        noClip: {
          title: 'Selecciona Clips para Subtítulos',
          no_selection:
            'Si AutoCut no responde, intenta reiniciar DaVinci Resolve.',
        },
      },
      process: {
        transcript: {
          button: 'Procesar',
        },
      },
      customization: {
        info: 'AutoCaption está en beta y se espera que se estabilice para el 10 de noviembre.',
        back: {
          button: 'Eliminar Transcripción y Volver',
        },
        example: {
          __text__:
            'Vista Previa <opacity50><i>Arrastra para ajustar la posición del subtítulo/emoji</i></opacity50>',
          text: 'AutoCaption añade subtítulos visibles en la vista previa. Personaliza las opciones dentro de AutoCaptions; edita la transcripción si es necesario. Los subtítulos se añaden instantáneamente a la línea de tiempo.',
        },
        animation: {
          __text__: 'Animación',
          helper: 'Controla los efectos de animación del texto',
          color: {
            __text__: 'Resaltar con <color>Audio</color>',
            text: {
              color: 'Color del Texto',
            },
            before: {
              highlight: {
                color: 'Color de Pre-Resaltado',
              },
            },
            after: {
              highlight: {
                color: 'Color de Post-Resaltado',
              },
            },
          },
          advanced: {
            color: {
              options: 'Opciones Avanzadas de Color',
            },
          },
          word: {
            box: 'Texto con <color>Caja</color>',
          },
          bold: {
            with: {
              text: 'El texto está <b>en negrita</b>',
            },
          },
          floating: {
            text: 'El texto está flotando',
          },
        },
        transition: {
          __text__: 'Transición',
          helper:
            'Gestiona las transiciones de los subtítulos; la duración puede no coincidir con el ejemplo.',
          effect: {
            zoomIn: 'Zoom-In',
            blurIn: 'Blur-In',
          },
        },
        outline: {
          __text__: 'Contorno',
          helper: 'Gestiona estilos de contorno',
          color: 'Color del Contorno',
          width: 'Grosor del Contorno',
        },
        font: {
          __text__: 'Fuente',
          helper: 'Gestiona estilos de fuente',
          font: 'Nombre de la Fuente',
          help: '¿Cómo añadir una nueva familia de fuentes?',
          size: 'Tamaño de Fuente',
          weight: 'Peso de Fuente',
          color: 'Color de Fuente',
        },
        formating: {
          __text__: 'Formato de Texto',
          helper: 'Controla las opciones de formato de texto',
          ponctuation: 'Eliminar Puntuación',
          uppercase: 'Mayúsculas',
          bold: 'Negrita',
          italic: 'Cursiva',
          glow: {
            __text__: 'Resplandor',
            color: 'Color del Resplandor',
            intensity: 'Intensidad del Resplandor',
          },
        },
        transform: {
          __text__: 'Transformar',
          x: 'Posición X',
          y: 'Posición Y',
          helper: 'Ajusta la posición y rotación del subtítulo',
          position: {
            __text__: 'Posición Vertical',
            emoji: 'Posición Vertical del Emoji',
            info: 'Mueve el texto en la vista previa',
          },
          rotation: 'Rotación',
        },
        multiple: {
          lines: 'Múltiples Líneas',
        },
        box: {
          __text__: 'Cajas',
          helper: 'Opciones de estilo para cajas de texto',
        },
        boxes: {
          padding: {
            x: 'Relleno Horizontal',
            y: 'Relleno Vertical',
          },
          opacity: 'Opacidad de la Caja',
          radius: 'Radio de Esquina',
          color: 'Color de la Caja',
        },
        emojis: {
          __text__: 'Emojis',
          helper: 'Emojis generados por IA',
          size: 'Tamaño del Emoji',
        },
        shadow: 'Sombra',
        transcript: 'Transcripción',
        action: 'Avanzar',
        select: {
          chunk: 'Elige segmento de transcripción para editar o personalizar',
        },
        delete: {
          caption: {
            modal: {
              title: 'Confirmar Eliminación de Subtítulo',
              cancel: 'No, Mantenerlo',
              confirm: 'Sí, Eliminarlo',
            },
          },
        },
        get: {
          presets: {
            number: {
              error:
                'Error al recuperar el número de preconfiguraciones. Contacta con nosotros vía Discord o en contact@autocut.com',
            },
            error:
              'Error al recuperar las preconfiguraciones. Contacta con nosotros vía Discord o en contact@autocut.com',
          },
          public: {
            presets: {
              error:
                'Error al recuperar las preconfiguraciones públicas. Contacta con nosotros vía Discord o en contact@autocut.com',
            },
          },
        },
        presets: {
          title: 'Preconfiguraciones',
          save: {
            new: {
              preset: 'Guardar Configuraciones Actuales como Preconfiguración',
            },
          },
          view: {
            more: 'Ver Más',
            less: 'Ver Menos',
          },
        },
        save: {
          preset: {
            info: {
              text: '¡Guarda estas configuraciones como una preconfiguración para uso futuro!',
            },
          },
        },
      },
      editor: {
        title: 'Editor de Subtítulos',
        description: {
          title: 'Selecciona Subtítulos para Editar',
        },
        chunk: {
          low: {
            confidence: {
              tooltip: 'Palabras con baja confianza',
            },
          },
          merge: {
            tooltip: 'Unir Segmentos',
          },
          split: {
            tooltip: 'Dividir Segmentos',
          },
        },
        sound: {
          play: 'Habilitar Reproducción de Sonido',
        },
      },
      fontSelect: {
        notFoundFontModal: {
          title: 'Fuente Faltante',
          message:
            'La fuente <i><b>{fontLabel}</b></i> no está disponible. Se usará la fuente predeterminada.',
          helper: 'Añade nuevas fuentes usando este tutorial.',
        },
      },
    },
    version: {
      not: {
        supported: 'Versión No Soportada',
      },
    },
    transcript: {
      usage: {
        unit: 'Minutos',
      },
      upgrade: {
        button: 'Actualizar a IA para {mode}',
      },
      too: {
        short: {
          error:
            'La transcripción puede ser demasiado corta o el idioma no soportado. Si el problema persiste, contacta con nosotros en contact@autocut.com.',
        },
      },
    },
    feature: {
      request: {
        __text__: 'Solicitud de Función',
        form: {
          placeholder:
            'Describe tu solicitud de función para una mejor consideración.',
        },
        screenshot:
          'Adjunta capturas de pantalla o videos para mayor claridad:',
        button: 'Enviar Solicitud de Función',
        success: {
          title: '¡Enviado! 🥳',
          body: '¡Gracias por ayudar a mejorar AutoCut! Revisaremos tu solicitud prontamente.',
        },
        error: {
          title: 'Vaya, algo salió mal... 🤕',
          body: 'Lamentamos el inconveniente. Por favor, intenta de nuevo más tarde o contacta con nosotros en contact@autocut.com.',
        },
      },
    },
    with: {
      emojis: 'Con Emojis',
    },
    resize: {
      invalid: {
        selection: {
          screen: {
            title: 'Establecer Puntos In & Out en Tu Línea de Tiempo',
            text1:
              'Para usar AutoResize, establece Puntos In & Out en tu línea de tiempo.',
            cta: 'Establecer Puntos In & Out para Toda la Secuencia',
          },
        },
      },
      text: {
        info: 'AutoResize ajusta y reencuadra automáticamente tu secuencia para formatos óptimos de redes sociales.',
      },
      in_out: {
        step: {
          title: 'Puntos In/Out',
          helper:
            'Asegúrate de que los Puntos In/Out coincidan con la sección deseada.',
        },
      },
      preset: {
        step: {
          title: 'Preconfiguraciones',
          helper:
            'Elige una preconfiguración para redimensionar tu secuencia o crea una personalizada. Ve las dimensiones abajo.',
        },
        undefined: 'Selecciona una preconfiguración.',
      },
      watermark: {
        step: {
          title: 'Marca de Agua',
          helper:
            'Añade una marca de agua a tu secuencia en la esquina inferior derecha.',
          text: 'Subir Tu Marca de Agua',
        },
      },
      button: 'Generar Clip Social',
      reframe: {
        undefined: 'Selecciona la velocidad "Seguir Sujeto".',
        step: {
          title: 'Seguir Sujeto',
          helper: 'Elige la velocidad para que la cámara siga al sujeto.',
          slower: 'Lento',
          default: 'Normal',
          faster: 'Rápido',
        },
      },
      backup: 'Realizando Respaldo de Tu Secuencia...',
      create: {
        new: {
          sequence: 'Creando Nueva Secuencia...',
        },
      },
      resizing: {
        sequence: 'Redimensionando Nueva Secuencia...',
      },
      adding: {
        watermark: 'Añadiendo Marca de Agua...',
      },
      cleaning: {
        sequence: 'Limpiando Secuencia...',
      },
    },
    broll: {
      invalid: {
        selection: {
          screen: {
            title: 'Selecciona Clips Base para B-Roll',
            text1: 'Para AutoB-Roll, selecciona al menos un clip con audio.',
          },
        },
      },
      text: {
        info: 'AutoB-Roll usa IA para analizar audio y añadir automáticamente B-Rolls relevantes de Pexels.',
      },
      duration: {
        step: {
          title: 'Configuraciones de B-Roll',
          minimum: {
            title: 'Duración Mínima de B-Roll',
            helper: 'Duración mínima para cada B-Roll.',
          },
          maximum: {
            title: 'Duración Máxima de B-Roll',
            helper: 'Duración máxima para cada B-Roll.',
          },
        },
      },
      choice: {
        step: {
          title: 'Selección de B-Roll',
        },
      },
      no_broll: {
        screen: {
          title: 'Transcripción procesada, pero no se encontró B-Roll.',
          text1: 'Para usar AutoB-Roll, selecciona un clip de audio con habla.',
          text2:
            'Verifica el idioma de la transcripción y asegúrate de que las pistas de audio no estén silenciadas.',
        },
      },
      button: {
        __text__: 'Añadir B-Rolls',
        next: 'Siguiente',
      },
      exporting: 'Exportando Clips de Audio Seleccionados...',
      analyse: 'Analizando Audio...',
      getting: {
        broll: 'Recuperando B-Rolls... (puede tardar unos minutos)',
      },
      transcript: {
        finished: 'Transcripción Generada',
      },
      step: {
        parameters: 'Configuraciones de B-Roll',
        language: 'Seleccionar Clips e Idioma',
        brolls: 'Editar Selección de B-Roll',
      },
      adding: {
        broll: 'Añadiendo B-Rolls...',
      },
      restore: 'Restaurando Tu Secuencia...',
      backup: 'Realizando Respaldo de Tu Secuencia...',
      credit: {
        modal: {
          title: 'Créditos de B-Roll:',
          provided: {
            by: 'Proporcionado por Pexels',
          },
          link: 'Enlace',
          button: 'Copiar al Portapapeles',
        },
      },
      search: {
        modal: {
          title: 'Seleccionar B-Roll',
          input: {
            placeholder: 'Buscar B-Rolls',
          },
          searching: 'Buscando B-Rolls...',
        },
      },
    },
    discordCta: {
      title: 'Discord',
    },
    double: {
      click: {
        to_edit: 'Doble Clic para Editar',
      },
    },
    swear: {
      words: {
        invalid: {
          selection: {
            screen: {
              title: 'Selecciona Clips para Filtrar Profanidades',
              text1: 'Selecciona un clip con audio para AutoProfanity Filter.',
            },
          },
        },
        text: {
          info: 'AutoProfanity Filter usa IA para detectar y censurar automáticamente profanidades con sonidos de bleep.',
        },
        mute: 'Silenciar',
        button: 'Eliminar Profanidades',
        audio: {
          export: 'Exportando Audio...',
          analyse: 'Analizando Audio...',
          insert: 'Insertando Bloops...',
          filter: 'Aplicando Ajustes de Volumen...',
        },
        script: {
          transcript: {
            part: 'Transcripción',
          },
        },
      },
    },
    bleep: {
      selection: {
        step: {
          title: 'Seleccionar Efecto de Sonido Bleep',
          helper: 'Selecciona un efecto de sonido para bleep',
        },
      },
    },
    create: {
      format: {
        modal: {
          title: 'Crear Nuevo Formato',
          button: {
            __text__: 'Crear',
            disabled: 'Nombre Tomado',
          },
          name: 'Nombre',
          width: 'Ancho',
          height: 'Alto',
        },
      },
    },
    process: {
      modal: {
        warning:
          'Por favor, evita hacer cambios en DaVinci Resolve para prevenir problemas.',
        generating: {
          emojis: 'Creando emojis a partir de la transcripción...',
        },
        formatting: {
          numbers: 'Formateando números en la transcripción...',
        },
      },
    },
    backup: {
      modal: {
        title: 'Respaldo de AutoCut',
        text: {
          '1': 'Los respaldos ocurren antes del procesamiento. Encuéntralos en la carpeta "AutoCut-Backup".',
          '2': 'Úsalos según sea necesario.',
        },
      },
    },
    confirm: {
      modal: {
        title: '¿Estás Seguro?',
        confirm: {
          label: 'Confirmar',
        },
        cancel: {
          label: 'Cancelar',
        },
      },
    },
    input: {
      speaker: {
        name: {
          placeholder: 'Ingresa el Nombre del Hablante...',
        },
      },
    },
    login: {
      resend: {
        email: '¿No lo recibiste? Reenvía el email a {trialEmail}',
      },
    },
    typeofcreation: {
      option: {
        interview: 'Entrevistas',
        podcast: 'Podcasts',
        youtube: 'Videos de YouTube',
        short: 'TikTok/Shorts/Reels',
        internal: 'Videos Internos',
        other: 'Otro',
      },
    },
    jobstatus: {
      option: {
        content: {
          creator: 'Creador de Contenido',
        },
        video: {
          editor: {
            professional: 'Editor de Video Profesional',
            semi: {
              professional: 'Editor de Video Semi-Profesional',
            },
            beginner: 'Editor de Video Principiante',
          },
        },
        student: 'Estudiante',
        other: 'Otro',
      },
    },
    progress: {
      captions: {
        modal: {
          message: {
            initialization: 'Configurando Subtítulos...',
          },
        },
      },
    },
    stat: {
      undo: {
        modal: '¡Buen trabajo! Completado en {minutes} min {seconds} seg.',
      },
    },
    cancelCutModal: {
      timesaved: {
        this: {
          cut: 'Tiempo Ahorrado: <b>{minutesSaved} {minutesSaved, plural, one {minuto} other {minutos}}</b> y <b>{secondsSaved} {secondsSaved, plural, one {segundo} other {segundos}}</b>.',
        },
        all: {
          cuts: '¡Tiempo Total Ahorrado: Un gran <b>{hourSaved} {hourSaved, plural, one {hora} other {horas}}</b> y <b>{minutesSaved} {minutesSaved, plural, one {minuto} other {minutos}}</b>! 🎉',
        },
      },
    },
    selected: {
      clips: {
        helper:
          'Desactiva las pistas de audio desde la detección de silencios para mantener música de fondo o sonidos de juego. Para dejar los silencios intactos en clips específicos, no selecciones esos clips.',
        video: 'Pistas de Video Seleccionadas:',
        audio: 'Pistas de Audio Seleccionadas:',
      },
    },
    free: {
      trial: {
        usage: {
          exceeded: {
            title: 'Límite de Prueba Gratis Alcanzado',
            cta: 'Suscribirse Ahora',
            text: 'Se excedieron los 1000 minutos de funciones de IA.{br}Suscríbete para continuar usando AutoCut IA.',
          },
        },
      },
    },
    paid: {
      usage: {
        exceeded: {
          title: 'Límite de Transcripción Alcanzado',
          text: 'Se excedieron los 6000 minutos de funciones de IA.{br}Contacta con nosotros para asistencia.',
        },
      },
    },
    validation: {
      error: {
        ai_transitions:
          'Selecciona un tipo de transición válido (Consulta <a><b><embedTranslation>text_transition_step_title</embedTranslation></b></a>).',
        ai_silences:
          'Selecciona una opción válida para el tratamiento de silencios (Consulta <a><b><embedTranslation>title_silences_step</embedTranslation></b></a>).',
        broll: {
          min: {
            max: {
              duration:
                'El tiempo máximo de B-Roll debe ser mayor que el mínimo (Consulta <a><b><embedTranslation>broll_duration_step_title</embedTranslation></b></a>).',
            },
          },
          minimumBRollTime:
            'El tiempo mínimo de B-Roll debe ser positivo (Consulta <a><b><embedTranslation>broll_duration_step_minimum_title</embedTranslation></b></a>).',
          maximumBRollTime:
            'El tiempo máximo de B-Roll debe ser positivo (Consulta <a><b><embedTranslation>broll_duration_step_maximum_title</embedTranslation></b></a>).',
        },
        caption: {
          position:
            'Los subtítulos deben permanecer dentro del área de previsualización (Consulta <a><b><embedTranslation>captions_customization_transform_position</embedTranslation></b></a>).',
          emoji: {
            position:
              'Los emojis deben permanecer dentro del área de previsualización (Consulta <a><b><embedTranslation>captions_customization_transform_position_emoji</embedTranslation></b></a>).',
          },
          text: {
            fontSize:
              'El tamaño de la fuente debe ser un número positivo (Consulta <a><b><embedTranslation>captions_customization_font_size</embedTranslation></b></a>).',
          },
          outline: {
            width:
              'El grosor del contorno debe ser un número positivo (Consulta <a><b><embedTranslation>captions_customization_outline_width</embedTranslation></b></a>).',
          },
          transition: {
            effects:
              'Elige un efecto de transición de subtítulo válido (Consulta <a><b><embedTranslation>captions_customization_transition</embedTranslation></b></a>).',
          },
          emojis: {
            size: 'El tamaño del emoji debe ser positivo (Consulta <a><b><embedTranslation>captions_customization_font_size</embedTranslation></b></a>).',
          },
          formating: {
            nbLines:
              'Al menos una línea debe estar seleccionada para el formato (Consulta <a><b><embedTranslation>captions_customization_formating_nbLines</embedTranslation></b></a>).',
          },
          title: {
            too: {
              short: 'El título debe tener al menos 5 caracteres.',
              long: 'El título no puede exceder los 25 caracteres.',
            },
            no_space:
              'El título debe contener al menos dos palabras separadas por espacio.',
          },
        },
        font: {
          fontFamily:
            'Se requiere familia de fuente (Consulta <a><b><embedTranslation>captions_customization_font_font</embedTranslation></b></a>).',
          label:
            'Selecciona una fuente (Consulta <a><b><embedTranslation>captions_customization_font_font</embedTranslation></b></a>).',
        },
        languageOfTranscription:
          'Selecciona un idioma de transcripción (Consulta <a><b><embedTranslation>language_selection_step_title_helper</embedTranslation></b></a>).',
        cameras: {
          speakerIds:
            'Cada cámara debe tener al menos un hablante asignado (Consulta <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
          label:
            'Cada cámara debe tener una pista de video asignada (Consulta <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
        },
        podcast: {
          minimum: {
            cam: {
              time: 'El tiempo mínimo de cámara debe ser positivo (Consulta <a><b><embedTranslation>text_step1_podcast_minimum</embedTranslation></b></a>).',
            },
          },
          maximum: {
            cam: {
              time: 'El tiempo máximo de cámara debe ser positivo (Consulta <a><b><embedTranslation>text_step1_podcast_maximum</embedTranslation></b></a>).',
            },
          },
          deleteUnusedClips:
            'Elige si remover o silenciar silencios (Consulta <a><b><embedTranslation>title_podcast_silences_step</embedTranslation></b></a>).',
          speakers: {
            cameras:
              'Cada hablante debe estar asignado a una cámara (Consulta <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
            audio:
              'Cada hablante debe tener una pista de audio asignada (Consulta <a><b><embedTranslation>header_speakers_track</embedTranslation></b></a>).',
          },
          cameras: {
            __text__:
              'Cada cámara debe tener su propia pista (Consulta <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
            length:
              'Se requieren al menos dos cámaras separadas (Consulta <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
          },
        },
        resize: {
          autoReframePreset: {
            required:
              'Se requiere una preconfiguración de auto reencuadre (Consulta <a><b><embedTranslation>resize_reframe_step_title</embedTranslation></b></a>).',
            invalid:
              'Opciones de preconfiguración: slower, default o faster (Consulta <a><b><embedTranslation>resize_reframe_step_title</embedTranslation></b></a>).',
          },
          watermark:
            'Formatos válidos de marca de agua: JPEG, PNG o WebP. (Consulta <a><b><embedTranslation>resize_watermark_step_title</embedTranslation></b></a>).',
          preset: {
            name: 'Se requiere el nombre de la preconfiguración.',
            width: 'El ancho debe ser al menos 1px.',
            height: 'El alto debe ser al menos 1px.',
          },
        },
        rgb: 'Los valores RGB deben estar entre 0 y 255.',
        silence: {
          noiseLevel: {
            type: 'El nivel de ruido debe ser un número (Consulta <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>).',
            nonpositive:
              'El nivel de ruido no puede ser positivo (Consulta <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>).',
            integer:
              'El nivel de ruido debe ser un entero (Consulta <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>).',
          },
          minTimeOfSilence: {
            type: 'El tiempo de silencio debe ser un número (Consulta <a><b><embedTranslation>text_Step1_Silences</embedTranslation></b></a>).',
          },
          maxTimeOfSilence: {
            type: 'El tiempo de silencio debe ser un número (Consulta <a><b><embedTranslation>text_Step1_Talks</embedTranslation></b></a>).',
          },
          marginBefore: {
            type: 'El relleno antes debe ser un número (Consulta <a><b><embedTranslation>text_Step2_Before</embedTranslation></b></a>).',
          },
          marginAfter: {
            type: 'El relleno después debe ser un número (Consulta <a><b><embedTranslation>text_Step2_After</embedTranslation></b></a>).',
          },
          transitions:
            'Selecciona un tipo de transición válido (Consulta <a><b><embedTranslation>text_transition_step_title</embedTranslation></b></a>).',
          silences:
            'Selecciona una opción válida para el tratamiento de silencios (Consulta <a><b><embedTranslation>title_silences_step</embedTranslation></b></a>).',
        },
        swearWord: {
          bleepFile:
            'Selecciona un efecto de sonido para bleep (Consulta <a><b><embedTranslation>bleep_selection_step_title_helper</embedTranslation></b></a>).',
        },
        textBox: {
          xPadding:
            'El relleno horizontal debe ser un número (Consulta <a><b><embedTranslation>captions_customization_boxes_padding_x</embedTranslation></b></a>).',
          yPadding:
            'El relleno vertical debe ser un número (Consulta <a><b><embedTranslation>captions_customization_boxes_padding_y</embedTranslation></b></a>).',
          opacity: {
            min: 'La opacidad debe ser al menos 0% (Consulta <a><b><embedTranslation>captions_customization_boxes_opacity</embedTranslation></b></a>).',
            max: 'La opacidad no puede exceder el 100% (Consulta <a><b><embedTranslation>captions_customization_boxes_opacity</embedTranslation></b></a>).',
          },
        },
        anchor:
          'El anclaje debe estar dentro del área de previsualización (Consulta <a><b><embedTranslation>autozoom_anchor_title</embedTranslation></b></a>).',
        zoom: {
          autoZoomMinCoef: {
            min: 'El coeficiente mínimo de zoom debe ser mayor que 1 (Consulta <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
            max: 'El coeficiente mínimo de zoom debe ser menor que 2 (Consulta <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
          },
          autoZoomMaxCoef: {
            min: 'El coeficiente máximo de zoom debe ser mayor que 1 (Consulta <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
            max: 'El coeficiente máximo de zoom debe ser menor que 2 (Consulta <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
          },
          minZoomTime:
            'La duración mínima de zoom debe ser positiva en segundos.',
          maxZoomTime:
            'La duración máxima de zoom debe ser positiva en segundos.',
          dynamicZoomPercentage: {
            min: 'El porcentaje de zoom debe estar por encima del 0%',
            max: 'El porcentaje de zoom debe estar por debajo del 100%',
          },
          coef: 'El coeficiente máximo de zoom debe ser mayor que el mínimo (Consulta <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
          time: 'El tiempo máximo de zoom debe ser mayor que el tiempo mínimo.',
          dynamicMinZoomIntensity:
            'La intensidad del zoom dinámico debe estar entre 0-100% (Consulta <a><b><embedTranslation>autozoom_dynamic_zoom_coverage</embedTranslation></b></a>).',
          smoothZoomPercentage:
            'La cobertura de zoom suave debe estar entre 0-100% (Consulta <a><b><embedTranslation>autozoom_smooth_zoom_coverage</embedTranslation></b></a>).',
          nervousness:
            'El nivel de nerviosismo debe estar entre 0-100% (Consulta <a><b><embedTranslation>autozoom_smooth_zoom_nervousness</embedTranslation></b></a>).',
          totalZoomPercentage:
            'La cobertura total de zoom debe estar entre 0-100% (Consulta <a><b><embedTranslation>autozoom_zoom_coverage</embedTranslation></b></a>).',
          types: {
            at_least: {
              one: {
                true: 'Selecciona al menos un tipo de zoom (Consulta <a><b><embedTranslation>autozoom_zoom_types</embedTranslation></b></a>).',
              },
            },
          },
        },
        repeat: {
          algorithm: 'Algoritmo seleccionado inválido.',
        },
      },
    },
    key: {
      expired: {
        modal: {
          title: 'Problema de Pago',
          button: {
            recover: 'Recuperar Suscripción',
          },
          to_continue:
            'Tu suscripción fue <b>{status}</b> debido a problemas de pago. Para continuar:',
          deactivated: 'desactivada',
          deleted: 'eliminada',
          recover: {
            list: {
              click:
                'Haz clic en el botón abajo para recuperar tu suscripción o revisa tu email para detalles de pago.',
              re_activated:
                'Tu clave será <b>reactivada inmediatamente</b> después de actualizar tu pago.',
            },
          },
          new: {
            list: {
              website:
                'Visita AutoCut: <span>https://www.autocut.com/#pricing</span>',
              subscribe: 'Compra una nueva clave de licencia',
            },
          },
          support:
            '¿Necesitas ayuda? Contáctanos en: <span>contact@autocut.com</span>',
          thanks: '¡Gracias!',
        },
        moda: {
          button: {
            new: 'Comprar Nueva Suscripción',
          },
        },
      },
    },
    gaming: {
      trial: {
        modal: {
          title: 'Inicia Tu Viaje con AutoCut',
          header: {
            days: {
              left: 'Comienza tu prueba de 14 días con {completedElements} funciones adicionales. ¡Quedan {daysLeft} días para explorar!',
            },
            more: {
              days: 'Continúa con <b>{bonusDays} días adicionales</b> para experimentar completamente AutoCut.',
            },
          },
          timeline: {
            left: 'Prueba Gratis de 14 Días',
            right: 'Prueba Gratis de 24 Días',
          },
          task: {
            list: {
              title: 'Progreso de Tareas: {completed}/{maximum} Completadas',
            },
          },
        },
      },
    },
    reload: {
      autocut: {
        modal: {
          title: 'Se Requiere Reinicio',
          cta: 'Cerrar Todas las Extensiones',
          text: 'Se detectaron múltiples instancias de AutoCut. Haz clic abajo para cerrarlas y prevenir problemas.',
        },
      },
    },
    'key-already-activated':
      'La clave está activa en otro dispositivo. Desvincúlala en el panel de AutoCut (https://www.autocut.com/en/) e intenta de nuevo.',
    'key-does-not-exist':
      'Clave no encontrada. Por favor, verifica tu clave e intenta de nuevo.',
    unsupported: {
      version: {
        modal: {
          title: {
            __text__: 'Versión de DaVinci Resolve No Soportada',
            '24.4.0': 'Vuelve a DaVinci Resolve 24.3 o actualiza a 24.5',
          },
          cta: 'Reconocer',
          text: {
            __text__:
              'Se detectó una versión no soportada de DaVinci Resolve ({version}). La experiencia puede verse afectada. Usa la <b>última versión estable</b> para un rendimiento óptimo de AutoCut.',
            '24.4.0':
              'Versión de DaVinci Resolve no soportada ({version}). Actualiza a 24.5 o vuelve a 24.3 para usar AutoCut.',
          },
        },
      },
      rendering: {
        engine: {
          title: 'Motor de Renderizado No Soportado',
          modal: {
            text: 'El motor de renderizado actual no es soportado. AutoCaptions y AutoZoom no funcionarán. {br}{br}Selecciona la aceleración de GPU en las configuraciones del proyecto.',
          },
        },
      },
    },
    handshake: {
      restart: {
        modal: {
          title: 'Reinicio Requerido',
          cta: 'Cerrar Todas las Extensiones',
          text: 'Se detectaron múltiples instancias de AutoCut. Haz clic abajo para cerrarlas y evitar problemas.',
        },
      },
    },
    disabled: {
      features: {
        info: 'La combinación de idioma/fuente seleccionada deshabilita: <b>{features}</b>. Estas funciones no estarán disponibles en los subtítulos.',
        tips: 'Posible problema de soporte de caracteres. Cambia la fuente o el idioma para habilitar estas funciones.',
      },
    },
    upgrade: {
      to: 'Actualizar a {plan}',
    },
    deepgram: {
      error: {
        retry: 'Reintenta la transcripción con un modelo de IA diferente.',
        modal: {
          title: 'Error de Traducción',
          cta: 'Unirse a Discord',
          text: 'Ocurrió un error durante la traducción de audio. Podría deberse a la calidad del audio o al soporte de idioma. {br}Reporta vía Discord o al email contact@autocut.com.',
        },
      },
    },
    completing: {
      preset: {
        preview: 'Finalizando vista previa de preconfiguración... Casi listo.',
      },
    },
    chapters: {
      preview: {
        error:
          'Error al generar la vista previa. Ajusta las marcas de tiempo o reinicia AutoCut si el problema persiste.',
      },
      step: {
        language: 'Seleccionar Clips e Idioma',
        editor: 'Editor de Capítulos',
      },
      button: {
        chapters: 'Añadir Capítulos',
      },
      editor: {
        chapters: {
          title: 'Capítulos Detectados',
        },
        parameters: {
          title: 'Configuraciones de Capítulo',
        },
        download: {
          youtube: {
            chapters: {
              file: {
                download: 'Descargar Capítulos de YouTube',
                success: 'chapter.txt guardado en el proyecto de Premiere Pro',
              },
            },
          },
        },
      },
      invalid: {
        selection: {
          screen: {
            title: 'Selecciona Clips para Base de Capítulos',
            text1:
              'Selecciona al menos un clip con audio para AutoCut Chapter.',
          },
        },
      },
      process: {
        transcript: {
          button: 'Generar Capítulos',
        },
      },
      adding: {
        chapters: 'Añadiendo Capítulos...',
      },
      backup: 'Realizando Respaldo de Tu Secuencia...',
      analyse: 'Analizando Audio...',
      getting: {
        chapters: 'Generando Capítulos...',
      },
      transcript: {
        finished: 'Capítulos Generados',
      },
      disabled: {
        language:
          'El idioma seleccionado no soporta la detección de capítulos.',
        file: {
          too: {
            long: 'Los clips exceden la duración máxima. Usa clips de menos de {limit} minutos.',
          },
        },
      },
    },
    time: {
      badge: {
        error: {
          wrong: {
            format: 'Formato de tiempo incorrecto. Usa HH:MM:SS.',
          },
        },
      },
    },
    emoji: {
      picker: {
        animated: {
          option: 'Animado',
        },
        static: {
          option: 'Estático',
        },
      },
    },
    viral: {
      clips: {
        invalid: {
          selection: {
            screen: {
              title:
                'Selecciona clips de la línea de tiempo para Detección de Clips Virales',
              text1:
                'Selecciona al menos un clip con audio para AutoCut Viral Clips.',
            },
          },
        },
        disabled: {
          language:
            'La Detección de Clips Virales no está soportada en este idioma.',
          file: {
            too: {
              long: 'Los clips son demasiado largos. Selecciona clips de menos de {limit} minutos.',
            },
          },
        },
        process: {
          transcript: {
            button: 'Generar Clips Virales',
          },
        },
        button: {
          process: 'Crear Clips',
        },
        selector: {
          title: 'Detectado {number} Clips Virales Potenciales',
        },
        virality: {
          score: 'Puntuación de Viralidad',
        },
        step: {
          language: 'Seleccionar Clips e Idioma',
          selector: 'Seleccionar Clips Virales',
        },
        backup: 'Realizando Respaldo de Tu Secuencia...',
        analyse: 'Analizando Audio...',
        getting: {
          clips: 'Generando Clips Virales...',
        },
        transcript: {
          finished: 'Clips Virales Generados',
        },
        adding: {
          creating: {
            clips: 'Creando Clips...',
          },
        },
      },
      score: 'Puntuación Viral',
    },
    mode: {
      description: {
        silence: 'Elimina silencios de la línea de tiempo',
        ai: 'Elimina silencios automáticamente de la línea de tiempo',
        podcast: 'Edita podcasts instantáneamente',
        repeat: 'Elimina tomas repetitivas con IA',
        zoom: 'Añade efectos de zoom automáticamente',
        captions: 'Añade subtítulos animados usando IA',
        resize: 'Redimensiona secuencias para diferentes plataformas',
        broll: 'Añade metraje de B-Roll automáticamente con IA',
        profanity: 'Censura lenguaje no deseado automáticamente',
        chapter: 'Detecta y añade marcadores de capítulos automáticamente',
        viral:
          'Identifica clips con potencial viral fácilmente en videos largos',
      },
    },
    formatted: {
      numbers: {
        title: 'Números Formateados',
        modal: {
          cta: 'Cerrar Modal',
          text: '<b>{number} palabras</b> han sido convertidas a números en la transcripción.',
        },
      },
    },
    transcription: {
      provider: {
        step: {
          title: 'Elegir Modelo de Transcripción',
          model: {
            '1': 'Modelo 1 (predeterminado)',
            '2': 'Modelo 2',
          },
        },
      },
    },
  },
} as const;

export default translations;
