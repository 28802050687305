import css from './OnboardingStatModal.module.css';
import {setAutocutStore} from '@autocut/utils/zustand';
import {FaRegHandPaper} from 'react-icons/fa';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {AutoCutApiError} from '@autocut/utils/errors/AutoCutApiError';
import {Button} from '@autocut/components/Button/Button';
import {CustomSelectText} from '@autocut/components/CustomSelectText/CustomSelectText';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {
  OldTranslatedMessage,
  OnlyOldTranslations,
} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import logLevel from '@autocut/enums/logLevel.enum';
import {useLogger} from '@autocut/hooks/useLogger';
import {autocutApi} from '@autocut/utils/http.utils';
import React, {useState} from 'react';
import {useOldIntl} from '@autocut/hooks/useOldIntl';
import {SingleValue} from 'react-select';
import {TagSelection} from '@autocut/components/TagSelection/TagSelection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

const DISCOVER_OPTIONS = [
  'facebook',
  'youtube-video',
  'youtube-comment',
  'reddit',
  'google',
  'friend',
  'school',
  'other',
];

const TYPEOFCREATION_OPTIONS = [
  'interview',
  'podcast',
  'short',
  'youtube',
  'internal',
  'other',
];

const JOBSTATUS_OPTIONS = [
  'content_creator',
  'video_editor_professional',
  'video_editor_semi_professional',
  'student',
  'video_editor_beginner',
  'other',
];

export const OnboardingStatModal = () => {
  const intl = useOldIntl();
  const {logMessage} = useLogger('OnboardingStatModal');

  const [discover, setDiscover] = useState<string | null>(null);
  const [jobStatus, setJobStatus] = useState<string | null>(null);
  const [typeOfCreation, setTypeOfCreation] = useState<string[] | null>(null);

  const handleDiscoverChange = (
    value: SingleValue<{value: string; label: string}>,
  ) => {
    logMessage(logLevel.debug, 'Discover option changed', {value});
    setDiscover(value?.value || null);
  };

  const handleJobStatusChange = (
    value: SingleValue<{value: string; label: string}>,
  ) => {
    logMessage(logLevel.debug, 'Job status option changed', {value});
    setJobStatus(value?.value || null);
  };

  const handleTypeOfCreationChange = (values: string[]) => {
    logMessage(logLevel.debug, 'Type of creation changed', {values});
    setTypeOfCreation(values);
  };

  const handleSendStat = async () => {
    await autocutApi
      .post('/statistics/onboarding-stats', {
        discover,
        jobStatus,
        typeOfCreation,
      })
      .catch((error: AutoCutApiError | IncrementalError) => {
        logMessage(logLevel.error, 'Error while sending onboarding stats', {
          error,
        });

        return {} as never;
      });

    setAutocutStore('user.additionalInformations', s => ({
      ...s.user.additionalInformations,
      displayOnboarding: false,
    }));
    setAutocutStore('ui.openedModalName', '');
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: 'trial_stepper_onboarding',
        defaultMessage: 'Help us to improve AutoCut',
      })}
      icon={<FaRegHandPaper size={24} />}
      footer={
        <Button
          onClickFunction={handleSendStat}
          buttonType="primary"
        >
          <OldTranslatedMessage
            id="button_save"
            defaultMessage="Save"
          />
        </Button>
      }
      closeModalFunction={handleSendStat}
    >
      <FlexContainer
        flexDirection="column"
        gap={20}
      >
        <FlexContainer
          flexDirection="column"
          gap={16}
        >
          <div className={css.subtitle}>
            <OldTranslatedMessage
              id="trial_onboarding_subtitle"
              defaultMessage="To improve the extension and imagine new features, please help us to understand your usage of DaVinci Resolve"
            />
          </div>
          <FlexContainer
            flexDirection="column"
            gap={4}
          >
            <div className={css.inputTitle}>
              <OldTranslatedMessage
                id="trial_onboarding_discover"
                defaultMessage="Where did you discover AutoCut?"
              />
            </div>
            <CustomSelectText
              options={DISCOVER_OPTIONS.map(option => ({
                value: option,
                label: intl.formatMessage({
                  id: `discover-option-${option}` as OnlyOldTranslations,
                }),
              }))}
              handleSelectChange={handleDiscoverChange}
            />
          </FlexContainer>
          <FlexContainer
            flexDirection="column"
            gap={4}
          >
            <div className={css.inputTitle}>
              <OldTranslatedMessage
                id="trial_onboarding_job_status"
                defaultMessage="What is your job status?"
              />
            </div>
            <CustomSelectText
              options={JOBSTATUS_OPTIONS.map(option => ({
                value: option,
                label: intl.formatMessage({
                  id: `jobstatus_option_${option}` as OnlyOldTranslations,
                }),
              }))}
              handleSelectChange={handleJobStatusChange}
            />
          </FlexContainer>
          <FlexContainer
            flexDirection="column"
            gap={4}
          >
            <div className={css.inputTitle}>
              <OldTranslatedMessage
                id="trial_onboarding_type_creation"
                defaultMessage="Type of creations you do on DaVinci Resolve"
              />
            </div>
            <TagSelection
              options={TYPEOFCREATION_OPTIONS.map(option => ({
                value: option,
                label: intl.formatMessage({
                  id: `typeofcreation_option_${option}` as OnlyOldTranslations,
                }),
              }))}
              handleSelectChange={handleTypeOfCreationChange}
            />
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};
