import {OS_MAC, OS_WINDOWS} from '@autocut/constants/constants';
import {isPreloadReady, preload} from '@autocut/types/ElectronPreload';
import {autocutStoreVanilla, setAutocutStore} from '@autocut/utils/zustand';

export const fetchOS = (): typeof OS_MAC | typeof OS_WINDOWS | undefined => {
  if (!isPreloadReady()) {
    return undefined;
  }
  const platform = preload().os.platform();
  if (platform === 'win32') return OS_WINDOWS;
  else if (platform === 'darwin') return OS_MAC;
  return undefined;
};

export const refreshOS = () => {
  const os = fetchOS();
  if (os) {
    setAutocutStore('user.clientInfos.os', os);
  }
  return os;
};

export const getOS = () => {
  //As it's called sooner than the store init we need to catch the error
  try {
    const res = autocutStoreVanilla().user.clientInfos.os;
    if (res) {
      return res;
    }
  } catch (e) {
    return fetchOS();
  }

  //If do not exist we refresh it
  return refreshOS();
};
