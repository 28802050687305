import {setAutocutStore} from '@autocut/utils/zustand';
import React from 'react';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {Slider} from '@autocut/designSystem/components/atoms/Slider/Slider';
import ColorPicker from '@autocut/components/ColorPicker/ColorPicker';
import ParameterLayout from '@autocut/components/ParametersGroupLayout/ParameterLayout';
import {ParametersGroupLayout} from '@autocut/components/ParametersGroupLayout/ParametersGroupLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';

const OutlineCustomization = () => {
  const {enabled, color, width} = useAutoCutStore(
    state => state.ui.parameters.caption.outline,
  );

  return (
    <ParametersGroupLayout
      style={{
        overflow: 'hidden',
      }}
      status={enabled}
      onStatusChange={checked =>
        setAutocutStore('ui.parameters.caption.outline.enabled', checked)
      }
      title={
        <TranslatedMessage
          id={
            'modes_captions_steps_customization_parts_style_modal_outline_title'
          }
          defaultMessage="Outline"
        />
      }
    >
      <ParameterLayout
        title={
          <TranslatedMessage
            id={
              'modes_captions_steps_customization_parts_style_modal_text_color'
            }
            defaultMessage="Color"
          />
        }
      >
        <ColorPicker
          value={color}
          onChange={color =>
            setAutocutStore('ui.parameters.caption.outline.color', color)
          }
        />
      </ParameterLayout>
      <ParameterLayout
        title={
          <TranslatedMessage
            id={
              'modes_captions_steps_customization_parts_style_modal_outline_width'
            }
            defaultMessage="Width"
          />
        }
        wrap={true}
      >
        <Slider
          variant="valueOnLeft"
          value={`${width}`}
          min={0}
          max={100}
          unit="px"
          onChange={value =>
            setAutocutStore('ui.parameters.caption.outline.width', value)
          }
        />
      </ParameterLayout>
    </ParametersGroupLayout>
  );
};

export default OutlineCustomization;
