import {resourcesConfigs} from '@autocut/enums/resources.enum';
import {LaunchStepHandler} from '@autocut/pages/LaunchManager/LaunchManager';
import {ResourceManagerElementConfig} from '@autocut/types/ResourceManager';

export const resourcesSetup: LaunchStepHandler = async setProgress => {
  console.log('resourcesSetup', resourcesConfigs);
  await setProgress?.(0);
  const initResources = Object.keys(resourcesConfigs).filter(
    key =>
      resourcesConfigs[key as keyof typeof resourcesConfigs].scope ===
        'global' &&
      resourcesConfigs[key as keyof typeof resourcesConfigs].requestOnInit,
  );
  return (
    await Promise.all(
      initResources.map(async (key, index) => {
        try {
          const config = resourcesConfigs[
            key as keyof typeof resourcesConfigs
          ] as ResourceManagerElementConfig;
          console.log('config', key);

          const {isExist} = await config.existCheck();
          if (isExist) return true;

          await config.downloadMethod();

          return true;
        } catch (e) {
          console.log('Error while installing resources', e);
          return false;
        } finally {
          await setProgress?.(((index + 1) * 100) / initResources.length);
        }
      }),
    )
  ).reduce((acc, cur) => acc && cur, true);
};
