import React, {useEffect} from 'react';

import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {colors} from '@autocut/designSystem/colors';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {TranscriptLanguageStep} from '@autocut/components/TranscriptLanguageStep/TranscriptLanguageStep';
import {SelectedClipsStep} from '@autocut/components/SelectedClipsStep/SelectedClipsStep';
import {useHandleProcessTranscript} from '@autocut/hooks/useHandleProcessTranscript/useHandleProcessTranscript';

const CaptionsLanguage = () => {
  const {handleProcessTranscript, error, loading} =
    useHandleProcessTranscript('caption');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({isLoading, isDisabled}) => (
            <Button
              onClick={() => handleProcessTranscript()}
              disabled={loading || isLoading || isDisabled}
              color={colors.primary600}
            >
              <TranslatedMessage
                id="modes_captions_steps_language_button"
                defaultMessage="Go to captions editor"
              />
            </Button>
          )}
        />
      }
    >
      <TranscriptLanguageStep modeId="caption" />
      <SelectedClipsStep />
    </ModeLayout>
  );
};

export default CaptionsLanguage;
