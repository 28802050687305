import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {SpeakersStep} from './steps/SpeakersStep/SpeakersStep';
import {CamerasStep} from './steps/CamerasStep/CamerasStep';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {useEffect, useState} from 'react';
import {LoaderInfinity} from '@autocut/components/LoaderInfinity/LoaderInfinity';
import {PodcastFooterButton} from './steps/PodcastFooterButton/PodcastFooterButton';
import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {CameraTimeStep} from './steps/CamTimeStep/CameraTimeStep';
import {SilentVideoClipsStep} from './steps/SilentVideoClipsStep/SilentVideoClipsStep';
import {compute} from '@autocut/utils/compute.utils';

export const PodcastCustomization = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [maxAudioTrackId, setMaxAudioTrackId] = useState(0);
  const [maxVideoTrackId, setMaxVideoTrackId] = useState(0);

  useEffect(() => {
    const fetchTimelineDisposition = async () => {
      const result = await compute.timeline.getDisposition();

      setMaxAudioTrackId(result.maxAudioTrackId);
      setMaxVideoTrackId(result.maxVideoTrackId);

      setIsLoading(false);
    };

    void fetchTimelineDisposition();
  }, []);

  if (isLoading) {
    return (
      <FlexContainer
        alignItems="center"
        justifyContent="center"
      >
        <LoaderInfinity
          isLoading
          height={400}
        />
      </FlexContainer>
    );
  }

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({buttonRef, isLoading, isDisabled}) => (
            <PodcastFooterButton
              ref={buttonRef}
              isLoading={isLoading}
              isDisabled={isDisabled}
            />
          )}
        />
      }
    >
      <FlexContainer
        flexDirection="column"
        gap={Spacing.s4}
      >
        <SpeakersStep maxAudioTrackId={maxAudioTrackId} />
        <CamerasStep maxVideoTrackId={maxVideoTrackId} />
        <CameraTimeStep />
        <SilentVideoClipsStep />
      </FlexContainer>
    </ModeLayout>
  );
};
