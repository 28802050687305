import {useCanvasObject} from '../captionDrawHooks.utils';
import {CaptionCanvasObjectHook} from '../captionHooks.type';
import {useDrawBackground} from './useDrawBackground';

export const useBackgroundCanvasObject: CaptionCanvasObjectHook = args => {
  const drawReturn = useDrawBackground(args);

  return useCanvasObject({
    name: 'background',
    paths: {
      x: 'ui.parameters.caption.position.xPercentage',
      y: 'ui.parameters.caption.position.yPercentage',
      enabled: 'ui.parameters.caption.box.enabled',
    },
    ...drawReturn,
    ...args,
  });
};
