import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {
  AnimatedCanvasType,
  AnimatedCanvas,
} from '@autocut/pages/modes/captions/utils/canvas/classes/animatedCanvas.class.utils';
import {
  DraggableCanvas,
  DraggableCanvasObject,
} from '@autocut/pages/modes/captions/utils/canvas/classes/draggableCanvas.class.utils';
import {CanvasObjectModifier} from '@autocut/pages/modes/captions/utils/canvas/modifier.canvas.utils';

import type {CaptionsParameters} from '@autocut/types/CaptionsParameters';

import {CURRENT_ENV} from '@autocut/utils/currentEnv.utils';
import {ppcm} from '@autocut/utils/math.utils';
import {DeepPartial, PickPartial} from '@autocut/utils/type.utils';
import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {useBackgroundCanvasObject} from './hooks/Draw/Background/useBackgroundCanvasObject';
import {useCaptionsTextMetrics} from './hooks/Draw/captionDrawHooks.utils';
import {useWordBoxesCanvasObject} from './hooks/Draw/HighlightBoxes/useWordBoxesCanvasObject';
import {useTextCanvasObject} from './hooks/Draw/Text/useTextCanvasObject';
import {
  useBlurInCanvasObjectModifier,
  BLUR_IN_ANIMATION_LENGTH_FRAME,
} from './hooks/Modifiers/Floating/useBlurCanvasObjectModifier';
import {
  useFloatingCanvasObjectModifier,
  FLOATING_ANIMATION_LENGTH_FRAME,
} from './hooks/Modifiers/Floating/useFloatingCanvasObjectModifier';
import {
  useZoomInCanvasObjectModifier,
  ZOOM_IN_ANIMATION_LENGTH_FRAME,
} from './hooks/Modifiers/Floating/useZoomCanvasObjectModifier';

const forcedXPercentage = 0.5;
const forcedYPercentage = 0.6;

export const HIGHLIGHT_DURATION_FRAMES = 20;
export const CAPTIONS_PREVIEW_FRAME_RATE = 30;

export const TEXT_PREVIEW_WIDTH = 360;
export const TEXT_PREVIEW_HEIGHT = 60;

const FORCED_X_FACTOR = 10;
const FORCED_Y_FACTOR = 15;

export const ExampleCaptionText = ({
  boundingBox,
  height,
  width,
  debug,
  setCanvas,
  displayedText,
}: {
  boundingBox: {
    width: number;
    height: number;
  };
  height?: number;
  width?: number;
  debug?: boolean;
  setCanvas?: (canvas: AnimatedCanvasType<DraggableCanvas>) => void;
  displayedText?: string;
}) => {
  const {captionsParams} = useAutoCutStore(state => ({
    captionsParams: state.ui.parameters.caption,
  }));

  const xFactor = FORCED_X_FACTOR;
  const yFactor = FORCED_Y_FACTOR;

  const forcedParams: DeepPartial<CaptionsParameters> = {
    text: {fontSize: 500},
    formating: {maxWidth: 90, nbLines: 1},
    position: {
      xPercentage: forcedXPercentage * 100,
      yPercentage: forcedYPercentage * 100,
    },
  };

  const metrics = useCaptionsTextMetrics({
    displayedText,
    xFactor,
    yFactor,
    providedWidth: width,
    ...forcedParams,
  });
  const nbWords = metrics.lines.reduce(
    (sum: any, line: any) => sum + line.nbWords,
    0,
  );
  const highlightAnimation = nbWords * HIGHLIGHT_DURATION_FRAMES;

  const canvasObjectHooksParam = {
    xFactor,
    yFactor,
    metrics,
    debug,
    boundingBox,
    forcedX: forcedXPercentage * boundingBox.width,
    forcedY: forcedYPercentage * boundingBox.height,
  };

  const textWidth =
    width ??
    metrics.textMetrics.width *
      (1 +
        Math.max(
          captionsParams.box.xPadding,
          captionsParams.animations.wordBox.xPadding,
        ) /
          4 /
          100);
  const textHeight =
    height ??
    metrics.textMetrics.height *
      (1.1 +
        Math.max(
          captionsParams.animations.wordBox.yPadding,
          captionsParams.box.yPadding,
        ) /
          2 /
          100);

  const backgroundCanvasObject = useBackgroundCanvasObject({
    ...canvasObjectHooksParam,
    forcedY: forcedYPercentage * textHeight,
    forcedX: forcedXPercentage * textWidth,
    zIndex: 0,
  });
  const textCanvasObject = useTextCanvasObject({
    ...canvasObjectHooksParam,
    forcedY: forcedYPercentage * textHeight,
    forcedX: forcedXPercentage * textWidth,
    zIndex: 9,
  });

  const wordBoxCanvasObject = useWordBoxesCanvasObject({
    ...canvasObjectHooksParam,
    forcedY: forcedYPercentage * textHeight,
    forcedX: forcedXPercentage * textWidth,
    zIndex: 8,
  });
  const floatingTextAnimation = useFloatingCanvasObjectModifier({debug});
  const zoomInAnimation = useZoomInCanvasObjectModifier({
    loop: {enabled: true, loopLengthInFrames: highlightAnimation},
  });
  const blurInAnimation = useBlurInCanvasObjectModifier({
    loop: {enabled: true, loopLengthInFrames: highlightAnimation},
  });

  const floatingTextAnimationDuration = floatingTextAnimation.enabled
    ? FLOATING_ANIMATION_LENGTH_FRAME
    : 0;
  const zoomInAnimationDuration = blurInAnimation.enabled
    ? ZOOM_IN_ANIMATION_LENGTH_FRAME
    : 0;
  const blurInAnimationDuration = blurInAnimation.enabled
    ? BLUR_IN_ANIMATION_LENGTH_FRAME
    : 0;
  const animationDuration = ppcm(
    highlightAnimation,
    floatingTextAnimationDuration,
    zoomInAnimationDuration,
    blurInAnimationDuration,
  );
  const animationDurationSec = animationDuration / CAPTIONS_PREVIEW_FRAME_RATE;

  const allEffectsModifier: CanvasObjectModifier = useCallback(
    c =>
      blurInAnimation.modifier(
        zoomInAnimation.modifier(floatingTextAnimation.modifier(c)),
      ),
    [floatingTextAnimation, zoomInAnimation],
  );

  const objects: PickPartial<
    Omit<DraggableCanvasObject, 'isDragging' | 'id'>,
    'zIndex' | 'forcedX' | 'forcedY'
  >[] = useMemo(
    () => [
      allEffectsModifier(backgroundCanvasObject),
      allEffectsModifier(textCanvasObject),
      allEffectsModifier(wordBoxCanvasObject),
    ],
    [
      allEffectsModifier,
      backgroundCanvasObject,
      textCanvasObject,
      wordBoxCanvasObject,
    ],
  );

  return (
    <DisplayComponent
      objects={objects}
      animationDuration={animationDurationSec}
      height={textHeight}
      width={textWidth}
      debug={debug}
      setCanvas={setCanvas}
    />
  );
};

const DisplayComponent = ({
  objects,
  animationDuration,
  height,
  width,
  debug,
  setCanvas,
}: {
  objects: PickPartial<
    Omit<DraggableCanvasObject, 'isDragging' | 'id'>,
    'zIndex' | 'forcedX' | 'forcedY'
  >[];
  animationDuration: number;
  height: number;
  width: number;
  debug?: boolean;
  setCanvas?: (canvas: AnimatedCanvasType<DraggableCanvas>) => void;
}) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const draggableCanvasRef = useRef<AnimatedCanvasType<DraggableCanvas>>();

  useEffect(() => {
    //To make hot reload works
    if (draggableCanvasRef.current && CURRENT_ENV === 'development')
      draggableCanvasRef.current.start();

    return () => {
      draggableCanvasRef.current?.destroy();
    };
  }, []);

  useEffect(() => {
    if (draggableCanvasRef.current) {
      draggableCanvasRef.current.duration = animationDuration;
    }
  }, [animationDuration]);

  useEffect(() => {
    const currentCanvas = canvasRef.current;
    if (!currentCanvas || !!draggableCanvasRef.current) return;
    const canvas = new (AnimatedCanvas(DraggableCanvas, {
      animation: {
        fps: CAPTIONS_PREVIEW_FRAME_RATE,
        duration: animationDuration,
        loop: true,
      },
      showFps: false,
    }))(canvasRef, debug);
    draggableCanvasRef.current = canvas;

    draggableCanvasRef.current.setObjects(objects);
    draggableCanvasRef.current.start();

    draggableCanvasRef.current.draw();

    if (setCanvas && draggableCanvasRef.current) {
      setCanvas(draggableCanvasRef.current);
    }
  }, [canvasRef.current]);

  useEffect(() => {
    if (!draggableCanvasRef.current) return;

    objects.forEach((object, index) => {
      draggableCanvasRef.current?.updateObjectByIndex(index, object);
    });
  }, [objects]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        maxWidth: width,
        maxHeight: height,
      }}
      width={width}
      height={height}
    />
  );
};
