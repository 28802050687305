import {preload} from '@autocut/types/ElectronPreload';
import {TrackItem, TrackType} from '@autocut/types/JSONTimeline';
import {IncrementalError} from './errors/IncrementalError';
import {AutoCutState, autocutStoreVanilla} from './zustand';

export type HostRequestId = ReturnType<
  ReturnType<typeof preload>['hostNodeIpc']['request']
>['requestId'];

export type HostHandler<OutputType> = Promise<OutputType> & {
  requestId: HostRequestId;
};

type ExportArgs =
  | {inPoint: number; outPoint: number}[]
  | {exportBetweenMarkers?: boolean; inPoint?: number; outPoint?: number};

/* This function is there to check if the current environment is Host or Premiere */
export const isHost = (host: AutoCutState['ui']['host']) => {
  return host === autocutStoreVanilla().ui.host;
};
export const isMac = () => {
  return navigator.userAgent.toUpperCase().indexOf('MAC') >= 0;
};

const hostHandler = <OutputType>(
  path: string,
  data?: {},
): HostHandler<OutputType> => {
  const runRequest = () => {
    console.log(
      ...[
        `[Lua] Requesting "${path}"${data ? 'with data:' : ''}`,
        ...(data ? [data] : []),
      ],
    );
    return preload().hostNodeIpc.request(path, data);
  };

  const request = runRequest();
  const res = request.promise.catch(err => {
    throw new IncrementalError(err.content, err.from);
  }) as HostHandler<OutputType>;
  res.requestId = request.requestId;

  return res;
};

const onlyFor = (hostName: 'ppro' | 'davinci', func: Function) => {
  if (autocutStoreVanilla().ui.host !== hostName) {
    throw new IncrementalError(
      `This function is only available for ${hostName}`,
      'host',
    );
  }

  return func();
};

export const host = {
  misc: {
    isConnected: () => hostHandler<{isConnected: boolean}>('isConnected'),
    close: () => hostHandler<{isConnected: boolean}>('close'),
    version: () =>
      hostHandler<{
        clientVersion: string;
        serverVersion: string;
        hostName: 'ppro' | 'davinci';
      }>('/misc/version'),
    hostVersion: () => hostHandler<number[]>('/misc/host-version'),
    dev: () => hostHandler<string>('/misc/dev'),
    bigData: () => hostHandler<string>('/misc/big-data'),
  },
  timeline: {
    getName: () => hostHandler<string>('/timeline/get-name'),
    getFrameRate: () => hostHandler<number>('/timeline/framerate'),
    getStartFrame: () => hostHandler<number>('/timeline/start-frame'),
    exportFrame: () => hostHandler<string>('/timeline/export-frame'),
    getCurrentTimecode: () =>
      hostHandler<number>('/timeline/get-current-timecode'),
    setCurrentTimecode: (timecode: number) =>
      hostHandler<number>('/timeline/set-current-timecode', timecode),
    getTrackCount: (trackType: TrackType) =>
      hostHandler<number>('/timeline/track-count', {
        track_type: trackType,
      }),
    getInOut: () =>
      hostHandler<{
        inPoint: number;
        outPoint: number;
      }>('/timeline/get-in-out'),
    getTimelineFormat: () =>
      hostHandler<{
        width: `${number}`;
        height: `${number}`;
      }>('/timeline/format'),
    getTrackItemsCount: () =>
      hostHandler<{audio: number; video: number}>(
        '/timeline/get-track-items-count',
      ),
    drt: {
      export: () => hostHandler<string>('/timeline/export'),
      import: (drtPath: string) =>
        hostHandler<void>('/timeline/import', drtPath),
    },
    wav: {
      export: <T extends string | string[]>(data: ExportArgs): Promise<T> =>
        hostHandler<T>('/export-audio/timeline', data),
    },
  },
  track: {
    enable: (trackType: TrackType, trackIndex: number) =>
      hostHandler<boolean>('/track/enable', {
        track_type: trackType,
        track_index: trackIndex,
      }),
    disable: (trackType: TrackType, trackIndex: number) =>
      hostHandler<boolean>('/track/disable', {
        track_type: trackType,
        track_index: trackIndex,
      }),
    isEnabled: (trackType: TrackType, trackIndex: number) =>
      hostHandler<boolean>('/track/is-enabled', {
        track_type: trackType,
        track_index: trackIndex,
      }),
    getTrackItems: (trackType: TrackType, trackIndex: number) =>
      hostHandler<TrackItem[]>('/track/get-track-items', {
        track_type: trackType,
        track_index: trackIndex,
      }),
  },
  mediaPool: {
    createFolders: (folders: string[]) =>
      hostHandler<boolean>('/media-pool/create-folders', {
        folderPaths: folders,
      }),
    deleteFolders: (folders: string[]) =>
      hostHandler<boolean>('/media-pool/delete-folders', {
        folderPaths: folders,
      }),
    isFileExists: (folders: string[], fileName: string) =>
      hostHandler<boolean>('/media-pool/is-file-exists', {
        folderPaths: folders,
        fileName,
      }),
    importFile: (folderPaths: string[], filePath: string) =>
      hostHandler<boolean>('/media-pool/import-file', {
        folderPaths,
        filePath,
      }),
  },
};
