import {AutoCutApiError} from '../errors/AutoCutApiError';
import {IncrementalError} from '../errors/IncrementalError';
import {autocutApi} from '../http.utils';
import {manageError} from '../manageError';
import {EmojiCategory} from './getEmojisCategories';

export const getAnimatedEmojis = async (
  category?: EmojiCategory,
): Promise<
  {
    url: string;
    name: string;
  }[]
> => {
  const endpoint = category
    ? `emojis/animated?category=${category}`
    : 'emojis/animated';

  const {
    data = [],
  }: {
    data: {
      url: string;
      name: string;
    }[];
  } = await autocutApi
    .get(endpoint)
    .catch((error: AutoCutApiError | IncrementalError) => {
      manageError({
        error: new IncrementalError(error, 'getAnimatedEmojis'),
      });

      return {} as never;
    });

  return data;
};
