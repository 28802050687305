import {useClickOutside} from '@autocut/hooks/useClickOutside';
import React from 'react';
import ReactDOM from 'react-dom';

export type PopupPortalProps = {
  target: React.RefObject<HTMLElement> | HTMLElement;
  children: React.ReactNode;
  position?:
    | 'right'
    | 'bottom'
    | 'left'
    | 'top'
    | 'bottom-right'
    | 'top-right'
    | 'bottom-left';
  onClickOutside?: () => void;
  containerStyle?: React.CSSProperties;
};

const PopupPortal = ({
  target,
  children,
  position = 'bottom',
  onClickOutside,
  containerStyle,
}: PopupPortalProps) => {
  const targetElement = 'current' in target ? target.current : target;
  const popupRef = useClickOutside<HTMLDivElement>(
    () => onClickOutside?.(),
    true,
  );

  const targetRect = targetElement?.getBoundingClientRect();
  if (!targetRect) return null;

  let top = targetRect?.top + window.scrollY;
  let left = targetRect?.left + window.scrollX + targetRect?.width / 2;
  let transform = 'translate(0, 0)';

  switch (position) {
    case 'right':
      left = targetRect?.left + window.scrollX + targetRect?.width;
      top = targetRect?.top + window.scrollY + targetRect?.height / 2;
      transform = 'translate(0, -50%)';
      break;
    case 'left':
      left = targetRect?.left + window.scrollX;
      top = targetRect?.top + window.scrollY + targetRect?.height / 2;
      transform = 'translate(-100%, -50%)';
      break;
    case 'top':
      top = targetRect?.top + window.scrollY;
      transform = 'translate(-50%, -100%)';
      break;
    case 'bottom':
      top = targetRect?.top + window.scrollY + targetRect?.height;
      transform = 'translate(-50%, 0)';
      break;
    case 'bottom-right':
      top = targetRect?.top + window.scrollY + targetRect?.height;
      left = targetRect?.left + window.scrollX;
      transform = 'translate(0, 0)';
      break;
    case 'top-right':
      top = targetRect?.top + window.scrollY;
      left = targetRect?.left + window.scrollX;
      transform = 'translate(0, -100%)';
      break;
    case 'bottom-left':
      top = targetRect?.top + window.scrollY + targetRect?.height;
      left = targetRect?.left + window.scrollX;
      transform = 'translate(-100%, 0)';
      break;
  }

  return ReactDOM.createPortal(
    <div
      id="portal"
      ref={popupRef}
      style={{
        ...containerStyle,
        position: 'absolute',
        top,
        left,
        transform,
        height: 'fit-content',
      }}
    >
      {children}
    </div>,
    document.body,
  );
};

export default PopupPortal;
