import axios from 'axios';

import {AUTOCUT_CONSTANTS} from '@autocut/constants/configs';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import json from '../../package.json';
import {CURRENT_ENV} from './currentEnv.utils';
import {AutoCutApiError, TAutoCutApiError} from './errors/AutoCutApiError';

export let accessTokensModule: {
  accessToken: string;
  refreshToken: string;
};
let areInterceptorsInitialized = false;

const cloudflareHeaders =
  !!'__CLOUDFLARE_CLIENT_ID__' && !!'__CLOUDFLARE_CLIENT_SECRET__'
    ? {
        'CF-Access-Client-Id': '__CLOUDFLARE_CLIENT_ID__',
        'CF-Access-Client-Secret': '__CLOUDFLARE_CLIENT_SECRET__',
      }
    : {};

const autocutApi = axios.create({
  baseURL: AUTOCUT_CONSTANTS[CURRENT_ENV].SERVER_ADDRESS,
  headers: {
    'AutoCut-Client': `Extension-${json.version}`,
    ...cloudflareHeaders,
  },
});

export const initInterceptors = () => {
  if (areInterceptorsInitialized) return;

  autocutApi.interceptors.request.use((config: any) => {
    // Modify the request config
    config.headers.Authorization = `Bearer ${accessTokensModule.accessToken}`;
    return config;
  });

  autocutApi.interceptors.response.use(
    response => response,
    async (error: TAutoCutApiError) => {
      const originalRequest = error.config as any;
      const apiError = new AutoCutApiError(error);

      if (
        apiError.status === 403 &&
        originalRequest &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        const newTokens = await refreshAccessToken(
          accessTokensModule.refreshToken,
        );

        originalRequest.headers.Authorization = `Bearer ${newTokens.accessToken}`;
        setTokens(newTokens);

        return axios.request(originalRequest);
      }

      const pathname = originalRequest.url ?? '?';

      return Promise.reject(
        new IncrementalError(apiError, `autocutApi-${pathname}`),
      );
    },
  );

  areInterceptorsInitialized = true;
};

export const setTokens = (accessTokens: {
  accessToken: string;
  refreshToken: string;
}) => {
  accessTokensModule = accessTokens;
};

const refreshAccessToken = async (
  refreshToken: string,
): Promise<{
  accessToken: string;
  refreshToken: string;
}> => {
  try {
    const response = await autocutApi.post(`/auth/refresh`, {
      refreshToken,
    });

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const http = axios;

export {autocutApi, http};
