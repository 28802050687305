import {autocutApi} from '@autocut/utils/http.utils';

export const getCaptionsPresetUploadUrl = async (
  presetTitle: string,
): Promise<{videoUploadUrl: string; thumbnailUploadUrl: string}> => {
  const res = await autocutApi.post(`/captions-presets/uploadUrl`, {
    presetTitle,
  });

  if (res?.status === 201) {
    const {
      videoUploadUrl,
      thumbnailUploadUrl,
    }: {
      videoUploadUrl: string;
      thumbnailUploadUrl: string;
    } = res.data;
    return {videoUploadUrl, thumbnailUploadUrl};
  }

  return {
    videoUploadUrl: '',
    thumbnailUploadUrl: '',
  };
};
