import {useCanvasObject} from '../captionDrawHooks.utils';
import {CaptionCanvasObjectHook} from '../captionHooks.type';
import {useDrawEmoji} from './useDrawEmoji';

export const useEmojiCanvasObject: CaptionCanvasObjectHook = args => {
  const drawReturn = useDrawEmoji(args);

  return useCanvasObject({
    name: 'emoji',
    paths: {
      x: 'ui.parameters.caption.position.emojiXPercentage',
      y: 'ui.parameters.caption.position.emojiYPercentage',
    },
    ...drawReturn,
    ...args,
  });
};
