/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';

import ConfettiOption from './ConfettiOptions/ConfettiOption';
import FireWorksOptions from './ConfettiOptions/FireWorksOption';
import Particles from 'react-tsparticles';
import {loadFull} from 'tsparticles';
import {Container, Engine} from 'tsparticles-engine';

const ConfettiBackground = () => {
  const [options, setOptions] = useState<any>();

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(
    async (container: Container | undefined) => {},
    [],
  );

  useEffect(() => {
    setOptions(
      Math.floor(Math.random() * 2) === 0 ? FireWorksOptions : ConfettiOption,
    );
  }, []);

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={options}
    />
  );
};

export default ConfettiBackground;
