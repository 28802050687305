import {z as zod} from 'zod';

export const rgbValidationSchema = zod.object({
  r: zod
    .number()
    .min(0, 'validation_error_rgb')
    .max(255, 'validation_error_rgb')
    .default(255),
  g: zod
    .number()
    .min(0, 'validation_error_rgb')
    .max(255, 'validation_error_rgb')
    .default(255),
  b: zod
    .number()
    .min(0, 'validation_error_rgb')
    .max(255, 'validation_error_rgb')
    .default(255),
});
