import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {
  getHighlightedWordMetrics,
  drawRoundedRectOnCanvas,
  drawDebugRectangle,
} from '@autocut/pages/modes/captions/utils/canvas/canvas.utils';

import {rgbToHex} from '@autocut/utils/color.utils';
import chroma from 'chroma-js';
import {cloneDeep, isEqual} from 'lodash';
import {useMemo, useRef} from 'react';
import {HIGHLIGHT_DURATION_FRAMES} from '../../../ExampleCaptionText';
import {CaptionDrawHook} from '../captionHooks.type';

export const useDrawWordBoxes: CaptionDrawHook = ({metrics, debug}) => {
  const {params} = useAutoCutStore(state => ({
    params: {
      formating: state.ui.parameters.caption.formating,
      animations: {
        enabled: state.ui.parameters.caption.animations.enabled,
        wordBox: state.ui.parameters.caption.animations.wordBox,
      },
      font: state.ui.parameters.caption.text.font,
      unsupportedFeatures:
        state.ui.parameters.caption.languageOfTranscription.unsupportedFeatures,
    },
  }));

  const isNotSupported = params.unsupportedFeatures?.(params.font).wordBox
    .disabled;
  //Cache management
  const lastDrawnParams = useRef(params);
  const lastDrawnHighlight = useRef(0);

  const {
    textMetrics,
    fontParamObject,
    maxLineWidth,
    lineHeight,
    lines,
    accentSize,
  } = metrics;

  const paddings = useMemo(
    () => ({
      x: params.animations.wordBox.enabled
        ? (params.animations.wordBox.xPadding * fontParamObject.fontSize) / 100
        : 0,
      y: params.animations.wordBox.enabled
        ? (params.animations.wordBox.yPadding * fontParamObject.fontSize) / 100
        : 0,
    }),
    [
      fontParamObject.fontSize,
      params.animations.wordBox.enabled,
      params.animations.wordBox.xPadding,
      params.animations.wordBox.yPadding,
    ],
  );

  const draw = (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    args: {frame: number},
  ) => {
    if (isNotSupported) return;

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    const highlightIndex = Math.floor(args?.frame / HIGHLIGHT_DURATION_FRAMES);

    const leftX = x - maxLineWidth / 2;
    const topY = y - textMetrics.height / 2;

    if (params.animations.enabled && params.animations.wordBox.enabled) {
      lines.map((line: any, lineIndex: number) => {
        // ===== Process positions =====
        // Sequence based position of the center of the text
        const lineXPosition = leftX;
        const lineYPosition =
          topY + // Center top of text block
          lineHeight * lineIndex;
        const index =
          highlightIndex %
          lines
            .map((line: any) => line.value)
            .join(' ')
            .split(' ').length;

        const {highlightedWord} = getHighlightedWordMetrics(
          {index: index, startIndexOfLine: line.startIndex},
          {
            value: line.value,
            position: {x: lineXPosition, y: lineYPosition},
            size: {width: line.width},
            font: fontParamObject,
            uppercase: params.formating.uppercase,
          },
        );

        // ===== Draw text =====
        if (params.animations.wordBox.enabled && highlightedWord.value !== '') {
          const backgroundPaddingX =
            (params.animations.wordBox.xPadding * fontParamObject.fontSize) /
            100;
          const backgroundPaddingY =
            (params.animations.wordBox.yPadding * fontParamObject.fontSize) /
            100;
          const radius =
            (params.animations.wordBox.radius / 100) *
            (fontParamObject.fontSize + backgroundPaddingY);

          const wordBackgroundConfig = {
            color: params.animations.wordBox.color,
            opacity: params.animations.wordBox.opacity,
            position: {
              x:
                highlightedWord.position.x +
                maxLineWidth / 2 -
                backgroundPaddingX,
              y: highlightedWord.position.y - backgroundPaddingY - accentSize,
            },
            size: {
              width: highlightedWord.size.width + 2 * backgroundPaddingX,
              height: lineHeight + 2 * backgroundPaddingY,
            },
            radius,
          };
          drawRoundedRectOnCanvas(ctx, wordBackgroundConfig);
          if (debug) {
            drawDebugRectangle(
              ctx,
              wordBackgroundConfig.position.x,
              wordBackgroundConfig.position.y,
              wordBackgroundConfig.size.width,
              wordBackgroundConfig.size.height,
              chroma(rgbToHex(params.animations.wordBox.color))
                .set('hsl.h', '+180')
                .luminance(0.4)
                .hex(),
            );
          }
        }
      });
    }

    lastDrawnParams.current = cloneDeep(params);
    lastDrawnHighlight.current = highlightIndex;
  };

  const customCacheCheck = (args: {frame: number}) => {
    const highlightIndex = Math.floor(args?.frame / HIGHLIGHT_DURATION_FRAMES);
    return (
      isEqual(lastDrawnParams.current, params) &&
      lastDrawnHighlight.current === highlightIndex
    );
  };

  return {
    draw,
    customCacheCheck,
    drawDebug: () => void 0,
    objectMetrics: {
      width: textMetrics.width + paddings.x * 2,
      height: lineHeight * lines.length + paddings.y * 2,
    },
  };
};
