import {PreviewAudio} from '@autocut/components/PreviewAudio/PreviewAudio';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {useIntl} from 'react-intl';

export const PreviewStep = () => {
  const intl = useIntl();

  return (
    <FormSection
      title={intl.formatMessage({
        id: 'modes_silence_steps_preview_title',
        defaultMessage: 'Preview',
      })}
      description={intl.formatMessage({
        id: 'modes_silence_steps_preview_description',
        defaultMessage:
          'Preview of preserved and removed segments. Use the arrows to move forward or backward, or center the preview based on the time cursor’s position.',
      })}
    >
      <PreviewAudio />
    </FormSection>
  );
};
