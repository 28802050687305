import * as React from 'react';
import {useState} from 'react';

import {AiOutlineStar} from 'react-icons/ai';
import {TiArrowLeft} from 'react-icons/ti';
import {useOldIntl} from '@autocut/hooks/useOldIntl';
import {Rating} from 'react-simple-star-rating';

import reviewIconUrl from '@autocut/assets/images/review.svg';
const reviewIcon = new URL(reviewIconUrl, import.meta.url).href;
import {ModalType} from '@autocut/enums/modals.enum';
import {AutocutModeIds} from '@autocut/enums/modes.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useLogger} from '@autocut/hooks/useLogger';
import {getOS} from '@autocut/utils/system/os.system.utils';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {manageError} from '@autocut/utils/manageError';
import {closeModal} from '@autocut/utils/modal/closeModal';
import {END_MODAL} from '@autocut/utils/process/handleProcessEnd';
import {setAutocutStore} from '@autocut/utils/zustand';
import './ReviewModal.css';
import {AutoCutApiError} from '@autocut/utils/errors/AutoCutApiError';
import {Button} from '@autocut/components/Button/Button';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import styleConfig from '@autocut/components/styleconfig';
import {OldTranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import logLevel from '@autocut/enums/logLevel.enum';
import {autocutApi} from '@autocut/utils/http.utils';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

const ReviewModal = () => {
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');

  const {mode, license} = useAutoCutStore(state => ({
    mode: state.ui.process.mode,
    license: state.user.license,
  }));
  const {logMessage} = useLogger('ReviewModal');
  const intl = useOldIntl();

  const handleReviewChange = async () => {
    if (rating === 0) return;

    const lastLog: string[] = [];

    const postData = {
      stars: rating,
      review: review,
      locale: intl.locale,
      os: getOS(),
      lastLog,
      mode: mode.name,
    };

    logMessage(logLevel.notice, 'Send review to server.', {postData});
    await autocutApi
      .post(`/statistics/review`, postData)
      .catch((error: AutoCutApiError | IncrementalError) => {
        manageError({
          error: new IncrementalError(error, 'ReviewModal'),
        });

        return {} as never;
      });
    logMessage(logLevel.notice, 'Review send to server.');
  };

  const goBack =
    END_MODAL[mode.id as AutocutModeIds] === ModalType.EndProcess
      ? () => setAutocutStore('ui.openedModalName', ModalType.EndProcess)
      : undefined;
  const goAskRate = () =>
    setAutocutStore('ui.openedModalName', ModalType.ExchangeRate);

  const handleClick = async (back = false) => {
    if (!isMessageSent) {
      await handleReviewChange();
    }
    if (license?.type === 'trial' && rating === 5) {
      goAskRate();
    } else if (back && goBack) {
      goBack();
    } else {
      closeModal();
    }
  };

  return (
    <Modal
      title={
        isMessageSent
          ? intl.formatMessage({
              id: 'review_thank_title',
              defaultMessage: 'Thank you for your feedback !',
            })
          : intl.formatMessage({
              id: 'star_review_title',
              defaultMessage: 'Grade AutoCut !',
            })
      }
      icon={
        !isMessageSent && goBack && rating !== 0 ? (
          <TiArrowLeft
            size={40}
            color="#FFFFFF"
          />
        ) : (
          <FlexContainer
            justifyContent="center"
            alignItems="center"
            className="star-icon"
          >
            <AiOutlineStar color="#FFFFFF" />
          </FlexContainer>
        )
      }
      footer={
        !isMessageSent && (
          <Button
            disabled={rating === 0}
            onClickFunction={async () => {
              await handleReviewChange();
              rating === 5 && license?.type === 'trial'
                ? goAskRate()
                : setIsMessageSent(true);
            }}
          >
            <OldTranslatedMessage
              id="review_button"
              defaultMessage="Send"
            />
          </Button>
        )
      }
      closeModalFunction={() => handleClick(false)}
    >
      {!isMessageSent && (
        <>
          {rating <= 0 && (
            <p className="review-text">
              <OldTranslatedMessage
                id="star_review_text"
                defaultMessage={'Tell us what you think about AutoCut !'}
              />
            </p>
          )}

          <FlexContainer className="star-rating">
            <Rating
              initialValue={rating}
              size={28}
              onClick={rate => {
                setRating(rate);
              }}
              fillColor={styleConfig.primaryColor}
              transition
            />
          </FlexContainer>
        </>
      )}

      {rating > 0 && !isMessageSent && (
        <FlexContainer
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <p className="review-text">
            <OldTranslatedMessage
              id="review_text"
              defaultMessage="Have the cuts been good ? Do you have an review to share with us to improve AutoCut ? We're listening to you :"
            />
          </p>
          <textarea
            className="modal-text-area"
            onChange={e => {
              setReview(e.target.value);
            }}
            rows={10}
            placeholder={intl.formatMessage({
              id: 'review_form_placeholder',
              defaultMessage: 'Write your review here..',
            })}
          />
        </FlexContainer>
      )}

      {isMessageSent && (
        <FlexContainer
          flexDirection="row"
          justifyContent="space-between"
        >
          <p className="review-text">
            <OldTranslatedMessage
              id="review_thank_text"
              defaultMessage="We let you continue to cut your videos."
            />
          </p>
          <div className="review_icon_container">
            <img
              src={reviewIcon}
              alt="review"
            />
          </div>
        </FlexContainer>
      )}
    </Modal>
  );
};

export default ReviewModal;
