import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import React, {useState} from 'react';
import {IoCreateOutline} from 'react-icons/io5';
import css from './EditTranscriptModal.module.scss';
import {autocutStoreVanilla, setAutocutStore} from '@autocut/utils/zustand';
import {CaptionChunk} from '@autocut/types/Captions';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {colors} from '@autocut/designSystem/colors';
import {Switch} from '@autocut/designSystem/components/atoms/CheckBox/Variants/Switch/Switch';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {ParametersGroupLayout} from '@autocut/components/ParametersGroupLayout/ParametersGroupLayout';
import {CaptionsTranscriptButtons} from '../CaptionsTranscriptButtons/CaptionsTranscriptButtons';
import {CustomizeChunkCard} from '../CustomizeChunkCard/CustomizeChunkCard';
import {Chunk} from '../Chunk/Chunk';
import {mergeChunks} from '../../../utils/mergeChunks.utils';

export const EditTranscriptModal = () => {
  const {captionsChunks, enableSoundPlay = true} = useAutoCutStore(state => ({
    captionsChunks: state.onGoingProcess.captionChunks,
    enableSoundPlay: state.ui.parameters.caption.enableSoundPlay,
  }));

  const [localeCaptionChunks, setLocaleCaptionChunks] =
    useState(captionsChunks);
  const [selectedChunkIndex, setSelectedChunkIndex] = React.useState<
    null | number
  >(0);
  const [currentTimeout, setCurrentTimeout] = useState<
    NodeJS.Timeout | undefined
  >(undefined);

  if (!localeCaptionChunks) {
    return null;
  }

  const isRtl: boolean =
    autocutStoreVanilla().ui.parameters.caption?.languageOfTranscription
      .textDirection === 'rtl';

  return (
    <Modal
      icon={<IoCreateOutline size="1.5rem" />}
      title={
        <TranslatedMessage
          id={'modes_captions_steps_customization_parts_transcription_title'}
          defaultMessage="Edit transcription"
        />
      }
      closeModalFunction={() => setAutocutStore('ui.openedModalName', '')}
      fullScreen
      footer={
        <Button
          onClick={() => setAutocutStore('ui.openedModalName', '')}
          color={colors.primary600}
        >
          <TranslatedMessage
            id={
              'modes_captions_steps_customization_parts_transcription_modal_save'
            }
            defaultMessage="Save"
          />
        </Button>
      }
      withCloseButton={false}
      closeOnClickOutside={false}
    >
      <FlexContainer
        flexDirection="column"
        gap={8}
      >
        <FlexContainer
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <CaptionsTranscriptButtons
            captionChunks={localeCaptionChunks}
            updateChunks={(captionChunks: CaptionChunk[]) => {
              setLocaleCaptionChunks(captionChunks);
              setAutocutStore('onGoingProcess.captionChunks', captionChunks);
            }}
          />

          {/* TODO: Enable sound play
          <Switch
            checked={enableSoundPlay}
            onChange={value =>
              setAutocutStore('ui.parameters.caption.enableSoundPlay', value)
            }
            size={18}
            leftLabel={
              <TranslatedMessage
                id={'captions_editor_sound_play' as any}
                defaultMessage="Enable sound play"
              />
            }
          /> */}
        </FlexContainer>

        <CustomizeChunkCard
          selectedChunkIndex={selectedChunkIndex}
          chunks={localeCaptionChunks ?? []}
          onChange={newChunks => {
            if (selectedChunkIndex === null) return;

            setLocaleCaptionChunks(newChunks);
            setAutocutStore('onGoingProcess.captionChunks', newChunks);
          }}
        />

        <ParametersGroupLayout
          title={
            <div className={css.title}>
              <TranslatedMessage
                id={
                  'modes_captions_steps_customization_parts_transcription_modal_select'
                }
                defaultMessage="Select captions you want to edit"
              />
            </div>
          }
        >
          <FlexContainer
            flexDirection={isRtl ? 'row-reverse' : 'row'}
            flexWrap="wrap"
            gap={2}
          >
            {(localeCaptionChunks || []).map((chunk, index) => {
              if (!chunk) return null;

              return (
                <Chunk
                  key={index}
                  chunk={chunk}
                  onSelect={async () => {
                    setSelectedChunkIndex(index);

                    if (enableSoundPlay) {
                      // TODO
                      // setCurrentTimeout(
                      //   await playChunk(chunk.start, chunk.end, currentTimeout),
                      // );
                    }
                  }}
                  onMerge={async () => {
                    if (!localeCaptionChunks) return;
                    const newChunks = await mergeChunks(
                      localeCaptionChunks,
                      index,
                    );
                    if (selectedChunkIndex && selectedChunkIndex > index) {
                      setSelectedChunkIndex(selectedChunkIndex - 1);
                    }

                    setLocaleCaptionChunks(newChunks);
                    setAutocutStore('onGoingProcess.captionChunks', newChunks);
                  }}
                  selected={index === selectedChunkIndex}
                  isRtl={isRtl}
                  isLast={index === localeCaptionChunks.length - 1}
                />
              );
            })}
          </FlexContainer>
        </ParametersGroupLayout>
      </FlexContainer>
    </Modal>
  );
};
