import logLevel from '@autocut/enums/logLevel.enum';
import {resourcesConfigs} from '@autocut/enums/resources.enum';
import {
  ResourceManagerElement,
  ResourceManagerElementConfig,
  ResourceManagerState,
} from '@autocut/types/ResourceManager';
import {autocutStoreVanilla, setAutocutStore} from '@autocut/utils/zustand';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';

export type ResourceManagerKeys = keyof typeof resourcesConfigs;

export const getInitialResourceState = () => {
  const baseState = resourcesConfigs;
  const resources: Partial<ResourceManagerState<ResourceManagerKeys>> = {};

  Object.keys(baseState).map(key => {
    const resource = baseState[key as ResourceManagerKeys];
    resources[key as ResourceManagerKeys] = {
      ...resource,
      loading: false,
      required: resource.requestOnInit ? true : false,
      filePath: undefined,
    };
  });

  return resources as ResourceManagerState<ResourceManagerKeys>;
};

export const getResourceKey = (config: ResourceManagerElementConfig) => {
  const resources = autocutStoreVanilla().resources;
  const key = Object.keys(resources).find(
    key => resources[key as ResourceManagerKeys].fileName === config.fileName,
  );
  return key as ResourceManagerKeys;
};

export function getResource(
  config: ResourceManagerElementConfig,
): ResourceManagerElement;
export function getResource(key: ResourceManagerKeys): ResourceManagerElement;
export function getResource(
  configOrKey: ResourceManagerElementConfig | ResourceManagerKeys,
) {
  const resources = autocutStoreVanilla().resources;

  if (typeof configOrKey === 'string') {
    return resources[configOrKey];
  }

  return resources[getResourceKey(configOrKey)];
}

export const waitForResource = async (
  resourceConfig: ResourceManagerElementConfig,
  timeout = 30000,
) => {
  const resourceKey = getResourceKey(resourceConfig);
  setAutocutStore(`resources.${resourceKey}.required`, true);

  await new Promise(async (resolve, reject) => {
    const startTime = Date.now();
    let finished = false;
    do {
      const resource = getResource(resourceKey);
      if (resource.filePath) {
        resolve(resource.filePath);
        finished = true;
      } else if (resource.error) {
        reject(resource.error);
        finished = true;
      } else {
        await new Promise(resolve => setTimeout(resolve, 100));
      }
    } while (Date.now() - startTime < timeout && !finished);
    reject(`Timeout while waiting for resource "${resourceConfig.fileName}"`);
  }).catch(err => {
    throw new IncrementalError(err, 'waitForResource');
  });
};
