import {ZoomTypes} from '@autocut/enums/zoomTypes.enum';
import {z as zod} from 'zod';

const anchorValidationSchema = zod.object({
  xPercentage: zod
    .number()
    .min(0, 'validation_error_anchor')
    .max(1, 'validation_error_anchor'),
  yPercentage: zod
    .number()
    .min(0, 'validation_error_anchor')
    .max(1, 'validation_error_anchor'),
});

export const zoomValidationSchema = zod
  .object({
    autoZoomMinCoef: zod
      .number()
      .min(1, 'validation_error_zoom_autoZoomMinCoef_min')
      .max(2, 'validation_error_zoom_autoZoomMinCoef_max'),
    autoZoomMaxCoef: zod
      .number()
      .min(1, 'validation_error_zoom_autoZoomMaxCoef_min')
      .max(2, 'validation_error_zoom_autoZoomMaxCoef_max'),
    anchorPosMinCoef: anchorValidationSchema,
    anchorPosMaxCoef: anchorValidationSchema,
    totalZoomPercentage: zod
      .number()
      .gt(0, 'validation_error_zoom_totalZoomPercentage')
      .max(1, 'validation_error_zoom_totalZoomPercentage'),
    dynamicZoomPercentage: zod
      .number()
      .min(0, 'validation_error_zoom_dynamicZoomPercentage_min')
      .max(1, 'validation_error_zoom_dynamicZoomPercentage_max'),
    dynamicMinZoomTime: zod
      .number()
      .min(0, 'validation_error_zoom_minZoomTime'),
    dynamicMaxZoomTime: zod
      .number()
      .min(0, 'validation_error_zoom_maxZoomTime'),
    dynamicMinZoomIntensity: zod
      .number()
      .min(0, 'validation_error_zoom_dynamicMinZoomIntensity')
      .max(1, 'validation_error_zoom_dynamicMinZoomIntensity'),
    constraintZoom: zod.boolean(),
    smoothZoomPercentage: zod
      .number()
      .min(0, 'validation_error_zoom_smoothZoomPercentage')
      .max(1, 'validation_error_zoom_smoothZoomPercentage'),
    smoothMinZoomTime: zod.number().min(0, 'validation_error_zoom_minZoomTime'),
    smoothMaxZoomTime: zod.number().min(0, 'validation_error_zoom_maxZoomTime'),
    smoothZoomSpeed: zod.number(),
    nervousness: zod
      .number()
      .min(0, 'validation_error_zoom_nervousness')
      .max(1, 'validation_error_zoom_nervousness'),
    zoomTypes: zod.object({
      [ZoomTypes.DYNAMIC]: zod.boolean(),
      [ZoomTypes.SMOOTH]: zod.boolean(),
    }),
  })
  .refine(
    schema => schema.dynamicMinZoomTime < schema.dynamicMaxZoomTime,
    'validation_error_zoom_time',
  )
  .refine(
    schema => schema.smoothMinZoomTime < schema.smoothMaxZoomTime,
    'validation_error_zoom_time',
  )
  .refine(
    data => Object.values(data.zoomTypes).some(value => value === true),
    'validation_error_zoom_types_at_least_one_true',
  );
