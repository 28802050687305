import {useCallback, useEffect, useState} from 'react';

import {useIntl} from 'react-intl';

import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {debounce} from '@autocut/utils/debounce';
import {autocutStoreVanilla, setAutocutStore} from '@autocut/utils/zustand';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {PaddedSection} from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {Input} from '@autocut/designSystem/components/atoms/Input/Input';

export type SilenceSizeStepProps = {
  modeId?: 'silence';
};

export const SilenceSizeStep = ({modeId = 'silence'}: SilenceSizeStepProps) => {
  const intl = useIntl();
  const {
    minTimeOfSilence: minTimeOfSilenceParam,
    minTimeOfTalk: minTimeOfTalkParam,
  } = useAutoCutStore(state => state.ui.parameters[modeId]);
  const [minTimeOfSilence, setMinTimeOfSilence] = useState(minTimeOfTalkParam);
  const [minTimeOfTalk, setMinTimeOfTalk] = useState(minTimeOfSilenceParam);

  useEffect(() => {
    setMinTimeOfSilence(minTimeOfSilenceParam);
  }, [minTimeOfSilenceParam]);
  useEffect(() => {
    setMinTimeOfTalk(minTimeOfTalkParam);
  }, [minTimeOfTalkParam]);

  const debouncedSetMinTimeOfSilence = useCallback(
    debounce((value: number) => {
      if (
        value !== autocutStoreVanilla().ui.parameters[modeId].minTimeOfSilence
      )
        setAutocutStore(`ui.parameters.${modeId}.minTimeOfSilence`, value);
    }, 1000),
    [],
  );

  const debouncedSetMinTimeOfTalk = useCallback(
    debounce((value: number) => {
      if (value !== autocutStoreVanilla().ui.parameters[modeId].minTimeOfTalk)
        setAutocutStore(`ui.parameters.${modeId}.minTimeOfTalk`, value);
    }, 1000),
    [],
  );

  return (
    <FormSection
      title={intl.formatMessage({
        id: 'modes_silence_steps_silenceSize_title',
        defaultMessage: 'Silence duration',
      })}
    >
      <PaddedSection>
        <FlexContainer
          flexDirection="column"
          gap={Spacing.s2}
        >
          <FormSection
            variant="secondary"
            title={intl.formatMessage({
              id: 'modes_silence_steps_silenceSize_items_silence_title',
              defaultMessage: 'Remove silences longer than',
            })}
            description={intl.formatMessage({
              id: 'modes_silence_steps_silenceSize_items_silence_description',
              defaultMessage:
                'Minimum duration (in milliseconds) a silence must have to be removed.',
            })}
          >
            <Input
              type="number"
              style={{width: '50%'}}
              value={`${minTimeOfSilence * 1000}`}
              onChange={async (value: string) => {
                const numberValue = Number(value) / 1000;
                setMinTimeOfSilence(numberValue);
                await debouncedSetMinTimeOfSilence(numberValue);
              }}
              unit={intl.formatMessage({
                id: 'modes_silence_steps_silenceSize_items_silence_unit',
                defaultMessage: 'milliseconds',
              })}
            />
          </FormSection>
          <FormSection
            variant="secondary"
            title={intl.formatMessage({
              id: 'modes_silence_steps_silenceSize_items_talk_title',
              defaultMessage: 'Remove talks shorter than',
            })}
            description={intl.formatMessage({
              id: 'modes_silence_steps_silenceSize_items_talk_description',
              defaultMessage:
                'Minimum duration (in milliseconds) a talk segment must have to be kept.',
            })}
          >
            <Input
              type="number"
              style={{width: '50%'}}
              value={`${minTimeOfTalk * 1000}`}
              onChange={async (value: string) => {
                const numberValue = Number(value) / 1000;
                setMinTimeOfTalk(numberValue);
                await debouncedSetMinTimeOfTalk(numberValue);
              }}
              unit={intl.formatMessage({
                id: 'modes_silence_steps_silenceSize_items_talk_unit',
                defaultMessage: 'milliseconds',
              })}
            />
          </FormSection>
        </FlexContainer>
      </PaddedSection>
    </FormSection>
  );
};
