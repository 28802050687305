import React from 'react';
import css from './HeaderButton.module.scss';
import {IconType} from 'react-icons';
import {colors} from '@autocut/designSystem/colors';

export const HeaderButton = ({
  colors: bgColors,
  onClick,
  icon,
  forcedIcon = false,
  isMac,
}: {
  colors: {
    mac: string;
    windows: string;
  };
  onClick: () => void;
  icon: IconType;
  forcedIcon?: boolean;
  isMac: boolean;
}) => {
  return (
    <div
      data-variant={isMac ? 'mac' : 'windows'}
      className={css.headerButton}
      style={{
        ...({
          '--color': isMac ? bgColors.mac : bgColors.windows,
          '--active-color': isMac
            ? bgColors.mac
            : bgColors.windows === 'transparent'
              ? colors.gray700
              : bgColors.windows,
          '--display-icon': forcedIcon ? 'flex' : 'none',
          '-webkit-app-region': 'no-drag',
        } as React.CSSProperties),
      }}
      onClick={onClick}
    >
      <div className={css.icon}>
        {React.createElement(icon, {
          size: isMac ? 8 : 14,
          strokeWidth: 2,
          color: isMac
            ? colors.gray700
            : forcedIcon
              ? colors.primary400
              : colors.gray100,
        })}
      </div>
    </div>
  );
};
