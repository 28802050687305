import {config} from '@autocut/constants/configs';
import logLevel from '@autocut/enums/logLevel.enum';
import {addBreadcrumb} from '@sentry/react';
import {CURRENT_ENV, Env} from './currentEnv.utils';
import {manageError} from './manageError';
import {getSentryLogLevel} from './sentry.utils';
import {preload, waitForPreload} from '@autocut/types/ElectronPreload';

const id = config.id;

export const MAX_MB_SIZE_TO_SEND = 25e4; //0.25MB

const LOG_NAME_REGEXP = /^[0-9]{4}-[0-9]{2}-[0-9]{2}.log/;

const getLogName = () => {
  const today = new Date().toISOString().split('T')[0];
  return `${today}.log`;
};

export function getLogPath(id: string): string {
  const file = getLogName();

  const homeDir = preload().os.homedir() || '';
  const platform = preload().os.platform() || '';
  let logPath;

  switch (platform) {
    case 'darwin': {
      logPath = preload().path.join(homeDir, 'Library', 'Logs', id);
      break;
    }

    case 'win32': {
      logPath = preload().path.join(homeDir, 'AppData', 'Roaming', id);
      break;
    }

    case 'linux': {
      logPath = preload().path.join(homeDir, '.logs', id);
      break;
    }

    default:
      logPath = preload().path.join(homeDir, '.logs', id);
      break;
  }

  try {
    if (!preload().fs.existsSync(logPath || '')) {
      preload().fs.mkdirSync(logPath || '');
    }
  } catch (err: any) {
    console.log(logLevel.crit, `Failed command:  + fs.mkdirSync(${logPath})`);
    console.log(logLevel.crit, 'Open ErrorModal for : ' + err.message);
    manageError({
      error: err,
      disableModal: true,
    });
  }

  return preload().path.join(logPath || '', file) || '';
}

export const getLogFolder = () => {
  const homeDir = preload().os.homedir();
  const platform = preload().os.platform();
  let logPath;

  switch (platform) {
    case 'darwin': {
      logPath = preload().path.join(homeDir || '', 'Library', 'Logs', id);
      break;
    }

    case 'win32': {
      logPath = preload().path.join(homeDir || '', 'AppData', 'Roaming', id);
      break;
    }

    case 'linux': {
      logPath = preload().path.join(homeDir || '', '.logs', id);
      break;
    }

    default:
      logPath = preload().path.join(homeDir || '', '.logs', id);
      break;
  }

  try {
    if (!preload().fs.existsSync(logPath || '')) {
      preload().fs.mkdirSync(logPath || '');
    }
  } catch (err: any) {
    console.log(logLevel.crit, `Failed command:  + fs.mkdirSync(${logPath})`);
    console.log(logLevel.crit, 'Open ErrorModal for : ' + err.message);
    manageError({
      error: err,
      disableModal: true,
    });
  }

  return logPath;
};

export function clearPreviousLogs() {
  const logFolder = getLogFolder();
  const files = preload().fs.readdirSync(logFolder || '');

  const todayLogFileName = getLogName();

  (files || []).forEach((file: string) => {
    //We don't want to remove today's log file nor the persistenceStorage.log file
    if (LOG_NAME_REGEXP.test(file) && file !== todayLogFileName) {
      const filePath = preload().path.join(logFolder || '', file);

      if (preload().fs.isFile(filePath || '')) {
        preload().fs.unlinkSync(filePath || '');
      }
    }
  });
}

export function createLogger(logPath?: string) {
  if (!logPath)
    return (
      loggerName: string,
      level: logLevel,
      message: string,
      objects = {},
    ) => console.log(loggerName, level, message, objects);

  return (
    loggerName: string,
    level: logLevel,
    message: string,
    objects = {},
  ) => {
    const log = {
      loggerName: loggerName,
      level: level,
      time: new Date().getTime(),
      message: message,
      objects: objects,
    };
    try {
      if (CURRENT_ENV === Env.Development) {
        if (log?.level === logLevel.warn)
          console.warn(log.loggerName, log.message, log.objects);
        else if (log?.level > logLevel.warn)
          console.error(log.loggerName, log.message, log.objects);
        else console.debug(log.loggerName, log.message, log.objects);
      } else {
        addBreadcrumb({
          message: log.message,
          category: log.loggerName,
          data: log.objects,
          level: getSentryLogLevel(log?.level),
        });
      }
      preload().fs.writeFileSync(logPath, safeJSONStringify(log) + '\n', {
        flag: 'a',
      });
    } catch (err: any) {
      console.log(
        logLevel.crit,
        `Failed command:  + fs.writeFileSync(${logPath}, ${safeJSONStringify(
          log,
        )} + '\n', { flag: 'a' });`,
      );
      console.log(logLevel.crit, 'Open ErrorModal for : ' + err.message);
      manageError({
        error: err,
        disableModal: true,
      });
    }
  };
}

const getLogFileSize = (path: string): number => {
  return preload().fs.statSync(path).size;
};

const chunkLogFile = (filePath: string) => {
  const chunks: string[] = [];
  const log = preload()
    .fs?.readFileSync(filePath, 'utf8')
    .split('\n') as string[];

  let chunk: string[] = [];
  let lastIndex = 0;
  for (let index = 100; index < log.length; index += 100) {
    const element = log.slice(lastIndex, index);

    chunk.push(...element);

    const size = chunk
      .slice()
      .reduce((acc, line) => acc + new TextEncoder().encode(line).length, 0);

    if (size >= MAX_MB_SIZE_TO_SEND) {
      chunk.splice(-100);
      index -= 100;
      chunks.push(chunk.join('\n'));
      chunk = [];
    }

    if (index + 100 >= log.length) {
      chunks.push(log.slice(-(log.length - lastIndex)).join('\n'));
    }

    lastIndex = index;
  }

  return chunks.length > 0 ? chunks : [chunk.join('\n')];
};

// safely handles circular references
const safeJSONStringify = (obj: any, indent = 2) => {
  let cache: any[] = [];
  const retVal = JSON.stringify(
    obj,
    (_, value) =>
      typeof value === 'object' && value !== null
        ? cache.includes(value)
          ? undefined // Duplicate reference found, discard key
          : cache.push(value) && value // Store value in our collection
        : value,
    indent,
  );
  cache = [];
  return retVal;
};

export const logger: any = () => {
  console.warn('Do not use the logger anymore');
  return;
};

export {chunkLogFile, getLogFileSize, safeJSONStringify};
