import {ComponentPropsWithoutRef} from 'react';

export type IconComponentProps = Omit<
  ComponentPropsWithoutRef<'svg'>,
  'xmlns'
> & {size: number};

export const DiscordIcon = ({
  size,
  color,
  children,
  className,
  ...props
}: IconComponentProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      viewBox="0 -28.5 256 256"
      className={className}
      height={`${(size as number) / 16}rem`}
      width={`${(size as number) / 16}rem`}
      color={color || '#5865F2'}
      {...props}
    >
      <path
        fill="currentColor"
        d="M216.8563 16.5966C200.285 8.8433 182.5661 3.2085 164.0416 0c-2.275 4.1132-4.933 9.6455-6.7655 14.0464-19.6921-2.9614-39.2031-2.9614-58.533 0C96.9107 9.6455 94.1925 4.1132 91.897 0a207.809 207.809 0 0 0-52.855 16.6377C5.6175 67.1465-3.4433 116.4008 1.087 164.9557c22.169 16.5552 43.6533 26.612 64.775 33.1929 5.2151-7.1775 9.8663-14.8073 13.8731-22.8483-7.6312-2.8997-14.9402-6.4781-21.8464-10.6323a108.6364 108.6364 0 0 0 5.3558-4.2367c42.1228 19.7019 87.8903 19.7019 129.51 0 1.7517 1.4602 3.5436 2.8793 5.3557 4.2367-6.9265 4.1746-14.2556 7.753-21.8868 10.653 4.0069 8.0203 8.638 15.6708 13.8731 22.848 21.1421-6.581 42.6464-16.6374 64.8153-33.2133 5.3158-56.2875-9.0808-105.0895-38.0556-148.3591ZM85.474 135.0949c-12.6449 0-23.0147-11.8047-23.0147-26.18S72.6076 82.7146 85.474 82.7146c12.8666 0 23.236 11.8043 23.0146 26.2003.02 14.3753-10.148 26.18-23.0146 26.18Zm85.0513 0c-12.6448 0-23.0146-11.8047-23.0146-26.18s10.148-26.2003 23.0146-26.2003c12.8663 0 23.2361 11.8043 23.0147 26.2003 0 14.3753-10.1484 26.18-23.0147 26.18Z"
      />
    </svg>
  );
};
