import {AutoCutApiError} from '@autocut/utils/errors/AutoCutApiError';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {autocutApi} from '@autocut/utils/http.utils';
import {manageError} from '@autocut/utils/manageError';
import {CaptionsPreset} from '@autocut/validationSchema/caption.validationSchema';
import {decodeCaptionParams} from './applyPresets';

export const copyPresetFromPresetId = async (presetId: string) => {
  const res = await autocutApi
    .post(`captions-presets/copy-from-preset-id/${presetId}`)
    .catch((error: AutoCutApiError | IncrementalError) => {
      manageError({
        error: new IncrementalError(error, 'createCaptionsPreset'),
      });

      return null;
    });

  if (res?.status === 201) {
    const captionsPreset: CaptionsPreset[] = res.data.map(
      ({
        id,
        title,
        params,
        videoUrl,
        thumbnailUrl,
      }: {
        id: string;
        title: string;
        params: string;
        videoUrl: string;
        thumbnailUrl: string;
      }) => {
        const decodedParams = decodeCaptionParams(params);
        return {id, title, params: decodedParams, videoUrl, thumbnailUrl};
      },
    );

    return captionsPreset;
  }

  return null;
};
