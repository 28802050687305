import {Font} from '@autocut/types/font';
import {hexToHexOpacity, rgbToHex} from '@autocut/utils/color.utils';
import {range} from '@autocut/utils/math.utils';
import {RgbColor} from 'react-colorful';

//Ensure that you import the font to the document using the loadFontForCanvas function before using this one
export const measureTextOnCanvas = (
  text: string,
  params: CanvasFontParams,
  allCaps: boolean,
) => {
  const textCanvas = document.createElement('canvas');
  const textContext = textCanvas.getContext('2d')!;

  const fontParam = generateCanvasFontParam(params);

  textContext.font = fontParam;
  const metrics = textContext.measureText(allCaps ? text.toUpperCase() : text);

  return {
    canvas: textCanvas,
    metrics: {
      width: metrics.width,
      actualBoundingBoxAscent: metrics.actualBoundingBoxAscent,
      actualBoundingBoxDescent: metrics.actualBoundingBoxDescent,
      height:
        metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent,
      fontBoundingBox:
        metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent,
    },
  };
};

export type CanvasFontParams = {
  italic: boolean;
  fontSize: number;
  fontFamily: string | Font;
};
export const generateCanvasFontParam = ({
  italic,
  fontSize,
  fontFamily,
}: CanvasFontParams) => {
  const fontParamArray: string[] = [];

  if (italic) {
    fontParamArray.push('italic');
  } else {
    fontParamArray.push('normal');
  }

  fontParamArray.push(``);
  fontParamArray.push(`${fontSize}px`);
  fontParamArray.push(`${fontFamily}`);

  const fontParam = fontParamArray.join(' ');
  return fontParam;
};

CanvasRenderingContext2D.prototype.roundRect = function (
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number,
) {
  if (width < 2 * radius) radius = width / 2;
  if (height < 2 * radius) radius = height / 2;
  this.beginPath();
  this.moveTo(Math.floor(x + radius), Math.floor(y));
  this.arcTo(
    Math.floor(x + width),
    Math.floor(y),
    Math.floor(x + width),
    Math.floor(y + height),
    Math.floor(radius),
  );
  this.arcTo(
    Math.floor(x + width),
    Math.floor(y) + height,
    Math.floor(x),
    Math.floor(y + height),
    Math.floor(radius),
  );
  this.arcTo(
    Math.floor(x),
    Math.floor(y + height),
    Math.floor(x),
    Math.floor(y),
    Math.floor(radius),
  );
  this.arcTo(
    Math.floor(x),
    Math.floor(y),
    Math.floor(x + width),
    Math.floor(y),
    Math.floor(radius),
  );
  this.closePath();
  return this;
};

export const getHighlightedWordMetrics = (
  highlight: {
    index: number;
    startIndexOfLine: number;
  },
  text: {
    value: string;
    position: {x: number; y: number};
    size: {width: number};
    font: CanvasFontParams;
    uppercase: boolean;
  },
) => {
  const indexInLine = highlight.index - highlight.startIndexOfLine;

  const words = text.value.split(' ');
  const highlightedWord = words[indexInLine] ?? '';
  const trailingSpace =
    indexInLine > 0 && indexInLine < words.length ? ' ' : ''; //If beforeText or highlightWord is empty, we don't want to add a space before the highlighted word
  const beforeText =
    words.slice(0, Math.max(indexInLine, 0)).join(' ').trim() + trailingSpace; //Math.max to avoid slice(0,-N) which return all except the last N elements
  const startingSpace =
    indexInLine + 1 < words.length && indexInLine + 1 > 0 ? ' ' : ''; //If afterText or highlightWord are empty, we don't want to add a space after the highlighted word
  const afterText =
    startingSpace +
      words
        .slice(Math.max(indexInLine + 1, 0))
        .join(' ')
        .trim() || '';

  const {
    metrics: {
      width: highlightedWordWidth,
      height: highlightedWordHeight,
      actualBoundingBoxAscent: highlightedWordAscentHeight,
      actualBoundingBoxDescent: highlightedWordDescentHeight,
    }, //Do not use the word height, boxes are based on the maximum line height
  } = measureTextOnCanvas(highlightedWord, text.font, text.uppercase);

  const {
    metrics: {
      width: beforeTextWidth,
      height: beforeTextHeight,
      actualBoundingBoxAscent: beforeTextAscentHeight,
      actualBoundingBoxDescent: beforeTextDescentHeight,
    }, //Do not use the word height, boxes are based on the maximum line height
  } = measureTextOnCanvas(beforeText, text.font, text.uppercase);

  const {
    metrics: {
      width: afterTextWidth,
      height: afterTextHeight,
      actualBoundingBoxAscent: afterTextAscentHeight,
      actualBoundingBoxDescent: afterTextDescentHeight,
    }, //Do not use the word height, boxes are based on the maximum line height
  } = measureTextOnCanvas(afterText, text.font, text.uppercase);

  const positionY = text.position.y;

  const beforeTextXPosition = text.position.x - text.size.width / 2;
  const beforeTextYPosition = positionY;

  const highlightedWordXPosition = beforeTextXPosition + beforeTextWidth;
  const highlightedWordYPosition = positionY;

  const afterTextXPosition = highlightedWordXPosition + highlightedWordWidth;
  const afterTextYPosition = positionY;

  return {
    beforeText: {
      value: beforeText,
      position: {x: beforeTextXPosition, y: beforeTextYPosition},
      size: {
        width: beforeTextWidth,
        height: beforeTextHeight,
        ascentHeight: beforeTextAscentHeight,
        descentHeight: beforeTextDescentHeight,
      },
    },
    highlightedWord: {
      value: highlightedWord,
      position: {x: highlightedWordXPosition, y: highlightedWordYPosition},
      size: {
        width: highlightedWordWidth,
        height: highlightedWordHeight,
        ascentHeight: highlightedWordAscentHeight,
        descentHeight: highlightedWordDescentHeight,
      },
    },
    afterText: {
      value: afterText,
      position: {x: afterTextXPosition, y: afterTextYPosition},
      size: {
        width: afterTextWidth,
        height: afterTextHeight,
        ascentHeight: afterTextAscentHeight,
        descentHeight: afterTextDescentHeight,
      },
    },
  };
};

export const drawRoundedRectOnCanvas = (
  ctx: CanvasRenderingContext2D,
  {
    color,
    opacity,
    position,
    size,
    radius,
  }: {
    color: RgbColor;
    opacity: number;
    position: {x: number; y: number};
    size: {width: number; height: number};
    radius: number;
  },
) => {
  ctx.fillStyle = rgbToHex(color, true);
  ctx.globalAlpha = opacity / 100;
  ctx.roundRect?.(position.x, position.y, size.width, size.height, radius);
  ctx.fill();
  ctx.globalAlpha = 1;
};

const defaultOutline = {
  enabled: false,
  width: 0,
  color: '',
};
const defaultGlow = {
  enabled: false,
  color: '',
  intensity: 0,
};

export const drawTextOnCanvas = ({
  context,
  x,
  y,
  text: {value, font, color},
  outline: {
    enabled: outlineEnabled,
    width: outlineWidth,
    color: outlineColor,
  } = defaultOutline,
  glow: {
    enabled: glowEnabled,
    color: glowColor,
    intensity: glowIntensity,
  } = defaultGlow,
  debug,
}: {
  context: CanvasRenderingContext2D;
  x: number;
  y: number;
  text: {
    value: string;
    font: string;
    color: string;
  };
  outline?: {
    enabled: boolean;
    width: number;
    color: string;
  };
  glow?: {
    enabled: boolean;
    color: string;
    intensity: number;
  };
  debug?: boolean;
}) => {
  context.font = font;
  context.fillStyle = color;

  if (outlineEnabled) {
    context.strokeStyle = outlineColor;
    context.lineWidth = outlineWidth;
    context.lineJoin = 'round';
    context.strokeText(value, Math.floor(x), Math.floor(y));
  }

  if (glowEnabled) {
    const intensity = glowIntensity / 250;
    const strokeIntensity = 1 - intensity;

    for (let i = 0; i < intensity; i += 0.05) {
      if (!!strokeIntensity) {
        context.strokeStyle = hexToHexOpacity(
          glowColor,
          strokeIntensity * range(0, 1, 0, 15, strokeIntensity),
        );
        context.lineWidth = 4;
        context.lineJoin = 'round';
      }

      context.shadowColor = hexToHexOpacity(
        glowColor,
        intensity * range(0, 1, 40, 10, intensity),
      );
      context.shadowOffsetX = 0;
      context.shadowOffsetY = 0;
      context.shadowBlur = 15;

      if (!!strokeIntensity)
        context.strokeText(value, Math.floor(x), Math.floor(y));

      context.fillText(value, Math.floor(x), Math.floor(y));
    }
  }

  context.fillText(value, Math.floor(x), Math.floor(y));

  if (debug) {
    const {width} = context.measureText(value);
    const {
      actualBoundingBoxAscent: maxBoundingBoxAscent,
      actualBoundingBoxDescent: maxBoundingBoxDescent,
    } = context.measureText(value + 'Éj');

    context.lineWidth = 2;
    context.strokeStyle = 'green';
    context.strokeRect(
      Math.floor(x - width / 2),
      Math.floor(y - maxBoundingBoxAscent),
      Math.floor(width),
      Math.floor(maxBoundingBoxAscent + maxBoundingBoxDescent),
    );
    context.fillStyle = 'red';
    context.fillRect(
      Math.floor(x),
      Math.floor(y - maxBoundingBoxAscent),
      Math.floor(2),
      Math.floor(maxBoundingBoxAscent + maxBoundingBoxDescent),
    );
    context.fillRect(
      Math.floor(x - width / 2),
      Math.floor(
        y -
          maxBoundingBoxAscent +
          (maxBoundingBoxAscent + maxBoundingBoxDescent) / 2,
      ),
      Math.floor(width),
      2,
    );
  }

  return context;
};

export const splitTextIntoLines = (
  text: string,
  {
    font,
    maxWidth,
    uppercase,
  }: {
    font: CanvasFontParams;
    maxWidth: number;
    uppercase: boolean;
  },
) => {
  const words = text.split(' ');
  const lines = [];

  let currentLine = '';
  let currentLineNbWords = 0;
  let currentIndex = 0;

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    const newLineWidth = measureTextOnCanvas(
      `${currentLine} ${word}`,
      font,
      uppercase,
    ).metrics.width;

    // If the new line width is greater than the max width, we push the current line and start a new one
    if (newLineWidth > maxWidth) {
      if (currentLine === '') {
        const wordMetrics = measureTextOnCanvas(word, font, uppercase).metrics;
        //If the new line is empty, it's only the new word that is too long, so we add it as a line
        lines.push({
          value: word,
          width: wordMetrics.width,
          height: wordMetrics.height,
          nbWords: 1,
          startIndex: currentIndex,
          endIndex: currentIndex,
        });
        currentLine = '';
        currentLineNbWords = 0;
      } else {
        const lineMetrics = measureTextOnCanvas(
          currentLine.trim(),
          font,
          uppercase,
        ).metrics;
        lines.push({
          value: currentLine.trim(),
          width: lineMetrics.width,
          height: lineMetrics.height,
          nbWords: currentLineNbWords,
          startIndex: currentIndex - currentLineNbWords,
          endIndex: currentIndex - 1,
        });
        currentLine = word;
        currentLineNbWords = 1;
      }
    } else {
      currentLine = `${currentLine} ${word}`;
      currentLineNbWords++;
    }
    currentIndex++;
  }

  if (currentLine !== '') {
    const lineMetrics = measureTextOnCanvas(
      currentLine.trim(),
      font,
      uppercase,
    ).metrics;
    //If the last line is empty because the last word was too long, we don't add it
    lines.push({
      value: currentLine.trim(),
      width: lineMetrics.width,
      height: lineMetrics.height,
      nbWords: currentLineNbWords,
      startIndex: currentIndex - currentLineNbWords,
      endIndex: currentIndex - 1,
    });
  }

  return lines;
};

export const drawDebugRectangle = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  color = 'red',
  text?: string,
) => {
  const font = ctx.font;
  ctx.font = '10px Arial';
  const textAlign = ctx.textAlign;
  ctx.textAlign = 'left';
  const fillStyle = ctx.fillStyle;
  ctx.fillStyle = color;
  const strokeStyle = ctx.strokeStyle;
  ctx.strokeStyle = color;
  const textBaseline = ctx.textBaseline;
  ctx.textBaseline = 'middle';
  const lineWidth = ctx.lineWidth;
  ctx.lineWidth = 1;

  const hintText =
    text ??
    `x:${x.toFixed(1)} y:${y.toFixed(1)} (${width.toFixed(1)}x${height.toFixed(
      1,
    )})`;

  const hintWidth = ctx.measureText(hintText).width + 8;
  const hintHeight = 15;

  ctx.strokeStyle = color;
  ctx.lineWidth = 1;
  ctx.strokeRect(
    Math.floor(x),
    Math.floor(y),
    Math.floor(width),
    Math.floor(height),
  );
  ctx.fillStyle = color;
  ctx.fillRect(
    Math.floor(x - 1),
    Math.floor(y - hintHeight),
    Math.floor(hintWidth),
    Math.floor(hintHeight),
  );

  ctx.fillStyle = 'white';
  ctx.fillText(hintText, Math.floor(x + 3), Math.floor(y - 5));

  //RESET
  ctx.font = font;
  ctx.textAlign = textAlign;
  ctx.fillStyle = fillStyle;
  ctx.strokeStyle = strokeStyle;
  ctx.lineWidth = lineWidth;
  ctx.textBaseline = textBaseline;
};

export const isInBounds = (
  x: number,
  y: number,
  object: {x1: number; y1: number; x2: number; y2: number},
) => {
  return x >= object.x1 && x <= object.x2 && y >= object.y1 && y <= object.y2;
};
