export type ExtractArrayType<T extends Array<any>> =
  T extends Array<infer K> ? K : never;

export type NoSymbol<T> = T extends symbol ? string : T;

type NoArray<T> = T extends (infer K)[] ? string : T;
export type GenerateKeys<
  T,
  Delimiter extends string = '.',
  OnlyLeaf = false,
> = {
  [K in keyof T]: NoArray<T[K]> extends object
    ? //@ts-ignore
      | `${NoSymbol<K>}${Delimiter}${GenerateKeys<NoArray<T[K]>, Delimiter, OnlyLeaf>}`
        | (OnlyLeaf extends false ? `${NoSymbol<K>}` : never)
    : `${NoSymbol<K>}`;
}[keyof T];

export type FilteredLiterral<
  Litteral extends string,
  ExcludedPrefix extends string,
> = Litteral extends `${ExcludedPrefix}${string}` ? never : Litteral;
export type RemovePrefix<
  Litteral extends string,
  RemovedPrefix extends string,
> = Litteral extends `${RemovedPrefix}${infer Rest}` ? Rest : Litteral;

// Define a mapped type to get value types based on keys
export type DeepTypeSearch<
  T,
  K extends string,
> = K extends `${infer U}.${infer R}`
  ? U extends keyof T
    ? DeepTypeSearch<T[U], R>
    : never
  : K extends keyof T
    ? T[K]
    : never;

export type IfAny<T, Y, N> = 0 extends 1 & T ? Y : N;

export const isFunction = (value: any): value is Function =>
  typeof value === 'function';

export type IsPromise<T> = T extends Promise<any> ? true : false;

export type Standardize<T> = T extends number
  ? number
  : T extends string
    ? string
    : T extends boolean
      ? boolean
      : {[K in keyof T]: any} extends Record<string, never>
        ? T
        : {[K in keyof T]: Standardize<T[K]>};

export type DeepPartial<T> = T extends number | string | boolean
  ? T
  : {[K in keyof T]: any} extends Record<string, never>
    ? T
    : {[K in keyof T]?: DeepPartial<T[K]>};

export type DeepWriteable<T> = {
  -readonly [P in keyof T]: DeepWriteable<T[P]>;
};

export type PickPartial<Base, Keys extends keyof Base> = Omit<Base, Keys> &
  Partial<Pick<Base, Keys>>;

export type Unshift<T extends [...any]> = T extends [infer K, ...infer R]
  ? R
  : never;
