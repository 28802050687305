import {OnlyOldTranslations} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Unshift} from '@autocut/utils/type.utils';
import {useIntl} from 'react-intl';

export const useOldIntl = () => {
  const intl = useIntl();

  return {
    ...intl,
    formatMessage: (
      descriptor: Omit<Parameters<typeof intl.formatMessage>[0], 'id'> & {
        id: OnlyOldTranslations;
      },
      ...args: Unshift<Parameters<typeof intl.formatMessage>>
    ) => {
      const argsCopy: Parameters<typeof intl.formatMessage> = [
        {
          ...descriptor,
          // @ts-ignore
          id: `old_${descriptor.id}`,
        },
        ...args,
      ];
      console.log(
        'Old translation id used, please migrate it:',
        argsCopy[0].id,
      );
      return intl.formatMessage(...argsCopy) as string;
    },
  };
};
