import {PiWarningCircleBold} from 'react-icons/pi';

import './ErrorModal.css';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {
  IdentifiedErrorKeys,
  IdentifiedErrorType,
} from '@autocut/enums/errors.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {getErrorInfosById} from '@autocut/utils/errors/errors.util';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {TranslationKeys} from '@autocut/contexts/LocaleContext';
import {useIntl} from 'react-intl';
import {preload} from '@autocut/types/ElectronPreload';
import {AUTOCUT_CONSTANTS} from '@autocut/constants/configs';
import {CURRENT_ENV} from '@autocut/utils/currentEnv.utils';

const ErrorModal = () => {
  const intl = useIntl();

  const {errorId} = useAutoCutStore(state => ({
    errorId: state.ui.currentErrorId,
  }));
  const {intlId, link} = getErrorInfosById(
    errorId as IdentifiedErrorKeys,
  ) as IdentifiedErrorType;

  // If a fixable error pop up again, change this
  const isFixable = false;

  const linkTextID = isFixable
    ? 'link_not_supported_modal'
    : 'link_not_supported_modal_no_solution';
  const linkTextDefaultMessage = `To better understand this error${
    isFixable ? 'and how to fix it' : ''
  }, go to `;

  return (
    <Modal
      title={intl.formatMessage({
        id: `errors_modal_title_${intlId}` as TranslationKeys,
        defaultMessage: 'An error has occured.',
      })}
      icon={
        <PiWarningCircleBold
          fill="red"
          size="24"
        />
      }
    >
      <FlexContainer justifyContent="space-between">
        <FlexContainer
          flexDirection="column"
          alignItems="flex-start"
        >
          <p className="error-modal-text">
            {!intlId && (
              <TranslatedMessage
                id={`errors_modal_text_${intlId}` as TranslationKeys}
                defaultMessage={'An error has occured.'}
              />
            )}
            <br />
            <TranslatedMessage
              id={`errors_modal_tips_${intlId}` as TranslationKeys}
              defaultMessage={'Try to change your selection or your settings.'}
            />
          </p>
          {link && (
            <p>
              <TranslatedMessage
                id={linkTextID as TranslationKeys}
                defaultMessage={linkTextDefaultMessage}
              />
              <a
                className={'tuto_link'}
                onClick={async () => {
                  await preload().electron.openLinkInBrowser(
                    AUTOCUT_CONSTANTS[CURRENT_ENV].ACADEMY_ADDRESS[
                      intl.locale.toUpperCase() as 'FR' | 'EN'
                    ] + link,
                  );
                }}
              >
                AutoCut Academy
              </a>
              <TranslatedMessage
                id={'link_not_supported_modal_end' as TranslationKeys}
                defaultMessage={' our documentation and tutorials site'}
              />
            </p>
          )}
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};

export default ErrorModal;
