import * as React from 'react';

import {useOldIntl} from '@autocut/hooks/useOldIntl';

import {uploadFile} from '@autocut/utils/files.utils';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {closeModal} from '@autocut/utils/modal/closeModal';
import {manageError} from '@autocut/utils/manageError';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {AutoCutApiError} from '@autocut/utils/errors/AutoCutApiError';
import {Button} from '@autocut/components/Button/Button';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {OldTranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import logLevel from '@autocut/enums/logLevel.enum';
import {useLogger} from '@autocut/hooks/useLogger';
import {autocutApi} from '@autocut/utils/http.utils';
import {FileInput} from '@autocut/components/FileInput/FileInput';
import ConfettiBackground from '@autocut/components/ConfettiBackground/ConfettiBackground';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

const FeatureRequestModal = () => {
  const {logMessage} = useLogger('FeatureRequestModal');
  const intl = useOldIntl();

  const [files, setFiles] = React.useState<File[]>([]);
  const [featureDescription, setFeatureDescription] = React.useState('');
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const userKey = useAutoCutStore(state => state.user.key);

  const handleSendFeatureRequest = async ({
    featureDescription,
    files,
  }: {
    featureDescription: string;
    files: File[];
  }) => {
    if (!featureDescription.length) return;

    const fileSignedUrls: string[] = [];

    if (files.length) {
      await Promise.all(
        files.map(async file => {
          const fileNameWithTimestamp = `${Date.now()}_${file.name}`;

          const {data: uploadUrl} = await autocutApi.post(
            '/feature-request/uploadUrl',
            {
              fileName: fileNameWithTimestamp,
              fileType: file.type,
            },
          );

          await uploadFile(
            (file as any).path,
            uploadUrl,
            () => {
              return;
            },
            true,
            file.type,
          );

          const {data: signedUrlResponse} = await autocutApi.post(
            '/feature-request/fileUrl',
            {
              fileName: fileNameWithTimestamp,
            },
          );

          fileSignedUrls.push(signedUrlResponse);
        }),
      );
    }

    const postData = {
      userKey,
      featureDescription,
      fileUrls: fileSignedUrls,
    };

    logMessage(logLevel.notice, 'Send feature request to server.', {
      postData,
    });
    await autocutApi
      .post('/feature-request', postData)
      .catch((error: AutoCutApiError | IncrementalError) => {
        manageError({
          error: new IncrementalError(error, 'FeatureRequestModal'),
        });

        setIsError(true);
        return {} as never;
      });
    logMessage(logLevel.notice, 'feature request sent to server.');

    setIsSuccess(true);
  };

  if (isSuccess) return <SuccessModal closeModal={closeModal} />;

  if (isError) return <ErrorModal closeModal={closeModal} />;

  return (
    <Modal
      title={intl.formatMessage({
        id: 'feature_request___text__',
        defaultMessage: 'Feature request',
      })}
      footer={
        <Button
          onClickFunction={async () => {
            logMessage(logLevel.info, 'Open SendDailyLog modal.');
            await handleSendFeatureRequest({
              featureDescription,
              files,
            });
          }}
        >
          <span className="feature-request-modal-button">
            <OldTranslatedMessage
              id="feature_request_button"
              defaultMessage="Send feature request"
            />
          </span>
        </Button>
      }
    >
      <FlexContainer
        className="feature-request-modal"
        flexDirection="column"
        gap="16px"
        alignItems="flex-end"
      >
        <textarea
          className="modal-text-area"
          onChange={e => {
            setFeatureDescription(e.target.value);
          }}
          rows={10}
          placeholder={intl.formatMessage({
            id: 'feature_request_form_placeholder',
            defaultMessage:
              'Enter your feature request. Feel free to explain in detail your request so that we can study it more easily.',
          })}
        />

        <FlexContainer
          className="feature-request-modal-attach-content"
          flexDirection="column"
          gap="16px"
        >
          <OldTranslatedMessage
            id="feature_request_screenshot"
            defaultMessage="You can attach screenshots/videos to help us better understand your feature request:"
          />

          <FileInput
            id="test"
            files={files}
            setFiles={setFiles}
          />
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};

export default FeatureRequestModal;

const SuccessModal = ({closeModal}: {closeModal: () => void}) => {
  const intl = useOldIntl();

  return (
    <Modal
      title={intl.formatMessage({
        id: 'feature_request_success_title',
        defaultMessage: 'Feature request sent! 🥳',
      })}
      closeModalFunction={closeModal}
      extraChildren={<ConfettiBackground />}
    >
      <div className="success-modal-text">
        <OldTranslatedMessage
          id="feature_request_success_body"
          defaultMessage="Thank you for helping AutoCut grow! We will review your request with the most attention."
        />
      </div>
    </Modal>
  );
};

const ErrorModal = ({closeModal}: {closeModal: () => void}) => {
  const intl = useOldIntl();

  return (
    <Modal
      title={intl.formatMessage({
        id: 'feature_request_error_title',
        defaultMessage: 'Oops, something went wrong... 🤕',
      })}
      closeModalFunction={closeModal}
    >
      <div className="error-modal-text">
        <OldTranslatedMessage
          id="feature_request_error_body"
          defaultMessage="We are deeply sorry for the inconvenience. Please trya again later or contact us at contact@autocut.fr."
        />
      </div>
    </Modal>
  );
};
