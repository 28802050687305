export function convertSecondsToHMS(secondsTotal: number, withMillis = false) {
  secondsTotal = withMillis ? secondsTotal : Math.ceil(secondsTotal);
  const hours = ~~(secondsTotal / 3600);
  const minutes = ~~((secondsTotal - hours * 3600) / 60);
  const seconds = secondsTotal - hours * 3600 - minutes * 60;

  let hoursStringed = hours.toString();
  let minutesStringed = minutes.toString();
  let secondsStringed = withMillis ? seconds.toFixed(3) : seconds.toString();

  if (hours < 10) {
    hoursStringed = '0' + hoursStringed;
  }
  if (minutes < 10) {
    minutesStringed = '0' + minutesStringed;
  }
  if (seconds < 10) {
    secondsStringed = '0' + secondsStringed;
  }
  return {
    hours: hoursStringed,
    minutes: minutesStringed,
    seconds: secondsStringed,
  };
}

export function convertSecondsToHMSString(
  secondsTotal: number,
  withMillis = false,
) {
  const {hours, minutes, seconds} = convertSecondsToHMS(
    secondsTotal,
    withMillis,
  );
  return `${hours}:${minutes}:${seconds}`;
}
