import React, {CSSProperties, forwardRef} from 'react';

import css from './Badge.module.scss';
import {Text} from '../Text/Text';
import {BadgeVariant} from './variants';

export type BadgeProps = {
  children: React.ReactNode;
  variant?: BadgeVariant;
  hoverable?: boolean;
  onClick?: () => void;
  color?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  (
    {children, variant, hoverable, onClick, color, style, className, ...props},
    ref,
  ) => (
    <div
      ref={ref}
      data-variant={variant}
      className={`${className} ${css.root} ${hoverable ? css.hoverable : ''}`}
      onClick={onClick}
      style={
        {
          '--badge-color': color,
          ...style,
        } as CSSProperties
      }
      {...props}
    >
      <Text
        variant="textXs.bold"
        color="white"
      >
        {children}
      </Text>
    </div>
  ),
);
