import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {AutocutModes} from '@autocut/enums/modes.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import * as React from 'react';
import {useIntl} from 'react-intl';
import css from './SelectedClips.module.css';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {PaddedSection} from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';

export const SelectedClipsStep = () => {
  const {mode} = useAutoCutStore(state => ({
    // selectionInfos: state.sequence.infos,
    // isSelectionParsing: state.sequence.parsingProcess.isLoading,
    // progressPercent: state.sequence.parsingProcess.progress.percentage,
    mode: state.ui.process.mode,
  }));
  const intl = useIntl();

  return null;

  // return (
  //   <FormSection
  //     title={intl.formatMessage({
  //       id: 'silence_step_selected_clips_title' as any,
  //       defaultMessage: 'Selected clips',
  //     })}
  //     description={
  //       [
  //         AutocutModes.Legacy.id,
  //         AutocutModes.Ai.id,
  //         AutocutModes.Repeat.id,
  //       ].includes(mode.id as any)
  //         ? intl.formatMessage({
  //             id: 'silence_step_selected_clips_helper' as any,
  //             defaultMessage:
  //               "Exclude certain audio tracks from silence detection by deactivating them. This is ideal for retaining background music or game sounds. To keep silences in a specific clip unaltered, simply don't select that clip.",
  //           })
  //         : undefined
  //     }
  //   >
  //     <PaddedSection>
  //       <FlexContainer flexDirection="column" gap={Spacing.s2}>
  //         {/* {selectionInfos &&
  //           selectionInfos.videoTracks &&
  //           selectionInfos.videoTracks.length > 0 && ( */}
  //             {/* <FormSection
  //               variant="secondary"
  //               title={intl.formatMessage({
  //                 id: 'silence_step_selected_clips_video_title' as any,
  //                 defaultMessage: 'Selected video tracks',
  //               })}
  //             >
  //               {(selectionInfos as Sequence).videoTracks.map(track =>
  //                 track.nbClipsSelected ? (
  //                   <TrackSelectionDisplay key={track.id} track={track} />
  //                 ) : null
  //               )}
  //             </FormSection> */}
  //           {/* )} */}
  //         {selectionInfos &&
  //           selectionInfos.audioTracks &&
  //           selectionInfos.audioTracks.length > 0 && (
  //             <FormSection
  //               variant="secondary"
  //               title={intl.formatMessage({
  //                 id: 'silence_step_selected_clips_audio_title',
  //                 defaultMessage: 'Selected audio tracks',
  //               })}
  //             >
  //               {(selectionInfos as Sequence).audioTracks.map(track =>
  //                 track.nbClipsSelected ? (
  //                   <TrackSelectionDisplay
  //                     key={track.id}
  //                     track={track}
  //                     isDeactivable={[
  //                       AutocutModes.Legacy.id,
  //                       AutocutModes.Ai.id,
  //                       AutocutModes.Repeat.id,
  //                     ].includes(mode.id as any)}
  //                   />
  //                 ) : null
  //               )}
  //             </FormSection>
  //           )}
  //       </FlexContainer>
  //     </PaddedSection>
  //     {isSelectionParsing && (
  //       <>
  //         <div className={css.backdropShadow} />
  //         <Progress
  //           progress={progressPercent}
  //           width="80%"
  //           height="16px"
  //           innerText={intl.formatMessage({
  //             id: 'text_noClip_loading_bar',
  //             defaultMessage: 'Loading - Sequence analysis in progress',
  //           })}
  //           style={{
  //             position: 'absolute',
  //             top: '50%',
  //             left: '50%',
  //             transform: 'translate(-50%, -50%)',
  //           }}
  //         />
  //       </>
  //     )}
  //   </FormSection>
  // );

  // return (
  //   <StepLayout
  //     title={intl.formatMessage({
  //       id: 'text_step6_selected_clips',
  //       defaultMessage: 'Selected clips',
  //     })}
  //     helperId={
  //       [
  //         AutocutModes.Legacy.id,
  //         AutocutModes.Ai.id,
  //         AutocutModes.Repeat.id,
  //       ].includes(mode.id as any)
  //         ? 'selected_clips_helper'
  //         : undefined
  //     }
  //     helperDefault={
  //       [
  //         AutocutModes.Legacy.id,
  //         AutocutModes.Ai.id,
  //         AutocutModes.Repeat.id,
  //       ].includes(mode.id as any)
  //         ? "Exclude certain audio tracks from silence detection by deactivating them. This is ideal for retaining background music or game sounds. To keep silences in a specific clip unaltered, simply don't select that clip."
  //         : undefined
  //     }
  //     alwaysOpen
  //   >
  //     <FlexContainer flexDirection="column" gap={8}></FlexContainer>
  //   </StepLayout>
  // );
};
