import React, {useEffect, useRef} from 'react';

export const EditableText = ({
  initialValue,
  onChange,
  disabled = false,
  filteredCharacters = [],
  style,
  className,
}: {
  initialValue: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  filteredCharacters?: string[];
  style?: React.CSSProperties;
  className?: HTMLDivElement['className'];
}) => {
  const textRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!textRef.current) return;

    textRef.current.textContent = initialValue;
  }, [initialValue]);

  const getFilteredValue = (value: string) => {
    if (filteredCharacters.length === 0) return value;

    return value.replace(new RegExp(filteredCharacters.join('|'), 'g'), '');
  };

  return (
    <div
      ref={textRef}
      className={className}
      contentEditable={!disabled}
      onBlur={() => {
        if (!textRef.current) return;
        const newValue = textRef.current.textContent ?? '';
        const filteredValue = getFilteredValue(newValue);

        textRef.current.textContent = filteredValue;
        onChange(filteredValue);
      }}
      onKeyDown={e => {
        if (!textRef.current) return;

        const newValue = textRef.current.textContent ?? '';
        const filteredValue = getFilteredValue(newValue);

        textRef.current.textContent = filteredValue;

        if (e.key === 'Enter') {
          e.preventDefault();

          onChange(filteredValue);
        }
      }}
      style={style}
    />
  );
};
