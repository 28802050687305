import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {useLocale} from '@autocut/hooks/useLocale';
import {preload} from '@autocut/types/ElectronPreload';
import React from 'react';
import {IoWarningOutline} from 'react-icons/io5';

export const FontNotFoundModal = () => {
  const {locale} = useLocale();

  return (
    <Modal
      title={
        <TranslatedMessage
          id="globals_defaults_components_FontNotFoundModal_title"
          defaultMessage="Font not found"
        />
      }
      icon={<IoWarningOutline color="#E25D2A" />}
    >
      <>
        <TranslatedMessage
          id="globals_defaults_components_FontNotFoundModal_description"
          defaultMessage="The font was not found on your system, please select a new font to add captions."
        />
        <br />
        <a
          style={{fontSize: '10px', color: 'inherit'}}
          href="#"
          onClick={() =>
            void preload().electron.openLinkInBrowser(
              `https://www.autocut.fr/${locale.toLocaleLowerCase()}/academy/autocut-parameters/captions-fonts/`,
            )
          }
        >
          <TranslatedMessage
            id="globals_defaults_components_FontNotFoundModal_help"
            defaultMessage="You can add a new font family by following this tutorial."
          />
        </a>
      </>
    </Modal>
  );
};
