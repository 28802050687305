import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

import {CaptionChunk, CaptionChunkHighlight} from '@autocut/types/Captions';
import React, {CSSProperties, useState} from 'react';

import css from './EditableWord.module.scss';

import {FiPlusCircle} from 'react-icons/fi';
import {FiTrash} from 'react-icons/fi';
import {EditableText} from '@autocut/components/EditableText/EditableText';
import PopupPortal from '@autocut/designSystem/components/atoms/PopupPortal/PopupPortal';
import {InfoText} from '@autocut/components/InfoText/InfoText';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';

export const EditableWord = ({
  highlight,
  onTextChange,
  onSplit,
  onAddWord,
  onRemoveWord,
  isLast,
  isRtl,
}: {
  chunk: CaptionChunk;
  highlight: CaptionChunkHighlight;
  onTextChange: (word: string) => void;
  onSplit: () => Promise<void>;
  onAddWord: () => Promise<void>;
  onRemoveWord: () => Promise<void>;
  isLast: boolean;
  isRtl: boolean;
}) => {
  const iconRef = React.useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  const iconAnimationVariables = isRtl
    ? {
        '--margin-left': '6px',
        '--margin-right': '0',
      }
    : {
        '--margin-left': '0',
        '--margin-right': '6px',
      };

  return (
    <FlexContainer
      flexDirection={isRtl ? 'row-reverse' : 'row'}
      gap={4}
      alignItems="center"
      className={css.word}
    >
      <EditableText
        initialValue={highlight.word}
        onChange={newValue => onTextChange(newValue)}
        filteredCharacters={['\n', '\r']}
        style={
          highlight.word.length
            ? undefined
            : {
                border: '1px solid white',
                opacity: 0.3,
                borderRadius: '4px',
                minWidth: '20px',
              }
        }
      />

      {!isLast && (
        <div
          ref={iconRef}
          className={css.splitIcon}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={async () => {
            setIsHovered(false);
            await onSplit();
          }}
          style={iconAnimationVariables as CSSProperties}
        >
          /
          {isHovered && (
            <PopupPortal
              target={iconRef}
              position="bottom"
              containerStyle={{
                cursor: 'pointer',
              }}
            >
              <InfoText
                style="info"
                hideHelper
              >
                <TranslatedMessage
                  id={
                    'modes_captions_steps_customization_parts_transcription_tooltips_split'
                  }
                  defaultMessage="Split chunks"
                />
              </InfoText>
            </PopupPortal>
          )}
        </div>
      )}

      <div className={css.menu}>
        {highlight.word.length !== 0 && (
          <MenuOption
            text="Add word"
            icon={
              <FiPlusCircle
                size={16}
                color="currentColor"
              />
            }
            onClick={onAddWord}
          />
        )}
        <MenuOption
          text="Remove word"
          icon={
            <FiTrash
              size={16}
              color="currentColor"
            />
          }
          onClick={onRemoveWord}
        />
      </div>
    </FlexContainer>
  );
};

const MenuOption = ({
  text,
  icon,
  onClick,
}: {
  text: React.ReactNode;
  icon: React.ReactNode;
  onClick: () => void;
}) => {
  return (
    <FlexContainer
      className={css.option}
      flexDirection="row"
      flexWrap="nowrap"
      gap={4}
      alignItems="center"
      onClick={onClick}
    >
      <div
        style={{
          width: '20px',
          height: '20px',
        }}
      >
        {icon}
      </div>
      {text}
    </FlexContainer>
  );
};
